import {
  STATUS_COMMENTS_GLOBAL,
  SET_COMMENTS_GLOBAL,
  SET_COMMENTS_GLOBAL_STATE_SUCCESS,
  SET_COMMENTS_GLOBAL_API_SUCCESS,
  GET_COMMENTS_GLOBAL,
  GET_SINGLE_COMMENT,
  GET_SINGLE_COMMENT_SUCCESS,
  FETCH_COMMENTS_GLOBAL_SUCCESS,
  GET_COMMENTS_FEED_GLOBAL,
  GET_COMMENTS_FEED_GLOBAL_SUCCESS,
  GET_LIKES_INFO_USERS,
  FETCH_LIKES_INFO_USERS_SUCCESS,
  LOAD_COMMENTS_GLOBAL,
  LOAD_COMMENTS_GLOBAL_SUCCESS,
  UPDATE_COMMENTS_GLOBAL,
  UPDATE_COMMENTS_GLOBAL_SUCCESS,
  DELETE_COMMENTS_GLOBAL,
  DELETE_COMMENTS_GLOBAL_SUCCESS,
  SET_COMMENTS_GLOBAL_REPLY_ID,
  SET_OLD_COMMENT_ID,
  LIKE_DISLIKE_COMMENTS_GLOBAL,
  LAST_COMMENTS_GLOBAL,
  LAST_COMMENTS_GLOBAL_SUCCESS,
  SET_LOADER_IMAGE_QUILL,
  SET_FOUND_COMMENT,
} from 'shared/providers/redux/actionTypes';

export const setCommentGlobal = ({
  commentableType,
  commentableId,
  comment,
  parent,
  mentions,
  images,
}) => ({
  type: SET_COMMENTS_GLOBAL,
  payload: {
    commentableType,
    commentableId,
    comment,
    parent,
    mentions,
    images,
  },
});

export const setCommentGlobalStateSuccess = (comments, total) => ({
  type: SET_COMMENTS_GLOBAL_STATE_SUCCESS,
  payload: { comments, total },
});

export const setCommentGlobalApiSuccess = (comments, total) => ({
  type: SET_COMMENTS_GLOBAL_API_SUCCESS,
  payload: { comments, total },
});

export const setOldCommentId = (id) => ({
  type: SET_OLD_COMMENT_ID,
  payload: { id },
});

export const updateCommentGlobal = ({
  commentableType,
  commentableId,
  comment,
}) => ({
  type: UPDATE_COMMENTS_GLOBAL,
  payload: {
    commentableType,
    commentableId,
    comment,
  },
});

export const updateCommentGlobalSuccess = (comment) => ({
  type: UPDATE_COMMENTS_GLOBAL_SUCCESS,
  payload: comment,
});

export const getCommentsGlobal = ({
  commentableType,
  commentableId,
  page,
}) => ({
  type: GET_COMMENTS_GLOBAL,
  payload: { commentableType, commentableId, page },
});

export const setFoundComment = (found) => ({
  type: SET_FOUND_COMMENT,
  payload: { found },
});

export const getSingleComment = (commentableType, commentableId, id) => ({
  type: GET_SINGLE_COMMENT,
  payload: { commentableType, commentableId, id },
});

export const getSingleCommentSuccess = (comment) => ({
  type: GET_SINGLE_COMMENT_SUCCESS,
  payload: { comment },
});

export const getCommentsGlobalSuccess = (data) => ({
  type: FETCH_COMMENTS_GLOBAL_SUCCESS,
  payload: data,
});
export const getCommentsGlobalFeed = ({
  commentableType,
  commentableId,
  page,
}) => ({
  type: GET_COMMENTS_FEED_GLOBAL,
  payload: { commentableType, commentableId, page },
});

export const getCommentsGlobalFeedSuccess = (data) => ({
  type: GET_COMMENTS_FEED_GLOBAL_SUCCESS,
  payload: data,
});
export const loadCommentsGlobal = ({ commentableType, commentableId }) => ({
  type: LOAD_COMMENTS_GLOBAL,
  payload: { commentableType, commentableId },
});

export const loadCommentsGlobalSuccess = (data) => ({
  type: LOAD_COMMENTS_GLOBAL_SUCCESS,
  payload: data,
});

export const deleteCommentGlobal = ({
  commentableType,
  commentableId,
  commentId,
}) => ({
  type: DELETE_COMMENTS_GLOBAL,
  payload: { commentableType, commentableId, commentId },
});

export const deleteCommentGlobalSuccess = (comments, total) => ({
  type: DELETE_COMMENTS_GLOBAL_SUCCESS,
  payload: { comments, total },
});

export const setReplyId = (id) => ({
  type: SET_COMMENTS_GLOBAL_REPLY_ID,
  payload: id,
});

export const setLikeDislikeCommentGlobal = ({
  commentableType,
  commentableId,
  commentId,
  option,
}) => ({
  type: LIKE_DISLIKE_COMMENTS_GLOBAL,
  payload: {
    commentableType,
    commentableId,
    commentId,
    option,
  },
});

export const getLikesInfoUsers = ({ likeableType, likeableId }) => ({
  type: GET_LIKES_INFO_USERS,
  payload: {
    likeableType,
    likeableId,
  },
});

export const fetchLikesInfoUsersSuccess = (likes) => ({
  type: FETCH_LIKES_INFO_USERS_SUCCESS,
  payload: likes,
});

export const setStatusCommentsGlobal = (status) => ({
  type: STATUS_COMMENTS_GLOBAL,
  payload: status,
});

export const lastCommentsGlobal = ({
  commentableType,
  commentableId,
  page,
}) => ({
  type: LAST_COMMENTS_GLOBAL,
  payload: { commentableType, commentableId, page },
});

export const lastCommentsGlobalSuccess = (data) => ({
  type: LAST_COMMENTS_GLOBAL_SUCCESS,
  payload: data,
});

// React Quill
export const setLoadingImageQuill = (loader) => ({
  type: SET_LOADER_IMAGE_QUILL,
  payload: loader,
});
