import {
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS,
  CREATE_REVIEW,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  CHANGE_REVIEW_SUCCESS_STATE,
  CHANGE_DELETED_REVIEW_STATE,
  CLEANING_NEWLY_CREATED_REVIEWS,
} from 'shared/providers/redux/actionTypes';

export const fetchReviews = (contentable_type, contentable_id, page = 1, per_page = 5, rate = null) => ({
  type: GET_REVIEWS,
  payload: { contentable_type, contentable_id, page, per_page, rate },
});

export const fetchReviewsSuccess = reviews => ({
  type: GET_REVIEWS_SUCCESS,
  payload: reviews,
});

export const createReview = (contentable_type, contentable_id, rate, description) => {
  return {
    type: CREATE_REVIEW,
    payload: { contentable_type, contentable_id, rate, description },
  };
};

export const createReviewSuccess = review => {
  return {
    type: CREATE_REVIEW_SUCCESS,
    payload: review,
  }
}

export const deleteReview = (reviewId, contentable_type, contentable_id, stateArray = "reviews") => {
  return {
    type: DELETE_REVIEW,
    payload: { reviewId, contentable_type, contentable_id, stateArray },
  };
};

export const deleteReviewSuccess = (response, reviewId, stateArray) => {
  return {
    type: DELETE_REVIEW_SUCCESS,
    payload: { response, reviewId, stateArray },
  }
}

export const changeReviewSuccessState = () => {
  return {
    type: CHANGE_REVIEW_SUCCESS_STATE,
  };
}

export const cleaningNewlyCreatedReviews = () => {
  return {
    type: CLEANING_NEWLY_CREATED_REVIEWS,
  };
}

export const changeDeletedReviewState = () => {
  return {
    type: CHANGE_DELETED_REVIEW_STATE,
  };
}
