import React from 'react';
import { Spin } from 'antd';
import { Container } from './styles';

const AppLoader = () => (
    <Container data-testid="app-component">
      <Spin size="large" />
    </Container>
  );

export default AppLoader;
