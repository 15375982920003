import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function PageRoutes({ component: Component, location, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
}

PageRoutes.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
};

export default PageRoutes;
