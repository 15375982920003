import { UserOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import Alert from 'shared/components/molecules/Alert';
import {
  forgotPassword,
  hideAuthMessage,
} from 'shared/providers/redux/actions';
import { validateEmail } from 'shared/utils/regex';
import SuccessMessage from './components/SuccessMessage';

import { Container, Form } from './styles';

import { emailProviderValidator } from '../../../../../utils/emailProviderValidator';
import EmailInvalid from '../../components/EmailInvalid';
import Input from '../../components/Input';
import NavigateBackModal from '../../components/NavigateBackModal';

const FormItem = Form.Item;

const ForgotPassword = ({
  navigate,
  forgotPassword,
  alertMessage,
  messageSuccess,
  showMessage,
  hideAuthMessage,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);

  const [userEmail, setUserEmail] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [loadSpin, setLoadSpin] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const { settings } = useSelector(({ tenancy }) => tenancy?.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const isResetPasswordPath = window.location.pathname === '/resetpassword';

  const { users, site } = settings;
  const theme = settings['general-theme'];

  const resetPasswordImageStatus = users?.userResetPasswordImage;
  const resetPasswordImage = users?.userResetPasswordCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  useEffect(() => {
    setLoadSpin(false);
    if (showMessage) {
      setMessage(alertMessage);

      dispatch(hideAuthMessage());
    }
    // eslint-disable-next-line
  }, [showMessage, hideAuthMessage, alertMessage]);

  useEffect(() => {
    if (messageSuccess) {
      setSuccessMessage(messageSuccess);
    }
    // eslint-disable-next-line
  }, [messageSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadSpin(true);

    setUserEmail(emailValue);
    forgotPassword(emailValue);
  };

  return (
    <>
      <NavigateBackModal page="login" navigate={navigate} />
      <Container isDarkMode={isDarkMode}>
        <div className="col">
          {!isResetPasswordPath && (
            <div
              className="gx-login-header gx-text-center"
              style={{ marginBottom: 0 }}
            >
              {resetPasswordImageStatus === 'none' || platformHasNoImage ? (
                <h1 className="siteName">{site?.siteName}</h1>
              ) : isDarkMode ? (
                <img
                  src={theme?.themeDarkModeLogoHorizontal || defaultImage}
                  alt="logo"
                />
              ) : resetPasswordImageStatus === 'default' ? (
                <img src={defaultImage || fallbackImage} alt="logo" />
              ) : (
                <img
                  src={resetPasswordImage || fallbackImage || defaultImage}
                  alt="logo"
                />
              )}
            </div>
          )}

          <div className="titulos">
            <h1>{users?.userResetPasswordTitle}</h1>
            <h2>{users?.userResetPasswordDescriptionTop}</h2>
          </div>

          {loadSpin && <Spin />}

          {message && !successMessage && (
            <Alert message={message} error onClose={() => setMessage(null)} />
          )}
          {successMessage ? (
            <>
              <SuccessMessage
                title={intl?.formatMessage({
                  id: 'authModal.forgot.aMensageWasSent',
                })}
                msg1={intl?.formatMessage({
                  id: 'authModal.forgot.openTheMessage',
                })}
                email={userEmail}
                msg2={intl?.formatMessage({
                  id: 'authModal.forgot.andClickTheLink',
                })}
              />
            </>
          ) : (
            <Form theme={theme} isDarkMode={isDarkMode} onSubmit={handleSubmit}>
              <FormItem>
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={emailValue.replace(' ', '')}
                  isDarkMode={isDarkMode}
                  onChange={(e) => setEmailValue(e.target.value)}
                  onBlur={() => {
                    if (emailValue.length) {
                      if (
                        validateEmail(emailValue) &&
                        emailProviderValidator(emailValue)
                      ) {
                        setEmailInvalid(false);
                      } else {
                        setEmailInvalid(true);
                      }
                    }
                  }}
                  onFocus={() => {
                    setEmailInvalid(false);
                  }}
                  placeholder="Email"
                />
                {emailInvalid && <EmailInvalid isDarkMode={isDarkMode} />}
              </FormItem>

              <FormItem>
                <Button
                  htmlType="submit"
                  block
                  disabled={!emailValue || emailInvalid}
                  size="large"
                >
                  <StaticIntlTranslator id="app.userAuth.reset" />
                </Button>

                {users?.userResetPasswordDescriptionBottom ? (
                  <div
                    className="bottomDescription"
                    dangerouslySetInnerHTML={{
                      __html: users?.userResetPasswordDescriptionBottom,
                    }}
                  />
                ) : null}
              </FormItem>
            </Form>
          )}
        </div>
      </Container>
    </>
  );
};

ForgotPassword.defaultProps = {
  alertMessage: null,
  messageSuccess: null,
};

ForgotPassword.propTypes = {
  messageSuccess: PropTypes.any,
  alertMessage: PropTypes.string,
  forgotPassword: PropTypes.func.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideAuthMessage: PropTypes.bool.isRequired,
  navigate: PropTypes.any.isRequired,
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

const mapStateToProps = ({ auth, warnings }) => {
  const { loader, authUser } = auth;
  const { alertMessage, messageSuccess, showMessage } = warnings;

  return { loader, alertMessage, messageSuccess, showMessage, authUser };
};

export default connect(mapStateToProps, {
  forgotPassword,
  hideAuthMessage,
})(WrappedForgotPasswordForm);
