import {
  STATUS_COMMENTS_GLOBAL,
  SET_LOADER_IMAGE_QUILL,
  SET_COMMENTS_GLOBAL,
  SET_COMMENTS_GLOBAL_STATE_SUCCESS,
  SET_COMMENTS_GLOBAL_API_SUCCESS,
  GET_COMMENTS_GLOBAL,
  FETCH_COMMENTS_GLOBAL_SUCCESS,
  GET_COMMENTS_FEED_GLOBAL_SUCCESS,
  LOAD_COMMENTS_GLOBAL,
  LOAD_COMMENTS_GLOBAL_SUCCESS,
  SET_COMMENTS_GLOBAL_REPLY_ID,
  SET_OLD_COMMENT_ID,
  UPDATE_COMMENTS_GLOBAL_SUCCESS,
  DELETE_COMMENTS_GLOBAL,
  DELETE_COMMENTS_GLOBAL_SUCCESS,
  LAST_COMMENTS_GLOBAL,
  LAST_COMMENTS_GLOBAL_SUCCESS,
  GET_LIKES_INFO_USERS,
  FETCH_LIKES_INFO_USERS_SUCCESS,
  GET_SINGLE_COMMENT,
  GET_SINGLE_COMMENT_SUCCESS,
  SET_FOUND_COMMENT,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  status: 'all_logged_users',
  replyId: null,
  comments: [],
  commentsLoad: false,
  singleComment: null,
  singleCommentLoad: false,
  commentsRendered: false,
  oldCommentId: '',
  lastComments: [],
  lastCommentsLoad: false,
  edit: null,
  totalComments: 0,
  currentPage: 1,
  totalPages: null,
  pagingLoad: false,
  orderDirection: 'DESC',
  likes: null,
  likesLoader: true,
  apiLoader: false,
  loaderImageQuill: false,
  foundComment: false,
  deletedComment: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;
    case STATUS_COMMENTS_GLOBAL: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case SET_OLD_COMMENT_ID: {
      return {
        ...state,
        oldCommentId: action.payload.id,
      };
    }

    case SET_COMMENTS_GLOBAL: {
      return {
        ...state,
        apiLoader: true,
      };
    }

    case SET_COMMENTS_GLOBAL_STATE_SUCCESS: {
      return {
        ...state,
        comments: action.payload.comments,
        totalComments: action.payload.total,
      };
    }

    case SET_COMMENTS_GLOBAL_API_SUCCESS: {
      return {
        ...state,
        comments: action.payload.comments,
        totalComments: action.payload.total,
        apiLoader: false,
      };
    }

    case GET_COMMENTS_GLOBAL: {
      return {
        ...state,
        commentsLoad: true,
      };
    }

    case FETCH_COMMENTS_GLOBAL_SUCCESS: {
      return {
        ...state,
        comments: action.payload.data,
        totalComments: action.payload.totalComments,
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.last_page,
        commentsLoad: false,
        commentsRendered: true,
      };
    }

    case GET_COMMENTS_FEED_GLOBAL_SUCCESS: {
      return {
        ...state,
        lastComments: action.payload,
      };
    }

    case LOAD_COMMENTS_GLOBAL: {
      return {
        ...state,
        pagingLoad: true,
      };
    }

    case LOAD_COMMENTS_GLOBAL_SUCCESS: {
      return {
        ...state,
        comments: [...state.comments, ...action.payload.data],
        totalComments: action.payload.totalComments,
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.last_page,
        pagingLoad: false,
      };
    }

    case SET_COMMENTS_GLOBAL_REPLY_ID: {
      return {
        ...state,
        replyId: action.payload,
      };
    }

    case UPDATE_COMMENTS_GLOBAL_SUCCESS: {
      return {
        ...state,
        edit: null,
        comments: action.payload,
      };
    }

    case DELETE_COMMENTS_GLOBAL: {
      return {
        ...state,
        deletedComment: action.payload,
      };
    }

    case DELETE_COMMENTS_GLOBAL_SUCCESS: {
      return {
        ...state,
        comments: action.payload.comments,
        totalComments: action.payload.total,
      };
    }

    case LAST_COMMENTS_GLOBAL: {
      return {
        ...state,
        lastCommentsLoad: true,
        lastComments: [],
      };
    }

    case LAST_COMMENTS_GLOBAL_SUCCESS: {
      return {
        ...state,
        lastComments: action.payload,
        lastCommentsLoad: false,
      };
    }

    case GET_LIKES_INFO_USERS: {
      return {
        ...state,
        likesLoader: true,
      };
    }

    case FETCH_LIKES_INFO_USERS_SUCCESS: {
      return {
        ...state,
        likes: action.payload,
        likesLoader: false,
      };
    }

    case GET_SINGLE_COMMENT: {
      return {
        ...state,
        singleCommentLoad: true,
      };
    }

    case GET_SINGLE_COMMENT_SUCCESS: {
      return {
        ...state,
        singleComment: action.payload.comment[0],
        singleCommentLoad: false,
      };
    }

    case SET_LOADER_IMAGE_QUILL: {
      return {
        ...state,
        loaderImageQuill: action.payload,
      };
    }

    case SET_FOUND_COMMENT: {
      return {
        ...state,
        foundComment: action.payload.found,
      };
    }
  }
};
