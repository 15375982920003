import {
  CLEAR_NOT_FOUND_ON_COURSE_STATE,
  FETCH_ALL_COURSES_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  FILTER_CATEGORY_BY_NAME,
  FILTER_CATEGORY_BY_NAME_SUCCESS,
  FILTER_CATEGORY_COURSE_BY,
  FILTER_CATEGORY_COURSE_BY_SUCCESS,
  GET_ALL_COURSES,
  GET_ALL_COURSES_FROM_AN_AUTHOR,
  GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS,
  GET_COURSE,
  GET_COURSES_BEING_WATCHED,
  GET_COURSES_BEING_WATCHED_SUCCESS,
  GET_COURSES_CATEGORIES,
  GET_COURSES_CATEGORIES_SUCCESS,
  GET_COURSES_FROM_CATEGORY,
  GET_COURSES_FROM_CATEGORY_SUCCESS,
  GET_FEATURED_COURSES,
  GET_FEATURED_COURSES_SUCCESS,
  SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL,
  SET_NOT_FOUND_ON_COURSE_STATE,
  SET_SEARCH_LESSONS_MODULES,
  UPDATE_PERCENTAGE,
} from 'shared/providers/redux/actionTypes';

export const fetchCourses = (order_by = 'position', order = 'asc') => ({
  type: GET_ALL_COURSES,
  payload: { order_by, order },
});

export const fetchCoursesFromAnAuthor = (
  author_id,
  per_page = '4',
  page = '1'
) => ({
  type: GET_ALL_COURSES_FROM_AN_AUTHOR,
  payload: { author_id, per_page, page },
});

export const fetchCoursesFromAnAuthorSuccess = (courses) => ({
  type: GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS,
  payload: courses,
});

export const fetchCourse = (params) => ({
  type: GET_COURSE,
  payload: params,
});

export const fetchFeaturedCourses = () => ({
  type: GET_FEATURED_COURSES,
});

export const fetchFeaturedCoursesSuccess = (courses) => ({
  type: GET_FEATURED_COURSES_SUCCESS,
  payload: courses,
});

export const fetchSearchLessonsModules = (paramsFilter) => ({
  type: SET_SEARCH_LESSONS_MODULES,
  payload: paramsFilter,
});

export const updatePercentage = (moduleId, courseLessons, modules) => ({
  type: UPDATE_PERCENTAGE,
  payload: { moduleId, courseLessons, modules },
});

export const fetchCourseSuccess = (course) => ({
  type: FETCH_COURSE_SUCCESS,
  payload: course,
});

export const setNotFoundOnCourseState = () => ({
  type: SET_NOT_FOUND_ON_COURSE_STATE,
});

export const clearNotFoundOnCourseState = () => ({
  type: CLEAR_NOT_FOUND_ON_COURSE_STATE,
});

export const fetchCoursesSuccess = (courseList) => ({
  type: FETCH_ALL_COURSES_SUCCESS,
  payload: courseList,
});

export const fetchSearchLessonsModulesSuccess = (resourcesFilter) => ({
  type: FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  payload: resourcesFilter,
});

export const fetchCoursesCategories = (
  with_course = false,
  per_page = 30,
  page = 1
) => ({
  type: GET_COURSES_CATEGORIES,
  payload: { with_course, per_page, page },
});

export const fetchCoursesCategoriesSuccess = (categories) => ({
  type: GET_COURSES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const fetchCoursesFromCategory = (
  category_slug,
  per_page = 20,
  page = 1
) => ({
  type: GET_COURSES_FROM_CATEGORY,
  payload: { category_slug, per_page, page },
});

export const fetchCoursesFromCategorySuccess = (courses) => ({
  type: GET_COURSES_FROM_CATEGORY_SUCCESS,
  payload: courses,
});

export const fetchCoursesBeingWatched = () => ({
  type: GET_COURSES_BEING_WATCHED,
});

export const fetchCoursesBeingWatchedSuccess = (courses) => ({
  type: GET_COURSES_BEING_WATCHED_SUCCESS,
  payload: courses,
});

export const filterCategoryByName = (name) => ({
  type: FILTER_CATEGORY_BY_NAME,
  payload: name,
});

export const filterCategoryByNameSuccess = (categories) => ({
  type: FILTER_CATEGORY_BY_NAME_SUCCESS,
  payload: categories,
});

export const filterCategoryCourseBy = ({ screen, value, type }) => ({
  type: FILTER_CATEGORY_COURSE_BY,
  payload: { screen, value, type },
});

export const filterCategoryCourseBySuccess = (courses) => ({
  type: FILTER_CATEGORY_COURSE_BY_SUCCESS,
  payload: courses,
});

export const setCompleteCourseAfterCloseModal = () => ({
  type: SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL,
});
