import styled, { css } from 'styled-components';
import dark from 'shared/assets/images/darkmode_icon.png';
import lightIcon from 'shared/assets/images/darkmode-light.png';
import { Popover, Menu } from 'antd';

export const UserProgressContainer = styled.div`
  margin-top: ${({ overflowImage, topbar, zeroMarginTop }) =>
    overflowImage ? '-60px' : topbar || zeroMarginTop ? '0px' : '15px'};
  height: ${({ sizeDefault, mobile }) =>
    sizeDefault ? '63px' : mobile ? '45px' : ''};
  cursor: ${({ noPointer }) => (noPointer ? 'auto' : 'pointer')};

  p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8em;
    text-align: center;
    margin: 10px;
    color: #87d068;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: ${({ mini, sizeDefault }) =>
    mini || sizeDefault ? 'flex-start' : 'center'};
  height: auto;
  width: 100%;
  margin-left: 0px;
  margin: ${({ autoHeight }) => (autoHeight ? '-20px 0 0 8px' : 0)};
  margin-top: 0;

  .progress-bar {
    z-index: 2;
    position: absolute;
  }

  ${(props) =>
    props.theme === 'dark' &&
    css`
      .white-bg {
        background: #242424 !important;
      }

      .ant-progress-circle-trail {
        stroke: #242424 !important;
      }
    `}

  .ant-card-body {
    padding: 10px;
  }

  .ant-progress-circle {
    margin: 0.7px 0px -1px 0px;
    width: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '42px' : sizeDefault ? '62px' : 'auto'};
    height: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '42px' : sizeDefault ? '62px' : 'auto'};

    .ant-progress-inner {
      width: ${({ mini, mobile, sizeDefault }) =>
        mini || sizeDefault || mobile ? 'auto !important' : ''};
      height: ${({ mini, mobile, sizeDefault }) =>
        mini || sizeDefault || mobile ? 'auto !important' : ''};
    }
  }

  span {
    display: none;
  }

  img,
  .content-user-info {
    z-index: 1;
    margin: 2.5px;

    width: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '39px' : sizeDefault ? '50px' : '115px'};
    ${({ mini, mobile }) =>
      (mini || mobile) &&
      css`
        min-width: 39px;
        min-height: 39px;
      `};
    height: ${({ mini, mobile, sizeDefault }) =>
      mini || mobile ? '39px' : sizeDefault ? '50px' : '115px'};

    border-radius: 100px;
  }

  .white-bg {
    display: ${({ mini, mobile, sizeDefault }) =>
      mini || sizeDefault || mobile ? 'none' : 'block'};
    background: #fff;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    position: absolute;
  }
`;

export const PopoverAnt = styled(Popover)`
  margin-top: 0;
`;

export const DarkModeIcon = styled.div`
  margin-right: 20px;
  width: 19px;
  height: 19px;
  background-image: url(${(props) => (props.isDarkMode ? lightIcon : dark)});
  background-size: 19px;
  background-repeat: no-repeat;
`;

export const ContentMenu = styled.span`
  display: flex;
  align-items: center;
  line-height: 0;

  .fa-check {
    font-size: 1.5rem;
    margin-left: -24px;
    margin-right: 10px;
  }

  .icon-custom {
    margin: 0 1.25rem 0 0;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem;
  }
`;

export const Strong = styled.strong`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
`;

export const MenuCustom = styled(Menu)`
  width: 100%;

  border-right: none;
  background: transparent !important;

  height: 100%;
  max-height: 60vh;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    background: #f7f7f7;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e5e5;
  }

  li {
    i {
      margin-right: 1.25rem;
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0 !important;
    padding: 0 8px !important;
    border-radius: 6px;
  }

  .ant-menu-item:hover,
  .ant-menu-item:active,
  .ant-menu-item-selected {
    background: var(--hover-button-background) !important;
  }
`;

export const ContentUserInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #13c2c2;

  ${(props) =>
    props.sizeText === 'small'
      ? css`
          font-size: 12px;
          text-align: center;
        `
      : props.size &&
        css`
          font-size: 36px;
        `}
  span {
    display: inline;
    color: #fff;
    font-weight: 400;
  }
`;

export const MyAccessIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
