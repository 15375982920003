import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import GlobalSearch from 'shared/components/organisms/GlobalSearch';
import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';
import useWindowSize from 'shared/utils/hooks/useWindowSize';

import IconSidebar from './components/IconSidebar';
import MainMenu from './components/MainMenu';
import UserMenu from './components/UserMenu';

import { Container, Header } from './styles';

const TopbarContent = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize?.width < 992;

  const [searchBottomBar, setSearchBottomBar] = useState(false);

  const tenant_data = JSON.parse(sessionStorage.getItem('tenant_data'));
  const tenantTheme = tenant_data?.settings['general-theme'];
  const isGlobalSearchEnabled = useFeature(typeFeatures.globalSearch);
  const isSideMenuEnabled = useFeature(typeFeatures.sideMenu);

  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');
  const sidebarIsRendered = useSelector(
    ({ sidebar }) => sidebar?.sidebarIsRendered
  );

  function handleShowMobileSearchBar() {
    setSearchBottomBar(!searchBottomBar);
  }

  return (
    <Header isDarkMode={isDarkMode} tenantTheme={tenantTheme}>
      {sidebarIsRendered ? isSideMenuEnabled && <IconSidebar /> : <MainMenu />}
      <Container>
        <div className="content-container">
          <Link to="/">
            {isDarkMode && tenantTheme?.themeDarkModeLogoHorizontal ? (
              <img
                className="logo horizontal-logo"
                src={
                  tenant_data?.settings['general-theme']
                    .themeDarkModeLogoHorizontal
                }
                alt="Logotipo"
              />
            ) : tenantTheme?.themeLogoOptionsTopBar === 'custom_image' ? (
              tenantTheme?.themeLogoTopBar ? (
                <img
                  className="logo horizontal-logo"
                  src={tenant_data.settings['general-theme'].themeLogoTopBar}
                  alt="Logotipo"
                />
              ) : (
                <div className="site-title">
                  <h1>{tenant_data?.settings?.site?.siteName}</h1>
                </div>
              )
            ) : tenantTheme?.themeLogoHorizontal ? (
              <img
                className="logo horizontal-logo"
                src={tenant_data?.settings['general-theme'].themeLogoHorizontal}
                alt="Logotipo"
              />
            ) : (
              <div className="site-title">
                <h1>{tenant_data?.settings?.site?.siteName}</h1>
              </div>
            )}

            <img
              className="logo icon-logo"
              src={
                isDarkMode
                  ? tenantTheme?.themeDarkModeLogoIcon
                  : tenantTheme?.themeLogoIcon
              }
              alt="Logotipo"
            />
          </Link>
          {!isMobile && isGlobalSearchEnabled && (
            <GlobalSearch isDarkMode={isDarkMode} />
          )}
          <UserMenu onShowMobileSearchBar={handleShowMobileSearchBar} />
        </div>
      </Container>

      {isMobile && searchBottomBar && (
        <div className="search-container-on-mobile">
          <GlobalSearch isDarkMode={isDarkMode} />
        </div>
      )}
    </Header>
  );
};

export default memo(TopbarContent);
