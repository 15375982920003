import { confirmationEmailStatus } from 'modules/Auth/actions';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getLocalUser as userFromLocalStorage,
  updateLocalUserData
} from 'shared/infra/services/auth';
import api from 'shared/infra/services/tenantAPI';
import {
  fetchAllCountries,
  fetchAllDevices,
  fetchAllFollowers,
  fetchAllFollowing,
  fetchAllInterests,
  fetchCheckUsername,
  fetchLoggedUser,
  fetchOneUser,
  fetchPassword,
  fetchUserLink,
  fetchUserNotFound,
  followUserSuccess,
  getAllAuthorsSuccess,
  getAllNonAuthorUsersSuccess,
  getAllUsersSuccess,
  getUserCollectionSuccess,
  getUserCommentsSuccess,
  getUserMediaSpaceContentSuccess,
  lastTopicsUserSuccess,
  removeLinkSuccess,
  updateLoggedUser
} from 'shared/providers/redux/actions';
import {
  CHECK_USERNAME,
  CREATE_PRIVATE_PHONE,
  DELETE_COVER_IMAGE,
  DELETE_PROFILE_IMAGE,
  FOLLOW_USER,
  GET_ALL_AUTHORS,
  GET_ALL_NON_AUTHOR_USERS,
  GET_ALL_USERS,
  GET_FOLLOWERS,
  GET_FOLLOWING,
  GET_INTERESTS,
  GET_LOGGED_USER,
  GET_USER,
  GET_USER_COLLECTION,
  GET_USER_COMMENTS,
  GET_USER_COUNTRIES,
  GET_USER_DEVICES,
  GET_USER_MEDIA_SPACE_CONTENT,
  LAST_TOPICS_USER,
  REMOVE_LINK,
  RESEND_CONFIRMATION_EMAIL,
  SET_USERNAME,
  SET_USER_LINK,
  UNFOLLOW_USER,
  UPDATE_BIOGRAPHY,
  UPDATE_COVER_IMAGE,
  UPDATE_DISPLAY_NAME,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_NAME,
  UPDATE_PASSWORD,
  UPDATE_PRIVATE_EMAIL,
  UPDATE_PRIVATE_PHONE,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PUBLIC_ADDRESS,
  UPDATE_PUBLIC_EMAIL,
  UPDATE_PUBLIC_PHONE,
  UPDATE_SITE,
  UPDATE_USER_LINK
} from 'shared/providers/redux/actionTypes';
import { checkingExpirationDate } from 'shared/utils/expirationDate';

// GET USER

const getUserRequest = async (payload) => {
  try {
    const response = await api.get(`user-profile/${payload}`);
    const { data } = response.data;
    return data;
  } catch (error) {
    console.log('error -->', error);
    return null;
  }
};

function* getLocalUser({ payload }) {
  const user = yield call(getUserRequest, payload);
  if (user) {
    yield put(fetchOneUser(user));
  } else {
    yield put(fetchUserNotFound());
  }
}

export function* fetchUser() {
  yield takeLatest(GET_USER, getLocalUser);
}

// GET LOGGED USER

const getLoggedUserRequest = async (payload) => {
  try {
    const response = await api.get(`user-profile/${payload}`);
    const { data } = response.data;
    localStorage.setItem('cover_image', data.cover_image);
    localStorage.setItem('photo', data.photo);
    return data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* getLoggedUser({ payload }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const expirationDate = user?.data?.expireAt;
  const isExpired = checkingExpirationDate(expirationDate);

  const userData =
    isExpired || !user
      ? yield call(getLoggedUserRequest, payload)
      : userFromLocalStorage();

  if (userData) yield put(fetchLoggedUser(userData));
}

export function* fetchLogged() {
  yield takeLatest(GET_LOGGED_USER, getLoggedUser);
}

// CHECK_USERNAME

const checkUsernameRequest = async (payload) => {
  try {
    const { username } = payload;
    const res = await api.post(`username/check`, {
      username,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* checkUsername({ payload }) {
  const res = yield call(checkUsernameRequest, payload);
  yield put(fetchCheckUsername(res));
}

// SET_USERNAME

const setUsernameRequest = async (payload) => {
  try {
    const { username } = payload;
    const res = await api.put(`user-profile`, {
      username,
    });
    updateLocalUserData('username', res?.data?.data.username);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* setUsername({ payload }) {
  const res = yield call(setUsernameRequest, payload);
  yield put(fetchOneUser(res));
  yield put(updateLoggedUser({ username: res?.data.username }));
}

// UPDATE_BIOGRAPHY

const updateBiographyRequest = async (payload) => {
  try {
    const { biography } = payload;
    const res = await api.put(`user-profile`, {
      biography,
    });
    updateLocalUserData('biography', res?.data?.data.biography);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateBiography({ payload }) {
  const res = yield call(updateBiographyRequest, payload);
  yield put(updateLoggedUser({ biography: res?.data.biography }));
}

// UPDATE_COVER_IMAGE

const updateCoverImageRequest = async (payload) => {
  try {
    const { cover_img } = payload;
    const data = new FormData();
    const resPromise = await cover_img;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    data.append('cover_image', resPromise);

    await api.delete('/user/coverimage');
    const res = await api.post(`user/coverimage`, data, config);
    localStorage.setItem('cover_image', res.data.attrs.cover_image);
    updateLocalUserData('cover_image', res.data.attrs.cover_image);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateCoverImage({ payload }) {
  const res = yield call(updateCoverImageRequest, payload);
  yield put(updateLoggedUser({ cover_image: res?.attrs.cover_image }));
}

// DELETE_COVER_IMAGE

const deleteCoverImageRequest = async (payload) => {
  try {
    localStorage.setItem('cover_image', null);
    await api.delete('/user/coverimage');
    const user = {
      ...payload,
      cover_image: '',
    };
    updateLocalUserData('cover_image', '');

    return user;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* deleteCoverImage({ payload }) {
  yield call(deleteCoverImageRequest, payload);
  yield put(updateLoggedUser({ cover_image: '' }));
}

const deleteProfileImageRequest = async (payload) => {
  try {
    localStorage.removeItem('photo');
    await api.delete('/user/photo');
    const user = {
      ...payload,
      photo: '',
    };
    updateLocalUserData('photo', '');

    return user;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* deleteProfileImage({ payload }) {
  yield call(deleteProfileImageRequest, payload);
  yield put(updateLoggedUser({ photo: '' }));
}

// UPDATE_PROFILE_IMAGE

const updateProfileImageRequest = async (payload) => {
  try {
    const { profile_img } = payload;

    const data = new FormData();

    const resPromise = await profile_img;

    data.append('photo', resPromise);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    await api.delete('/user/photo');
    const res = await api.post(`user/photo`, data, config);
    updateLocalUserData('photo', res.data.attrs.photo);
    localStorage.setItem('photo', res.data.attrs.photo);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateProfileImage({ payload }) {
  const res = yield call(updateProfileImageRequest, payload);
  yield put(updateLoggedUser({ photo: res?.attrs.photo }));
}

// UPDATE_NAME

const updateNameRequest = async (payload) => {
  try {
    const { display_name } = payload;
    const res = await api.put(`user-profile`, {
      display_name,
    });
    updateLocalUserData('display_name', res?.data?.data.display_name);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateName({ payload }) {
  const res = yield call(updateNameRequest, payload);
  yield put(updateLoggedUser({ display_name: res?.data.display_name }));
}

// UPDATE_FULL_NAME

const updateFirstNameRequest = async (payload) => {
  try {
    const { first_name } = payload;
    const res = await api.put(`user-profile`, {
      first_name,
    });
    updateLocalUserData('first_name', res?.data?.data.first_name);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateFirstName({ payload }) {
  const res = yield call(updateFirstNameRequest, payload);
  yield put(updateLoggedUser({ first_name: res?.data.first_name }));
}

// UPDATE_LAST_NAME

const updateLastNameRequest = async (payload) => {
  try {
    const { last_name } = payload;
    const res = await api.put(`user-profile`, {
      last_name,
    });
    updateLocalUserData('last_name', res?.data?.data.last_name);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateLastName({ payload }) {
  const res = yield call(updateLastNameRequest, payload);
  yield put(updateLoggedUser({ last_name: res?.data.last_name }));
}

// UPDATE_SITE

const updateSiteRequest = async (payload) => {
  try {
    const { site } = payload;
    const res = await api.put(`user-profile`, {
      site,
    });
    updateLocalUserData('site_public', res?.data?.data.site_public);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateSite({ payload }) {
  const res = yield call(updateSiteRequest, payload);
  yield put(updateLoggedUser({ site_public: res?.data.site_public }));
}

// UPDATE_PUBLIC_EMAIL

const updatePublicEmailRequest = async (payload) => {
  try {
    const { email_public } = payload;
    const res = await api.put(`user-profile`, {
      email_public,
    });
    updateLocalUserData('email_public', res?.data?.data.email_public);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePublicEmail({ payload }) {
  const res = yield call(updatePublicEmailRequest, payload);
  yield put(updateLoggedUser({ email_public: res?.data.email_public }));
}

// UPDATE_PASSWORD

const updatePasswordRequest = async (payload) => {
  try {
    const { current_password, new_password } = payload;
    const res = await api.put('user/changepassword', {
      current_password,
      new_password,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePassword({ payload }) {
  const res = yield call(updatePasswordRequest, payload);
  yield put(fetchPassword(res));
}

// UPDATE_PRIVATE_EMAIL

const updatePrivateEmailRequest = async (payload) => {
  try {
    const { email } = payload;
    const res = await api.put(`user-profile`, {
      email,
    });
    updateLocalUserData('email', res?.data?.data.email);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePrivateEmail({ payload }) {
  const res = yield call(updatePrivateEmailRequest, payload);
  yield put(updateLoggedUser({ email: res?.data.email }));
}

// UPDATE PUBLIC PHONE

const updatePublicPhoneRequest = async (payload) => {
  try {
    const { phone } = payload;
    const res = await api.put(`user-profile`, {
      phone: phone.full_number,
    });
    updateLocalUserData('phone_public', res?.data?.data.phone_public);

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePublicPhone({ payload }) {
  const res = yield call(updatePublicPhoneRequest, payload);
  yield put(updateLoggedUser({ phone_public: res?.data.phone_public }));
}

// UPDATE PUBLIC ADDRESS

const updatePublicAddressRequest = async (payload) => {
  try {
    const { address } = payload;
    const res = await api.put(`user-profile`, {
      address,
    });
    updateLocalUserData('address_public', res?.data?.data.address_public);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePublicAddress({ payload }) {
  const res = yield call(updatePublicAddressRequest, payload);
  yield put(updateLoggedUser({ address_public: res?.data.address_public }));
}

const getUserMediaSpaceContentRequest = async (username) => {
  const params = {
    'whereHas[0][index]': 'username',
    'whereHas[0][condition]': 'like',
    'whereHas[0][value]': username,
    'whereHasWith[0]': 'Authors',
  };

  try {
    const response = await api.get('media-space-content', { params });

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* getUserMediaSpaceContent({ payload }) {
  const response = yield call(
    getUserMediaSpaceContentRequest,
    payload?.username
  );

  yield put(getUserMediaSpaceContentSuccess(response));
}

const getLastTopicsUserRequest = async ({ user_id, currentePage }) => {
  const params = {
    page: currentePage,
    per_page: '5',
    'order[0][index]': 'created_at',
    'order[0][value]': 'desc',
  };

  try {
    const response = await api.get(`forum/feed/last-topics-user/${user_id}`, {
      params,
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* getLastTopicsUser({ payload }) {
  const response = yield call(getLastTopicsUserRequest, payload);

  yield put(lastTopicsUserSuccess(response));
}

const getUserCollectionsRequest = async ({ username, content_per_page }) => {
  try {
    const response = await api.get(
      `user/collection/${username}?per_page=${content_per_page}`
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* getUserCollections({ payload }) {
  const response = yield call(getUserCollectionsRequest, payload);

  yield put(getUserCollectionSuccess(response));
}

const getUserCommentsRequest = async ({ username, current_page = 1 }) => {
  const params = {
    page: current_page,
    per_page: '5',
    'order[0][index]': 'created_at',
    'order[0][value]': 'desc',
  };

  try {
    const response = await api.get(`user/comments/${username}`, { params });

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* getUserComments({ payload }) {
  const response = yield call(getUserCommentsRequest, payload);

  yield put(getUserCommentsSuccess(response));
}

// UPDATE_PRIVATE_PHONE

const updatePrivatePhoneRequest = async (payload) => {
  try {
    const { id, country_code, area_code, number } = payload;
    const res = await api.put(`phone/${id}`, {
      country_code,
      area_code,
      number,
    });
    updateLocalUserData('phone', res?.data?.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updatePrivatePhone({ payload }) {
  const res = yield call(updatePrivatePhoneRequest, payload);
  yield put(updateLoggedUser({ phone: res?.data.phone }));
}

const createPrivatePhoneRequest = async (payload) => {
  try {
    const { country_code, area_code, number } = payload;

    const res = await api.post('phone', {
      country_code,
      area_code,
      number,
    });

    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* createPrivatePhone({ payload }) {
  yield call(createPrivatePhoneRequest, payload);
}

// SET_USER_LINK

const setUserLinkRequest = async (payload) => {
  try {
    const { name, url } = payload;
    const res = await api.post(`user-profile/link`, {
      name,
      url,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* setUserLink({ payload }) {
  const res = yield call(setUserLinkRequest, payload);
  const user = {
    ...payload.user,
    user_links: [res.data, ...payload.user.user_links],
  };
  yield put(fetchUserLink(user));
}

// UPDATE_USER_LINK

const updateUserLinkRequest = async (payload) => {
  try {
    const { id, name, url } = payload;
    const res = await api.put(`user-profile/link/${id}`, {
      name,
      url,
    });
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
};

function* updateUserLink({ payload }) {
  yield call(updateUserLinkRequest, payload);
}

// REMOVE LINK

const removeLinkRequest = async (id) => {
  try {
    await api.delete(`user-profile/link/${id}`);
  } catch (err) {
    console.error(err);
    return null;
  }

  return null;
};

function* removeLink({ payload }) {
  yield call(removeLinkRequest, payload.id);
  yield put(removeLinkSuccess(payload.newLinks));
}

// GET USER DEVICES

const getUserDevicesRequest = async () => {
  try {
    const response = await api.get('user/device');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserDevices() {
  const devices = yield call(getUserDevicesRequest);
  if (devices) yield put(fetchAllDevices(devices));
}

export function* fetchDevices() {
  yield takeLatest(GET_USER_DEVICES, onGetUserDevices);
}
// GET FOLLOWING

const getFollowingRequest = async ({ username, page }) => {
  const params = {
    per_page: 15,
    page: page || 1,
  };

  try {
    const endpoint = username
      ? `user/followings/${username}`
      : 'user/followings';

    const response = await api.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetFollowing({ payload }) {
  const user = userFromLocalStorage();
  if (!user) return;

  const following = yield call(getFollowingRequest, payload);
  if (following) yield put(fetchAllFollowing(following));
}

export function* fetchFollowing() {
  yield takeLatest(GET_FOLLOWING, onGetFollowing);
}

// GET FOLLOWERS

const getFollowersRequest = async ({ username, page }) => {
  const params = {
    per_page: 15,
    page: page || 1,
  };

  try {
    const endpoint = username ? `user/followers/${username}` : 'user/followers';

    const response = await api.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetFollowers({ payload }) {
  const user = userFromLocalStorage();
  if (!user) return;

  const followers = yield call(getFollowersRequest, payload);
  if (followers) yield put(fetchAllFollowers(followers));
}

export function* fetchFollowers() {
  yield takeLatest(GET_FOLLOWERS, onGetFollowers);
}

// GET USER INTERESTS

const getUserInterestsRequest = async () => {
  try {
    const response = await api.get('user-profile/interest/list');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserInterests() {
  const interests = yield call(getUserInterestsRequest);
  if (interests) yield put(fetchAllInterests(interests));
}

export function* fetchInterests() {
  yield takeLatest(GET_INTERESTS, onGetUserInterests);
}

// GET USER COUNTRIES

const getUserCountriesRequest = async () => {
  try {
    const response = await api.get('country/json/dropdown');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetUserCountries() {
  const countries = yield call(getUserCountriesRequest);
  if (countries) yield put(fetchAllCountries(countries));
}

export function* fetchCountries() {
  yield takeLatest(GET_USER_COUNTRIES, onGetUserCountries);
}

// GET ALL USERS

const getAllUsersRequest = async (payload) => {
  const { page = 1, length = 15 } = payload;

  try {
    const response = await api.get(
      `user-profile/?page=${page}&per_page=${length}`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllUsers({ payload }) {
  const users = yield call(getAllUsersRequest, payload);
  if (users) yield put(getAllUsersSuccess(users));
}

export function* fetchAllUsers() {
  yield takeLatest(GET_ALL_USERS, onGetAllUsers);
}

// GET ALL AUTHORS USERS

const getAllAuthorsRequest = async (payload) => {
  const { page, length = 5 } = payload;

  try {
    const response = await api.get(
      `user-community/authors/?page=${page}&per_page=${length}`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllAuthors({ payload }) {
  const authors = yield call(getAllAuthorsRequest, payload);
  if (authors) yield put(getAllAuthorsSuccess(authors));
}

export function* fetchAllAuthors() {
  yield takeLatest(GET_ALL_AUTHORS, onGetAllAuthors);
}

// GET ALL NON-AUTHOR USERS

const getAllNonAuthorUsersRequest = async (payload) => {
  try {
    const response = await api.get(
      `user-community/users/?page=${payload}&per_page=5`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetAllNonAuthorUsers({ payload }) {
  const nonAuthorUsers = yield call(getAllNonAuthorUsersRequest, payload);
  if (nonAuthorUsers) yield put(getAllNonAuthorUsersSuccess(nonAuthorUsers));
}

export function* fetchAllNonAuthorUsers() {
  yield takeLatest(GET_ALL_NON_AUTHOR_USERS, onGetAllNonAuthorUsers);
}

// FOLLOW USER

const followUserRequest = async (payload) => {
  try {
    const response = await api.post(`user/follow/${payload}`);
    return response.data.success;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onFollowUser({ payload }) {
  const succeeded = yield call(followUserRequest, payload?.user?.id);
  if (succeeded) yield put(followUserSuccess(succeeded));
}

export function* fetchFollowUser() {
  yield takeLatest(FOLLOW_USER, onFollowUser);
}

// RESEND CONFIRMATION EMAIL

const resendConfirmationEmailRequest = async ({ email }) => {
  let message = '';

  try {
    await api.post('user/resendconfirmation', { email });

    return { success: true, message };
  } catch (error) {
    if (error.response.status === 409) {
      message = error.response.data.message;
    }

    return { success: false, message, status: error.response.status };
  }
};

function* onResendConfirmationEmail({ payload }) {
  const response = yield call(resendConfirmationEmailRequest, payload);
  yield put(confirmationEmailStatus(response));
}

// UNFOLLOW USER

const unfollowUserRequest = async (payload) => {
  try {
    const response = await api.post(`user/unfollow/${payload}`);
    return response.data.success;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

const updateDisplayNameRequest = async (payload) => {
  const { display_name, biography } = payload;

  try {
    const response = await api.put('user-profile', {
      display_name,
      biography,
    });
    updateLocalUserData('display_name', response?.data?.data.display_name);
    updateLocalUserData('biography', response?.data?.data.biography);

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onUnfollowUser({ payload }) {
  yield call(unfollowUserRequest, payload?.user?.id);
}

function* updateDisplayName({ payload }) {
  const res = yield call(updateDisplayNameRequest, payload);
  yield put(
    updateLoggedUser({
      display_name: res?.data.display_name,
      biography: res?.data.biography,
    })
  );
}

export function* fetchUnfollowUser() {
  yield takeLatest(UNFOLLOW_USER, onUnfollowUser);
}

export function* fetchDisplayName() {
  yield takeLatest(UPDATE_DISPLAY_NAME, updateDisplayName);
}

// EXPORTING SAGAS

export default function* rootSaga() {
  yield all([
    fork(fetchUser),
    fork(fetchLogged),
    fork(fetchDevices),
    fork(fetchFollowing),
    fork(fetchFollowers),
    fork(fetchCountries),
    fork(fetchInterests),
    fork(fetchDisplayName),
    takeLatest(SET_USER_LINK, setUserLink),
    takeLatest(SET_USERNAME, setUsername),
    takeLatest(CHECK_USERNAME, checkUsername),
    takeLatest(UPDATE_USER_LINK, updateUserLink),
    takeLatest(REMOVE_LINK, removeLink),
    takeLatest(UPDATE_COVER_IMAGE, updateCoverImage),
    takeLatest(UPDATE_PROFILE_IMAGE, updateProfileImage),
    takeLatest(UPDATE_BIOGRAPHY, updateBiography),
    takeLatest(UPDATE_FIRST_NAME, updateFirstName),
    takeLatest(UPDATE_LAST_NAME, updateLastName),
    takeLatest(UPDATE_NAME, updateName),
    takeLatest(UPDATE_PASSWORD, updatePassword),
    takeLatest(UPDATE_SITE, updateSite),
    takeLatest(DELETE_COVER_IMAGE, deleteCoverImage),
    takeLatest(DELETE_PROFILE_IMAGE, deleteProfileImage),
    takeLatest(UPDATE_PUBLIC_EMAIL, updatePublicEmail),
    takeLatest(UPDATE_PRIVATE_EMAIL, updatePrivateEmail),
    takeLatest(UPDATE_PRIVATE_PHONE, updatePrivatePhone),
    takeLatest(CREATE_PRIVATE_PHONE, createPrivatePhone),
    takeLatest(UPDATE_PUBLIC_PHONE, updatePublicPhone),
    takeLatest(UPDATE_PUBLIC_ADDRESS, updatePublicAddress),
    takeLatest(GET_USER_MEDIA_SPACE_CONTENT, getUserMediaSpaceContent),
    takeLatest(LAST_TOPICS_USER, getLastTopicsUser),
    takeLatest(GET_USER_COLLECTION, getUserCollections),
    takeLatest(GET_USER_COMMENTS, getUserComments),
    takeLatest(RESEND_CONFIRMATION_EMAIL, onResendConfirmationEmail),
    fork(fetchAllUsers),
    fork(fetchAllAuthors),
    fork(fetchAllNonAuthorUsers),
    fork(fetchFollowUser),
    fork(fetchUnfollowUser),
  ]);
}
