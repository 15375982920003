import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  animation: appearFromLeft 0.4s;

  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
  }

  h1.siteName {
    font-size: 24px;
    text-align: center;
  }

  h2,
  span,
  p,
  a {
    font-style: normal;
    font-weight: normal;
    font-family: Roboto;
  }

  .confirmation-icon {
    font-size: 22px;
  }

  ${(props) =>
    props.isDarkMode
      ? css`
          h1,
          h2,
          h3,
          p {
            color: #ffffff !important;
          }
        `
      : css`
          svg {
            color: #717171 !important;
          }
        `}

  .bottom-container {
    span {
      display: flex;
      flex-direction: column;
      margin: 20px 0px 10px 0px;
      align-items: center;
    }

    span > span {
      margin: 0px 5px;
    }
  }

  img {
    max-width: 250px;
    max-height: 90px;
    object-fit: contain;
  }

  h1 {
    text-align: center;
    color: #606060;
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 115%;
    letter-spacing: -0.5px;
    margin-bottom: 12px;
  }

  h2 {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 132.5%;
    letter-spacing: -0.5px;
    color: #606060;
  }

  div.botoes {
    display: flex;
    flex-direction: column;
    align-items: center;

    button.primary {
      cursor: pointer;
      width: 214px;
      height: 44px;
      padding: 0px 31px;
      font-size: 14px;
      color: #fefefe;
    }
  }

  .titles {
    width: 100%;
    height: 100%;

    min-height: 450px;
    margin: 20px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1400px) {
      margin: 0;
    }

    @media (max-width: 900px) {
      padding: 0 16px;
      width: 100vw;
    }
  }

  section {
    h3 {
      font-size: 16px;
      line-height: 132.5%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.5px;

      color: #888888;
    }
  }

  section.bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  section.bottom-container button {
    cursor: pointer;

    padding: 0px 31px;
    width: 321px;
    height: 44px;

    border: none;
    outline: none;
    color: #ffffff;
    font-size: 14px;
  }

  section.bottom-container {
    letter-spacing: -0.5px;
    color: #888888;
    font-size: 14px;
    line-height: 132.5%;
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Button = styled.p`
  margin-bottom: 20px;

  ${(props) =>
    props.$btnType === 'resend' &&
    css`
      font-weight: 400;
      cursor: pointer;
      margin: 0;
    `}

  ${props => props.disabled && css`
    cursor: not-allowed;
  `}
`;

export const ErrorSection = styled.section`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0 !important;
  }

  span {
    margin: 0 !important;
  }

  svg {
    margin-right: 10px;
  }

  margin-bottom: 20px;
`;
