import {
  SWITCH_LANGUAGE,
  TAB_ACTIVE,
  TOGGLE_COLLAPSED_NAV,
  VISUALIZATION_MODE,
  WINDOW_WIDTH,
  SET_THEME_MODE,
  SET_SEARCH_MODE,
 NAV_STYLE } from 'shared/providers/redux/actionTypes';

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function setThemeMode(mode) {
  return (dispatch) => {
    dispatch({ type: SET_THEME_MODE, mode });
  };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  };
}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE, navStyle });
  };
}

export function switchLanguage(locale) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale,
    });
  };
}

export function setActiveTab(activeTab) {
  return (dispatch) => {
    dispatch({ type: TAB_ACTIVE, payload: activeTab });
  };
}

export function setVisualizationMode(visualizationMode) {
  return (dispatch) => {
    dispatch({ type: VISUALIZATION_MODE, payload: visualizationMode });
  };
}

export const setSearchMode = (mode) => ({
  type: SET_SEARCH_MODE,
  payload: { mode },
});
