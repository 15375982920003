function removeDuplicates(array, object_1 = '', object_2 = '') {
  const verifyDuplicates = (duplicated_items) => {

    const novaArr = duplicated_items?.filter((item, idx) => {
      if (!duplicated_items[idx - 1]) {
        return item;
      }
      if (
        item[object_1] !== duplicated_items[idx - 1][object_1] ||
        item[object_2] !== duplicated_items[idx - 1][object_2]
      ) {
        return item;
      }
      return null;
    });
    return novaArr;
  };

  return verifyDuplicates(array);
}

export default removeDuplicates;
