import React from 'react';
import { useSelector } from 'react-redux';
import { FaAward, FaStar } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import Widget from 'shared/components/templates/Widget';
import { getLocalUser } from 'shared/infra/services/auth';
import UserProgress from 'shared/components/organisms/UserProgress';
import { CardContainer, InfoContainer, InsigniasContainer } from './styles';

const chartGamificationEnabled = [
  { name: 'Page A', price: 200 },
  { name: 'Page B', price: 800 },
  { name: 'Page C', price: 1600 },
  { name: 'Page D', price: 2200 },
  { name: 'Page D', price: 1400 },
  { name: 'Page H', price: 2960 },
  { name: 'Page K', price: 1960 },
];

const chartGamificationDisabled = [
  { name: 'Page A', price: -150 },
  { name: 'Page B', price: 600 },
  { name: 'Page C', price: 100 },
  { name: 'Page D', price: 900 },
  { name: 'Page D', price: 400 },
  { name: 'Page H', price: 1400 },
  { name: 'Page K', price: 0 },
];

const Status = ({ autoHeight = false, topbar }) => {
  const user = getLocalUser();
  const history = useHistory();
  const avatar = useSelector(({ user }) => user.loggedUser?.photo);
  const { tenantData } = useSelector(({ tenancy }) => tenancy);
  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');

  const { game_credits } = user;
  const { game_points } = user;

  const gamificationActive = 0;

  const handleNavigateToProfile = () => {
    history.push(`/user/${user.username}`);
  };

  return (
    <CardContainer
      gamificationOn={gamificationActive}
      topbar={topbar}
      isDarkMode={isDarkMode}
      tenantTheme={tenantData?.settings['general-theme']}
      autoHeight={autoHeight}
    >
      <Widget styleName="gx-card-full">
        <div className="gx-widget-badge points">
          <AiFillDollarCircle className="fa-coins" />
          <p>{game_credits}</p>
        </div>
        <UserProgress
          percent="75"
          image={avatar}
          userSession={user}
          onClick={handleNavigateToProfile}
        />

        <div className="gx-text-center gx-px-3 gx-pt-3">
          <div className="gx-mb-3">
            <h3 onClick={handleNavigateToProfile}>{user.display_name}</h3>
            {gamificationActive === '1' && (
              <p className="default-text">
                <StaticIntlTranslator id="gamification.componentsCards.nextLevelIn" />{' '}
                sem-api pts (sem-api% concluído)
              </p>
            )}
          </div>

          {gamificationActive === '1' ? (
            <>
              <InfoContainer>
                <FaAward className="fa-award" />
                <div className="text">
                  <p>Level: sem-api</p>
                  <p>{`Pontos: ${game_points}`}</p>
                  <p>Ranking: sem-api</p>
                </div>
              </InfoContainer>
              <InsigniasContainer>
                <FaStar className="fa-star" />
                <FaStar className="fa-star" />
                <FaStar className="fa-star" />
                <FaStar className="fa-star bw" />
                <FaStar className="fa-star bw" />
                <FaStar className="fa-star bw" />
              </InsigniasContainer>
              <p className="default-text">
                <StaticIntlTranslator id="gamification.componentsCards.pointsIn" />{' '}
                sem-api dias
              </p>
            </>
          ) : (
            <div className="temporarilyOff">
              <p>
                {game_points}{' '}
                <StaticIntlTranslator id="gamification.cards.status.points" />
                &nbsp;/&nbsp;
                {game_credits}{' '}
                <StaticIntlTranslator id="gamification.cards.status.credits" />
              </p>
              <p>
                <StaticIntlTranslator id="gamification.cards.status.temporalityDisable" />
              </p>
              <p>
                <StaticIntlTranslator id="gamification.cards.status.dontWorry" />
              </p>
            </div>
          )}
        </div>
        <div className="gx-rechart">
          <ResponsiveContainer
            width="100%"
            height={gamificationActive === '1' ? 118 : 58}
          >
            <AreaChart
              data={
                gamificationActive === '1'
                  ? chartGamificationEnabled
                  : chartGamificationDisabled
              }
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="5%" stopColor="#58e3ff" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00ba7c" stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="price"
                stackId="2"
                stroke="#FFF"
                fill={gamificationActive === '1' ? '#038FDE' : 'url(#colorUv)'}
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Widget>
    </CardContainer>
  );
};

Status.propTypes = {
  autoHeight: PropTypes.bool.isRequired,
  topbar: PropTypes.any.isRequired,
};
export default Status;
