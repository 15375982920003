import {
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOG_COMMENTS_SUCCESS,
  GET_FEATURED_BLOG_SUCCESS,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_FAILED,
  GET_SINGLE_BLOG_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  blogPost: null,
  blogComments: [],
  blogsFeatured: [],
  blogSpotlights: null,
  page: 1,
  total_content: 1,
  per_page: 15,
  blogLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SINGLE_BLOG: {
      return {
        ...state,
        blogLoader: true,
        blogPost: null,
      };
    }

    case GET_SINGLE_BLOG_SUCCESS: {
      return {
        ...state,
        blogLoader: false,
        blogPost: {
          ...action.payload?.blog?.data,
          ...action.payload?.blog?.navigation,
          ...{ last_comments: action.payload?.blog?.last_comments },
          ...{ last_posts: action.payload?.blog?.last_posts },
        },
      };
    }

    case GET_SINGLE_BLOG_FAILED: {
      return {
        ...state,
        blogLoader: false,
        blogPost: null,
      };
    }

    case GET_BLOGS: {
      return {
        ...state,
        blogListLoader: true,
        blogList: null,
      };
    }

    case GET_BLOGS_SUCCESS: {
      return {
        ...state,
        blogListLoader: false,
        blogList: action.payload.data.reverse(),
        blogSpotlights: action.payload?.spotlights,
        page: action.payload.meta?.current_page,
        total_content: action.payload.meta?.total,
        per_page: action.payload.meta?.per_page,
      };
    }

    case GET_FEATURED_BLOG_SUCCESS: {
      return {
        ...state,
        blogsFeatured: action.payload,
      };
    }

    case GET_BLOG_COMMENTS_SUCCESS: {
      const { id, data } = action.payload;
      const previusComment = state.blogComments;
      return {
        ...state,
        blogComments: [...previusComment, { id, data }],
      };
    }

    default:
      return state;
  }
};
