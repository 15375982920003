import React from 'react';
import {
  put,
  all,
  call,
  fork,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { message } from 'antd';
import { createIntl } from 'react-intl';
import axios from 'axios';
import AppLocale from 'shared/providers/i18n';

import {
  GET_ALL_ADDRESS,
  GET_ADDRESS_BRASIL_API,
  SET_ADDRESS,
  SET_DEFAULT_ADDRESS,
  UPDATE_ADDRESS_SESSION,
  REMOVE_ADDRESS,
} from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import {
  setCreditCard,
  setCurrentStep,
  setUserSetting,
  setAddressError,
  setAddressSuccess,
  removeAddressSuccess,
  getAllAddressSuccess,
  removeFieldUserSetting,
  getAddressBrazilAPISuccess,
  updateAddressSessionSuccess,
  getAllAddress as getAllAddressList,
} from 'shared/providers/redux/actions';

const setAddressRequest = async (payload) => {
  const { city, state, street, number, zipcode, neighborhood } = payload;

  try {
    const { data } = await api.post('address', {
      zipcode,
      country_id: 31,
      street_number: number,
      complementary: '',
      state,
      city,
      neighborhood,
      street,
    });
    return data.data;
  } catch (error) {
    const { data } = error.response;

    if (data) {
      sessionStorage.setItem(
        'register_address_error',
        JSON.stringify(Object.values(data))
      );
    }

    console.log('error -->', data);
  }
  return null;
};

const getAllAddressRequest = async () => {
  try {
    const response = await api.get('address?orderBy=id&orderBy=desc');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getAddressBrazilAPIRequest = async (payload) => {
  try {
    const response = await axios.get(
      `https://brasilapi.com.br/api/cep/v1/${payload}`
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return { error: 'CEP não encontrado na base do ViaCEP.' };
};

const updateAddressSessionRequest = async (payload) => {
  const { id, deliveryAddress } = payload;
  const { zipcode, state, city, neighborhood, street, number } =
    deliveryAddress;

  try {
    const response = await api.put(`address/${id}`, {
      street,
      city,
      state,
      neighborhood,
      zipcode: zipcode?.replace('-', ''),
      country_id: 31,
      street_number: number,
      complementary: '',
    });

    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const removeAddressRequest = async (payload) => {
  try {
    await api.delete(`address/${payload}`);
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setDefaultAddressRequest = async (payload) => {
  try {
    await api.post(`address/set-default/${payload}`);
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* setAddress({ payload }) {
  const { settings, address, checkout } = yield select();
  const { currentStep } = checkout;
  const { locale } = settings;

  const currentAppLocale = AppLocale[locale.locale];
  const intl = createIntl({
    locale: locale.locale,
    messages: currentAppLocale.messages,
  });

  const { navigationType, paymentType, creditCard } = payload;
  const newAddress = yield call(setAddressRequest, payload);

  if (newAddress) {
    yield put(setUserSetting('address_id', newAddress.id, 'payment'));
    yield put(getAddressBrazilAPISuccess(null));

    address.addressList.unshift(newAddress);
    yield put(setAddressSuccess(address.addressList));

    if (navigationType === 'checkout') {
      if (paymentType === 'credit_card') {
        const {
          cvc,
          phone,
          name,
          expiry,
          number,
          document,
          installment,
          type_entity,
          document_type,
          subscriptionId,
          navigation_type,
        } = creditCard;

        yield put(
          setCreditCard({
            cvc,
            phone,
            name,
            expiry,
            number,
            document,
            address_id: newAddress.id,
            installment,
            type_entity,
            document_type,
            subscriptionId,
            navigation_type,
          })
        );
      }

      if (paymentType === 'bank_slip')
        yield put(setCurrentStep(currentStep + 1));
    }

    if (navigationType !== 'checkout') {
      openNotificationWithIcon(
        'success',
        intl.formatMessage({
          id: 'appRedux.sagas.register.address.success.title',
        }),
        intl.formatMessage({
          id: 'appRedux.sagas.register.address.success.description',
        }),
        8
      );
    }
  } else {
    const registerAddressError = JSON.parse(
      sessionStorage.getItem('register_address_error')
    );
    const contentError = (
      <>
        <p>
          {intl.formatMessage({
            id: 'appRedux.sagas.register.address.subtitle',
          })}
        </p>
        <ul>
          {Array.isArray(registerAddressError) ? (
            registerAddressError.map((error) => (
              <li key={error} style={{ fontWeight: 500 }}>
                {error}
              </li>
            ))
          ) : (
            <li style={{ fontWeight: 500 }}>{registerAddressError}</li>
          )}
        </ul>
      </>
    );

    yield put(setAddressError());
    openNotificationWithIcon(
      'error',
      intl.formatMessage({ id: 'appRedux.sagas.register.address.title' }),
      contentError,
      8
    );
  }
}

function* getAllAddress() {
  const address = yield call(getAllAddressRequest);
  if (address) yield put(getAllAddressSuccess({ addressList: address }));
}

function* getAddressBrazilAPI({ payload }) {
  const address = yield call(getAddressBrazilAPIRequest, payload);

  if (!address?.error) {
    message.success('Endereço encontrado com sucesso! ✅');
  } else {
    message.error('CEP não encontrado na base do ViaCEP.');
  }

  if (address) yield put(getAddressBrazilAPISuccess(address));
}

function* updateAddressSession({ payload }) {
  const { address, checkout } = yield select();
  const { currentStep } = checkout;
  const { id, deliveryAddress, navigationType, paymentType } = payload;

  const { zipcode, state, city, neighborhood, street, number } =
    deliveryAddress;

  const addressChanged = address.addressList.filter(
    (address) => address.id === Number(id)
  );

  addressChanged[0].city = city;
  addressChanged[0].state = state;
  addressChanged[0].street = street;
  addressChanged[0].zipcode = zipcode?.replace('-', '');
  addressChanged[0].street_number = number;
  addressChanged[0].complementary = '';
  addressChanged[0].neighborhood = neighborhood;

  const addressUpdated = yield call(updateAddressSessionRequest, payload);

  if (addressUpdated && navigationType === 'checkout') {
    yield put(updateAddressSessionSuccess(address.addressList));
    if (paymentType === 'bank_slip') yield put(setCurrentStep(currentStep + 1));
  } else {
    yield put(updateAddressSessionSuccess(address.addressList));
  }
}

function* removeAddress({ payload }) {
  const { address, userSetting } = yield select();
  const { settings } = userSetting;
  const { payment } = settings;
  const { address_id } = payment;

  if (payload === Number(address_id)) {
    yield put(removeFieldUserSetting(['address_id'], ['payment']));
    delete payment.address_id;
  }

  const addressList = address.addressList.filter(
    (address) => address.id !== payload
  );
  yield put(removeAddressSuccess(addressList));
  yield call(removeAddressRequest, payload);
}

function* setDefaultAddress({ payload }) {
  yield call(setDefaultAddressRequest, payload);
  yield put(getAllAddressList());
}

export function* onSetAddress() {
  yield takeEvery(SET_ADDRESS, setAddress);
}

export function* onGetAddress() {
  yield takeEvery(GET_ALL_ADDRESS, getAllAddress);
}

export function* onGetAddressBrazilAPI() {
  yield takeEvery(GET_ADDRESS_BRASIL_API, getAddressBrazilAPI);
}

export function* onUpdateAddressSession() {
  yield takeEvery(UPDATE_ADDRESS_SESSION, updateAddressSession);
}

export function* onRemoveAddress() {
  yield takeEvery(REMOVE_ADDRESS, removeAddress);
}

export function* onSetDefaultAddress() {
  yield takeLatest(SET_DEFAULT_ADDRESS, setDefaultAddress);
}

export default function* rootSaga() {
  yield all([
    fork(onSetAddress),
    fork(onGetAddress),
    fork(onGetAddressBrazilAPI),
    fork(onRemoveAddress),
    fork(onSetDefaultAddress),
    fork(onUpdateAddressSession),
  ]);
}
