import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import PasswordInput from 'shared/components/atoms/PasswordInput';
import { validateEmail } from 'shared/utils/regex';
import { emailProviderValidator } from '../../../../../../../utils/emailProviderValidator';

import Input from '../../../../components/Input';
import EmailInvalid from '../../../../components/EmailInvalid';
import PasswordInvalid from '../../../../components/PasswordInvalid';

import { LoginStyle } from './styles';

function Login({ title, description, handleRegister, navigate }) {
  const intl = useIntl();

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [name, setName] = useState('');

  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const settings = useSelector(({ tenancy }) => tenancy.tenantData.settings);

  useEffect(() => {
    if (!email) {
      setEmailInvalid(false);
    }
  }, [email]);

  useEffect(() => {
    if (password?.length < 6) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    if (!password) {
      setPasswordInvalid(false);
    }
  }, [password]);

  const handleOnSubmit = () => {
    handleRegister({ password, email, name });
  };

  const disableButton =
    !email || !password || !name || emailInvalid || passwordInvalid;

  return (
    <LoginStyle hastext={title || description} $isDarkMode={isDarkMode}>
      <LoginStyle.Item>
        <Input
          id="username"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          isDarkMode={isDarkMode}
          onChange={(e) => setName(e.target.value)}
          placeholder={intl?.formatMessage({
            id: 'authModal.register.nameAndSurname',
          })}
        />
      </LoginStyle.Item>

      <LoginStyle.Item>
        <Input
          id="email"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          isDarkMode={isDarkMode}
          value={email}
          onChange={(e) => {
            const emailValue = e.target.value;

            setEmail(
              emailValue.toLowerCase().replace(/[^a-zA-Zs0-9@._-]/g, '')
            );
          }}
          onBlur={() => {
            if (email.length) {
              if (validateEmail(email) && emailProviderValidator(email)) {
                setEmailInvalid(false);
              } else {
                setEmailInvalid(true);
              }
            }
          }}
          onFocus={() => {
            setEmailInvalid(false);
          }}
          placeholder="Email"
        />
        {emailInvalid && <EmailInvalid isDarkMode={isDarkMode} />}
      </LoginStyle.Item>

      <PasswordInput
        FormItemComponent={LoginStyle.Item}
        InputComponent={Input}
        getValue={setPassword}
      />
      {passwordInvalid && <PasswordInvalid isDarkMode={isDarkMode} />}

      {settings?.users?.userSignUpDescriptionBottom ? (
        <div
          className="bottomDescription"
          dangerouslySetInnerHTML={{
            __html: settings?.users?.userSignUpDescriptionBottom,
          }}
        />
      ) : null}

      <LoginStyle.Item className="gx-text-center">
        <Button
          type="primary"
          className="primary"
          htmlType="submit"
          block
          size="large"
          disabled={disableButton}
          onClick={handleOnSubmit}
        >
          {intl?.formatMessage({
            id: 'authModal.register.registerAndContinue',
          })}
        </Button>
      </LoginStyle.Item>

      <button
        onClick={(e) => {
          e.preventDefault();
          navigate('login');
        }}
        type="button"
        className="link"
      >
        {intl?.formatMessage({
          id: 'authModal.register.alreadyHaveAccount',
        })}
        <b>
          {intl?.formatMessage({
            id: 'authModal.register.login',
          })}
        </b>
      </button>
    </LoginStyle>
  );
}

Login.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  handleRegister: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Login;
