import {
  GET_COURSES_KEEP_WATCHING_SUCCESS,
  GET_COURSES_KEEP_WATCHING,
} from 'shared/providers/redux/actionTypes';

export const fetchCoursesKeepWatching = () => ({
  type: GET_COURSES_KEEP_WATCHING,
});

export const fetchCoursesKeepWatchingSucess = (courses) => ({
  type: GET_COURSES_KEEP_WATCHING_SUCCESS,
  payload: courses,
});
