import axios from 'axios';

import { getMode } from 'shared/infra/services/tenantAPI';

const slack = axios.create();

const sendLogToSlack = (logText) => {
  const returnEnvAsBool = false;
  const enviroment = getMode(returnEnvAsBool);

  const payload = { text: logText };

  switch (enviroment) {
    case 'production':
      slack.post(
        'https://hooks.slack.com/services/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',
        JSON.stringify(payload)
      );
      break;

    default:
      if (window.location.hostname !== 'localhost') {
        slack.post(
          'https://hooks.slack.com/services/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',
          JSON.stringify(payload)
        );
      }
      break;
  }
};

export default sendLogToSlack;
