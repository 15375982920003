import {
  GET_BUNDLES,
  GET_BUNDLES_SUCCESS,
  GET_SINGLE_BUNDLE,
  GET_SINGLE_BUNDLE_SUCCESS,
  GET_BUNDLE_COURSES,
  GET_BUNDLE_COURSES_SUCCESS,
  GET_BUNDLE_MEDIA_CONTENTS,
  GET_BUNDLE_MEDIA_CONTENTS_SUCCESS,
  FILTER_BUNDLE_BY,
  FILTER_BUNDLE_BY_SUCCESS,
  LOAD_MORE_BUNDLES,
  LOAD_MORE_BUNDLES_SUCCESS,
  REMOVE_BUNDLE_DATA,
} from 'shared/providers/redux/actionTypes';

export const onGetBundles = (page, filterBy = null) => ({
  type: GET_BUNDLES,
  payload: { page, filterBy },
});

export const onGetBundlesSuccess = (bundles, filterBy) => ({
  type: GET_BUNDLES_SUCCESS,
  payload: { bundles, filterBy },
});

export const getSingleBundle = (slug) => ({
  type: GET_SINGLE_BUNDLE,
  payload: slug,
});

export const getSingleBundleSuccess = (bundle) => ({
  type: GET_SINGLE_BUNDLE_SUCCESS,
  payload: bundle,
});

export const loadMoreBundles = (currentPage) => ({
  type: LOAD_MORE_BUNDLES,
  payload: { currentPage },
});

export const loadMoreBundlesSuccess = (bundles) => ({
  type: LOAD_MORE_BUNDLES_SUCCESS,
  payload: { bundles },
});

export const getBundleCourses = (bundleSlug, page) => ({
  type: GET_BUNDLE_COURSES,
  payload: { bundleSlug, page },
});

export const getBundleCoursesSuccess = (courses) => ({
  type: GET_BUNDLE_COURSES_SUCCESS,
  payload: courses,
});

export const getBundleMediaContents = (bundleSlug, page) => ({
  type: GET_BUNDLE_MEDIA_CONTENTS,
  payload: { bundleSlug, page },
});

export const getBundleMediaContentsSuccess = (contents) => ({
  type: GET_BUNDLE_MEDIA_CONTENTS_SUCCESS,
  payload: contents,
});

export const filterBundleBy = ({ category }) => ({
  type: FILTER_BUNDLE_BY,
  payload: { category },
});

export const filterBundleBySuccess = (bundles) => ({
  type: FILTER_BUNDLE_BY_SUCCESS,
  payload: bundles,
});

export const removeBundleData = () => ({
  type: REMOVE_BUNDLE_DATA,
});
