import {
  GET_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  countriesList: JSON.parse(localStorage.getItem('countries')),
  loader: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        loader: true,
      };

    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loader: false,
        countriesList: action.payload,
      };

    default:
      return state;
  }
};
