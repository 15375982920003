const autoLogin = ({ user, token, userId }) => {
  localStorage.setItem('user', user);
  localStorage.setItem('token', token);
  localStorage.setItem('user_id', userId);

  if (!window.location.pathname.includes('authentication'))
    window.open('/', '_self');
};

export default autoLogin;
