import {
  CLEANING_NEWLY_CREATED_REVIEWS,
  CREATE_REVIEW_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  reviews: null,
  loading: false,
  newlyCreatedReviews: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REVIEWS: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };
    }

    case CREATE_REVIEW_SUCCESS: {
      return {
        ...state,
        newlyCreatedReviews: [...state.newlyCreatedReviews, action.payload],
      };
    }

    case CLEANING_NEWLY_CREATED_REVIEWS: {
      return {
        ...state,
        newlyCreatedReviews: [],
      };
    }

    case DELETE_REVIEW_SUCCESS: {
      const { reviewId, stateArray } = action.payload;

      if (stateArray === 'newlyCreatedReviews') {
        return {
          ...state,
          newlyCreatedReviews: state.newlyCreatedReviews.filter(
            (review) => review.data.id !== reviewId
          ),
        };
      }

      return {
        ...state,
        reviews: {
          ...state.reviews,
          data: state.reviews.data.filter(
            (review) => review.data.id !== reviewId
          ),
        },
      };
    }

    default:
      return state;
  }
};
