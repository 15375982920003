import React, { memo, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { EmitProvider } from 'react-emit';
import { useDispatch, useSelector } from 'react-redux';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import AppLoader from 'shared/components/atoms/AppLoader';
import languages from 'shared/providers/i18n';
import MaintenanceWarn from 'shared/components/organisms/MaintenanceWarn';
import {
  getCountries,
  onGetMainMenu,
  onGetMenuShortcuts,
  onGetProfileMenu,
  fetchTenantData,
  switchLanguage,
} from 'shared/providers/redux/actions';
import { MAINTENANCE_MODE } from 'shared/config';
import getDefaultLanguage from 'shared/utils/getDefaultLanguage';
import EmailConfirmation from 'modules/Auth/routes/EmailConfirmation';
import ResetPassword from 'modules/Auth/routes/ResetPassword';

import ComponentsTree from './ComponentsTree';
import PageRoutes from './PageRoutes';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const tenantData = useSelector(({ tenancy }) => tenancy?.tenantData);
  const mode = useSelector(({ settings }) => settings?.mode);
  const locale = useSelector(({ settings }) => settings?.locale);
  const countriesList = useSelector(
    ({ countries }) => countries?.countriesList
  );

  const isDarkMode = mode === 'dark';
  const i18n = languages[locale.locale];

  const favIconType = tenantData?.settings['general-theme']?.themeFavicon;
  const favIcon = tenantData?.settings['general-theme']?.themeFaviconCustom;
  const defaultFavIcon = isDarkMode
    ? tenantData?.settings['general-theme']?.themeDarkModeLogoIcon
    : tenantData?.settings['general-theme']?.themeLogoIcon;

  useEffect(() => {
    if (!tenantData) {
      dispatch(fetchTenantData(window.location.hostname));
    } else {
      document.title = tenantData.settings.site.seoSiteTitle;

      document.querySelector('meta[name="keywords"]').content =
        tenantData.settings.site.seoSiteKeywords;
      document.querySelector('meta[name="description"]').content =
        tenantData.settings.site.seoSiteDescription;
      document.querySelector('meta[name="theme-color"]').content =
        tenantData.settings['general-theme']?.themeMasterColor;

      if (favIconType !== 'none') {
        document.querySelector('link[rel="shortcut icon"]').href =
          favIconType === 'default' ? defaultFavIcon : favIcon;
      }

      const defaultLanguage = getDefaultLanguage(
        tenantData?.settings?.site?.defaultLanguage
      );

      dispatch(switchLanguage(defaultLanguage));
    }

    // eslint-disable-next-line
  }, [tenantData]);

  useEffect(() => {
    if (tenantData) {
      dispatch(onGetMainMenu());
      dispatch(onGetProfileMenu());
      dispatch(onGetMenuShortcuts());
    }
  }, [dispatch, tenantData]);

  useEffect(() => {
    if (tenantData && (location.pathname === '' || location.pathname === '/')) {
      const initialRoute = tenantData?.settings?.site?.mainPage || 'dashboard';

      history.push(`/${initialRoute}`);
    }
  }, [location, history, tenantData]);

  useEffect(() => {
    if (
      location.pathname === '/payment' &&
      !sessionStorage.getItem('product_session') &&
      !sessionStorage.getItem('checkout_direct_link_id')
    )
      history.push('/');
  }, [location, history]);

  useEffect(() => {
    if (!countriesList && tenantData) dispatch(getCountries());
  }, [dispatch, tenantData, countriesList]);

  return (
    <>
      {MAINTENANCE_MODE ? (
        <MaintenanceWarn />
      ) : (
        <>
          {tenantData ? (
            <ConfigProvider locale={i18n.antd}>
              <EmitProvider>
                <IntlProvider
                  defaultLocale="br"
                  locale={i18n.locale}
                  messages={i18n.messages}
                >
                  <Switch>
                    <Route
                      exact
                      path="/reset/:token?"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/confirm/:token?"
                      component={EmailConfirmation}
                    />
                    <PageRoutes
                      location={location}
                      component={ComponentsTree}
                    />
                  </Switch>
                </IntlProvider>
              </EmitProvider>
            </ConfigProvider>
          ) : (
            <AppLoader />
          )}
        </>
      )}
    </>
  );
};

export default memo(App);
