import React, { useState, useCallback, memo } from 'react';
import { FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import ModalContent from './ModalContent';

import { Container, Header, CloseButton } from './styles';

const OfflineFooterModal = memo(() => {
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = useCallback(() => {
    if (showModal) setShowModal(false);

    setTimeout(() => {
      setShowModal(true);
    }, 300000);
  }, [showModal]);

  const theme = useSelector(({ settings }) => settings.mode);
  const tenantData = useSelector(({ tenancy }) => tenancy.tenantData);

  const platformIsNotPrivate =
    tenantData?.settings.site.privatePlatform === '0';

  const generalTheme = tenantData?.settings['general-theme'];

  return (
    <>
      {platformIsNotPrivate && showModal && (
        <Container $generalTheme={generalTheme} $isDarkMode={theme === 'dark'}>
          <Header>
            <CloseButton
              type="button"
              className="close"
              onClick={handleCloseModal}
              $isDarkMode={theme === 'dark'}
              $generalTheme={generalTheme}
              backgroundColor={generalTheme?.themeMasterColor}
            >
              <FiX />
            </CloseButton>
          </Header>

          <ModalContent />
        </Container>
      )}
    </>
  );
});

export default OfflineFooterModal;
