import { createGlobalStyle } from 'styled-components';

export const GlobalDarkTheme = createGlobalStyle`
  input:-webkit-autofill {
    background: content-box;
    box-shadow: inset 0 0 0 50px #282828 !important;
    -webkit-text-fill-color: #fff;
  }
  .slick-dots .slick-active {
    background-color: ${(props) =>
      props.tenantData.settings['general-theme']
        ?.themeDarkModeMasterColor} !important;
  }

  .ant-empty,
  .shortcuts-text,
  .ant-select-dropdown,
  .shortcuts-on-mobile,
  .shortcuts-popover svg,
  .ant-notification-notice-message,
  .ant-notification-notice-description p,
  .ant-notification-notice-description ul li,
  .anticon-close.ant-notification-close-icon,
  .slick-slider .slick-next:before,
  .slick-slider .slick-prev:before {
    color: #ffffff !important;
  }

  .modal-remove-comment {
    .ant-modal-header {
      border-bottom: none !important;
    }
    .ant-modal-footer {
      border-top: none !important;
    }
  }

  .ant-radio-group {
    .ant-radio-button-wrapper {
      background: #303030;
      border-color: #303030;
      span {
        color: #ffffff;
      }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      background: ${(props) =>
        props.tenantData.settings['general-theme']
          .themeDarkModeMasterColor} !important;
      border-color: ${(props) =>
        props.tenantData.settings['general-theme']
          .themeDarkModeMasterColor} !important;

      span,
      span svg {
        color: #121212 !important;
      }
    }
  }

  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: #ffffff !important;
  }

  .ant-input-group-addon {
    background: linear-gradient(to bottom, #181818, #242424);
  }
  .ant-select-selection {
    box-shadow: rgba(0, 0, 0, 0.69) 0px 0px 100px -20px,
      rgba(0, 0, 0, 0.73) 0px 9px 29px -10px;
  }
  .anticon.anticon-close-circle.ant-cascader-picker-clear,
  .ant-popover-inner,
  .ant-select-selection {
    background: #242424;
    border-color: #242424;
    .ant-popover-message {
      color: #fff;
    }
  }

  .ql-tooltip.ql-editing {
    background: #252525;
    box-shadow: 0 0 5px 0px #272727;
    border: 1px solid #242424;
    color: #fff;

    input {
      border: 1px solid #242424 !important;
      background: #303030;
      color: #fff;
      outline: none;
    }
  }

  .ant-popover-placement-leftTop .ant-popover-arrow {
    border-right-color: #242424 !important;
  }

  .ant-popover-arrow {
    border-top-color: #242424 !important;
    border-left-color: #242424 !important;
  }

  .ant-btn-primary,
  .ant-btn-primary:focus,
  .ant-btn-primary:hover,
  .ant-btn-primary.disabled,
  .ant-btn-primary:disabled,
  .ant-input-search-button.ant-btn-primary:disabled {
    background-color: ${(props) =>
      props.tenantData.settings['general-theme']
        ?.themeDarkModeMasterColor} !important;
    border-color: transparent;
    color: #1a1a1a !important;
  }

  .buy-btn.ant-btn-primary,
  .buy-btn.ant-btn-primary:focus,
  .buy-btn.ant-btn-primary:hover,
  .buy-btn.ant-btn-primary.disabled,
  .buy-btn.ant-btn-primary:disabled {
    background-color: ${(props) =>
      props.tenantData.settings['advanced-theme']?.themeBuyButtonsOption !==
        'automatic' &&
      props.tenantData.settings['advanced-theme']
        ?.themeBuyButtonsColor} !important;
    border-color: transparent;
  }

  .ant-btn.ant-btn-link,
  .ant-btn.ant-btn-link.ant-btn-sm {
    background-color: unset !important;
    color: #fff !important;
  }
  .sweet-alert {
    &,
    div {
      background: #303030 !important;
    }

    span {
      z-index: 3 !important;
    }

    div,
    h2,
    p {
      color: #ffffff !important;
    }

    a.btn.btn-lg.btn-primary {
      box-shadow: none !important;
      color: #1a1a1a !important;
      border: none;
      background-color: ${(props) =>
        props.tenantData.settings['general-theme']
          ?.themeDarkModeMasterColor} !important;
    }
  }

  .ant-popover-inner-content {
    h3,
    h5 {
      color: #ffffff;
    }
    .ant-menu {
      background: #323232;
      a,
      span {
        color: #ffffff;
      }
    }
  }

  .search-container {
    box-shadow: rgba(255, 255, 255, 0.69) 0px 0px 200px -20px,
      rgba(0, 0, 0, 0.73) 0px 9px 20px -10px;
  }

  input.ant-input,
  .ant-input-affix-wrapper {
    background-color: #282828 !important;
    border-color: #282828 !important;
  }

  hr {
    background: #000000;
  }

  .gx-popover-header {
    border-color: #242424;
  }

  .ant-select-arrow,
  .ant-select-selection__placeholder,
  .ant-select-selection__clear {
    color: #ffffff !important;
  }

  .ant-select-selection__clear {
    background: transparent;
  }

  .ant-cascader-menu,
  .ant-select-dropdown {
    background: #242424 !important;
    border-radius: 6px;
    box-shadow: 0 0 2px 2px #242424;
    * {
      color: #ffffff;
    }
  }

  .ant-notification-notice,
  li.ant-cascader-menu-item-active,
  li.ant-select-item-option-content,
  div.ant-select-item-option-selected {
    background: #323232 !important;
  }

  .ant-select-item-option-active {
    background: transparent !important;
    color: #ffffff;
  }

  @media (max-width: 990px) {
    .points .dollar-icon {
      color: ${(props) => props?.generalTheme?.themeDarkModeMasterColor};
    }
  }
`;
