import {
  FETCH_PRODUCT_SUCCESS,
  FETCH_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
} from 'shared/providers/redux/actionTypes';

export const getProduct = slug => ({
  type: GET_PRODUCT,
  payload: slug,
});

export const getAllProducts = () => ({
  type: GET_ALL_PRODUCTS,
});

export const fetchProductSuccess = product => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: product,
});

export const fetchAllProductsSuccess = product => ({
  type: FETCH_ALL_PRODUCTS_SUCCESS,
  payload: product,
});
