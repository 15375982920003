import styled from 'styled-components';

const getTopbarIconsColor = (topbarTheme, tenantTopbarIconColor) => {
  switch (topbarTheme) {
    case 'dark':
      return '#ffffff';

    case 'automatic':
      return '#ffffff';

    case 'light':
      return '#545454';

    case 'gradient':
      return '#1a1a1a';

    case 'transparent':
      return '#1a1a1a';

    case 'advanced':
      return tenantTopbarIconColor;

    default:
      return '#ffffff';
  }
};

export const DefaultThemeSettings = styled.div`
  --topbar-icon-color: ${({ tenantTheme }) => {
    const topbarTheme = tenantTheme['general-theme']?.themeTopBar;
    const tenantTopbarIconColor =
      tenantTheme['general-theme']?.themeTopBarIconsColor;

    return getTopbarIconsColor(topbarTheme, tenantTopbarIconColor);
  }};

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  .search-container-on-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;

    width: 100vw;
    height: 62px;

    padding: 10px 20px;

    top: 60px;
    right: 0;
    left: 0;
    box-shadow: inset 0px 2px 1px 0px #0000001f;

    > div {
      padding: 0;
    }
  }
`;
