import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getPageSuccess } from 'shared/providers/redux/actions';
import { GET_PAGE } from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const getPageRequest = async payload => {
  try {
    const { data } = await api.get(`page/${payload}`);
    return data.data;
  } catch (error) {
    console.log('error -->', error);
  }
};

function* getPage({ payload }) {
  const pageData = yield call(getPageRequest, payload);
  yield put(getPageSuccess(pageData));
}

export function* fetchPage() {
  yield takeEvery(GET_PAGE, getPage);
}

export default function* rootSaga() {
  yield all([fork(fetchPage)]);
}
