export const isCheckoutRoute = (location) => {
  const checkoutRoutes = [
    '/payments',
    '/customer',
    '/installment',
    '/registerCreditCard',
    '/billingInformation',
    '/addressList',
    '/formAddress',
    '/buyErrors',
    '/congrats/card',
    '/congrats/bankSlip',
    '/singleReview',
    '/shippingSplit',
    '/singleShippingOptions',
  ];

  return checkoutRoutes.indexOf(location.pathname) !== -1;
};
