import {
  SET_COUPON,
  SET_COUPON_SUCCESS,
  REMOVE_COUPON_BOOKING,
  REMOVE_COUPON_BOOKING_ERROR,
  REMOVE_COUPON_BOOKING_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  couponInfos: null,
  errorMessage: '',
  loader: false,
  removeCouponBookingloader: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COUPON:
      return {
        ...state,
        loader: true,
      };

    case SET_COUPON_SUCCESS:
      return {
        ...state,
        loader: false,
        couponInfos: action.payload.couponInfos,
        errorMessage: action.payload.errorMessage,
      };

    case REMOVE_COUPON_BOOKING:
      return {
        ...state,
        removeCouponBookingloader: true,
      };

    case REMOVE_COUPON_BOOKING_ERROR:
      return {
        ...state,
        couponInfos: null,
        removeCouponBookingloader: false,
      };

    case REMOVE_COUPON_BOOKING_SUCCESS:
      return {
        ...state,
        couponInfos: null,
        removeCouponBookingloader: false,
      };

    default:
      return state;
  }
};
