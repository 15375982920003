import {
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOG_COMMENTS,
  GET_BLOG_COMMENTS_SUCCESS,
  GET_FEATURED_BLOG,
  GET_FEATURED_BLOG_SUCCESS,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_FAILED,
  GET_SINGLE_BLOG_SUCCESS,
  SET_DISLIKE_BLOG,
  SET_LIKE_BLOG,
} from 'shared/providers/redux/actionTypes';

export const getSingleBlog = (slug) => ({
  type: GET_SINGLE_BLOG,
  payload: { slug },
});

export const getSingleBlogSuccess = (blog) => ({
  type: GET_SINGLE_BLOG_SUCCESS,
  payload: { blog },
});

export const getSingleBlogFailed = () => ({
  type: GET_SINGLE_BLOG_FAILED,
});

export const getBlogs = (payload) => ({
  type: GET_BLOGS,
  payload: {
    perPage: payload?.perPage || '15',
    page: payload?.page || '1',
  },
});

export const getBlogsSuccess = (blogs) => ({
  type: GET_BLOGS_SUCCESS,
  payload: blogs,
});

export const onGetFeaturedBlog = () => ({
  type: GET_FEATURED_BLOG,
});

export const onGetFeaturedBlogSuccess = ({ data }) => ({
  type: GET_FEATURED_BLOG_SUCCESS,
  payload: data.data,
});

export const onGetBlogComments = (slug) => ({
  type: GET_BLOG_COMMENTS,
  payload: slug,
});

export const getBlogCommentsSuccess = ({ data, id }) => ({
  type: GET_BLOG_COMMENTS_SUCCESS,
  payload: { data, id },
});

export const setLikeBlog = (postSlug) => {
  const slug = postSlug;

  return {
    type: SET_LIKE_BLOG,
    payload: slug,
  };
};

export const setDislikeBlog = (postSlug) => {
  const slug = postSlug;

  return {
    type: SET_DISLIKE_BLOG,
    payload: slug,
  };
};
