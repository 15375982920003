import {
  GET_ALL_CREDIT_CARDS,
  FETCH_ALL_CREDIT_CARDS_SUCCESS,
  REMOVE_CREDIT_CARD,
  REMOVE_CREDIT_CARD_SUCCESS,
  SET_CREDIT_CARD,
  SET_ID_CREDIT_CARD,
  SET_CREDIT_CARD_SUCCESS,
  SET_CREDIT_CARD_ERROR,
  SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD,
  SET_CURRENT_STEP_REGISTER_CREDIT_CARD,
  SET_OPEN_MODAL_REGISTER_CREDIT_CARD,
  SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS,
  SET_PAYMENT_OPTIONS_MODAL_OPEN,
  CLEAR_PURCHASE_OPTIONS,
} from 'shared/providers/redux/actionTypes';

// Method register credit card for checkout
export const setCreditCard = ({
  cvc,
  phone,
  name,
  expiry,
  number,
  document,
  address_id,
  type_entity,
  document_type,
  installment = 1,
  subscriptionId = null,
  navigation_type = null,
}) => ({
  type: SET_CREDIT_CARD,
  payload: {
    cvc,
    name,
    expiry,
    number,
    phone,
    document,
    address_id,
    installment,
    type_entity,
    document_type,
    subscriptionId,
    navigation_type,
  },
});

export const setCreditCardSuccess = (cardsList) => ({
  type: SET_CREDIT_CARD_SUCCESS,
  payload: cardsList,
});

export const setIdCreditCard = (cardId) => ({
  type: SET_ID_CREDIT_CARD,
  payload: cardId,
});

export const setCreditCardError = () => ({
  type: SET_CREDIT_CARD_ERROR,
});

export const setIsVisibleFormNewCreditCard = (isVisibleFormNewCreditCard) => ({
  type: SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD,
  payload: isVisibleFormNewCreditCard,
});

export const removeCreditCard = (cardId) => ({
  type: REMOVE_CREDIT_CARD,
  payload: cardId,
});

export const removeCreditCardSuccess = (cardsList) => ({
  type: REMOVE_CREDIT_CARD_SUCCESS,
  payload: cardsList,
});

export const getAllCreditCards = (loader) => ({
  type: GET_ALL_CREDIT_CARDS,
  payload: loader,
});

export const getAllCreditCardsSuccess = (cardsList) => ({
  type: FETCH_ALL_CREDIT_CARDS_SUCCESS,
  payload: cardsList,
});

// Methods register credit card for account or subscriptions - START
export const setCreditCardOnSubscriptionOrAccountSuccess = (cardId) => ({
  type: SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS,
  payload: cardId,
});

export const setOpenModalForRegisterCreditCard = (isVisible) => ({
  type: SET_OPEN_MODAL_REGISTER_CREDIT_CARD,
  payload: isVisible,
});

export const setCurrentStepRegisterCreditCard = (current) => ({
  type: SET_CURRENT_STEP_REGISTER_CREDIT_CARD,
  payload: current,
});

// Methods register credit card for account or subscriptions - END
export const setPaymentOptionsModalOpen = ({
  entity,
  context,
  handlePurchase,
  show,
}) => ({
  type: SET_PAYMENT_OPTIONS_MODAL_OPEN,
  payload: { entity, context, handlePurchase, show },
});

export const clearPurchaseOptions = () => ({
  type: CLEAR_PURCHASE_OPTIONS,
});
