import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Dropdown, Menu } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import CustomScrollbars from 'shared/components/atoms/CustomScrollbar';
import Auxiliary from 'shared/components/atoms/Auxiliary';
import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import EmptyData from 'shared/components/atoms/EmptyData';
import {
  onGetNotifications,
  onMarkReadNotificationAll,
  onRemoveAllNotifications,
} from 'shared/providers/redux/actions';

import NotificationItem from './NotificationItem';
import {
  Container,
  IconSettingOutlined,
  ContainerEmptyData,
  MenuAnt,
} from './styles';

const AppNotification = () => {
  const dispatch = useDispatch();
  const { notificationsList, loader } = useSelector(
    ({ notifications }) => notifications
  );
  const { mode } = useSelector(({ settings }) => settings);

  const menu = (
    <MenuAnt theme={mode}>
      <Menu.Item
        key="0"
        onClick={() => dispatch(onMarkReadNotificationAll())}
        disabled={!notificationsList.length}
      >
        <CheckOutlined /> <StaticIntlTranslator id="notification.mark.all.read" />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={() => dispatch(onRemoveAllNotifications())}
        disabled={!notificationsList.length}
      >
        <DeleteOutlined /> <StaticIntlTranslator id="notification.clear.all" />
      </Menu.Item>
    </MenuAnt>
  );

  useEffect(() => {
    dispatch(onGetNotifications());
  }, [dispatch]);

  return (
    <Container>
      <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">
            <StaticIntlTranslator id="topbar.popover.title.notification" />
          </h3>

          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <IconSettingOutlined />
          </Dropdown>
        </div>

        <CustomScrollbars className="gx-popover-scroll">
          {loader ? (
            <div className="container-loader">
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
              <Skeleton avatar active loading={loader} />
            </div>
          ) : (
            <ul className="gx-sub-popover">
              {notificationsList.length > 0 ? (
                notificationsList.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                  />
                ))
              ) : (
                <ContainerEmptyData>
                  <EmptyData />
                </ContainerEmptyData>
              )}
            </ul>
          )}
        </CustomScrollbars>
      </Auxiliary>
    </Container>
  );
};

export default AppNotification;
