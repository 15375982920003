import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import { filterGlobalSuccess } from 'shared/providers/redux/actions';
import { GET_FILTER } from 'shared/providers/redux/actionTypes';

const getFilterRequest = async (payload) => {
  const { options, entity } = payload;
  const { apiURL } = options;
  const queryParams = {};
  let whereHas = 0;

  const orderBy = options.order === 'recent' ? 'desc' : 'asc';

  // COMMUNITY

  if (entity === 'community') {
    const { topic, authors, order } = options;

    if (topic) {
      queryParams['where[0][index]'] = 'name';
      queryParams['where[0][condition]'] = 'like';
      queryParams['where[0][value]'] = topic;
    }

    if (authors.length > 0) {
      authors.map((author) => {
        queryParams[`whereHas[${whereHas}][index]`] = 'username';
        queryParams[`whereHas[${whereHas}][condition]`] = 'like';
        queryParams[`whereHas[${whereHas}][value]`] = author;
        queryParams[`whereHasWith[${whereHas}]`] = 'User';

        return null;
      });

      whereHas += 1;
    }

    if (order) {
      if (order === 'most-commented') {
        queryParams['orderRelation[0][relation]'] = 'comments';
        queryParams['orderRelation[0][value]'] = 'desc';
      } else if (order === 'last-commented') {
        queryParams.order_by_last_comment = '1';
      } else {
        queryParams[`order[${whereHas}][index]`] = 'created_at';
        queryParams[`order[${whereHas}][value]`] = orderBy;
      }
    }
  }

  // ORDERS

  if (entity === 'orders') {
    const { status, uuid } = options;

    if (status) {
      queryParams['where[0][index]'] = 'status';
      queryParams['where[0][condition]'] = 'like';
      queryParams['where[0][value]'] = status;
    }

    if (uuid) {
      queryParams['where[1][index]'] = 'uuid';
      queryParams['where[1][condition]'] = 'like';
      queryParams['where[1][value]'] = uuid;
    }
  }

  // COLLECTIONS

  if (entity === 'collections') {
    const { name, className } = options;

    if (name) {
      queryParams['where[0][index]'] = 'name';
      queryParams['where[0][condition]'] = 'like';
      queryParams['where[0][value]'] = name;
    }

    if (className) {
      queryParams[`whereHas[0][index]`] = 'collectionable_type';
      queryParams[`whereHas[0][condition]`] = '=';
      queryParams[`whereHas[0][value]`] = className;
      queryParams[`whereHasWith[0]`] = 'UserCollectionItems';
    }
  }

  try {
    const response = await api.get(apiURL, { params: queryParams });

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* getFilter({ payload }) {
  const { data, attrs, meta } = yield call(getFilterRequest, payload);

  let dataFilter = data || attrs;

  if (payload?.entity === 'orders') {
    const aux = dataFilter.filter((item) => item?.transactions?.length > 0);

    dataFilter = aux;
  }

  if (dataFilter) yield put(filterGlobalSuccess({ data: dataFilter, meta }));
}

export function* getFilterSaga() {
  yield takeLatest(GET_FILTER, getFilter);
}

export default function* rootSaga() {
  yield all([fork(getFilterSaga)]);
}
