import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Card } from './styles';

const Widget = ({
  title,
  children,
  styleName,
  cover,
  extra,
  actions,
  transparentDarkDisabled,
}) => {
  const mode = useSelector(({ settings }) => settings.mode);

  return (
    <Card
      title={title}
      theme={mode}
      transparentDarkDisabled={transparentDarkDisabled}
      actions={actions}
      cover={cover}
      className={`gx-card-widget ${styleName}`}
      extra={extra}
    >
      {children}
    </Card>
  );
};

Widget.defaultProps = {
  title: '',
  styleName: '',
  extra: null,
  cover: null,
  actions: null,
  children: null,
  transparentDarkDisabled: true,
};

Widget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styleName: PropTypes.string,
  extra: PropTypes.any,
  cover: PropTypes.any,
  actions: PropTypes.any,
  children: PropTypes.any,
  transparentDarkDisabled: PropTypes.bool,
};

export default Widget;
