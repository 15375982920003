import ModelList from 'modules/GlobalSearch/components/molecules/ModelList';
import useGlobalSearch from 'modules/GlobalSearch/hooks/useGlobalSearch';
import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Container, ModelClass } from './styles';

const SelectModelClass = () => {
  const intl = useIntl();
  const [showModelList, setShowModelList] = useState(false);

  const { activeModelClasses } = useGlobalSearch();

  const [firstModel] = activeModelClasses
    ?.filter(model => model?.name === 'globalSearch.courses')

  const defaultModel = firstModel || activeModelClasses[0]

  const modelClass = useSelector(
    ({ globalSearch }) => globalSearch?.modelClass
  );

  function handleClose () {
    setShowModelList(false);
  }

  return (
    <Container>
      <ModelClass onClick={() => setShowModelList(!showModelList)}>
        <span className="search-options">
          {modelClass?.name
            ? intl.formatMessage({ id: modelClass?.name })
            : intl.formatMessage({ id: defaultModel?.name })}
        </span>

        <FiChevronDown />
      </ModelClass>

      {showModelList && <ModelList onClose={handleClose} />}
    </Container>
  );
};

export default SelectModelClass;
