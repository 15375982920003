import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { isCheckoutRoute } from 'shared/utils/isCheckoutRoute';
import { setSidebarIsCollapsed } from 'shared/providers/redux/actions';

import { Container } from './styles';

const IconSidebar = () => {
  const dispatch = useDispatch();
  const [sidebarIsCollapse, setSidebarIsCollapse] = useState(true);

  const handleCollapseSidebar = () => {
    setSidebarIsCollapse(!sidebarIsCollapse);
    dispatch(setSidebarIsCollapsed());
  };

  const hideSidebarIcon = isCheckoutRoute(window.location);

  return (
    <>
      {!hideSidebarIcon && (
        <Container
          className="icon sidebar"
          isCollapsed={sidebarIsCollapse}
          onClick={handleCollapseSidebar}
        >
          <i className="gx-icon-btn icon icon-menu-unfold" />
        </Container>
      )}
    </>
  );
};

export default IconSidebar;
