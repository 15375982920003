import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';

import { fetchNotificationsSuccess } from 'shared/providers/redux/actions';
import api from 'shared/infra/services/tenantAPI';
import {
  GET_NOTIFICATIONS,
  MARK_READ_NOTIFICATION_ALL,
  MARK_UNVIEWED_NOTIFICATION,
  MARK_READ_NOTIFICATION_UNIQUE,
  REMOVE_UNIQUE_NOTIFICATIONS,
  REMOVE_ALL_NOTIFICATIONS,
  PUST_NOTIFICATION_REAL_TIME,
} from 'shared/providers/redux/actionTypes';

const getNotificationsRequest = async () => {
  try {
    const response = await api.get('notifications');
    return response.data;
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

const markReadNotificationAllRequest = async () => {
  try {
    await api.post('notifications/markasread/all');
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

const markReadNotificationUniqueRequest = async payload => {
  const { id } = payload;
  try {
    await api.post(`notifications/markasread/${id}`);
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

const markUnviewedNotificationsRequest = async () => {
  try {
    await api.post('notifications/markasviewed/all');
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

const removeUniqueNotificationRequest = async payload => {
  const { id } = payload;
  try {
    await api.delete(`notifications/delete/${id}`);
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

const removeAllNotificationsRequest = async () => {
  try {
    await api.delete('notifications/delete/all');
  } catch (error) {
    console.log('error ->', error);
  }

  return null;
};

function* getNotifications() {
  const { data } = yield call(getNotificationsRequest);
  if (data) yield put(fetchNotificationsSuccess(data));
}

function* markReadNotificationAll() {
  const { notifications } = yield select();

  const arr = notifications.notificationsList.filter(
    notification => notification.is_read !== true
  );

  arr.map(item => {
    const param = item;
    if (!item.is_read) param.is_read = true;

    return param;
  });

  yield put(fetchNotificationsSuccess(notifications.notificationsList));
  yield call(markReadNotificationAllRequest);
}

function* markReadNotificationUnique({ payload }) {
  yield call(markReadNotificationUniqueRequest, payload);
}

function* markUnviewedNotifications() {
  yield call(markUnviewedNotificationsRequest);
}

function* removeUniqueNotification({ payload }) {
  const { notifications } = yield select();
  const { id } = payload;

  const notificationsList = notifications.notificationsList.filter(
    notification => notification.id !== id
  );

  yield put(fetchNotificationsSuccess(notificationsList));
  yield call(removeUniqueNotificationRequest, payload);
}

function* removeAllNotifications() {
  yield put(fetchNotificationsSuccess([]));
  yield call(removeAllNotificationsRequest);
}

function* pushNotificationRealTime({ payload: { event } }) {
  const { notifications } = yield select();
  notifications.notificationsList.unshift(event);

  yield put(fetchNotificationsSuccess(notifications.notificationsList));
}

export function* onGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
}

export function* onMarkReadNotificationAll() {
  yield takeLatest(MARK_READ_NOTIFICATION_ALL, markReadNotificationAll);
}

export function* onMarkReadNotificationUnique() {
  yield takeLatest(MARK_READ_NOTIFICATION_UNIQUE, markReadNotificationUnique);
}

export function* onMarkUnviewedNotifications() {
  yield takeLatest(MARK_UNVIEWED_NOTIFICATION, markUnviewedNotifications);
}

export function* onRemoveUniqueNotification() {
  yield takeLatest(REMOVE_UNIQUE_NOTIFICATIONS, removeUniqueNotification);
}

export function* onRemoveAllNotifications() {
  yield takeLatest(REMOVE_ALL_NOTIFICATIONS, removeAllNotifications);
}

export function* onPushNotificationRealTime() {
  yield takeLatest(PUST_NOTIFICATION_REAL_TIME, pushNotificationRealTime);
}

export default function* rootSaga() {
  yield all([fork(onGetNotifications)]);
  yield all([fork(onMarkReadNotificationAll)]);
  yield all([fork(onMarkUnviewedNotifications)]);
  yield all([fork(onRemoveUniqueNotification)]);
  yield all([fork(onRemoveAllNotifications)]);
  yield all([fork(onMarkReadNotificationUnique)]);
  yield all([fork(onPushNotificationRealTime)]);
}
