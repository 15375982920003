import React from 'react';
import PropTypes from 'prop-types';

import sendLogToSlack from 'shared/infra/services/slackAPI';
import { getLocalUser } from 'shared/infra/services/auth';
import { detect } from 'detect-browser';
import { Container } from './styles';

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  );
  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  }
  window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    retryPageLoading();
    const lines = [
      `\n *🔴 Erro:* ${error.message}`,
      `\n *Origem:* ${window.location.href}`,
      `\n *Navegador:* ${this.getBrowserInfo()}`,
      `\n ${this.userInitials()}`,
      `\n *Linha:* ${this.formatStackTrace(error.stack)}`,
    ];

    const logText = lines.reduce(
      (currentText, currentLine) => currentText + currentLine,
      ''
    );

    if (!window.location.hostname.includes('localhost')) {
      sendLogToSlack(logText);
    }
  }

  getBrowserInfo = () => {
    const browser = detect();
    return `${browser.name} v${browser.version}`;
  };

  formatStackTrace = (stackTrace) => {
    const firstLine = stackTrace.split('\n').slice(1, 2).join('\n');
    return `\`\`\`${firstLine}\`\`\``;
  };

  userInitials = () => {
    const user = getLocalUser();
    if (user) {
      return `\n *Usuário:* @${user?.username} | ${user?.display_name} | ${user?.email}`;
    }
    return '';
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Container>
          <h1>Algo deu errado :(</h1>

          <p>Lamentamos o inconveniente.</p>
          <p>
            Geramos automaticamente um relatório sobre o ocorrido, e
            trabalharemos para corrigir o quanto antes.
          </p>

          <button onClick={() => window.location.reload()} type="button">
            Tentar novamente
          </button>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
