import axios from 'axios';

import { getFullEndPoint } from 'shared/infra/services/tenantAPI';
import { guestId } from './uuid';

const tenancyAPI = axios.create();

tenancyAPI.defaults.withCredentials = true;
tenancyAPI.interceptors.request.use(async config => {
  const tenancyAPIConfig = config;

  tenancyAPIConfig.baseURL = await getFullEndPoint(true);

  tenancyAPIConfig.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-GUEST-ID': guestId(),
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  tenancyAPIConfig.withCredentials = false;

  return tenancyAPIConfig;
});

export default tenancyAPI;
