/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { Container, AlreadyHaveAccountButton, OptionButtons } from './styles';

function FirstModal({ navigate }) {
  const intl = useIntl();

  const { settings } = useSelector(({ tenancy }) => tenancy.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const { site, users } = settings;
  const theme = settings['general-theme'];

  const userWarningImage = users?.userWarningImage;
  const warningImage = users?.userLoginRequiredWarningImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const { userWarningTitle, userWarningDescription } = users;

  return (
    <Container $isDarkMode={isDarkMode}>
      <div className="col">
        {userWarningImage === 'none' || platformHasNoImage ? (
          <h1 className="siteName">{site?.siteName}</h1>
        ) : isDarkMode ? (
          <img
            src={theme?.themeDarkModeLogoHorizontal || defaultImage}
            alt="logo"
          />
        ) : userWarningImage === 'default' ? (
          <img src={defaultImage || fallbackImage} alt="logo" />
        ) : (
          <img src={warningImage || fallbackImage || defaultImage} alt="logo" />
        )}

        <div className="titulos">
          <h1>
            {userWarningTitle?.length > 3
              ? userWarningTitle
              : intl?.formatMessage({
                  id: 'authModal.first.accessYourAccount',
                })}
          </h1>
          <h2>
            {userWarningDescription?.length > 3
              ? userWarningDescription
              : `${intl?.formatMessage({
                  id: 'authModal.first.accessYourPlatformAccount',
                })} ${site.siteName}`}
          </h2>
        </div>

        <OptionButtons $isDarkMode={isDarkMode}>
          <Button
            type="primary"
            className="primary"
            onClick={() => navigate('register')}
          >
            {intl?.formatMessage({
              id: 'authModal.first.createAnAccount',
            })}
          </Button>

          <AlreadyHaveAccountButton
            onClick={() => navigate('login')}
            $isDarkMode={isDarkMode}
          >
            {intl?.formatMessage({
              id: 'authModal.first.iAlreadyHave',
            })}
          </AlreadyHaveAccountButton>
        </OptionButtons>
      </div>
    </Container>
  );
}

FirstModal.propTypes = {
  navigate: PropTypes.any.isRequired,
};
export default FirstModal;
