import {
  SET_SIDEBAR_IS_COLLAPSED,
  SIDEBAR_IS_RENDERED,
} from 'shared/providers/redux/actionTypes';

export const setSidebarIsCollapsed = () => ({
  type: SET_SIDEBAR_IS_COLLAPSED,
});

export const sidebarIsRendered = (isRendered) => ({
  type: SIDEBAR_IS_RENDERED,
  payload: { isRendered },
});
