import {
  START_ENROLLMENT,
  START_ENROLLMENT_SUCCESS,
  CLEAN_STARTED_ENROLLMENT,
  CLEAN_NEWLY_CREATED_ENROLLMENT,
} from 'shared/providers/redux/actionTypes';

export const startEnrollment = ({ entityType, id }) => ({
  type: START_ENROLLMENT,
  payload: { entityType, id },
});

export const startEnrollmentSuccess = () => ({
  type: START_ENROLLMENT_SUCCESS,
});

export const cleanStartedEnrollment = () => ({
  type: CLEAN_STARTED_ENROLLMENT,
});

export const cleanNewlyCreatedEnrollment = () => ({
  type: CLEAN_NEWLY_CREATED_ENROLLMENT,
});
