import { useDispatch, useSelector } from 'react-redux';
import {
  onSearchGlobally, setModelClass
} from 'shared/providers/redux/actions';
import useDebounce from '../../../shared/utils/hooks/useDebounce';
import useModelList from './useModelList';

const useGlobalSearch = () => {
  const dispatch = useDispatch();
  const debouncedChange = useDebounce();
  const { activeModelClasses, modelClass } = useModelList();

  const searchText = useSelector(
    ({ globalSearch }) => globalSearch?.searchText
  );

  const [firstModel] = activeModelClasses
    ?.filter(model => model?.name === 'globalSearch.courses');

  const defaultModel = firstModel || activeModelClasses[0];

  function handleSearchGlobally (value, model) {
    const model_class = model || modelClass?.model;

    dispatch(
      onSearchGlobally(value, model_class || defaultModel?.model)
    );
  }

  function onSearchChange (e, model) {
    const search = e?.target?.value;

    if (search.length > 1) {
      debouncedChange(() => handleSearchGlobally(search, model), 500);
    }
  }

  function onSelectSearchMethod (model) {
    const event = { target: { value: searchText } };

    dispatch(setModelClass(model));
    onSearchChange(event, model?.model);
  }

  return {
    onSelectSearchMethod,
    onSearchChange,
    activeModelClasses,
  };
};

export default useGlobalSearch;
