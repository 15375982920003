import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import openNotificationWithIcon from 'shared/components/atoms/Notification';
import api from 'shared/infra/services/tenantAPI';
import { setDenounceSuccess } from 'shared/providers/redux/actions';
import { SET_DENOUNCE } from 'shared/providers/redux/actionTypes';

const setDenounceRequest = async (payload) => {
  const { contentType, entityId, reportedUserId, type, description } = payload;
  try {
    const response = await api.post('report', {
      reportgable_type: contentType,
      reportgable_id: entityId,
      reported_user_id: reportedUserId,
      type,
      description,
    });
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onSetDenounce({ payload }) {
  const response = yield call(setDenounceRequest, payload);
  if (response) {
    yield put(setDenounceSuccess());

    openNotificationWithIcon({
      type: 'success',
      message: 'Registramos sua denúncia!',
      description:
        'Sua denúncia será analisada por nossa equipe. Agradecemos o seu feedback!',
      duration: 6,
    });
  } else {
    openNotificationWithIcon({
      type: 'error',
      message: 'Erro ao denunciar o conteúdo.',
      duration: 6,
    });
  }
}

export function* setDenounce() {
  yield takeEvery(SET_DENOUNCE, onSetDenounce);
}

export default function* rootSaga() {
  yield all([fork(setDenounce)]);
}
