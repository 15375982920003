import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import antdNotification from 'shared/utils/openNotificationWithIcon';
import { setLastToastNotifications } from 'shared/providers/redux/actions';

const ToastNotification = () => {
    const dispatch = useDispatch();
    const { toastNotificationsList, lastToastNotificationsList } = useSelector(({ notifications }) => notifications);

    const renderToastNotification = () => {
        if (toastNotificationsList) {
            const { type, message } = toastNotificationsList;
            if (toastNotificationsList?.message === lastToastNotificationsList?.message) {
                if ((Math.floor((new Date().getTime() - lastToastNotificationsList?.date.getTime()) / 1000)) > 30) {
                    dispatch(setLastToastNotifications({ ...toastNotificationsList, date: new Date() }));
                    return antdNotification(type, message);
                };
            } else {
                dispatch(setLastToastNotifications({ ...toastNotificationsList, date: new Date() }));
                return antdNotification(type, message);
            };
        };
    }

    return <>{renderToastNotification()}</>
}

export default memo(ToastNotification)



