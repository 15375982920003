import {
  SET_METRICS_ID,
  STARTING_PURCHASE_PROCESS,
  FINISHING_PURCHASE_PROCESS,
} from 'shared/providers/redux/actionTypes';

export const startingPurchaseProcess = ({
  os,
  device,
  browser,
  productId,
  productType,
  browserVersion,
}) => ({
  type: STARTING_PURCHASE_PROCESS,
  payload: {
    os,
    device,
    browser,
    productId,
    productType,
    browserVersion,
  },
});

export const setMetricsId = (metricsId) => ({
  type: SET_METRICS_ID,
  payload: {
    metricsId,
  },
});

export const finishingPurchaseProcess = ({ metricsId, paymentMethod }) => ({
  type: FINISHING_PURCHASE_PROCESS,
  payload: {
    metricsId,
    paymentMethod,
  },
});
