import { CloseOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Popover } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { MdApps, MdMonetizationOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Auxiliary from 'shared/components/atoms/Auxiliary';
import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import MenuShortcuts from 'shared/components/molecules/MenuShortcuts';
import AppNotification from 'shared/components/organisms/AppNotification';
import Status from 'shared/components/organisms/GamificationStatus';
import UserProgress from 'shared/components/organisms/UserProgress';
import { getMode } from 'shared/infra/services/tenantAPI';
import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';

import {
  onMarkUnviewedNotifications,
  setCommingSoon,
  setOpenModal,
} from 'shared/providers/redux/actions';
import useWindowSize from 'shared/utils/hooks/useWindowSize';
import MyAccessIcon from './components/MyAccessIcon';

const UserMenu = ({ onShowMobileSearchBar }) => {
  const dispatch = useDispatch();
  const isNotProduction = getMode();
  const windowSize = useWindowSize();
  const isMobile = windowSize?.width < 992;
  const isGlobalSearchEnabled = useFeature(typeFeatures.globalSearch);
  const isMyAccessEnabled = useFeature(typeFeatures.myAccess);
  const isNotificationsEnabled = useFeature(typeFeatures.notifications);
  const isShortcutMenuEnabled = useFeature(typeFeatures.shortcutMenu);

  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');
  const tenantData = useSelector(({ tenancy }) => tenancy.tenantData);
  const { openCommingSoon, commingSoonText } = useSelector(
    ({ warnings }) => warnings
  );
  const shortcutsList = useSelector(
    ({ dynamicMenu }) => dynamicMenu.shortcutsList
  );

  const unviewedNotificationsRealTime = useSelector(
    ({ notifications }) =>
      notifications?.notificationsList[0]?.unviewed_notifications
  );

  const user = useSelector(({ user }) => user?.loggedUser);
  const avatar = user?.photo;
  const unviewedNotificationsLoggerUser = user?.unviewed_notifications;

  const [isShowingGlobalSearch, setIsShowingGlobalSearch] = useState(false);
  const [showShortcutsMenu, setShowShortcutsMenu] = useState(false);
  const [alert, setAlert] = useState(false);
  const [countNotifications, setCountNotifications] = useState(
    unviewedNotificationsRealTime || unviewedNotificationsLoggerUser
  );

  const game_credits = user?.game_credits;

  const handleShortcutsVisibleChange = (visible) => {
    setShowShortcutsMenu(visible);
  };

  const SectionMenu = () => (
    <section onClick={() => setShowShortcutsMenu(!showShortcutsMenu)}>
      <MenuShortcuts />
    </section>
  );

  const handleCloseAlert = () => {
    if (openCommingSoon) dispatch(setCommingSoon(false));
    else setAlert(false);
  };

  const handleOpenLoginModal = () => {
    dispatch(setOpenModal(true));
  };

  const handleShowMobileSearchBar = () => {
    setIsShowingGlobalSearch(!isShowingGlobalSearch);
    if (onShowMobileSearchBar) onShowMobileSearchBar();
  };

  useEffect(() => {
    if (unviewedNotificationsRealTime || unviewedNotificationsLoggerUser)
      setCountNotifications(
        unviewedNotificationsRealTime || unviewedNotificationsLoggerUser
      );
  }, [unviewedNotificationsRealTime, unviewedNotificationsLoggerUser]);

  return (
    <ul className="gx-header-notifications gx-ml-auto">
      {isShowingGlobalSearch
        ? isGlobalSearchEnabled && (
            <CloseOutlined
              className="icon scale-animation search-on-mobile"
              onClick={handleShowMobileSearchBar}
            />
          )
        : isGlobalSearchEnabled && (
            <SearchOutlined
              className="icon scale-animation search-on-mobile"
              onClick={handleShowMobileSearchBar}
            />
          )}

      {(isNotProduction ||
        (tenantData.uuid === 'magicaonline' &&
          !!user?.game_credits &&
          !!user?.game_points)) && (
        <>
          {user ? (
            <Popover
              trigger="click"
              placement="bottomRight"
              overlayClassName="gx-popover-horizantal"
              content={
                <li style={{ padding: '4px 0' }}>
                  <Status autoHeight topbar />
                  {false && (
                    <Link to="/gamification" className="gamificationLink">
                      <Button
                        style={{ width: '100%', margin: '15px 0 0' }}
                        className="btn primary"
                      >
                        <StaticIntlTranslator id="container.topbar.seeDetails" />
                      </Button>
                    </Link>
                  )}
                </li>
              }
            >
              <li
                className={`points gx-pointer ${
                  window.innerWidth > 859 && 'scale-animation'
                }`}
              >
                {game_credits > 0 && <p>{game_credits}</p>}
                <MdMonetizationOn className="dollar-icon" />
              </li>
            </Popover>
          ) : (
            <li
              onClick={() =>
                !user ? dispatch(setOpenModal(true)) : setAlert(!alert)
              }
              className={`points gx-pointer ${
                window.innerWidth > 859 && 'scale-animation'
              }`}
            >
              {game_credits > 0 && <p>{game_credits}</p>}
              <MdMonetizationOn className="dollar-icon" />
            </li>
          )}
        </>
      )}

      <SweetAlert
        warning
        show={alert || openCommingSoon}
        onConfirm={handleCloseAlert}
        title={
          commingSoonText ||
          'Essa funcionalidade não está disponível no momento.'
        }
        confirmBtnText="Entendi"
        confirmBtnBsStyle="primary"
        style={{ borderRadius: 7 }}
      />

      <Auxiliary>
        {!isMobile && user && isMyAccessEnabled && (
          <li>
            <Link to="/myAccess">
              <MyAccessIcon color="#ffffff" />
            </Link>
          </li>
        )}

        {user && isNotificationsEnabled && (
          <li>
            <Popover
              overlayClassName="gx-popover-horizantal"
              content={<AppNotification />}
              placement="bottomRight"
              trigger="click"
            >
              <span
                className="gx-pointer gx-d-block scale-animation"
                onClick={() => {
                  setCountNotifications(0);
                  dispatch(onMarkUnviewedNotifications());
                }}
              >
                <Badge
                  count={countNotifications}
                  className="badge-notification"
                >
                  <i className="icon icon-notification" />
                </Badge>
              </span>
            </Popover>
          </li>
        )}

        {!isMobile &&
          shortcutsList &&
          isShortcutMenuEnabled &&
          shortcutsList[0]?.items?.length > 0 && (
            <Popover
              trigger="click"
              visible={showShortcutsMenu}
              placement="bottomRight"
              content={<SectionMenu />}
              overlayClassName="item gx-popover-horizantal"
              onVisibleChange={handleShortcutsVisibleChange}
            >
              <li
                onClick={() => setShowShortcutsMenu(!showShortcutsMenu)}
                className="gx-pointer"
              >
                <MdApps
                  size={24}
                  style={{ cursor: 'pointer' }}
                  color={
                    isDarkMode
                      ? '#fff'
                      : tenantData?.settings['general-theme']
                          ?.themeCourseTopBarIconsColor
                  }
                  className="item scale-animation shortcuts-on-mobile"
                />
              </li>
            </Popover>
          )}
      </Auxiliary>

      <div className="user-avatar">
        {user ? (
          <>
            {isMobile ? (
              <UserProgress popover topbar mobile percent={87} image={avatar} />
            ) : (
              <UserProgress
                popover
                mobile
                autoHeight
                topbar
                percent={87}
                image={avatar}
              />
            )}
          </>
        ) : (
          <button
            onClick={handleOpenLoginModal}
            type="button"
            style={{
              cursor: 'pointer',
              borderRadius: '50%',
              border: 0,
              padding: 0,
            }}
          >
            <Avatar icon={<UserOutlined />} />
          </button>
        )}
      </div>
    </ul>
  );
};

UserMenu.propTypes = {
  onShowMobileSearchBar: PropTypes.func.isRequired,
};

export default memo(UserMenu);
