import {
  SIDEBAR_IS_RENDERED,
  SET_SIDEBAR_IS_COLLAPSED,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  sidebarIsCollapsed: true,
  sidebarIsRendered: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SIDEBAR_IS_COLLAPSED: {
      return {
        ...state,
        sidebarIsCollapsed: !state.sidebarIsCollapsed,
      };
    }

    case SIDEBAR_IS_RENDERED: {
      return {
        ...state,
        sidebarIsRendered: action.payload?.isRendered,
      };
    }

    default:
      return state;
  }
};
