import {
  GET_BUNDLES,
  GET_BUNDLES_SUCCESS,
  GET_SINGLE_BUNDLE_SUCCESS,
  GET_BUNDLE_COURSES,
  GET_BUNDLE_COURSES_SUCCESS,
  GET_BUNDLE_MEDIA_CONTENTS,
  GET_BUNDLE_MEDIA_CONTENTS_SUCCESS,
  FILTER_BUNDLE_BY,
  FILTER_BUNDLE_BY_SUCCESS,
  LOAD_MORE_BUNDLES_SUCCESS,
  REMOVE_BUNDLE_DATA,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  bundle: null,
  bundles: [],
  spotlights: [],
  foundBundles: null,
  bundleCourses: null,
  loadingBundles: null,
  bundleCoursesLoading: null,
  featuredContents: null,
  searchingForBundle: false,
  bundleMediaContents: null,
  bundleMediaContentsLoading: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUNDLES: {
      return {
        ...state,
        loadingBundles: true,
      };
    }

    case GET_BUNDLES_SUCCESS: {
      switch (action.payload.filterBy) {
        case 'featured':
          return {
            ...state,
            loadingBundles: false,
            featuredContents: action.payload.bundles,
          };

        default:
          return {
            ...state,
            loadingBundles: false,
            bundlesMeta: action.payload?.bundles?.meta,
            bundles: action.payload?.bundles?.data,
            spotlights: action.payload?.bundles?.spotlights,
          };
      }
    }

    case GET_SINGLE_BUNDLE_SUCCESS: {
      return {
        ...state,
        bundle: action.payload,
      };
    }

    case GET_BUNDLE_COURSES: {
      return {
        ...state,
        bundleCoursesLoading: true,
      };
    }

    case GET_BUNDLE_COURSES_SUCCESS: {
      return {
        ...state,
        bundleCourses: action.payload,
        bundleCoursesLoading: false,
      };
    }

    case GET_BUNDLE_MEDIA_CONTENTS: {
      return {
        ...state,
        bundleMediaContentsLoading: true,
      };
    }

    case GET_BUNDLE_MEDIA_CONTENTS_SUCCESS: {
      return {
        ...state,
        bundleMediaContents: action.payload,
        bundleMediaContentsLoading: false,
      };
    }

    case FILTER_BUNDLE_BY: {
      return {
        ...state,
        searchingForBundle: true,
      };
    }

    case FILTER_BUNDLE_BY_SUCCESS: {
      return {
        ...state,
        foundBundles: action.payload.data,
        searchingForBundle: false,
      };
    }

    case LOAD_MORE_BUNDLES_SUCCESS: {
      return {
        ...state,
        bundles: !state.bundles?.length
          ? action.payload?.bundles?.data
          : [...state.bundles, ...action.payload?.bundles?.data],
      };
    }

    case REMOVE_BUNDLE_DATA: {
      return {
        ...state,
        bundle: null,
        bundleMediaContents: null,
        bundleMediaContentsLoading: null,
        bundleCourses: null,
      };
    }

    default:
      return state;
  }
};
