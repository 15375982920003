import {
  FETCH_ALL_COLLECTIONS_SUCCESS,
  SET_COLLECTION,
  SET_COLLECTION_TYPE,
  UPDATE_COLLECTION,
  SET_COLLECTION_SESSION,
  SET_PRIVATE_COLLECTION,
  FETCH_COLLECTION_SESSION_SUCCESS,
  GET_ALL_COLLECTIONS,
  SET_COLLECTION_SEARCH,
  SET_COLLECTION_ITEM_SEARCH,
  SET_COLLECTION_POSITION,
  SET_COLLECTION_ITEM_POSITION,
  CLEAN_RESOURCES_POSITION,
  SET_ITEMS_COLLECTIONS_SUCCESS,
  REMOVE_ITEM_COLLECTIONS_SUCCESS,
  LOAD_MORE_COLLECTION,
  LOAD_MORE_COLLECTION_SUCCESS,
  LOAD_MORE_COLLECTION_ITEMS,
  LOAD_MORE_COLLECTION_ITEMS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  collectionsList: [],
  collectionSession: null,
  collectionItemsMeta: null,
  loadingMoreCollectionItems: false,
  collectionOrder: [],
  resourceOrder: [],
  loader: true,
  loaderSession: true,
  searchCollection: '',
  searchCollectionItem: '',
  collectionType: '',
  privateCollection: '',
  newContentAdded: null,
  loadingMoreCollections: false,
  collectionsMeta: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COLLECTIONS: {
      return {
        ...state,
        loader: action.payload,
        collectionOrder: [],
      };
    }

    case SET_COLLECTION: {
      return {
        ...state,
        loader: true,
        collectionOrder: [],
      };
    }

    case UPDATE_COLLECTION: {
      return {
        ...state,
        collectionSession: action.payload.collection,
      };
    }

    case SET_COLLECTION_SESSION: {
      return {
        ...state,
        loaderSession: action.payload.loader,
      };
    }

    case SET_COLLECTION_SEARCH: {
      return {
        ...state,
        searchCollection: action.payload,
      };
    }

    case SET_COLLECTION_ITEM_SEARCH: {
      return {
        ...state,
        searchCollectionItem: action.payload,
      };
    }

    case SET_PRIVATE_COLLECTION: {
      return {
        ...state,
        privateCollection: action.payload.privateCollection,
      };
    }

    case SET_COLLECTION_TYPE: {
      return {
        ...state,
        collectionType: action.payload,
      };
    }

    case SET_COLLECTION_POSITION: {
      return {
        ...state,
        collectionOrder: action.payload,
      };
    }

    case SET_COLLECTION_ITEM_POSITION: {
      return {
        ...state,
        resourceOrder: action.payload,
      };
    }

    case CLEAN_RESOURCES_POSITION: {
      return {
        ...state,
        resourceOrder: [],
      };
    }

    case FETCH_ALL_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        collectionsList: action.payload.collections,
        collectionsMeta: action.payload.meta,
        loader: false,
        newContentAdded: null,
      };
    }

    case FETCH_COLLECTION_SESSION_SUCCESS: {
      return {
        ...state,
        loaderSession: false,
        collectionSession: action.payload.collectionSession.data,
        collectionItemsMeta: action.payload.collectionSession.meta,
        privateCollection: action.payload.privateCollection,
      };
    }

    case SET_ITEMS_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        newContentAdded: action.payload?.content,
      };
    }

    case REMOVE_ITEM_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        newContentAdded: null,
      };
    }

    case LOAD_MORE_COLLECTION: {
      return {
        ...state,
        loadingMoreCollections: true,
      };
    }

    case LOAD_MORE_COLLECTION_SUCCESS: {
      return {
        ...state,
        collectionsList: [
          ...state.collectionsList,
          ...action.payload.collections,
        ],
        collectionsMeta: action.payload.meta,
        loadingMoreCollections: false,
      };
    }

    case LOAD_MORE_COLLECTION_ITEMS: {
      return {
        ...state,
        loadingMoreCollectionItems: true,
      };
    }

    case LOAD_MORE_COLLECTION_ITEMS_SUCCESS: {

      return {
        ...state,
        loadingMoreCollectionItems: false,
        collectionItemsMeta: action.payload.meta,
        collectionSession: state.collectionSession
          ? {
              ...state.collectionSession,
              resources: [
                ...state.collectionSession.resources,
                ...action.payload.collections.resources,
              ],
            }
          : action.payload.collections,
      };
    }

    default:
      return state;
  }
};
