import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import {
  AdvancedMode,
  AutoMode,
  DarkMode,
  LightMode,
  TransparentMode,
  GradientMode,
} from 'shared/styles/TopbarThemes';

import TopbarContent from './TopbarContent';

const Topbar = () => {
  const { settings: tenantTheme } = useSelector(
    ({ tenancy }) => tenancy?.tenantData
  );

  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');
  const { isLessonPage } = useSelector(({ lessons }) => lessons);

  const themeTopBar = tenantTheme['general-theme']?.themeTopBar;

  return (
    <>
      {navStyle && !isLessonPage &&
        (isDarkMode || themeTopBar === 'dark' ? (
          <DarkMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </DarkMode>
        ) : themeTopBar === 'automatic' ? (
          <AutoMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </AutoMode>
        ) : themeTopBar === 'advanced' ? (
          <AdvancedMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </AdvancedMode>
        ) : themeTopBar === 'light' ? (
          <LightMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </LightMode>
        ) : themeTopBar === 'transparent' ? (
          <TransparentMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </TransparentMode>
        ) : themeTopBar === 'gradient' ? (
          <GradientMode tenantTheme={tenantTheme}>
            <TopbarContent />
          </GradientMode>
        ) : (
          <TopbarContent />
        ))}
    </>
  );
};

export default memo(Topbar);
