import {
  START_ENROLLMENT_SUCCESS,
  CLEAN_STARTED_ENROLLMENT,
  CLEAN_NEWLY_CREATED_ENROLLMENT,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  startedEnrollment: null,
  newlyCreatedEnrollment: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        startedEnrollment: true,
        newlyCreatedEnrollment: true,
      }
    }

    case CLEAN_STARTED_ENROLLMENT: {
      return {
        ...state,
        startedEnrollment: false,
      }
    }

    case CLEAN_NEWLY_CREATED_ENROLLMENT: {
      return {
        ...state,
        newlyCreatedEnrollment: false,
      }
    }

    default:
      return state;
  }
};
