import {
  FETCH_PLANS_SUCCESS,
  FETCH_ENTITY_PLANS_SUCCESS
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  plans: null,
  entitySlug: null,
  entityPlans: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        plans: action.payload,
      }
    }

    case FETCH_ENTITY_PLANS_SUCCESS: {
      return {
        ...state,
        entitySlug: action.payload.slug,
        entityPlans: action.payload.plans,
      }
    }

    default:
      return state;
  }
};
