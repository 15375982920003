import {
  SET_COUPON,
  SET_COUPON_SUCCESS,
  REMOVE_COUPON_BOOKING,
  REMOVE_COUPON_BOOKING_ERROR,
  REMOVE_COUPON_BOOKING_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const setCoupon = (coupon, entityType, entityId) => ({
  type: SET_COUPON,
  payload: { coupon, entityType, entityId },
});

export const setCouponSuccess = (couponInfos, errorMessage) => ({
  type: SET_COUPON_SUCCESS,
  payload: { couponInfos, errorMessage },
});

export const removeCouponBooking = (
  bookingId,
  entityType,
  entityId,
  type = 'manually'
) => ({
  type: REMOVE_COUPON_BOOKING,
  payload: { bookingId, entityType, entityId, type },
});

export const removeCouponBookingError = () => ({
  type: REMOVE_COUPON_BOOKING_ERROR,
});

export const removeCouponBookingSuccess = () => ({
  type: REMOVE_COUPON_BOOKING_SUCCESS,
});
