export const removeSpecialCharacters = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f?!@#$%^&*()\[\]\{\}\<\>\.\,\\\|\/]/g, '');

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validateUsername = (username) => /^[a-z0-9]+$/g.test(username);

export const validateLengthBio = (bio) => bio?.length <= 150;

export const validateUrl = (value) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );

export const validateNumbers = (number) => /^[0-9]+$/g.test(number);

export const validateDoubleNumbers = (n1, n2) =>
  /^[0-9]+$/g.test(n1) && /^[0-9]+$/g.test(n2);

export const validateTripleNumbers = (n1, n2, n3) =>
  /^[0-9]+$/g.test(n1) && /^[0-9]+$/g.test(n2) && /^[0-9]+$/g.test(n3);

export const validateTripleNumbersPhone = (n1, n2, n3) => n3.length > 8;

export const validateText = (name) => /^[a-zA-Z ]+$/g.test(name);

export const errorMessage = (type) => {
  switch (type) {
    case 'username':
      return 'regex.errorMessage.username';
    case 'biography':
      return 'regex.errorMessage.biography';
    case 'url':
      return 'regex.errorMessage.url';
    case 'email':
      return 'regex.errorMessage.email';
    case 'number':
      return 'regex.errorMessage.number';
    case 'doubleNumbers':
      return 'regex.errorMessage.number';
    case 'tripleNumbers':
      return 'regex.errorMessage.number';
    case 'tripleNumbersPhone':
      return 'regex.errorMessage.phone';
    case 'name':
      return 'regex.errorMessage.name';
    default:
  }
};

export const isBrazilianPhone = (phone) => {
  return phone.slice(0, 3) === '+55';
};

export const validateBrazilianPhone = (phone) => {
  const regex = /\+55 \(\d{2}\) \d{5}-\d{4}/;

  const isValidPhone = regex.test(phone) || phone === '+55';
  return isValidPhone;
};
