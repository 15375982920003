import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;

  .gx-login-content {
    width: 30rem;
    padding: 35px;

    .ant-input {
      border-radius: 8px;
      background-color: transparent;
    }
  }

  .ant-btn-primary {
    /* margin-top: 15px; */
    height: 50px;
    border-radius: 8px;
    border-color: ${(props) =>
      props.tenantData?.settings['general-theme']?.themeMasterColor} !important;
    background-color: ${(props) =>
      props.tenantData?.settings['general-theme']?.themeMasterColor} !important;
  }

  .passwordStatus {
    margin: -10px 0 20px;
    color: #f73333;
  }

  .gx-login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .gx-mb-4 {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1.siteName {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 24px;
        font-weight: 600;
      }

      img {
        max-width: 250px;
        max-height: 90px;
        margin-bottom: 24px;
      }
    }

    .gx-login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .gx-text-center {
        button {
          color: white !important;
        }
      }
    }

    .ant-form-item + span {
      width: 100%;
      margin: -10px 0 10px 0;
      padding: 0;
    }

    h2,
    p {
      text-align: center;
      padding-bottom: 4px;
      margin-bottom: 0;
    }

    .ant-alert-with-description {
      align-items: center;
    }
  }
`;
