import {
  GET_COMMUNITY_CATEGORIES,
  GET_COMMUNITY_TOPICS,
  GET_COMMUNITY_LAST_TOPICS,
  GET_COMMUNITY_LAST_COMMENTS,
  GET_COMMUNITY_AUTHORS,
  FETCH_COMMUNITY_AUTHORS_SUCCESS,
  FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS,
  FETCH_COMMUNITY_LAST_TOPICS_SUCCESS,
  FETCH_COMMUNITY_CATEGORIES_SUCCESS,
  FETCH_COMMUNITY_TOPICS_SUCCESS,
  SET_COMMUNITY_TOPIC,
  SET_COMMUNITY_TOPIC_SUCCESS,
  READ_COMMUNITY_TOPIC,
  READ_COMMUNITY_TOPIC_SUCCESS,
  UPDATE_COMMUNITY_TOPIC,
  DELETE_COMMUNITY_TOPIC,
  DELETE_COMMUNITY_TOPIC_SUCCESS,
  UPDATE_COMMUNITY_TOPIC_SUCCESS,
  LIKE_DISLIKE_COMMUNITY_TOPIC,
  LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS,
  PIN_COMMUNITY_TOPIC,
  SHOW_ENROLLED_USERS_PERMISSION,
} from 'shared/providers/redux/actionTypes';

export const getCommunityCategories = () => ({
  type: GET_COMMUNITY_CATEGORIES,
});

export const fetchCommunityCategoriesSuccess = (payload) => ({
  type: FETCH_COMMUNITY_CATEGORIES_SUCCESS,
  payload,
});

export const getCommunityTopics = ({ category, page = 1, orderBy }) => ({
  type: GET_COMMUNITY_TOPICS,
  payload: {
    category,
    page,
    orderBy,
  },
});

export const fetchCommunityTopicsSuccess = ({
  topics,
  fixed,
  pages,
  forum,
}) => ({
  type: FETCH_COMMUNITY_TOPICS_SUCCESS,
  payload: { topics, fixed, pages, forum },
});

export const getCommunityLastTopics = () => ({
  type: GET_COMMUNITY_LAST_TOPICS,
});

export const fetchCommunityLastTopicsSuccess = (lastTopics) => ({
  type: FETCH_COMMUNITY_LAST_TOPICS_SUCCESS,
  payload: lastTopics,
});

export const getCommunityLastComments = () => ({
  type: GET_COMMUNITY_LAST_COMMENTS,
});

export const fetchCommunityLastCommentsSuccess = (lastComments) => ({
  type: FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS,
  payload: lastComments,
});

export const getCommunityAuthors = (communityId) => ({
  type: GET_COMMUNITY_AUTHORS,
  payload: { communityId },
});

export const fetchCommunityAuthorsSuccess = (authors) => ({
  type: FETCH_COMMUNITY_AUTHORS_SUCCESS,
  payload: authors,
});

export const setCommunityTopic = ({ name, description, category }) => ({
  type: SET_COMMUNITY_TOPIC,
  payload: {
    name,
    description,
    category,
  },
});

export const setCommunityTopicSuccess = ({ topics, fixed, pages, forum }) => ({
  type: SET_COMMUNITY_TOPIC_SUCCESS,
  payload: { topics, fixed, pages, forum },
});

export const readCommunityTopic = ({ category, topic }) => ({
  type: READ_COMMUNITY_TOPIC,
  payload: {
    topic,
    category,
  },
});

export const readCommunityTopicSuccess = (topic) => ({
  type: READ_COMMUNITY_TOPIC_SUCCESS,
  payload: { topic },
});

export const setPinCommunityTopic = (slug) => ({
  type: PIN_COMMUNITY_TOPIC,
  payload: { slug },
});

export const updateCommunityTopic = ({
  category,
  topic,
  forum,
  name,
  description,
}) => ({
  type: UPDATE_COMMUNITY_TOPIC,
  payload: { category, topic, forum, name, description },
});

export const updateCommunityTopicSuccess = ({ slug }) => ({
  type: UPDATE_COMMUNITY_TOPIC_SUCCESS,
  payload: { slug },
});

export const deleteCommunityTopic = ({ category = '', topicSlug = '' }) => ({
  type: DELETE_COMMUNITY_TOPIC,
  payload: {
    category,
    topicSlug,
  },
});

export const deleteCommunityTopicSuccess = (deletedTopicSlug) => ({
  type: DELETE_COMMUNITY_TOPIC_SUCCESS,
  payload: { status: 'success', deletedTopicSlug },
});

export const likeDislikeCommunityTopic = ({
  option,
  category,
  forum,
  topic,
}) => ({
  type: LIKE_DISLIKE_COMMUNITY_TOPIC,
  payload: { option, category, forum, topic },
});

export const likeDislikeCommunityTopicSuccess = (topic) => ({
  type: LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS,
  payload: { topic },
});

export const showEnrolledUsersPermission = (payload) => ({
  type: SHOW_ENROLLED_USERS_PERMISSION,
  payload,
});
