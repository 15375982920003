import {
  SET_ADDRESS,
  SET_ADDRESS_ERROR,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_SUCCESS_API,
  SET_ADDRESS_EDITING,
  SHOW_BUTTON_RELOAD,
  GET_ALL_ADDRESS,
  GET_ADDRESS_BRASIL_API,
  FETCH_ALL_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SESSION,
  UPDATE_ADDRESS_SESSION_SUCCESS,
  FETCH_ADDRESS_BRASIL_API_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  addressList: [],
  addressSession: null,
  addressBrazilAPI: null,
  showButtonReload: false,
  isEditing: false,
  loader: false,
  loaderBrazilAPI: false,
  loaderNewAddress: false,
  loaderUpdateAddress: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_ADDRESS_BRASIL_API: {
      return {
        ...state,
        loaderBrazilAPI: true,
      };
    }

    case SET_ADDRESS: {
      return {
        ...state,
        loaderNewAddress: true,
      };
    }

    case SET_ADDRESS_SUCCESS: {
      return {
        ...state,
        loaderNewAddress: false,
        addressList: Array.from(action.payload),
      };
    }

    case SET_ADDRESS_ERROR: {
      return {
        ...state,
        loader: false,
        loaderNewAddress: false,
        loaderUpdateAddress: false,
      };
    }

    case SET_ADDRESS_SUCCESS_API: {
      return {
        ...state,
        loader: false,
        addressList: Array.from(action.payload),
      };
    }

    case SET_ADDRESS_EDITING: {
      return {
        ...state,
        isEditing: action.payload,
      };
    }

    case SHOW_BUTTON_RELOAD: {
      return {
        ...state,
        showButtonReload: action.payload,
      };
    }

    case FETCH_ALL_ADDRESS_SUCCESS: {
      return {
        ...state,
        loader: action.payload.loader || false,
        addressList: action.payload.addressList,
      };
    }

    case REMOVE_ADDRESS_SUCCESS: {
      return {
        ...state,
        addressList: Array.from(action.payload),
      };
    }

    case UPDATE_ADDRESS_SESSION: {
      return {
        ...state,
        loaderUpdateAddress: true,
      };
    }

    case UPDATE_ADDRESS_SESSION_SUCCESS: {
      return {
        ...state,
        loaderUpdateAddress: false,
        addressList: Array.from(action.payload),
      };
    }

    case FETCH_ADDRESS_BRASIL_API_SUCCESS: {
      return {
        ...state,
        loaderBrazilAPI: false,
        addressBrazilAPI: action.payload,
      };
    }

    default:
      return state;
  }
};
