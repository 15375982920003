import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

import { Container } from './styles';

const ConfirmationFailed = () => {
  const intl = useIntl();
  const history = useHistory();

  const handleAccessPlataform = () => {
    history.push('/');
  };

  return (
    <Container>
      <h1>{intl.formatMessage({ id: 'failed-to-confirm-your-account' })}</h1>
      <span className="description">
        {intl.formatMessage({ id: 'we-were-unable-to-confirm-your-account' })}
      </span>
      <Button type="primary" onClick={handleAccessPlataform}>
        {intl.formatMessage({ id: 'access-the-platform' })}
      </Button>
    </Container>
  );
};

export default ConfirmationFailed;
