import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 25px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 36px;
    margin-bottom: 35px;
  }

  p {
    font-size: 18px;
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
  }

  button {
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 5px;
    background: #000;
    padding: 12px 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;
