import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: max-content;
  height: 100%;

  @media (max-width: 500px) {
    ul {
      width: 100vw;
      right: -20px;
      top: 40px;
      height: calc(100vh - 100px);
      border-radius: 0;

      li {
        font-size: 1.25rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0.75rem 1rem;
        }
      }
    }
  }
`;

export const ModelClass = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  cursor: pointer;
  background: none;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  padding: 0 1.125rem;
  text-align: start;

  color: var(--themeTopbar-iconsColor);

  span {
    width: max-content;
    min-width: 70px;
    text-align: center;
  }

  svg {
    font-size: 1rem;
    margin: 0 0.75rem 0 0;
  }

  transition: transform 0.3s;

  &:hover {
    transform: translateY(-1px);
  }

  @media (max-width: 375px) {
    padding: 0;
  }
`;
