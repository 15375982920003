import { notification } from 'antd';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';

import {
  setUserSetting,
  removeFieldUserSetting,
} from 'modules/Checkout/entities/UserSetting/actions';
import {
  SET_COUPON,
  REMOVE_COUPON_BOOKING,
} from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';
import {
  setCouponSuccess,
  removeCouponBookingError,
  removeCouponBookingSuccess,
} from 'shared/providers/redux/actions';

async function setCouponRequest(payload) {
  try {
    const { coupon, entityType, entityId } = payload;
    const { data } = await api.get(
      `coupon/validate?${entityType}=${entityId}&code=${coupon}`
    );

    return data;
  } catch (error) {
    const { data } = error.response;
    const { errors } = data;

    console.log('error -->', errors);
    return data;
  }
}

function* setCoupon({ payload }) {
  const { entityType, entityId } = payload;
  const coupon = yield call(setCouponRequest, payload);

  if (coupon.code) {
    const { code } = coupon;
    const errorMessage = code && code[0];

    yield put(setCouponSuccess(null, errorMessage));
    return notification.error({
      message: 'Erro ao utilizar cupom',
      description: errorMessage,
      top: 85,
    });
  }

  if (coupon.message) {
    const { attrs, message } = coupon;
    const errorMessage = attrs[0] || message;

    yield put(setCouponSuccess(null, errorMessage));
    return notification.error({
      message: 'Erro ao utilizar cupom',
      description: errorMessage,
      top: 85,
    });
  }

  if (coupon) {
    const { installments } = coupon?.data?.receiverItem;
    const { coupon_code, price_with_discount, coupon_discount_value } =
      coupon.data.item;
    const { coupon_booking_id } = coupon.data;

    yield put(
      setUserSetting(
        [
          'coupon_code',
          'coupon_entity_id',
          'coupon_booking_id',
          'coupon_entity_type',
          'coupon_discount_value',
          'coupon_product_price_discount',
        ],
        [
          coupon_code,
          entityId,
          coupon_booking_id,
          entityType,
          coupon_discount_value,
          price_with_discount,
        ],
        ['payment', 'payment', 'payment', 'payment', 'payment', 'payment'],
        false,
        true
      )
    );

    const productSession = JSON.parse(
      sessionStorage.getItem('product_session')
    );

    productSession.map((item) => {
      const param = item;
      if (param.installments) param.installments = Object.values(installments);
      return param;
    });

    sessionStorage.setItem('product_session', JSON.stringify(productSession));
    yield put(setCouponSuccess(coupon, ''));
  }

  return null;
}

export function* onSetCoupon() {
  yield takeLatest(SET_COUPON, setCoupon);
}

async function removeCouponBookingRequest(payload) {
  try {
    const { bookingId, entityType, entityId } = payload;
    const response = await api.post(
      `coupon/remove-coupon-booking/${bookingId}?${entityType}=${entityId}`
    );

    return response;
  } catch (error) {
    console.log('error ->', error, error.response);

    if (error.response.status === 422) {
      return error.response;
    }
  }
  return null;
}

function* userSettingCouponInformationIsNull() {
  const payment = yield select(
    ({ userSetting }) => userSetting.settings.payment
  );

  const couponCode = payment.coupon_code;
  const couponEntityId = payment.coupon_entity_id;
  const couponBookingId = payment.coupon_booking_id;
  const couponEntityType = payment.coupon_entity_id;
  const couponDiscountValue = payment.coupon_discount_value;
  const couponProductPriceDiscount = payment.coupon_product_price_discount;

  const hasCouponInformation =
    [
      couponCode,
      couponEntityId,
      couponBookingId,
      couponEntityType,
      couponDiscountValue,
      couponProductPriceDiscount,
    ].indexOf(undefined) === -1;

  return hasCouponInformation;
}

function* removeUserSettingCouponInformation() {
  const fields = [
    'coupon_code',
    'coupon_entity_id',
    'coupon_booking_id',
    'coupon_entity_type',
    'coupon_discount_value',
    'coupon_product_price_discount',
  ];

  const groups = [
    'payment',
    'payment',
    'payment',
    'payment',
    'payment',
    'payment',
  ];

  yield put(removeFieldUserSetting(fields, groups));
}

function updateProductSectionInstallments(attrs) {
  const productSession = JSON.parse(sessionStorage.getItem('product_session'));

  productSession.map((item) => {
    const param = item;
    if (param.installments)
      param.installments = Object.values(attrs.installments);
    return param;
  });

  sessionStorage.setItem('product_session', JSON.stringify(productSession));
}

function* removeCouponBooking({ payload }) {
  const couponInfos = yield select(({ coupon }) => coupon.couponInfos);
  const hasUserSettingCouponInformation =
    yield userSettingCouponInformationIsNull();

  if (!couponInfos) {
    yield put(removeCouponBookingError('There is no coupon applied.'));
    if (hasUserSettingCouponInformation)
      yield removeUserSettingCouponInformation();
    return;
  }

  const response = yield call(removeCouponBookingRequest, payload);

  if (response.status !== 200) {
    yield put(
      removeCouponBookingError(
        'An error occurred while trying to remove the coupon.'
      )
    );

    yield removeUserSettingCouponInformation();
    return;
  }

  const { type } = payload;

  if (type === 'automatic') {
    openNotificationWithIcon(
      'warning',
      'O cupom expirou',
      'Tente aplicar o cupom novamente',
      5
    );
  }

  const { attrs } = response.data;
  updateProductSectionInstallments(attrs);

  yield removeUserSettingCouponInformation();
  yield put(removeCouponBookingSuccess());
}

export function* onRemoveCouponBooking() {
  yield takeLatest(REMOVE_COUPON_BOOKING, removeCouponBooking);
}

export default function* rootSaga() {
  yield all([fork(onSetCoupon), fork(onRemoveCouponBooking)]);
}
