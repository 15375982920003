const converterContentEditorQuill = content => {
  const converter = Array.isArray(content) ? JSON.stringify(content) : content;
  try {
    const arr = JSON.parse(converter);
    const insert = [];

    arr.map(item => {
      if (!item.insert.video && !item.insert.image) {
        if (item.insert.mention) {
          insert.push(item.insert.mention.value);
        } else {
          insert.push(item.insert.toString());
        }
      }

      return null;
    });

    return insert;
  } catch (error) {
    return content;
  }
};

export default converterContentEditorQuill;
