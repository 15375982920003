import {
  FETCH_USER_LINK_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_LOGGED_USER_SUCCESS,
  UPDATE_LOGGED_USER,
  FETCH_DEVICES_SUCCESS,
  SET_PASSWORD_LENGTH,
  FETCH_USER_COUNTRIES_SUCCESS,
  FETCH_INTERESTS_SUCCESS,
  FETCH_USER_PHOTO_SUCCESS,
  CHECK_USERNAME_SUCCESS,
  FETCH_USER_NOT_FOUND,
  FETCH_PASSWORD_STATUS_SUCCESS,
  FETCH_FOLLOWERS_SUCCESS,
  CLEAR_STATUS_PASSWORD,
  SET_CONTENT_LENGTH,
  GET_INTERESTS,
  GET_USER,
  GET_USER_DEVICES,
  GET_USER_COUNTRIES,
  SET_PAGE,
  FETCH_ALL_USERS_SUCCESS,
  GET_ALL_AUTHORS,
  GET_ALL_AUTHORS_SUCCESS,
  GET_ALL_NON_AUTHOR_USERS_SUCCESS,
  UPDATE_USER_LINK_SUCCESS,
  UPDATE_DISPLAY_NAME,
  FOLLOW_USER,
  REMOVE_LINK_SUCCESS,
  FETCH_FOLLOWING_SUCCESS,
  GET_USER_MEDIA_SPACE_CONTENT_SUCCESS,
  CLEAN_USER_DATA,
  LAST_TOPICS_USER,
  LAST_TOPICS_USER_SUCCESS,
  GET_USER_COLLECTION,
  GET_USER_COLLECTION_SUCCESS,
  GET_USER_COMMENTS,
  GET_USER_COMMENTS_SUCCESS,
  SET_IS_FOLLOWING_USER,
  UNFOLLOW_USER,
  GET_FOLLOWING,
  GET_FOLLOWERS,
} from 'shared/providers/redux/actionTypes';

import removeDuplicates from 'shared/utils/removeDuplicates';

const INIT_STATE = {
  user: null,
  usernameExists: false,
  users: null,
  userPhoto: null,
  userNotFound: false,
  loggedUser: null,
  initialDisplayPassword: null,
  coverInstructions: true,
  contentLength: [],
  totalLength: null,
  interests: null,
  statusPassword: null,
  devices: null,
  devicesLoader: true,
  countries: null,
  usersToMention: JSON.parse(sessionStorage.getItem('users_to_mention')),
  authors: null,
  nonAuthors: null,
  page: 'about',
  slugId: null,
  loading: false,
  mentions: [],
  userContent: [],
  userContentTotal: 0,
  lastTopicsUserMeta: null,
  lastTopicsUserPosts: null,
  lastTopicsLoading: false,
  userCollection: null,
  usersMetaComments: null,
  loadingUserCollection: false,
  loadingAuthors: false,
  userComments: [],
  userCommentsIsLoading: false,
  isLoadingFollowing: false,
  isLoadingFollowers: false,
  isFollowing: false,
  followers: null,
  following: null,
  followingMeta: null,
  followersMeta: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case GET_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_IS_FOLLOWING_USER: {
      return {
        ...state,
        isFollowing: action.payload.isFollowing,
      };
    }

    case CLEAR_STATUS_PASSWORD: {
      return {
        ...state,
        statusPassword: null,
      };
    }

    case FETCH_USER_NOT_FOUND: {
      return {
        ...state,
        userNotFound: true,
        loading: false,
      };
    }

    case SET_PASSWORD_LENGTH: {
      const length = action.payload;
      return {
        ...state,
        initialDisplayPassword: new Array(length).fill('*').join(''),
      };
    }

    case SET_CONTENT_LENGTH: {
      return {
        ...state,
        contentLength: +state.contentLength + +action.payload,
      };
    }

    case UPDATE_DISPLAY_NAME: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case FETCH_PASSWORD_STATUS_SUCCESS: {
      return {
        ...state,
        statusPassword: action.payload,
      };
    }

    case FETCH_USER_PHOTO_SUCCESS: {
      return {
        ...state,
        userPhoto: action.payload,
      };
    }

    case FETCH_USER_LINK_SUCCESS: {
      return {
        ...state,
        userPhoto: action.payload,
      };
    }

    case CHECK_USERNAME_SUCCESS: {
      return {
        ...state,
        usernameExists: action.payload,
      };
    }

    case UPDATE_USER_LINK_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case REMOVE_LINK_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          user_links: action.payload,
        },
      };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }

    case FETCH_LOGGED_USER_SUCCESS: {
      return {
        ...state,
        loggedUser: action.payload,
        loading: false,
        userNotFound: false,
      };
    }

    case UPDATE_LOGGED_USER: {
      const { loggedUser } = state;

      return {
        ...state,
        loggedUser: {
          ...loggedUser,
          ...action.payload,
        },
      };
    }

    case GET_USER_DEVICES: {
      return {
        ...state,
        devices: action.payload,
      };
    }

    case FOLLOW_USER: {
      const followMethod = action.payload?.followMethod;
      const totalMeta = state?.followersMeta?.total;
      const loggedUser = action.payload?.loggedUser;
      const user = action.payload?.user;

      if (followMethod) {
        if (followMethod === 'followers') {
          return {
            ...state,
            isFollowing: true,
            followersMeta: {
              ...state.followersMeta,
              total: totalMeta + 1,
            },
            followers:
              state.followers?.length > 0
                ? [...state.followers, loggedUser]
                : [loggedUser],
          };
        }

        if (followMethod === 'following') {
          return {
            ...state,
            isFollowing: true,
            followingMeta: {
              ...state.followingMeta,
              total: totalMeta + 1,
            },
            following:
              state.following?.length > 0 ? [...state.following, user] : [user],
          };
        }
      }
    }

    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.payload,
        devicesLoader: false,
      };
    }

    case UNFOLLOW_USER: {
      const followMethod = action.payload?.followMethod;
      const totalMeta = state?.followingMeta?.total || '1';

      if (followMethod) {
        if (followMethod === 'followers') {
          const newFollowers = state.followers?.filter(
            (follower) => follower?.id !== action.payload?.loggedUser?.id
          );

          return {
            ...state,
            isFollowing: false,
            followersMeta: {
              ...state.followersMeta,
              total: totalMeta - 1,
            },
            followers: newFollowers?.length ? newFollowers : [],
          };
        }

        if (followMethod === 'following') {
          const newFollowing = state.following?.filter(
            (follower) => follower?.id !== action.payload?.user?.id
          );

          return {
            ...state,
            isFollowing: false,
            followingMeta: {
              ...state.followingMeta,
              total: totalMeta - 1,
            },
            following: newFollowing.length ? newFollowing : [],
          };
        }
      }

      return {
        ...state,
      };
    }

    case FETCH_FOLLOWERS_SUCCESS: {
      const payload_followers = action.payload.data;
      const followersMeta = action.payload?.meta;
      const unduplicated_followers = removeDuplicates(
        action?.payload?.data,
        'id',
        'username'
      );

      return {
        ...state,
        isLoadingFollowers: false,
        followers:
          state?.followers?.length > 0 && followersMeta?.current_page > 1
            ? [...state?.followers, ...unduplicated_followers]
            : payload_followers?.length > 1
            ? unduplicated_followers
            : payload_followers,
        followersMeta,
      };
    }

    case GET_INTERESTS: {
      return {
        ...state,
        interests: action.payload,
      };
    }

    case FETCH_INTERESTS_SUCCESS: {
      return {
        ...state,
        interests: action.payload,
      };
    }

    case GET_USER_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case FETCH_USER_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case GET_ALL_AUTHORS: {
      return {
        ...state,
        loadingAuthors: true,
      };
    }

    case GET_ALL_AUTHORS_SUCCESS: {
      return {
        ...state,
        authors: action.payload,
        loadingAuthors: false,
      };
    }

    case GET_ALL_NON_AUTHOR_USERS_SUCCESS: {
      return {
        ...state,
        nonAuthors: action.payload,
      };
    }

    case FETCH_FOLLOWING_SUCCESS: {
      const payload_following = action.payload.data;
      const followingMeta = action.payload?.meta;
      const unduplicated_followers = removeDuplicates(
        action?.payload?.data,
        'id',
        'username'
      );

      return {
        ...state,
        isLoadingFollowing: false,
        following:
          state?.following?.length > 0 && followingMeta.current_page > 1
            ? [...state.following, ...unduplicated_followers]
            : payload_following?.length > 1
            ? unduplicated_followers
            : payload_following,
        followingMeta,
      };
    }

    case GET_USER_MEDIA_SPACE_CONTENT_SUCCESS: {
      return {
        ...state,
        userContent: action.payload?.contents?.data,
        userContentTotal: action.payload?.contents?.meta?.total,
      };
    }

    case CLEAN_USER_DATA: {
      return {
        ...state,
        user: null,
        loggedUser: null,
        userContent: [],
        userContentTotal: 0,
        lastTopicsUserMeta: null,
        lastTopicsUserPosts: null,
        userCollection: null,
        usersMetaComments: null,
        userComments: [],
      };
    }

    case LAST_TOPICS_USER: {
      return {
        ...state,
        lastTopicsLoader: true,
      };
    }

    case LAST_TOPICS_USER_SUCCESS: {
      return {
        ...state,
        lastTopicsUserPosts: state?.lastTopicsUserPosts
          ? [...state.lastTopicsUserPosts, ...action.payload.posts?.data]
          : action.payload.posts?.data,
        lastTopicsUserMeta: action.payload.posts?.meta,
        lastTopicsLoader: false,
      };
    }

    case GET_USER_COLLECTION: {
      return {
        ...state,
        loadingUserCollection: true,
      };
    }

    case GET_USER_COLLECTION_SUCCESS: {
      return {
        ...state,
        userCollection: action.payload.collections,
        loadingUserCollection: false,
      };
    }

    case GET_USER_COMMENTS: {
      return {
        ...state,
        userCommentsIsLoading: true,
      };
    }

    case GET_USER_COMMENTS_SUCCESS: {
      return {
        ...state,
        userComments:
          state?.userComments?.length <= 0
            ? action?.payload?.collections?.data
            : [...state?.userComments, ...action?.payload?.collections?.data],
        usersMetaComments: action?.payload?.collections?.meta,
        userCommentsIsLoading: false,
      };
    }

    case GET_FOLLOWERS: {
      return {
        ...state,

        isLoadingFollowers: true,
      };
    }

    case GET_FOLLOWING: {
      return {
        ...state,
        isLoadingFollowing: true,
      };
    }

    default:
      return state;
  }
};
