import { setThemeMode } from 'shared/providers/redux/actions';

const ToggleThemeMode = (initial, dispatch, darkModeOptions) => {
  const localDarkMode = localStorage.getItem('theme_mode');
  const darkModeIsDefault = darkModeOptions === 'only_theme_option';
  const switchThemeModeIsEnable = darkModeOptions === 'active';

  if (initial) {
    const defaultMode = darkModeIsDefault ? 'dark' : 'light';

    if (!localDarkMode) {
      localStorage.setItem('theme_mode', defaultMode);
      dispatch(setThemeMode(defaultMode));
    } else if (!switchThemeModeIsEnable && localDarkMode !== defaultMode) {
      dispatch(setThemeMode(defaultMode));
    } else {
      dispatch(setThemeMode(localDarkMode));
    }

    return;
  }

  switch (localDarkMode) {
    case 'light':
      localStorage.setItem('theme_mode', 'dark');
      dispatch(setThemeMode('dark'));
      break;

    default:
      localStorage.setItem('theme_mode', 'light');
      dispatch(setThemeMode('light'));
      break;
  }
};

export default ToggleThemeMode;
