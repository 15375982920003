import {
  GET_USER_SETTING,
  SET_USER_SETTING,
  FETCH_USER_SETTING_SUCCESS,
  REMOVE_FIELD_USER_SETTING,
  REMOVE_FIELD_USER_SETTING_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const setUserSetting = (
  key,
  value,
  group,
  loaderSetUserSetting,
  loaderSetUserSettingWithCoupon
) => ({
  type: SET_USER_SETTING,
  payload: {
    key,
    value,
    group,
    loaderSetUserSetting,
    loaderSetUserSettingWithCoupon,
  },
});

export const getUserSetting = (loader) => ({
  type: GET_USER_SETTING,
  payload: loader,
});

export const fetchUserSettingSuccess = (settings) => ({
  type: FETCH_USER_SETTING_SUCCESS,
  payload: settings,
});

export const removeFieldUserSetting = (field, group) => ({
  type: REMOVE_FIELD_USER_SETTING,
  payload: { field, group },
});

export const removeFieldUserSettingSuccess = () => ({
  type: REMOVE_FIELD_USER_SETTING_SUCCESS,
});
