import styled from 'styled-components';
import { CloseOutlined, NotificationOutlined } from '@ant-design/icons';

export const Container = styled.div`
  padding: 0px 16px;
  color: ${({ theme }) => theme === 'dark' && '#f5f5f5'};

  .gx-meta-date {
    color: #096dd9;
  }

  li {
    cursor: pointer;
  }

  i {
    padding-top: 2px;
  }

  @media (min-width: 1080px) {
    .notifications-actions {
      visibility: hidden;
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme === 'dark' ? '#545454' : '#f5f5f5'};

    @media (min-width: 1080px) {
      .notifications-actions {
        visibility: visible;
      }
    }
  }

  p.content-string {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
`;

export const Footer = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  float: right;

  .ant-checkbox-inner {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  .ant-checkbox-inner::after {
    display: none;
  }
`;

export const IconCloseOutlined = styled(CloseOutlined)`
  margin-right: 10px;
  color: ${({ theme }) => (theme === 'dark' ? '#f5f5f5' : '#545454')};
  cursor: pointer;
`;

export const IconNotificationOutlined = styled(NotificationOutlined)`
  margin-right: 1.8rem;
  padding: 1rem 0 0 0.8rem;

  font-size: 1.2rem;
  color: ${({ theme }) => (theme === 'dark' ? '#f5f5f5' : '#545454')};
  cursor: pointer;
`;
