import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  AUTHENTICATE_TOKEN,
  AUTHENTICATE_TOKEN_SUCCESS,
  RESEND_CONFIRMATION_EMAIL,
  CONFIRMATION_EMAIL_STATUS,
  CONFIRM_USER_EMAIL,
  CONFIRM_USER_EMAIL_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  loader: false,
  initURL: '',
  directlyAuthenticatedUser: null,
  directAuthenticationLoading: null,
  authUser: localStorage.getItem('user_id'),
  resendEmailLoader: false,
  resendEmailStatus: { success: false, message: '', status: null },
  confirmEmailLoader: true,
  confirmEmailSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER: {
      return {
        ...state,
        loader: true,
      };
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case AUTHENTICATE_TOKEN: {
      return {
        ...state,
        directAuthenticationLoading: true,
      };
    }

    case AUTHENTICATE_TOKEN_SUCCESS: {
      return {
        ...state,
        directlyAuthenticatedUser: action.payload,
        directAuthenticationLoading: false,
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/login',
        loader: false,
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case RESEND_CONFIRMATION_EMAIL: {
      return {
        ...state,
        resendEmailLoader: true,
        resendEmailStatus: { success: false, message: '' },
      };
    }

    case CONFIRMATION_EMAIL_STATUS: {
      return {
        ...state,
        resendEmailLoader: false,
        resendEmailStatus: action.payload.status,
      };
    }

    case CONFIRM_USER_EMAIL: {
      return {
        ...state,
        confirmEmailLoader: true,
      };
    }

    case CONFIRM_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        confirmEmailLoader: false,
        confirmEmailSuccess: true,
      };
    }

    default:
      return state;
  }
};
