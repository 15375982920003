import authSagas from 'modules/Auth/sagas';
import blogSagas from 'modules/Blog/sagas';
import bundle from 'modules/Bundle/sagas';
import categories from 'modules/Category/sagas';
import addressSagas from 'modules/Checkout/entities/Address/sagas';
import countriesSagas from 'modules/Checkout/entities/Country/sagas';
import couponSagas from 'modules/Checkout/entities/Coupon/sagas';
import metricsSagas from 'modules/Checkout/entities/Metrics/sagas';
import paySagas from 'modules/Checkout/entities/Pay/sagas';
import methodsPaymentSagas from 'modules/Checkout/entities/PaymentMethod/sagas';
import productSagas from 'modules/Checkout/entities/Product/sagas';
import userSetting from 'modules/Checkout/entities/UserSetting/sagas';
import checkoutSagas from 'modules/Checkout/sagas';
import collectionSagas from 'modules/Collection/sagas';
import communitySagas from 'modules/Community/sagas';
import certificateSagas from 'modules/Course/entities/Certificate/sagas';
import moduleSagas from 'modules/Course/entities/Module/sagas';
import lessonSagas from 'modules/Course/routes/Lesson/sagas';
import courseSagas from 'modules/Course/sagas';
import pageSagas from 'modules/CustomPage/sagas';
import dashboard from 'modules/Dashboard/sagas';
import globalSearch from 'modules/GlobalSearch/sagas';
import mediaSpaceSagas from 'modules/MediaSpace/sagas';
import orderSagas from 'modules/Order/sagas';
import plans from 'modules/Plan/sagas';
import subscriptions from 'modules/Subscription/sagas';
import tenancySagas from 'modules/Tenancy/sagas';
import userSagas from 'modules/User/sagas';
import { all } from 'redux-saga/effects';
import notificationsSagas from 'shared/components/organisms/AppNotification/sagas';
import denounceSagas from 'shared/components/organisms/Denounce/sagas';
import enrollments from 'shared/components/organisms/EnrollmentItems/sagas';
import commentsSagas from 'shared/components/organisms/GlobalComments/sagas';
import reviewSagas from 'shared/components/organisms/Reviews/sagas';
import spotlights from 'shared/components/organisms/SpotlightSlider/sagas';
import dynamicMenu from 'shared/components/organisms/Topbar/sagas';
import filterSaga from './toBeRefactored/Filter/sagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    addressSagas(),
    blogSagas(),
    courseSagas(),
    commentsSagas(),
    filterSaga(),
    collectionSagas(),
    checkoutSagas(),
    denounceSagas(),
    dynamicMenu(),
    moduleSagas(),
    lessonSagas(),
    productSagas(),
    userSagas(),
    communitySagas(),
    pageSagas(),
    reviewSagas(),
    paySagas(),
    methodsPaymentSagas(),
    orderSagas(),
    userSetting(),
    tenancySagas(),
    mediaSpaceSagas(),
    plans(),
    subscriptions(),
    notificationsSagas(),
    enrollments(),
    bundle(),
    categories(),
    spotlights(),
    couponSagas(),
    countriesSagas(),
    metricsSagas(),
    globalSearch(),
    dashboard(),
    certificateSagas(),
  ]);
}
