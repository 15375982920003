import {
  FETCH_MAIN_MENU_SUCCESS,
  FETCH_PROFILE_MENU_SUCCESS,
  FETCH_FOOTER_MENU_SUCCESS,
  FETCH_MENU_SHORTCUTS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  mainMenuList: null,
  profileMenuList: null,
  footerMenuList: null,
  shortcutsList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MAIN_MENU_SUCCESS: {
      return {
        ...state,
        mainMenuList: action.payload,
      };
    }

    case FETCH_PROFILE_MENU_SUCCESS: {
      return {
        ...state,
        profileMenuList: action.payload,
      };
    }

    case FETCH_FOOTER_MENU_SUCCESS: {
      return {
        ...state,
        footerMenuList: action.payload,
      };
    }

    case FETCH_MENU_SHORTCUTS_SUCCESS: {
      return {
        ...state,
        shortcutsList: action.payload,
      };
    }

    default:
      return state;
  }
};
