import { SET_DENOUNCE_SUCCESS } from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DENOUNCE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    default:
      return state;
  }
};
