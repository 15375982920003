function getDefaultLanguage(defaultLanguage) {
  const portuguese = {
    languageId: 'portuguese',
    locale: 'br',
    name: 'Português',
  };

  const english = {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  };

  switch (defaultLanguage) {
    case '1':
      return portuguese;
    case '2':
      return english;

    default:
      return portuguese;
  }
}

export default getDefaultLanguage;
