import {
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_CATEGORIES_ACTIVE_WITH_CONTENT,
  GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  categories: null,
  categoriesLoader: true,
  singleCategory: null,
  loader: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SINGLE_CATEGORY: {
      return {
        ...state,
        loader: true
      };
    }

    case GET_SINGLE_CATEGORY_SUCCESS: {
      return {
        ...state,
        singleCategory: action.payload,
        loader: false
      };
    }

    case GET_CATEGORIES_ACTIVE_WITH_CONTENT: {
      return {
        ...state,
        categoriesLoader: true
      };
    }

    case GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS: {
      return {
        ...state,
        categoriesLoader: false,
        categories: action.payload
      };
    }

    default:
      return state;
  }
}
