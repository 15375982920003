import {
  SET_PIX_PAYMENT,
  SET_PAYMENT_ERRORS,
  SET_INFO_INSTALLMENT,
  SET_BANK_SLIP_PAYMENT,
  SET_CREDIT_CARD_PAYMENT,
  SET_PIX_PAYMENT_SUCCESS,
  SET_BANK_SLIP_PAYMENT_SUCCESS,
  SET_CREDIT_CARD_PAYMENT_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  priceFormated: '',
  amountInstallment: '',
  totalPriceFormated: '',
  loader: false,
  errors: null,
  pixInfo: null,
  bankSlipInfo: null,
  creditCard: null,
  waitProcessing: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INFO_INSTALLMENT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_PIX_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }

    case SET_BANK_SLIP_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }

    case SET_CREDIT_CARD_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }

    case SET_PIX_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
        pixInfo: action.payload,
      };
    }

    case SET_BANK_SLIP_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
        bankSlipInfo: action.payload,
      };
    }

    case SET_CREDIT_CARD_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
        creditCardInfo: action.payload,
      };
    }

    case SET_PAYMENT_ERRORS: {
      return {
        ...state,
        loader: false,
        errors: action.payload.errors,
        waitProcessing: action.payload.waitProcessing,
      };
    }

    default:
      return state;
  }
};
