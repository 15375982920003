import {
  FETCH_CERTIFICATE_INFO_SUCCESS,
  FETCH_CERTIFICATE_VALIDATION_SUCCESS,
  GET_CERTIFICATE_INFO,
  GET_CERTIFICATE_VALIDATION,
} from 'shared/providers/redux/actionTypes';

export const onGetCertificateInfo = (slugCourse) => ({
  type: GET_CERTIFICATE_INFO,
  payload: slugCourse,
});

export const fetchCertificateInfoSuccess = (data) => ({
  type: FETCH_CERTIFICATE_INFO_SUCCESS,
  payload: data,
});

export const onGetCertificateValidation = (uuid) => ({
  type: GET_CERTIFICATE_VALIDATION,
  payload: uuid,
});

export const fetchCertificateValidationSuccess = (data) => ({
  type: FETCH_CERTIFICATE_VALIDATION_SUCCESS,
  payload: data,
});
