export const MAINTENANCE_MODE = false;
export const IS_MOBILE = window.innerHeight <= 812 && window.innerWidth <= 812;
export const FORM_ITEM_LAYOUT = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    lg: { span: 24 },
    md: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  },
};
