import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import openNotificationWithIcon from 'shared/components/atoms/Notification';
import api from 'shared/infra/services/tenantAPI';
import {
  createReviewSuccess,
  deleteReviewSuccess,
  fetchReviewsSuccess,
} from 'shared/providers/redux/actions';
import {
  CREATE_REVIEW,
  DELETE_REVIEW,
  GET_REVIEWS,
} from 'shared/providers/redux/actionTypes';

const fetchReviewsRequest = async ({
  contentable_type,
  contentable_id,
  page,
  per_page,
  rate,
}) => {
  try {
    const response = await api.get(
      `review/?contentable_type=${contentable_type}&contentable_id=${contentable_id}&page=${page}&per_page=${per_page}${
        rate ? `&rate=${rate}` : ''
      }`
    );
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* onFetchReviews({ payload }) {
  const reviews = yield call(fetchReviewsRequest, payload);
  if (reviews) yield put(fetchReviewsSuccess(reviews));
}

export function* fetchReviews() {
  yield takeEvery(GET_REVIEWS, onFetchReviews);
}

const createReviewRequest = async ({
  contentable_type,
  contentable_id,
  rate,
  description,
}) => {
  try {
    const response = await api.post('review', {
      contentable_type,
      contentable_id,
      rate,
      description,
    });
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onCreateReview({ payload }) {
  const response = yield call(createReviewRequest, payload);
  if (response) {
    yield put(createReviewSuccess(response));

    openNotificationWithIcon({
      type: 'success',
      message: 'Avaliação enviada com sucesso',
      description: 'Obrigado :)',
      duration: 7,
    });
  }
}

export function* createReview() {
  yield takeLatest(CREATE_REVIEW, onCreateReview);
}

const deleteReviewRequest = async ({
  reviewId,
  contentable_type,
  contentable_id,
}) => {
  try {
    const response = await api.delete(`review/${reviewId}`, {
      contentable_type,
      contentable_id,
    });
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onDeleteReview({ payload }) {
  const { reviewId, stateArray } = payload;
  const response = yield call(deleteReviewRequest, payload);
  if (response) {
    yield put(deleteReviewSuccess(response, reviewId, stateArray));

    openNotificationWithIcon({
      type: 'success',
      message: 'Avaliação excluída com sucesso',
      description: 'Sua avaliação não será mais exibida.',
      duration: 6,
    });
  }
}

export function* deleteReview() {
  yield takeLatest(DELETE_REVIEW, onDeleteReview);
}

export default function* rootSaga() {
  yield all([fork(fetchReviews), fork(createReview), fork(deleteReview)]);
}
