import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  animation: appearFromLeft 0.4s;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    margin: 20px 0;
    height: 100%;
    min-height: 450px;

    .logo {
      height: 90px;
    }

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
    }

    h1.siteName {
      font-size: 24px;
      text-align: center;
    }

    h2,
    span,
    p {
      font-style: normal;
      font-weight: normal;
      font-family: Roboto;
    }

    ${(props) =>
      props.$isDarkMode
        ? css`
            h1,
            h2,
            button.alreadyHaveAccount {
              color: #ffffff !important;
            }
          `
        : css`
            svg {
              color: #717171 !important;
            }
          `}

    img {
      max-width: 250px;
      max-height: 90px;
    }

    div.titulos {
      text-align: center;
      h1 {
        color: #606060;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 115%;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
      }

      h2 {
        color: #606060;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 132.5%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
      }
    }

    @media (max-width: 900px) {
      justify-content: space-around;
    }
  }

  @media (max-width: 1400px) {
    .col {
      margin: 0;
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const OptionButtons = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button.primary {
    cursor: pointer;
    width: 214px;
    height: 44px;
    padding: 0px 31px;
    margin: 0;
    font-size: 14px;

    span {
      color: ${(props) =>
        props.$isDarkMode ? '#1a1a1a' : '#fefefe'} !important;
    }
  }
`;

export const AlreadyHaveAccountButton = styled.button`
  margin-top: 30px;
  font-family: Roboto;
  font-weight: 500;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  color: ${(props) => (props.$isDarkMode ? '#ffffff' : '#606060')};
`;
