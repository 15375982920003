import React, { memo } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import staticIntlTranslatorToString from 'shared/utils/staticIntlTranslatorToString';
import ObjectIntlTranslator from 'shared/components/atoms/ObjectIntlTranslator';
import BadgeBuilder from './badgeBuilder';
import { Container, SubMenuTitle } from './styles';

const { SubMenu } = Menu;

const SidebarContent = () => {
  const tenantData = useSelector(({ tenancy }) => tenancy?.tenantData);
  const { pathname, navStyle, mode } = useSelector(({ settings }) => settings);
  const mainMenuList = useSelector(
    ({ dynamicMenu }) => dynamicMenu?.mainMenuList
  );
  const sidebarIsCollapsed = useSelector(
    ({ sidebar }) => sidebar?.sidebarIsCollapsed
  );

  const isDarkMode = mode === 'dark';

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const intl = useIntl();

  return (
    <Container
      isDarkMode={isDarkMode}
      navStyle={navStyle}
      tenantData={tenantData}
      className="gx-sidebar-content"
    >
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="inline"
        className="gx-layout-sider-menu"
        style={{
          background: 'transparent',
        }}
      >
        {mainMenuList?.map((item) =>
          item.menu_type === 'menu'
            ? item.items.map((subitem) => {
                if (subitem.enabled) {
                  if (subitem.children.length > 0) {
                    return (
                      <SubMenu
                        key={subitem.id}
                        title={
                          <SubMenuTitle>
                            <i
                              className={subitem.menu_icon || 'fa fa-circle'}
                            />
                            <BadgeBuilder
                              navStyle={navStyle}
                              item={subitem}
                              charsNumber={5}
                            />
                          </SubMenuTitle>
                        }
                      >
                        {subitem.children.map((item) => {
                          if (item.children.length > 0) {
                            return (
                              <SubMenu
                                key={item.id}
                                title={
                                  <SubMenuTitle>
                                    <i
                                      className={
                                        item.menu_icon || 'fa fa-circle'
                                      }
                                    />

                                    <BadgeBuilder
                                      navStyle={navStyle}
                                      item={item}
                                      charsNumber={5}
                                    />
                                  </SubMenuTitle>
                                }
                              >
                                {item.children.map((item) => (
                                  <Menu.Item key={item.id}>
                                    <i
                                      className={
                                        item.menu_icon || 'fa fa-circle'
                                      }
                                    />
                                    {sidebarIsCollapsed && (
                                      <span style={{ marginLeft: '10px' }}>
                                        <ObjectIntlTranslator
                                          variable={item.name}
                                        />
                                      </span>
                                    )}
                                    {item?.value && item?.value[0] !== '/' ? (
                                      <a href={item.value} target={item.target}>
                                        <BadgeBuilder
                                          navStyle={navStyle}
                                          item={item}
                                          charsNumber={5}
                                        />
                                      </a>
                                    ) : (
                                      <Link to={item.value}>
                                        <BadgeBuilder
                                          navStyle={navStyle}
                                          item={item}
                                          charsNumber={5}
                                        />
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </SubMenu>
                            );
                          }
                          return (
                            <Menu.Item key={item.id}>
                              <i className={item.menu_icon || 'fa fa-circle'} />
                              {sidebarIsCollapsed && (
                                <span style={{ marginLeft: '10px' }}>
                                  <ObjectIntlTranslator variable={item.name} />
                                </span>
                              )}
                              {item?.value && item?.value[0] !== '/' ? (
                                <a href={item.value} target={item.target}>
                                  <BadgeBuilder
                                    navStyle={navStyle}
                                    item={item}
                                    charsNumber={5}
                                  />
                                </a>
                              ) : (
                                <Link to={item.value}>
                                  <BadgeBuilder
                                    navStyle={navStyle}
                                    item={item}
                                    charsNumber={5}
                                  />
                                </Link>
                              )}
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item
                      title={staticIntlTranslatorToString(subitem.name, intl)}
                      key={subitem.id}
                    >
                      <i className={subitem.menu_icon || 'fa fa-circle'} />
                      {subitem.value !== '/' ? (
                        <a href={subitem.value} target={subitem.target}>
                          <BadgeBuilder
                            navStyle={navStyle}
                            item={subitem}
                            charsNumber={5}
                          />
                        </a>
                      ) : (
                        <Link to={subitem.value}>
                          <BadgeBuilder
                            navStyle={navStyle}
                            item={subitem}
                            charsNumber={5}
                          />
                        </Link>
                      )}
                    </Menu.Item>
                  );
                }
                return null;
              })
            : null
        )}
      </Menu>
    </Container>
  );
};

export default memo(SidebarContent);
