import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 20px;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;

  h1 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 10px;
  }

  span {
    color: #ababab;
    font-size: 17px;
    text-align: center;
  }

  p {
    font-size: 17px;
    margin-top: 10px;
    text-align: center;
  }

  .decorative-img {
    opacity: 0.13;
    margin-top: -50px;
    position: absolute;
  }
`;
