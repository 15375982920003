import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';

import ObjectIntlTranslator from 'shared/components/atoms/ObjectIntlTranslator';
import { List } from './styles';

const MenuShortcuts = memo(() => {
  const shortcutsList = useSelector(
    ({ dynamicMenu }) => dynamicMenu?.shortcutsList
  );
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const tenantTheme = useSelector(
    ({ tenancy }) => tenancy.tenantData.settings['general-theme']
  );
  const isShortcutMenuEnabled = useFeature(typeFeatures.shortcutMenu);

  if (!isShortcutMenuEnabled) return <></>;

  return (
    <List isDarkMode={isDarkMode} tenantTheme={tenantTheme}>
      {shortcutsList &&
        Array.isArray(shortcutsList) &&
        shortcutsList.map((main) =>
          main.items.map((item) => (
            <li key={item.id}>
              {item.value !== '/' ? (
                <a href={item.value} target={item.target}>
                  <i className={item.menu_icon} />
                  <p>
                    <ObjectIntlTranslator variable={item.name} />
                  </p>
                </a>
              ) : (
                <Link to={item.value}>
                  <i className={item.menu_icon} />
                  <p>
                    <ObjectIntlTranslator variable={item.name} />
                  </p>
                </Link>
              )}
            </li>
          ))
        )}
    </List>
  );
});

export default memo(MenuShortcuts);
