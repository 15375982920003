import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  position: relative;

  .ant-card-body {
    height: ${({ autoHeight }) => (autoHeight ? 'auto' : '450px')};
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gx-card-widget {
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.08);
  }

  .ant-progress-circle {
    margin: 0;
  }

  .ant-card {
    margin-bottom: 0 !important;
  }

  .gx-widget-badge {
    background: ${props =>
      props.isDarkMode
        ? props.tenantTheme?.themeDarkModeMasterColor
        : props.tenantTheme?.themeMasterColor};
    padding: 5px 8px;

    .fa-coins,
    p {
      font-size: 1em;
    }
  }

  .points {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: 0;

    p {
      margin: 0;
      font-size: 1.1em;
      color: #fff;
    }
  }

  .user-container {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    text-align: center;
    margin: 10px 0 3px 0;
  }

  .fa-award {
    font-size: 3em;
    background: #f8b101;
    color: #b27c14;
    padding: 10px;
    border-radius: 50px;
  }

  .fa-coins {
    font-size: 1.7em;
    margin-left: 3px;
    color: #fff;
  }

  .default-text {
    font-size: 0.8em;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
  }

  .temporarilyOff {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      text-align: center;

      color: #8a8a8a;

      margin-bottom: 20px;
      max-width: 290px;
    }

    p:first-child {
      font-size: 14px;
      margin-top: -10px;
    }

    p + p {
      font-size: 13px;
    }

    p:last-child {
      font-size: 11px;
    }
  }

  ${({ gamificationOn, topbar }) =>
    !Number(gamificationOn) &&
    !topbar &&
    css`
      .gx-rechart {
        margin-bottom: -15px;
      }
    `}
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .text {
    font-size: 0.8em;
    margin-left: 10px;
    text-align: left;

    p {
      margin-bottom: 0px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;

export const InsigniasContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .fa-star {
    font-size: 2em;
    margin: 5px 3px 0 3px;
    color: #fff;
    background: #0474da;
    padding: 7px;
    border-radius: 50px;
  }

  .bw {
    background: gray;
  }
`;
