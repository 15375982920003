import {
  GET_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  SET_TOAST_NOTIFICATIONS,
  SET_LAST_TOAST_NOTIFICATIONS,
  MARK_READ_NOTIFICATION_ALL,
  MARK_UNVIEWED_NOTIFICATION,
  MARK_READ_NOTIFICATION_UNIQUE,
  REMOVE_ALL_NOTIFICATIONS,
  REMOVE_UNIQUE_NOTIFICATIONS,
  PUST_NOTIFICATION_REAL_TIME
} from 'shared/providers/redux/actionTypes';

export const onGetNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

export const fetchNotificationsSuccess = notifications => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const setToastNotifications = notifications => {
  return {
    type: SET_TOAST_NOTIFICATIONS,
    payload: notifications,
  };
};

export const setLastToastNotifications = notifications => {
  return {
    type: SET_LAST_TOAST_NOTIFICATIONS,
    payload: notifications,
  };
};

export const onMarkReadNotificationAll = () => {
  return {
    type: MARK_READ_NOTIFICATION_ALL,
  };
};

export const onMarkReadNotificationUnique = id => {
  return {
    type: MARK_READ_NOTIFICATION_UNIQUE,
    payload: { id },
  };
};

export const onRemoveAllNotifications = () => {
  return {
    type: REMOVE_ALL_NOTIFICATIONS,
  };
};

export const onRemoveUniqueNotification = id => {
  return {
    type: REMOVE_UNIQUE_NOTIFICATIONS,
    payload: { id },
  };
};

export const onMarkUnviewedNotifications = () => {
  return {
    type: MARK_UNVIEWED_NOTIFICATION,
  };
};

export const onPushNotificationRealTime = event => {
  return {
    type: PUST_NOTIFICATION_REAL_TIME,
    payload: { event },
  };
};

