import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

import { logout } from 'shared/infra/services/auth';
import { userSignOut, setOpenModal } from 'shared/providers/redux/actions';

import { Container } from './styles';

const ConfirmationSuccess = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignIn = () => {
    dispatch(setOpenModal(true));

    history.push('/');
  };

  useEffect(() => {
    dispatch(userSignOut());

    setTimeout(() => {
      logout();
    }, 1000);
  }, []);

  return (
    <Container>
      <h1>{intl.formatMessage({ id: 'email-confirmed' })}</h1>
      <span className="description">
        {intl.formatMessage({
          id: 'your-account-has-been-successfully-confirmed',
        })}
      </span>
      <Button type="primary" onClick={handleSignIn}>
        {intl.formatMessage({ id: 'login' })}
      </Button>
    </Container>
  );
};

export default ConfirmationSuccess;
