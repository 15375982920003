import React from 'react';

import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Lock from 'shared/assets/icons/Lock.svg';

import NavigateBackModal from '../../components/NavigateBackModal';
import { Container } from './styles';

function ConfirmationModal({ navigate }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { userSignUpClosedWarning } = useSelector(
    ({ tenancy }) => tenancy.tenantData?.settings?.users
  );
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  return (
    <>
      <NavigateBackModal page="first" navigate={navigate} />
      <Container isDarkMode={isDarkMode}>
        <div className="titles">
          <img src={Lock} alt="logo" />
          <section className="header-title">
            <h1>
              {intl?.formatMessage({
                id: 'authModal.close.registrationClosed',
              })}
            </h1>

            <h3>{userSignUpClosedWarning}</h3>
          </section>

          <Button
            type="primary"
            onClick={() => dispatch({ type: 'DISPLAY', payload: 'login' })}
          >
            {intl?.formatMessage({
              id: 'authModal.close.alreadyHaveAccount',
            })}
          </Button>
        </div>
      </Container>
    </>
  );
}

ConfirmationModal.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default ConfirmationModal;
