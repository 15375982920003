import { Button } from 'antd';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';

import { checkIfBrowserIsOutdated } from 'shared/utils/validateBrowser.js';

import { Container, Content, CloseIcon } from './styles';

const BrowserNotSupported = () => {
  const intl = useIntl();
  const browserIsOutdated = checkIfBrowserIsOutdated();

  const [showBrowserModal, setShowBrowserModal] = useState(browserIsOutdated);

  const onCloseBrowserModal = () => {
    setShowBrowserModal(false);
    sessionStorage.setItem('browserOutdated', true);
  };

  useLayoutEffect(() => {
    const browserOutdated = sessionStorage.getItem('browserOutdated');

    if (browserIsOutdated) setShowBrowserModal(!browserOutdated);
  }, [browserIsOutdated]);

  useEffect(() => {
    const handleClick = (e) => {
      const elementClass = e?.target?.className;

      if (typeof elementClass === 'string'&& elementClass?.includes('modal-background'))
        onCloseBrowserModal();
    };

    document.addEventListener('click', handleClick, false);

    return () => document.removeEventListener('click', handleClick, false);
  }, []);

  return (
    <>
      {showBrowserModal && (
        <Container className="modal-background">
          <Content>
            <CloseIcon onClick={onCloseBrowserModal} />

            <h1>{intl.formatMessage({ id: 'browserNotSupported.title' })}</h1>

            <h3>
              {intl.formatMessage({ id: 'browserNotSupported.description' })}
            </h3>

            <Button onClick={onCloseBrowserModal} type="primary">
              {intl.formatMessage({ id: 'browserNotSupported.close' })}
            </Button>
          </Content>
        </Container>
      )}
    </>
  );
};

export default BrowserNotSupported;
