/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  hideAuthMessage,
  showAuthLoader,
  userSignIn,
} from 'shared/providers/redux/actions';
import Alert from 'shared/components/molecules/Alert';
import { validateEmail } from 'shared/utils/regex';

import Login from './components/LoginComponent';
import NavigateBackModal from '../../components/NavigateBackModal';
import { Container } from './styles';

function LoginModal({ navigate }) {
  const globalDispatch = useDispatch();
  const intl = useIntl();

  const [message, setMessage] = useState(null);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const { alertMessage, showMessage } = useSelector(({ warnings }) => warnings);
  const { settings } = useSelector(({ tenancy }) => tenancy.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const { users, site } = settings;
  const theme = settings['general-theme'];

  const loginImageStatus = users?.userLoginImage;
  const loginImage = users?.userLoginRequiredImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const title = users?.userLoginTitle;
  const description = users?.userLoginDescriptionTop;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  useEffect(() => {
    if (showMessage && alertMessage) {
      setMessage(alertMessage);
      setLoadingSpin(false);

      globalDispatch(hideAuthMessage());
    }
    // eslint-disable-next-line
  }, [showMessage, alertMessage]);

  const handleSubmit = ({ email, password }) => {
    if (validateEmail(email)) {
      if (email && password) {
        globalDispatch(showAuthLoader());
        globalDispatch(userSignIn({ email, password }));
        setLoadingSpin(true);
      }
    } else {
      setMessage(
        intl.formatMessage({ id: 'authModal.register.notAValidEmail' })
      );
    }
  };

  return (
    <>
      <NavigateBackModal page="first" navigate={navigate} />
      <Container
        hasImage={loginImageStatus !== 'none'}
        $isDarkMode={isDarkMode}
      >
        <div className="col">
          {loginImageStatus === 'none' || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <img
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              alt="logo"
              className="logo"
            />
          ) : loginImageStatus === 'default' ? (
            <img
              src={defaultImage || fallbackImage}
              alt="logo"
              className="logo"
            />
          ) : (
            <img
              src={loginImage || fallbackImage || defaultImage}
              alt="logo"
              className="logo"
            />
          )}

          <div className="titulos">
            <h1>{title}</h1>
            <h2>{description}</h2>
          </div>

          {loadingSpin && <Spin />}

          {message && message !== 'password_reset_id' ? (
            <Tooltip
              title={intl?.formatMessage({
                id: 'authModal.login.createAnAccount',
              })}
              placement="bottomRight"
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('register');
                }}
              >
                <Alert
                  message={message}
                  error
                  onClose={() => setMessage(null)}
                  margin="0"
                />
              </a>
            </Tooltip>
          ) : null}

          <Login handleSubmit={handleSubmit} />

          <div className="links">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                navigate('forgot');
              }}
              style={{
                color: '#000',
              }}
              className="link"
            >
              {intl?.formatMessage({
                id: 'authModal.login.forgotYourPassword',
              })}
            </Link>

            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate('register');
              }}
              className="link"
            >
              {intl?.formatMessage({
                id: 'authModal.login.dontHaveAnAccount',
              })}
              <b>
                {intl?.formatMessage({
                  id: 'authModal.login.createAnAccount',
                })}
              </b>
            </a>
          </div>
        </div>
      </Container>
    </>
  );
}

LoginModal.propTypes = {
  navigate: PropTypes.any.isRequired,
};

export default LoginModal;
