import { notification } from 'antd';

const openNotificationWithIcon = ({
  message,
  description,
  type,
  icon,
  duration,
}) => {
  notification[type]({
    message,
    description,
    top: 110,
    icon,
    duration,
  });
};

export default openNotificationWithIcon;
