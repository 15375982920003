import contrastAutoAdjust from 'shared/utils/contrastAutoAdjust';
import hexToRgb from 'shared/utils/hexToRgb';
import styled, { createGlobalStyle, css } from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) =>
    props.stylesContentCourse
      ? css`
          padding: 15px 15px 0px 0px;
        `
      : ''}

  @media (max-width: 800px) {
    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  :root {
    --text-tenant-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeTextMasterColor ||
          'var(--text-color)'
        : props?.$generalTheme?.themeMasterTextColor || 'var(--text-color)'};

    --master-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor || '#1264a3'
        : props?.$generalTheme?.themeMasterColor || 'rgba(9, 30, 66, 0.08)'};

    --text-color: ${(props) => (props.$isDarkMode ? '#f7f7f7' : '#262626')};

    --button-color: ${(props) => (props.$isDarkMode ? '#1a1a1a' : '#f7f7f7')};
    --hover-button-background: ${(props) =>
      props.$isDarkMode ? '#323232' : '#eeeeee'};
    --modal-background: ${(props) =>
      props.$isDarkMode ? '#303030' : '#fefefe'};
    --input-background: ${(props) =>
      props.$isDarkMode ? '#242424' : '#f5f5f5'};
    --background-color: ${(props) =>
      props.$isDarkMode ? '#1d1d1d' : '#f5f5f5'};

    --link-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : '#1155cc'};

    --text-with-contrast: ${(props) => {
      const masterColor = props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor;

      const buttonTextColor = props.$isDarkMode ? '#1a1a1a' : '#f7f7f7';

      return contrastAutoAdjust({
        background: masterColor,
        text: buttonTextColor,
      });
    }};

    --button-text-with-contrast: ${(props) => {
      const masterColor = props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor;

      const buttonColorTheme = props.$advancedTheme.themeBuyButtonsColor;
      const buttonColorOption = props.$advancedTheme.themeBuyButtonsOption;

      const backgroundColor =
        buttonColorOption === 'custom' ? buttonColorTheme : masterColor;

      const buttonTextColor = props.$isDarkMode ? '#1a1a1a' : '#f7f7f7';

      return contrastAutoAdjust({
        background: backgroundColor,
        text: buttonTextColor,
      });
    }};

    --themeTopbar-iconsColor: ${({ $isDarkMode, $generalTheme }) =>
      $isDarkMode ||
      $generalTheme.themeTopBar === 'dark' ||
      $generalTheme.themeTopBar === 'automatic'
        ? '#f7f7f7'
        : $generalTheme.themeTopBar === 'light'
        ? '#545454'
        : $generalTheme.themeTopBar === 'advanced'
        ? $generalTheme?.themeTopBarIconsColor
        : '#000000'};

    --themeTopbar-backgroundColor: ${(props) =>
      props.$isDarkMode
        ? '#f7f7f7'
        : props?.$generalTheme?.themeTopBarBackgroundColor};

    --header-background: ${({ $isDarkMode, $generalTheme, $advancedTheme }) => {
      const themeHeaderStyle =
        $generalTheme.themeHeaderStyle || $advancedTheme.themeHeaderStyle;
      const themeMasterColor =
        $generalTheme.themeMasterColor || $advancedTheme.themeMasterColor;
      const themeHeaderBackgroundColor =
        $generalTheme.themeHeaderBackgroundColor ||
        $advancedTheme.themeHeaderBackgroundColor;

      if ($isDarkMode) return '#404040';
      return themeHeaderStyle === 'automatic'
        ? themeMasterColor
        : themeHeaderBackgroundColor;
    }};

    --header-color: ${({ $isDarkMode, $generalTheme, $advancedTheme }) => {
      const themeHeaderStyle =
        $generalTheme.themeHeaderStyle || $advancedTheme.themeHeaderStyle;
      const themeHeaderTextColor =
        $generalTheme.themeHeaderTextColor ||
        $advancedTheme.themeHeaderTextColor;

      if ($isDarkMode || themeHeaderStyle === 'automatic') return '#f7f7f7';

      return themeHeaderTextColor;
    }}
  }

  /* Start new version ant-design */

    * {
      font-family: Inter, sans-serif;
    }

  .ant-row, .ant-form-inline, .ant-progress-circle {
    margin-left: 0;
    margin-right: 0;
  }

  .ant-btn {
    padding-top: 0%;
  }

  /* End new version ant-design */

  &::-webkit-scrollbar {
    width: 13px;
  }

  @media(max-width: 438px) {
    iframe.ql-video {
      width: 100%;
    }
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }

  ${(props) =>
    props.$isDarkMode
      ? css`
          .ant-notification-notice-description {
            color: #f5f5f5;
          }

          &::-webkit-scrollbar-track {
            background: #303030;
          }
          &::-webkit-scrollbar-thumb {
            background: #000;
          }
        `
      : css`
          &::-webkit-scrollbar-track {
            background: #ededed;
          }
          &::-webkit-scrollbar-thumb {
            background: #c0c0c0;
          }
        `}

  .ant-select-item-option-content {
    display: flex;
  }

  .ant-skeleton-title,
  .ant-skeleton-paragraph > li {
    border-radius: 20px;
  }

  .ant-timeline-item {
    padding-bottom: 15px !important;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .complementary-materials .ant-modal-content {
    background-color: var(--modal-background) !important;

    .ant-modal-body {
      * {
        color: var(--text-color);
      }
    }
  }

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }

  .ant-popover-inner-content {
    .edit-container {
      .text-edit {
        position: absolute;
        z-index: 0;
      }
      .input-file-editar {
        position: relative;
        z-index: 10;
        opacity: 0;
        width: 38px;
        height: 12px;
        top: 1px;
      }

      input[type='file'],
      input[type='file']::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }

  .ant-btn,
  .ant-input,
  .ant-dropdown,
  .ant-select-selection {
    border-radius: 8px;
  }

  .ant-popover-message > .anticon {
    top: 6px;
  }

  .ant-menu-item:after {
    border-color: transparent !important;
  }

  .ant-popover-buttons {

    .ant-btn {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: radial-gradient(
      148.71% 2000.16% at -43.87% -42.65%,
      rgba(26, 58, 81, 0.3) 0%,
      rgba(26, 58, 81, 0) 100%
    );
  }

  sup.ant-badge-count,
  .ant-btn-primary,
  .ant-progress-bg {
    background-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;

    border-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;

    &, i > svg, p {
      color: ${(props) =>
        props.$isDarkMode ? '#1A1A1A' : '#FFFFFF'} !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-input-search-button,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;

    border-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;
  }
  .ant-input:hover,
  .ant-input:focus,
  .ant-input .focus-visible,
  .ant-select-selection:hover,
  .ant-select-focused .ant-select-selection {
    border-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;
  }

  *::selection,
  .ant-spin-dot-item {
    background-color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeMasterColor
        : props?.$generalTheme?.themeMasterColor} !important;
        color: ${(props) =>
          props.$isDarkMode
            ? props?.$generalTheme?.themeDarkModeTextMasterColor
            : props?.$generalTheme?.themeTextMasterColor} !important;
  }

  .ant-btn-primary:hover {
    background-color: ${(props) =>
      `rgba(${hexToRgb(
        props.$isDarkMode
          ? props?.$generalTheme?.themeDarkModeMasterColor
          : props?.$generalTheme?.themeMasterColor
      )}, 0.8)`} !important;
  }

  .ant-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-active {
    background-color: ${(props) =>
      `rgba(${hexToRgb(
        props.$isDarkMode
          ? props?.$generalTheme?.themeDarkModeMasterColor
          : props?.$generalTheme?.themeMasterColor
      )}, 0.15)`} !important;
  }

  .card-style {
    background: #fff;
    border-radius: 10px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.05);
    padding: 15px;
  }

  .soft-bold {
    font-weight: 600;
    display: inline-block;
  }

  .gx-course-banner {
    background-color: var(--header-background);

    &, h4, h2, a {
      color: ${(props) =>
        props?.$generalTheme?.themeHeaderStyle === 'automatic'
          ? '#ffffff'
          : props?.$generalTheme?.themeHeaderTextColor} !important;
    }
  }

  .gx-course-banner:before {
    background-color: transparent;
  }

  .widget-margin-top {
    margin-top: 32px;
  }

  .ant-popover.ant-popover-placement-left {
    button.ant-btn.ant-btn-sm {
      margin: 5px;
    }

    .ant-popover-message-title {
      padding-top: 2px;
    }
  }

  .ql-mention-list-container {
    height: 300px !important;
  }

  .ql-tooltip.ql-editing {
    z-index: 99;
  }

  .gx-popover-horizantal {
    padding-top: 10px;
    z-index: 99 !important;
  }

  @media (min-width: 700px) {
    .gx-popover-horizantal {
      top: 44px !important;
   }

   .gx-popover-horizantal.avatar-user {
      top: 50px !important;
   }
  }

  @media screen and (max-width: 575px) {
   .ql-action {
      display: none;
    }

    .gx-main-content-wrapper {
      padding-bottom: 100px;
    }

    .gx-popover-horizantal,
    .gx-popover-horizantal.avatar-user {
      top: 61px !important;
   }
  }
`;
