import {
  FETCH_ALL_LESSONS_SUCCESS,
  FETCH_INIT_LESSON_SUCCESS,
  FETCH_LESSON_SUCCESS,
  FETCH_LIST_LESSON_SUCCESS,
  GET_ALL_LESSONS,
  GET_INIT_LESSON,
  GET_LESSON,
  GET_LIST_LESSON,
  SET_LESSON_ASSISTED,
  SET_LESSON_ASSISTED_SUCCESS,
  SET_URL_LESSON,
  SET_URL_LESSON_SUCCESS,
  SET_ASSISTED_LESSON,
  IS_LESSON_PAGE,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  lessonsList: [],
  lesson: {},
  courseLessons: [],
  initLesson: [],
  params: {},
  paramsLessonUrl: {},
  loader: false,
  isLessonPage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LESSONS: {
      return {
        ...state,
      };
    }

    case GET_LESSON: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case GET_INIT_LESSON: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case GET_LIST_LESSON: {
      const { moduleSlug } = action.payload;
      return {
        ...state,
        lessonsList: state.courseLessons.filter(
          (lesson) => lesson.module_id === Number(moduleSlug)
        ),
      };
    }

    case SET_ASSISTED_LESSON: {
      const { slug, assisted } = action.payload;
      const newCourseLesson = state.courseLessons.map((lesson) => {
        if (lesson.slug === slug) {
          lesson.user_assisted = assisted;
        }
        return lesson;
      });

      const newlessonsList = state.lessonsList.map((lesson) => {
        if (lesson.slug === slug) {
          lesson.user_assisted = assisted;
        }
        return lesson;
      });

      const newLesson = { ...state.lesson };
      newLesson.user_assisted = assisted;
      return {
        ...state,
        courseLessons: newCourseLesson,
        lessonsList: newlessonsList,
        lesson: newLesson,
      };
    }

    case SET_LESSON_ASSISTED: {
      return {
        ...state,
        params: action.payload,
      };
    }

    case SET_LESSON_ASSISTED_SUCCESS: {
      return {
        ...state,
      };
    }

    case SET_URL_LESSON: {
      return {
        ...state,
        loader: true,
        paramsLessonUrl: action.payload,
      };
    }

    case SET_URL_LESSON_SUCCESS: {
      return {
        ...state,
        loader: false,
        paramsLessonUrl: action.payload,
      };
    }

    case FETCH_LESSON_SUCCESS: {
      return {
        ...state,
        lesson: action.payload,
      };
    }

    case FETCH_INIT_LESSON_SUCCESS: {
      return {
        ...state,
        initLesson: action.payload,
      };
    }

    case FETCH_LIST_LESSON_SUCCESS: {
      return {
        ...state,
        lessonsList: action.payload,
      };
    }

    case FETCH_ALL_LESSONS_SUCCESS: {
      return {
        ...state,
        courseLessons: action.payload,
      };
    }

    case IS_LESSON_PAGE: {
      return {
        ...state,
        isLessonPage: action.payload,
      };
    }

    default:
      return state;
  }
};
