import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useWindowSize from 'shared/utils/hooks/useWindowSize';
import ensinioBrandIcon from 'shared/assets/images/ensinio_logo.svg';
import ensinioBrandIconDark from 'shared/assets/images/ensinio_logo_dark_new.svg';

import { Container } from './styles';

const EnsinioBrand = () => {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 800;

  const generalTheme = useSelector(
    ({ tenancy }) => tenancy.tenantData?.settings['general-theme']
  );
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const showEnsinioBrand = generalTheme.themeOthersShowEnsinioBrand === '1';

  return (
    <>
      {showEnsinioBrand && (
        <Container
          href="https://ensinio.com"
          target="_blank"
          isDarkMode={isDarkMode}
          isMobile={isMobile}
        >
          <p>{intl.formatMessage({ id: 'ensinioBrand.plataformMadeWith' })}</p>

          {isDarkMode ? (
            <img src={ensinioBrandIconDark} alt="ensinio_logo" />
          ) : (
            <img src={ensinioBrandIcon} alt="ensinio_logo" />
          )}
        </Container>
      )}
    </>
  );
};

export default memo(EnsinioBrand);
