import { useSelector } from 'react-redux';
import { ReactComponent as Blog } from 'shared/assets/icons/blog-class-list.svg';
import { ReactComponent as Bundle } from 'shared/assets/icons/box.svg';
import { ReactComponent as Comunity } from 'shared/assets/icons/chat-social.svg';
import { ReactComponent as Course } from 'shared/assets/icons/course-class-list.svg';
import { ReactComponent as Lesson } from 'shared/assets/icons/lesson-play.svg';
import { ReactComponent as MediaSpace } from 'shared/assets/icons/media-space-class-list.svg';
import { ReactComponent as Modules } from 'shared/assets/icons/module-class-list.svg';
import { ReactComponent as Category } from 'shared/assets/icons/stack-files-category.svg';
import { ReactComponent as Topic } from 'shared/assets/icons/topic-class-list.svg';
import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';

const useModelList = () => {
  const tenantData = useSelector(({ tenancy }) => tenancy?.tenantData);

  const modelClass = useSelector(
    ({ globalSearch }) => globalSearch?.modelClass
  );

  const isCoursesEnabled = useFeature(typeFeatures.courses);
  const isBlogEnabled = useFeature(typeFeatures.blog);
  const isCategoriesEnabled = useFeature(typeFeatures.categories);
  const isCommunityEnabled = useFeature(typeFeatures.community);
  const isMediaSpaceEnabled = useFeature(typeFeatures.mediaSpace);
  const isProductBundlesEnabled = useFeature(typeFeatures.productBundles);

  const modelClassList = [
    {
      model: 'Vico068\\CourseTools\\Lesson',
      name: 'globalSearch.lessons',
      icon: Lesson,
      active: tenantData?.settings.courses.courseActive === '1',
      enabled: isCoursesEnabled,
    },
    {
      model: 'App\\Blog',
      name: 'globalSearch.blogs',
      icon: Blog,
      active: tenantData?.settings.blog.blogActive === '1',
      enabled: isBlogEnabled,
    },
    {
      model: 'App\\Category',
      name: 'globalSearch.categories',
      icon: Category,
      active: true,
      enabled: isCategoriesEnabled,
    },
    {
      model: 'Deskti\\Forum\\Models\\Forum',
      name: 'globalSearch.community',
      icon: Comunity,
      active: tenantData?.settings['social-network'].communityActive === '1',
      enabled: isCommunityEnabled,
    },
    {
      model: 'App\\MediaSpaceContent',
      name: 'globalSearch.contents',
      icon: MediaSpace,
      active: tenantData?.settings['media-space'].mediaSpaceActive === '1',
      enabled: isMediaSpaceEnabled,
    },
    {
      model: 'Vico068\\CourseTools\\Course',
      name: 'globalSearch.courses',
      icon: Course,
      active: tenantData?.settings.courses.courseActive === '1',
      enabled: isCoursesEnabled,
    },
    {
      model: 'Vico068\\CourseTools\\Module',
      name: 'globalSearch.modules',
      icon: Modules,
      active: tenantData?.settings.courses.courseActive === '1',
      enabled: isCoursesEnabled,
    },
    {
      model: 'App\\ProductBundle',
      name: 'globalSearch.bundles',
      icon: Bundle,
      active: tenantData?.settings.bundles.bundleActive === '1',
      enabled: isProductBundlesEnabled,
    },
    {
      model: 'Deskti\\Forum\\Models\\ForumTopic',
      name: 'globalSearch.topics',
      icon: Topic,
      active: tenantData?.settings['social-network'].communityActive === '1',
      enabled: isCommunityEnabled,
    },
  ];

  const activeModelClasses = modelClassList.filter(
    (model) => model.active && model.enabled
  );

  return { activeModelClasses, modelClass };
};

export default useModelList;
