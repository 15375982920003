import styled from 'styled-components';

import { DefaultThemeSettings } from './DefaultThemes';

export const DarkMode = styled(DefaultThemeSettings)`
  header.ant-layout-header,
  .search-container-on-mobile {
    background: #121212;
  }

  .search-input {
    background: #323232;
  }

  header.ant-layout-header {
    .icon.sidebar {
      color: #ffffff;
    }

    .content-container {
      .site-title h1,
      .anticon-search.icon,
      .course_options,
      ul li,
      ul li i {
        color: #ffffff;
      }
    }
  }
`;
