import { fetchCoursesKeepWatchingSucess } from 'shared/providers/redux/actions';

import { GET_COURSES_KEEP_WATCHING } from 'shared/providers/redux/actionTypes';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import api from 'shared/infra/services/tenantAPI';

const getKeepWatchingRequest = async () => {
  try {
    const response = await api.get('course/continuewatching');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getKeepWatching() {
  const courses = yield call(getKeepWatchingRequest);
  if (courses) yield put(fetchCoursesKeepWatchingSucess(courses));
}

export function* fetchCoursesKeepWatching() {
  yield takeEvery(GET_COURSES_KEEP_WATCHING, getKeepWatching);
}

export default function* rootSaga() {
  yield all([fork(fetchCoursesKeepWatching)]);
}
