import placeholder from 'shared/assets/images/mock_empty.svg';

const setProductSession = (entityType, paramType, product) => {
  sessionStorage.removeItem('paid_product');

  sessionStorage.setItem(
    'product_session',
    JSON.stringify([
      {
        title: product?.title || product?.name,
        slug_product: product.slug,
        cover:
          product?.image_thumbnail ||
          product?.thumbnail ||
          product?.content?.thumbnail ||
          product?.content?.image_thumbnail ||
          placeholder,
      },
      {
        amount: 1,
        paramType,
        entityType,
        entityId: product.id,
        className: product.className || product.content_type,
        product_virtual: true,
        payment_methods: product.payment_methods,
        frequency: product?.interval_description,
        final_price: product.price,
        final_price_int: product.price_info.value,
        installments: product.installments,
        has_installments: product.has_installments,
        total_installments: product.total_installments,
        maintenance: product.maintenance,
      },
    ])
  );
};

export default setProductSession;
