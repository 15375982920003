import {
  SET_DENOUNCE,
  SET_DENOUNCE_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const setDenounce = (
  contentType,
  entityId,
  reportedUserId,
  type,
  description
) => {
  return {
    type: SET_DENOUNCE,
    payload: { contentType, entityId, reportedUserId, type, description },
  };
};

export const setDenounceSuccess = () => {
  return {
    type: SET_DENOUNCE_SUCCESS,
  };
};
