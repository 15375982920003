import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const getPage = pageSlug => ({
  type: GET_PAGE,
  payload: pageSlug,
});

export const getPageSuccess = pageData => ({
  type: GET_PAGE_SUCCESS,
  payload: pageData,
});
