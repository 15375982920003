import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LockOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const PasswordInput = ({ FormItemComponent, InputComponent, getValue }) => {
  const intl = useIntl();
  const [password, setPassword] = useState('');

  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const definePasswordForce = (e) => {
    const { value } = e.target;

    setPassword(value?.replace(' ', ''));

    if (getValue) getValue(value);
  };

  return (
    <>
      <FormItemComponent>
        <InputComponent
          style={{ height: '3.125rem' }}
          value={password}
          onChange={definePasswordForce}
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          size="large"
          placeholder={intl?.formatMessage({
            id: 'authModal.register.password',
          })}
          id="password"
          isDarkMode={isDarkMode}
        />
      </FormItemComponent>
    </>
  );
};

PasswordInput.defaultProps = {
  getValue: () => {},
};

PasswordInput.propTypes = {
  FormItemComponent: PropTypes.func.isRequired,
  InputComponent: PropTypes.func.isRequired,
  getValue: PropTypes.func,
};

export default PasswordInput;
