import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import notfound from 'shared/assets/images/notfound.png';
import notfoundDarkMode from 'shared/assets/images/lostAstronaut.png';

import { Container } from './styles';

const NotFound = ({ path, title, description, buttonText }) => {
  const history = useHistory();
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  return (
    <Container isDarkMode={isDarkMode}>
      <p>ERRO 404</p>
      <h1>{title}</h1>
      <p>{description}</p>
      <Button onClick={() => history.replace(path)} type="primary">
        {buttonText}
      </Button>
      <img src={isDarkMode ? notfoundDarkMode : notfound} alt="Not Found" />
    </Container>
  );
};

NotFound.defaultProps = {
  path: '/',
  title: 'Ops :(',
  description: 'Essa página não existe ou está indisponível no momento',
  buttonText: 'Voltar para a página inicial',
};

NotFound.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
};

export default NotFound;
