/* eslint-disable consistent-return */
function parseData (param) {
  if (typeof param === typeof 'string') {
    return param.startsWith('{') ? JSON.parse(param) : param;
  }
  return param;
}

function staticIntlTranslatorToString (param, intl) {
  const data = parseData(param);

  if (!data) return;
  if (typeof data === 'string') return data;

  const locale = intl.locale?.replace('-', '_');

  const formattedString =
    data[locale] || data[intl.locale] || data[locale.slice(0, 2)];

  return (
    formattedString ||
    (typeof data === 'object'
      ? Object.keys(data).map((key) => data[key])[0]
      : data)
  );
}

export default staticIntlTranslatorToString;
