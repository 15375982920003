import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 20;
  top: 42px;

  width: 100%;
  max-width: 32rem;
  max-height: 60rem;

  margin-top: 1rem;
  padding: 1.5rem 2rem;
  gap: 1rem;

  border-radius: 8px;
  background: ${(props) => (props.$isDarkMode ? '#2f2f2f' : '#ffffff')};

  overflow: hidden;

  @media (max-width: 500px) {
    gap: 0.5rem;
    padding: 1rem;

    height: calc(100vh - 120px);
    width: 100vw;
    border-radius: 0;
    margin: 0.5rem 0;
  }
`;

export const FoundedResultsText = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.094rem;
  width: 100%;

  margin-bottom: 0.5rem;

  color: var(--text-color);
`;

export const SeeMoreResultsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: none;
  border: none;

  height: max-content !important;
  margin-top: 0.5rem;
  width: 100%;

  svg {
    margin-left: 10px;
    font-size: 20px;
  }

  section {
    display: flex;
    align-items: center;

    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.094rem;

    color: var(--text-color);
    cursor: pointer;

    transition: 0.3s all ease-in-out;

    &:hover {
      transform: translateX(+3px);
      color: var(--master-color);
    }
  }
`;

export const NoResultsFound = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 200px;
  font-size: 1.125rem;

  color: var(--text-color);
`;
