import { connectRouter } from 'connected-react-router';
import Auth from 'modules/Auth/reducers';
import Blog from 'modules/Blog/reducers';
import Bundle from 'modules/Bundle/reducers';
import Categories from 'modules/Category/reducers';
import Address from 'modules/Checkout/entities/Address/reducers';
import Countries from 'modules/Checkout/entities/Country/reducers';
import Coupon from 'modules/Checkout/entities/Coupon/reducers';
import CheckoutMetrics from 'modules/Checkout/entities/Metrics/reducers';
import Pay from 'modules/Checkout/entities/Pay/reducers';
import MethodsPayment from 'modules/Checkout/entities/PaymentMethod/reducers';
import Product from 'modules/Checkout/entities/Product/reducers';
import UserSetting from 'modules/Checkout/entities/UserSetting/reducers';
import Checkout from 'modules/Checkout/reducers';
import Collection from 'modules/Collection/reducers';
import Community from 'modules/Community/reducers';
import Certificate from 'modules/Course/entities/Certificate/reducers';
import Module from 'modules/Course/entities/Module/reducers';
import Course from 'modules/Course/reducers';
import Lesson from 'modules/Course/routes/Lesson/reducers';
import Page from 'modules/CustomPage/reducers';
import Dashboard from 'modules/Dashboard/reducer';
import GlobalSearch from 'modules/GlobalSearch/reducers';
import MediaSpace from 'modules/MediaSpace/reducers';
import Orders from 'modules/Order/reducers';
import Plans from 'modules/Plan/reducers';
import Subscriptions from 'modules/Subscription/reducers';
import Tenancy from 'modules/Tenancy/reducers';
import User from 'modules/User/reducers';
import UsersCommunity from 'modules/User/routes/UsersCommunity/reducers';
import { combineReducers } from 'redux';
import Notifications from 'shared/components/organisms/AppNotification/reducers';
import Denounce from 'shared/components/organisms/Denounce/reducers';
import Enrollments from 'shared/components/organisms/EnrollmentItems/reducers';
import CommentGlobal from 'shared/components/organisms/GlobalComments/reducers';
import Review from 'shared/components/organisms/Reviews/reducers';
import Sidebar from 'shared/components/organisms/Sidebar/reducers';
import Spotlights from 'shared/components/organisms/SpotlightSlider/reducers';
import Menu from 'shared/components/organisms/Topbar/reducers';
import Common from './toBeRefactored/Common/reducers';
import Custom from './toBeRefactored/Custom/reducers';
import Filter from './toBeRefactored/Filter/reducers';
import Settings from './toBeRefactored/Setting/reducers';
import Warnings from './toBeRefactored/Warnings/reducers';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    address: Address,
    blog: Blog,
    common: Common,
    courses: Course,
    commentGlobal: CommentGlobal,
    filter: Filter,
    collections: Collection,
    modules: Module,
    lessons: Lesson,
    products: Product,
    user: User,
    userSetting: UserSetting,
    usersCommunity: UsersCommunity,
    checkout: Checkout,
    tenancy: Tenancy,
    dynamicMenu: Menu,
    custom: Custom,
    community: Community,
    page: Page,
    denounce: Denounce,
    review: Review,
    pay: Pay,
    methodsPayment: MethodsPayment,
    orders: Orders,
    warnings: Warnings,
    mediaSpace: MediaSpace,
    plans: Plans,
    subscriptions: Subscriptions,
    notifications: Notifications,
    enrollments: Enrollments,
    bundle: Bundle,
    categories: Categories,
    spotlights: Spotlights,
    coupon: Coupon,
    countries: Countries,
    sidebar: Sidebar,
    checkoutMetrics: CheckoutMetrics,
    globalSearch: GlobalSearch,
    dashboard: Dashboard,
    certificate: Certificate,
  });

export default createRootReducer;
