import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FiX, FiArrowLeft } from 'react-icons/fi';

import { Container, Header } from './styles';

const Modal = ({ children, onClose, onGoBack, paddingDisabled }) => {
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleGoBack = () => {
    if (onGoBack) onGoBack();
  };

  const maskStyle = {
    backgroundColor: 'rgb(0 0 0 / 10%)',
    backdropFilter: 'blur(15px)',
  };

  const wrapStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Container
      isDarkMode={isDarkMode}
      paddingDisabled={paddingDisabled}
      visible
      maskStyle={maskStyle}
      wrapProps={{ style: wrapStyle }}
      footer={null}
      closable={false}
    >
      {(onGoBack || onClose) && (
        <Header goBackIsEnable={onGoBack} isDarkMode={isDarkMode}>
          {onGoBack && (
            <FiArrowLeft
              onClick={handleGoBack}
              className="back_button"
              id="back"
            />
          )}

          {onClose && (
            <FiX onClick={handleClose} className="close_button" id="close" data-testid="close"/>
          )}
        </Header>
      )}

      {children}
    </Container>
  );
};

Modal.defaultProps = {
  onClose: null,
  children: null,
  onGoBack: null,
  paddingDisabled: false,
};

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func,
  paddingDisabled: PropTypes.bool,
};

export default Modal;
