import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
  }

  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  ${props =>
    props.isDarkMode
      ? css`
          h1,
          h3 {
            color: #ffffff;
          }
        `
      : css`
          svg {
            color: #717171 !important;
          }

          h1,
          h3 {
            color: #242424;
          }
        `}

  button.ant-btn.ant-btn-primary {
    cursor: pointer;
    width: 280px;
    height: 44px;
    margin: 20px 0px 0px 0px;

    font-size: 14px;
    text-align: center;
  }

  img {
    max-width: 88px;
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 115%;
    letter-spacing: -0.5px;
    margin-bottom: 12px;
  }

  .titles {
    width: 100%;
    height: 100%;

    margin: 40px 0;
    padding: 0;
    min-height: 400px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1400px) {
      margin: 0;
    }
  }

  section {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  section h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin: auto 0;

    height: 100vh;
    max-height: 100vh;
    border-radius: 0px;

    img {
      max-width: 70% !important;
    }
  }
`;
