import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';

import {
  GET_COMMUNITY_CATEGORIES,
  GET_COMMUNITY_TOPICS,
  GET_COMMUNITY_LAST_COMMENTS,
  GET_COMMUNITY_LAST_TOPICS,
  GET_COMMUNITY_AUTHORS,
  SET_COMMUNITY_TOPIC,
  READ_COMMUNITY_TOPIC,
  UPDATE_COMMUNITY_TOPIC,
  DELETE_COMMUNITY_TOPIC,
  LIKE_DISLIKE_COMMUNITY_TOPIC,
  PIN_COMMUNITY_TOPIC,
} from 'shared/providers/redux/actionTypes';
import {
  fetchCommunityCategoriesSuccess,
  fetchCommunityTopicsSuccess,
  fetchCommunityLastCommentsSuccess,
  fetchCommunityLastTopicsSuccess,
  fetchCommunityAuthorsSuccess,
  setCommunityTopicSuccess,
  readCommunityTopicSuccess,
  updateCommunityTopicSuccess,
  deleteCommunityTopicSuccess,
  likeDislikeCommunityTopicSuccess,
} from 'shared/providers/redux/actions';

async function getCommunityCategoriesRequest() {
  try {
    const response = await api.get('forum');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* getCommunityCategories() {
  const { data } = yield call(getCommunityCategoriesRequest);
  if (data) yield put(fetchCommunityCategoriesSuccess(data));
}

export function* onGetCommunityCategories() {
  yield takeLatest(GET_COMMUNITY_CATEGORIES, getCommunityCategories);
}

async function getCommunityTopicsRequest({ category, page, orderBy }) {
  let params = {};

  if (!orderBy || orderBy === 'recent') {
    params = {
      'order[0][index]': 'created_at',
      'order[0][value]': 'desc',
    };
  } else if (orderBy === 'older') {
    params = {
      'order[0][index]': 'created_at',
      'order[0][value]': 'asc',
    };
  }

  try {
    const response = await api.get(
      `forum/${category}/topics?page=${page || 1}`,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* getCommunityTopics({ payload }) {
  const {
    data,
    fixed,
    meta: { total, per_page, current_page },
    forum,
  } = yield call(getCommunityTopicsRequest, payload);
  const topics = data;
  const pages = { total, per_page, current_page };

  if (data)
    yield put(
      fetchCommunityTopicsSuccess({
        topics,
        pages,
        fixed: fixed.topics.data,
        forum,
      })
    );
}

export function* onGetCommunityTopics() {
  yield takeLatest(GET_COMMUNITY_TOPICS, getCommunityTopics);
}

async function getCommunityLastTopicsRequest() {
  try {
    const response = await api.get('forum/last-topics');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* getCommunityLastTopics({ payload }) {
  const { data } = yield call(getCommunityLastTopicsRequest, payload);
  if (data) yield put(fetchCommunityLastTopicsSuccess(data));
}

export function* onGetCommunityLastTopics() {
  yield takeLatest(GET_COMMUNITY_LAST_TOPICS, getCommunityLastTopics);
}

async function getCommunityLastCommentsRequest() {
  try {
    const response = await api.get('forum/last-comments');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* getCommunityLastComments({ payload }) {
  const { data } = yield call(getCommunityLastCommentsRequest, payload);
  if (data) yield put(fetchCommunityLastCommentsSuccess(data));
}

export function* onGetCommunityLastComments() {
  yield takeLatest(GET_COMMUNITY_LAST_COMMENTS, getCommunityLastComments);
}

async function setCommunityTopicRequest(payload) {
  const { name, description, category } = payload;
  try {
    const response = await api.post(`forum/${category}/topics`, {
      name,
      description,
      status: 'published',
    });

    if (response.data) {
      const topics = await api.get(`forum/${category}/topics?page=${1}`, {
        params: {
          'order[0][index]': 'created_at',
          'order[0][value]': 'desc',
        },
      });
      return topics.data;
    }
  } catch (error) {
    console.log(error);
    return error;
  }

  return null;
}

async function getCommunityAuthorsRequest(payload) {
  const { communityId } = payload;
  try {
    const response = await api.get(`forum/list-authors/${communityId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* getCommunityAuthors({ payload }) {
  const { data } = yield call(getCommunityAuthorsRequest, payload);
  if (data) yield put(fetchCommunityAuthorsSuccess(data));
}

export function* onGetCommunityAuthors() {
  yield takeLatest(GET_COMMUNITY_AUTHORS, getCommunityAuthors);
}

function* setCommunityTopic({ payload }) {
  const {
    data,
    fixed,
    forum,
    meta: { total, per_page, current_page },
  } = yield call(setCommunityTopicRequest, payload);
  const topics = data;
  const pages = { total, per_page, current_page };

  if (data) {
    yield put(
      setCommunityTopicSuccess({
        topics,
        fixed: fixed.topics.data,
        pages,
        forum,
      })
    );
  }
}

export function* onSetCommunityTopic() {
  yield takeLatest(SET_COMMUNITY_TOPIC, setCommunityTopic);
}

async function readCommunityTopicRequest({ category, topic }) {
  try {
    const response = await api.get(`forum/${category}/topics/${topic}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* readCommunityTopic({ payload }) {
  const { data } = yield call(readCommunityTopicRequest, payload);
  if (data) yield put(readCommunityTopicSuccess(data));
}

export function* onReadCommunityTopic() {
  yield takeLatest(READ_COMMUNITY_TOPIC, readCommunityTopic);
}

async function updateCommunityTopicRequest({
  category,
  topic,
  name,
  description,
}) {
  try {
    const response = await api.put(`forum/${category}/topics/${topic}`, {
      name,
      description,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* updateCommunityTopic({ payload }) {
  const { data } = yield call(updateCommunityTopicRequest, payload);
  if (data.slug) yield put(updateCommunityTopicSuccess({ slug: data.slug }));
}

export function* onUpdateCommunityTopic() {
  yield takeLatest(UPDATE_COMMUNITY_TOPIC, updateCommunityTopic);
}

async function deleteCommunityTopicRequest(payload) {
  const { category, topicSlug } = payload;

  try {
    const response = await api.delete(`forum/${category}/topics/${topicSlug}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function setPinCommunityTopicRequest(payload) {
  const { slug } = payload;

  try {
    await api.post(`forum${slug}/fixed`);
  } catch (error) {
    console.log(error);
  }
}

function* deleteCommunityTopic({ payload }) {
  const { status } = yield call(deleteCommunityTopicRequest, payload);
  if (status === 200) yield put(deleteCommunityTopicSuccess(payload.topicSlug));
}

export function* onDeleteCommunityTopic() {
  yield takeLatest(DELETE_COMMUNITY_TOPIC, deleteCommunityTopic);
}

async function likeDislikeCommunityTopicRequest(payload) {
  const { option, topic } = payload;

  try {
    const response = await api.post(`/forum/topic/${option}/${topic}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

function* likeDislikeCommunityTopic({ payload }) {
  const { data } = yield call(likeDislikeCommunityTopicRequest, payload);
  if (data) {
    const { data: read } = yield call(readCommunityTopicRequest, payload);
    if (read) yield put(likeDislikeCommunityTopicSuccess(read));
  }
}

function* setPinCommunityTopic({ payload }) {
  yield call(setPinCommunityTopicRequest, payload);
}

export function* onLikeDislikeCommunityTopic() {
  yield takeLatest(LIKE_DISLIKE_COMMUNITY_TOPIC, likeDislikeCommunityTopic);
}

export function* onSetPinCommunityTopic() {
  yield takeLatest(PIN_COMMUNITY_TOPIC, setPinCommunityTopic);
}

export default function* rootSaga() {
  yield all([
    fork(onGetCommunityCategories),
    fork(onGetCommunityTopics),
    fork(onGetCommunityLastTopics),
    fork(onGetCommunityLastComments),
    fork(onGetCommunityAuthors),
    fork(onSetCommunityTopic),
    fork(onUpdateCommunityTopic),
    fork(onReadCommunityTopic),
    fork(onDeleteCommunityTopic),
    fork(onLikeDislikeCommunityTopic),
    fork(onSetPinCommunityTopic),
  ]);
}
