import {
  CLEAR_FILTER,
  GET_FILTER,
  FETCH_FILTER_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const filterGlobal = (options, entity) => ({
  type: GET_FILTER,
  payload: { options, entity },
});

export const filterGlobalSuccess = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const clearfilterGlobal = () => ({
  type: CLEAR_FILTER,
});
