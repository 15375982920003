import {
  fork,
  all,
  call,
  takeEvery,
  takeLatest,
  put,
  delay,
} from 'redux-saga/effects';

import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_SESSION,
  GET_SUBSCRIPTION_CANCELLATION_HISTORY,
  GET_CANCELLATION_STATUS,
  SET_SUBSCRIPTION_CANCELLATION,
  FILTER_SUBSCRIPTIONS_BY_NAME,
} from 'shared/providers/redux/actionTypes';
import {
  getSubscriptionCancellationHistory as getCancellationHistory,
  fetchSubscriptionsSuccess,
  fetchSubscriptionSessionSuccess,
  fetchSubscriptionCancellationHistorySuccess,
  fetchCancellationStatusSuccess,
  filterSubscriptionsByNameSuccess,
} from 'shared/providers/redux/actions';
import api from 'shared/infra/services/tenantAPI';

const getSubscriptionsRequest = async () => {
  try {
    const response = await api.get('subscription');
    const { data } = response.data;
    return data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getSubscriptionSessionRequest = async (payload) => {
  try {
    const response = await api.get(`subscription/${payload}`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getSubscriptionCancellationHistoryRequest = async (payload) => {
  try {
    const response = await api.get(`subscription/${payload}/cancel`);
    const { data } = response.data;
    return data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getCancellationStatusRequest = async () => {
  try {
    const response = await api.get(
      'subscription/help/get-status-and-reason-list'
    );
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setSubscriptionCancellationRequest = async (payload) => {
  const { id, reason, reason_description } = payload;

  try {
    const response = await api.post(`subscription/${id}/cancel`, {
      reason,
      reason_description,
    });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getSubscriptions() {
  const subscriptions = yield call(getSubscriptionsRequest);
  if (subscriptions) yield put(fetchSubscriptionsSuccess(subscriptions));
}

function* getSubscriptionSession({ payload }) {
  const subscription = yield call(getSubscriptionSessionRequest, payload);
  if (subscription) yield put(fetchSubscriptionSessionSuccess(subscription));
}

function* getSubscriptionCancellationHistory({ payload }) {
  const subscriptionCancellationHistory = yield call(
    getSubscriptionCancellationHistoryRequest,
    payload
  );

  if (subscriptionCancellationHistory)
    yield put(
      fetchSubscriptionCancellationHistorySuccess(
        subscriptionCancellationHistory
      )
    );
}

function* getCancellationStatus() {
  const status = yield call(getCancellationStatusRequest);
  if (status) yield put(fetchCancellationStatusSuccess([status]));
}

function* setSubscriptionCancellation({ payload }) {
  const { id } = payload;
  yield call(setSubscriptionCancellationRequest, payload);
  yield delay(2000);
  yield put(getCancellationHistory(id));
}

export function* onGetSubscriptions() {
  yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptions);
}

export function* onGetSubscriptionSession() {
  yield takeEvery(GET_SUBSCRIPTION_SESSION, getSubscriptionSession);
}

export function* onGetSubscriptionCancellationHistory() {
  yield takeEvery(
    GET_SUBSCRIPTION_CANCELLATION_HISTORY,
    getSubscriptionCancellationHistory
  );
}

export function* onGetCancellationStatus() {
  yield takeEvery(GET_CANCELLATION_STATUS, getCancellationStatus);
}

export function* onSetSubscriptionCancellation() {
  yield takeEvery(SET_SUBSCRIPTION_CANCELLATION, setSubscriptionCancellation);
}

const filterSubscriptionsRequest = async (payload) => {
  const params = {
    'whereHas[0][index]': 'name',
    'whereHas[0][condition]': 'like',
    'whereHas[0][value]': `${payload.search}`,
    'whereHasWith[0]': 'plan',
  };

  try {
    const response = await api.get('subscription', { params });

    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getFilterSubscriptions({ payload }) {
  const subscriptions = yield call(filterSubscriptionsRequest, payload);
  yield put(filterSubscriptionsByNameSuccess(subscriptions));
}

export function* onFilterSubscriptions() {
  yield takeLatest(FILTER_SUBSCRIPTIONS_BY_NAME, getFilterSubscriptions);
}

export default function* rootSaga() {
  yield all([
    fork(onGetSubscriptions),
    fork(onGetSubscriptionSession),
    fork(onGetSubscriptionCancellationHistory),
    fork(onGetCancellationStatus),
    fork(onSetSubscriptionCancellation),
    fork(onFilterSubscriptions),
  ]);
}
