import React, { useState, memo, lazy, Suspense } from 'react';
import { Progress, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getLocalUser } from 'shared/infra/services/auth';

import {
  UserProgressContainer,
  Content,
  PopoverAnt,
  ContentUserInfo,
} from './styles';

const MenuProfile = lazy(() => import('./menuProfile'));

const UserProgress = ({
  image,
  autoHeight,
  topbar = false,
  zeroMarginTop,
  noPointer = false,
  mobile,
  mini = false,
  percentLabel,
  sizeDefault,
  overflowImage = false,
  userSession,
  sizeText,
  ...props
}) => {
  const mode = useSelector(({ settings }) => settings?.mode);
  const [themeMode, setThemeMode] = useState(mode);
  const user = getLocalUser();

  function userInitials(item, photo, size) {
    try {
      if (!photo) {
        const nameSplit = item?.display_name.split(' ');

        if (item?.display_name.split(' ').length === 1) {
          const initials = nameSplit[0].charAt(0).toUpperCase();
          return (
            <ContentUserInfo
              className="content-user-info gx-bg-primary"
              size={size === 'large' && 'true'}
            >
              <span>{initials}</span>
            </ContentUserInfo>
          );
        }

        let initials;

        if (nameSplit?.length > 0) {
          initials =
            nameSplit[0].charAt(0).toUpperCase() +
            nameSplit[1].charAt(0).toUpperCase();
        }

        return (
          <ContentUserInfo
            className="content-user-info gx-bg-cyan"
            size={size === 'large' && 'true'}
          >
            <span>{initials}</span>
          </ContentUserInfo>
        );
      }
    } catch (error) {
      return (
        <ContentUserInfo
          className="content-user-info gx-bg-cyan"
          sizeText={sizeText}
          size={size === 'large'}
        >
          <span>not user</span>
        </ContentUserInfo>
      );
    }

    return <img alt="" src={photo} />;
  }

  return (
    <>
      {topbar ? (
        <PopoverAnt
          overlayClassName="item gx-popover-horizantal avatar-user"
          placement="bottomRight"
          trigger="click"
          content={
            <Suspense fallback={<Spin />}>
              <MenuProfile
                themeMode={themeMode}
                changeThemeMode={setThemeMode}
              />
            </Suspense>
          }
        >
          <UserProgressContainer
            noPointer={noPointer}
            overflowImage={overflowImage}
            sizeDefault={sizeDefault}
            autoHeight={autoHeight}
            mobile={mobile}
            topbar={topbar}
          >
            {percentLabel && <p>{`${percentLabel}%`}</p>}
            <Content
              theme={themeMode}
              mini={mini}
              sizeDefault={sizeDefault}
              mobile={mobile}
              topbar={topbar}
              {...props}
            >
              <Progress
                strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                className="progress-bar"
                type="circle"
                percent={0}
              />

              {userInitials(user, image)}
              <div className="item white-bg" />
            </Content>
          </UserProgressContainer>
        </PopoverAnt>
      ) : (
        <UserProgressContainer
          noPointer={noPointer}
          overflowImage={overflowImage}
          sizeDefault={sizeDefault}
          mobile={mobile}
          topbar={topbar}
          zeroMarginTop={zeroMarginTop}
          {...props}
        >
          {percentLabel && <p>{`${percentLabel}%`}</p>}
          <Content
            mini={mini}
            sizeDefault={sizeDefault}
            mobile={mobile}
            theme={mode}
          >
            <Progress
              strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
              className="item progress-bar"
              type="circle"
              percent={0}
            />

            {userInitials(userSession, image, 'large')}
            <div className="item white-bg" />
          </Content>
        </UserProgressContainer>
      )}
    </>
  );
};

UserProgress.defaultProps = {
  percentLabel: '',
  sizeDefault: '',
  mini: false,
  mobile: false,
  topbar: false,
  noPointer: false,
  overflowImage: false,
  autoHeight: false,
  zeroMarginTop: false,
  image: '',
  userSession: {},
  sizeText: '',
};

UserProgress.propTypes = {
  percentLabel: PropTypes.any,
  sizeDefault: PropTypes.any,
  mini: PropTypes.bool,
  mobile: PropTypes.bool,
  topbar: PropTypes.bool,
  noPointer: PropTypes.bool,
  overflowImage: PropTypes.bool,
  image: PropTypes.string,
  autoHeight: PropTypes.bool,
  zeroMarginTop: PropTypes.bool,
  userSession: PropTypes.object,
  sizeText: PropTypes.string,
};

export default memo(UserProgress);
