import styled, { css } from 'styled-components';

export const List = styled.ul`
  padding: 0;
  width: 100%;

  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 120px);

  ${(props) =>
    props.isDarkMode
      ? css`
          li a {
            i,
            p {
              color: #f7f7f7;
            }

            &:hover {
              background: #323232;
            }
          }

          background: #242424;
        `
      : props.tenantTheme.themeTopBar === 'advanced'
      ? css`
          li a {
            color: ${props.tenantTheme.themeTopBarShortcutItemsColor};
          }
        `
      : css`
          li a {
            color: #333; /* #103745 */
          }
        `}

  li {
    width: 100px;
    display: flex;
    margin: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    a {
      width: 110px;
      display: flex;
      padding: 10px 0;
      border-radius: 5px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      transition: 0.1s ease-in-out;

      i {
        font-size: 24px;
      }

      p {
        display: flex;
        text-align: center;
        margin: 10px 0 0;
        font-size: 0.9em;
      }

      &:hover {
        box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.13);
        transition: 0.1s ease-in-out;
      }
    }
  }

  @media (max-width: 370px) {
    grid-template-columns: repeat(2, 120px);
  }
`;
