import React from 'react';
import { ReactComponent as Blog } from 'shared/assets/icons/blog-class-list.svg';
import { ReactComponent as Bundle } from 'shared/assets/icons/box.svg';
import { ReactComponent as CourseWindow } from 'shared/assets/icons/course-class-list.svg';
import { ReactComponent as FileText } from 'shared/assets/icons/file-text-white.svg';
import { ReactComponent as LessonPlay } from 'shared/assets/icons/lesson-play.svg';
import { ReactComponent as MediaSpace } from 'shared/assets/icons/media-space-class-list.svg';
import { ReactComponent as Modules } from 'shared/assets/icons/module-class-list.svg';
import { ReactComponent as SocialRoom } from 'shared/assets/icons/social-rooms-icon-white.svg';
import { ReactComponent as Category } from 'shared/assets/icons/stack-files-category.svg';
import light_fallback from 'shared/assets/images/light_fallback.jpg';

export default function handleCardIcon({ modelClass }) {
  const model = modelClass?.name;
  const className = 'result-card-icon';

  if (!model)
    return (
      <div className={className}>
        <LessonPlay />
      </div>
    );

  const selector = {
    'globalSearch.community': <SocialRoom />,
    'globalSearch.topics': <FileText />,
    'globalSearch.courses': <CourseWindow />,
    'globalSearch.lessons': <LessonPlay />,
    'globalSearch.bundles': <Bundle />,
    'globalSearch.blogs': <Blog />,
    'globalSearch.modules': <Modules />,
    'globalSearch.contents': <MediaSpace />,
    'globalSearch.categories': <Category />,
  };

  if (!selector[model]) return <img src={light_fallback} alt="fallback" />;

  return <div className={className}>{selector[model]}</div>;
}
