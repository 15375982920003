import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from 'shared/infra/services/tenantAPI';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import setProductSession from 'shared/utils/setProductSession';

import { setCheckoutLoaderFalse } from 'shared/providers/redux/actions';

import fallback from 'shared/assets/images/large_light_fallback.jpg';

export const saveCheckoutDirectLinkId = (id) => {
  sessionStorage.setItem('checkout_direct_link_id', id);
};

export const getCheckoutDirectLinkId = () => {
  const id = sessionStorage.getItem('checkout_direct_link_id');

  return id === '' ||
    id === null ||
    id === 'null' ||
    id === undefined ||
    id === 'undefined'
    ? null
    : id;
};

export const removeCheckoutDirectLinkId = () => {
  sessionStorage.removeItem('checkout_direct_link_id');
};

export const checkIsCheckoutRoute = () => {
  const pathname = window.location.pathname;

  if (pathname.slice(0, 8) === '/payment') {
    const id = pathname.slice(9);
    const idSession = getCheckoutDirectLinkId();

    return {
      isCheckoutRoute: true,
      id: id || idSession,
    };
  }

  return { isCheckoutRoute: false, id: undefined };
};

export const setDefaultValueToProductSession = () => {
  sessionStorage.setItem(
    'product_session',
    JSON.stringify([
      {
        title: {
          pt_BR: '',
        },
        slug_product: '',
        cover: fallback,
      },
      {
        amount: 1,
        paramType: 'course_id',
        entityType: 'course',
        entityId: 0,
        className: '',
        product_virtual: true,
        payment_methods: {
          boleto: false,
          credit_card: false,
        },
        final_price: '',
        final_price_int: 0,
        installments: [],
        has_installments: false,
        total_installments: 1,
      },
    ])
  );
};

const fetchProductRequest = async (id) => {
  try {
    const response = await api.get(`pay/direct/${id}`);

    return response;
  } catch (error) {
    return error.response;
  }
};

const getEntityData = (className) => {
  let entityType;
  let paramType;

  switch (className) {
    case 'Vico068\\CourseTools\\Course':
      entityType = 'course';
      paramType = 'course_id';
      break;

    case 'App\\MediaSpaceContent':
      entityType = 'media-space-content';
      paramType = 'media_space_content_id';
      break;

    case 'App\\ProductBundle':
      entityType = 'product-bundle';
      paramType = 'product_bundle_id';
      break;

    default:
      entityType = 'plan';
      paramType = 'plan_id';
      break;
  }

  return {
    entityType,
    paramType,
  };
};

export const checkoutDirectLink = async (id) => {
  const history = useHistory();
  const dispatch = useDispatch();

  if (id?.length !== 13) return history.push('/404');

  const response = await fetchProductRequest(id);

  if (response?.status === 404) return history.push('/404');
  if (response?.status === 403) {
    const { attrs, type, message } = response?.data;
    const { slug, content_class } = attrs;

    let entityType;

    switch (content_class) {
      case 'Vico068\\CourseTools\\Course':
        entityType = 'courses';
        break;

      case 'App\\MediaSpaceContent':
        entityType = 'media';
        break;

      case 'App\\ProductBundle':
        entityType = 'bundles';
        break;

      default:
        entityType = 'plans';
        break;
    }

    openNotificationWithIcon(
      type,
      'Esse conteúdo não está liberado para compra',
      message,
      5
    );
    history.push(`/${entityType}/${slug}`);
    dispatch(setCheckoutLoaderFalse());
    return;
  }

  const entityData = getEntityData(response?.data?.data?.className);

  setProductSession(
    entityData?.entityType,
    entityData?.paramType,
    response?.data?.data
  );

  dispatch(setCheckoutLoaderFalse());
};
