import {
  SET_PIX_PAYMENT,
  SET_PAYMENT_ERRORS,
  SET_INFO_INSTALLMENT,
  SET_BANK_SLIP_PAYMENT,
  SET_CREDIT_CARD_PAYMENT,
  SET_PIX_PAYMENT_SUCCESS,
  SET_BANK_SLIP_PAYMENT_SUCCESS,
  SET_CREDIT_CARD_PAYMENT_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const setPixPayment = ({
  phone,
  document,
  documentType,
  paramType,
  typeEntity,
  entityType,
  entityId,
  addressId,
  couponCode,
  couponBookingId,
}) => ({
  type: SET_PIX_PAYMENT,
  payload: {
    phone,
    document,
    documentType,
    paramType,
    typeEntity,
    entityType,
    entityId,
    addressId,
    couponCode,
    couponBookingId,
  },
});

export const setBankSlipPayment = ({
  phone,
  document,
  documentType,
  paramType,
  typeEntity,
  entityType,
  entityId,
  addressId,
  couponCode,
  couponBookingId,
}) => ({
  type: SET_BANK_SLIP_PAYMENT,
  payload: {
    phone,
    document,
    documentType,
    paramType,
    typeEntity,
    entityType,
    entityId,
    addressId,
    couponCode,
    couponBookingId,
  },
});

export const setCreditCardPayment = ({
  cardId,
  addressId,
  paramType,
  entityType,
  entityId,
  hasInstallments,
  installments,
  couponCode,
  couponBookingId,
}) => ({
  type: SET_CREDIT_CARD_PAYMENT,
  payload: {
    cardId,
    addressId,
    paramType,
    entityType,
    entityId,
    hasInstallments,
    installments,
    couponCode,
    couponBookingId,
  },
});

export const setPixPaymentSuccess = (pixInfo) => ({
  type: SET_PIX_PAYMENT_SUCCESS,
  payload: pixInfo,
});

export const setBankSlipPaymentSuccess = (bankSlipInfo) => ({
  type: SET_BANK_SLIP_PAYMENT_SUCCESS,
  payload: bankSlipInfo,
});

export const setCreditCardPaymentSuccess = (creditCardInfo) => ({
  type: SET_CREDIT_CARD_PAYMENT_SUCCESS,
  payload: creditCardInfo,
});

export const setPaymentErrors = ({ errors, waitProcessing }) => ({
  type: SET_PAYMENT_ERRORS,
  payload: { errors, waitProcessing },
});

export const setInfoInstallment = (info) => ({
  type: SET_INFO_INSTALLMENT,
  payload: info,
});
