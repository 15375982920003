import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_USER_EMAIL,
  CONFIRM_USER_EMAIL_SUCCESS,
  AUTHENTICATE_TOKEN,
  AUTHENTICATE_TOKEN_SUCCESS,
  RESEND_CONFIRMATION_EMAIL,
  CONFIRMATION_EMAIL_STATUS,
} from 'shared/providers/redux/actionTypes';

export const authenticateToken = (token) => ({
  type: AUTHENTICATE_TOKEN,
  payload: token,
});

export const authenticateTokenSuccess = (user) => ({
  type: AUTHENTICATE_TOKEN_SUCCESS,
  payload: user,
});

export const userSignUp = (user, navigation_type = null) => ({
  type: SIGNUP_USER,
  payload: { user, navigation_type },
});

export const confirmEmail = (token) => ({
  type: CONFIRM_USER_EMAIL,
  payload: token,
});

export const confirmEmailSuccess = () => ({
  type: CONFIRM_USER_EMAIL_SUCCESS,
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  payload: email,
});

export const resetPassword = (formValues, token) => ({
  type: RESET_PASSWORD,
  payload: { formValues, token },
});

export const userSignIn = (user) => ({
  type: SIGNIN_USER,
  payload: user,
});

export const userSignOut = () => ({
  type: SIGNOUT_USER,
});

export const userSignUpSuccess = (email) => ({
  type: SIGNUP_USER_SUCCESS,
  payload: email,
});

export const userSignUpError = () => ({
  type: SIGNUP_USER_ERROR,
});

export const userSignInSuccess = (authUser) => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser,
});

export const userSignOutSuccess = () => ({
  type: SIGNOUT_USER_SUCCESS,
});

export const showAuthMessage = (message, success = null) => ({
  type: SHOW_AUTH_MESSAGE,
  payload: { message, success },
});

export const userGoogleSignIn = () => ({
  type: SIGNIN_GOOGLE_USER,
});

export const userGoogleSignInSuccess = (authUser) => ({
  type: SIGNIN_GOOGLE_USER_SUCCESS,
  payload: authUser,
});

export const userFacebookSignIn = () => ({
  type: SIGNIN_FACEBOOK_USER,
});

export const userFacebookSignInSuccess = (authUser) => ({
  type: SIGNIN_FACEBOOK_USER_SUCCESS,
  payload: authUser,
});

export const setInitUrl = (url) => ({
  type: INIT_URL,
  payload: url,
});

export const userTwitterSignIn = () => ({
  type: SIGNIN_TWITTER_USER,
});

export const userTwitterSignInSuccess = (authUser) => ({
  type: SIGNIN_TWITTER_USER_SUCCESS,
  payload: authUser,
});

export const userGithubSignIn = () => ({
  type: SIGNIN_GITHUB_USER,
});

export const userGithubSignInSuccess = (authUser) => ({
  type: SIGNIN_GITHUB_USER_SUCCESS,
  payload: authUser,
});

export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER,
});

export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE,
});

export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER,
});

export const resendConfirmationEmail = (email) => ({
  type: RESEND_CONFIRMATION_EMAIL,
  payload: { email },
});

export const confirmationEmailStatus = (status) => ({
  type: CONFIRMATION_EMAIL_STATUS,
  payload: { status },
});
