import React, { useMemo, useLayoutEffect } from 'react';
import { Route as ReactDOMRoute, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLocalUser } from 'shared/infra/services/auth';
import { useSelector, useDispatch } from 'react-redux';

import {
  setCheckoutLoaderTrue,
  onNavStyleChange,
} from 'shared/providers/redux/actions';
import { NAV_STYLE_CHECKOUT_HEADER } from 'shared/providers/redux/actionTypes';

import {
  checkIsCheckoutRoute,
  saveCheckoutDirectLinkId,
} from 'modules/Checkout/utils/checkoutDirectLink';

import NotFound from 'shared/components/organisms/NotFound';
import ExclusivePage from '../ExclusivePage';

const Route = ({ isPrivate, component: Component, disabled, ...rest }) => {
  const user = getLocalUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isCheckoutRoute, id } = checkIsCheckoutRoute();

  const {
    tenantData: {
      settings: {
        site: { privatePlatform },
      },
    },
  } = useSelector(({ tenancy }) => tenancy);

  const permission = useMemo(() => {
    const isAuthenticationRoute =
      history.location.pathname === '/authentication';

    if (privatePlatform === '1' && !user && !isAuthenticationRoute) {
      return false;
    }

    if (isPrivate !== 'public' && !user && typeof isPrivate === 'string') {
      return false;
    }

    if (isPrivate && !user && typeof isPrivate === 'boolean') {
      return false;
    }

    return true;
  }, [isPrivate, user, privatePlatform]);

  useLayoutEffect(() => {
    if (isCheckoutRoute) {
      saveCheckoutDirectLinkId(id);

      if (!user) return history.push('/authentication');
      dispatch(onNavStyleChange(NAV_STYLE_CHECKOUT_HEADER));
      dispatch(setCheckoutLoaderTrue());
    }
  }, [isCheckoutRoute]);

  return (
    <ReactDOMRoute
      {...rest}
      render={(props) =>
        disabled === '0' ? (
          <NotFound />
        ) : permission ? (
          <Component {...props} />
        ) : (
          <ExclusivePage />
        )
      }
    />
  );
};

Route.defaultProps = {
  isPrivate: false,
  disabled: '1',
};

Route.propTypes = {
  component: PropTypes.any.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isPrivate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Route;
