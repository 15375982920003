/* eslint-disable import/no-extraneous-dependencies */
import createSagaMiddleware, { END } from 'redux-saga';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import rootSaga from './sagas';
import createRootReducer from './reducers';
import asyncDispatchMiddleware from './middlewares/asyncDispatch';

const { createBrowserHistory } = require('history');

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  thunk,
  sagaMiddleware,
  routeMiddleware,
  asyncDispatchMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  if (process.env.NODE_ENV !== 'test') {
    middlewares.push(logger)
  }
};

export default function configureStore(preloadedState, dispatch) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(...middlewares))
  );

  store.close = () => dispatch(END);

  sagaMiddleware.run(rootSaga);
  return store;
}
