import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  getAllAPayOrdersSuccess,
  getSessionPayOrderSuccess,
} from 'shared/providers/redux/actions';
import api from 'shared/infra/services/tenantAPI';
import {
  GET_ALL_PAY_ORDERS,
  GET_SESSION_PAY_ORDER,
} from 'shared/providers/redux/actionTypes';

const getAllPayOrdersRequest = async () => {
  try {
    const response = await api.get('pay/order');
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getSessionPayOrderRequest = async (payload) => {
  try {
    const response = await api.get(`pay/order/${payload}`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getAllPayOrders() {
  const orders = yield call(getAllPayOrdersRequest);

  const ordersWithTransactions = orders?.attrs.filter(
    (item) => item?.transactions?.length > 0
  );

  if (orders?.attrs) yield put(getAllAPayOrdersSuccess(ordersWithTransactions));
}

function* getSessionPayOrder({ payload }) {
  const order = yield call(getSessionPayOrderRequest, payload);
  if (order?.attrs) yield put(getSessionPayOrderSuccess(order.attrs));
}

export function* onGetPayOrders() {
  yield takeEvery(GET_ALL_PAY_ORDERS, getAllPayOrders);
}

export function* onGetSessionPayOrder() {
  yield takeEvery(GET_SESSION_PAY_ORDER, getSessionPayOrder);
}

export default function* rootSaga() {
  yield all([fork(onGetPayOrders), fork(onGetSessionPayOrder)]);
}
