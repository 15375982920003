import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_SESSION,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_SESSION_SUCCESS,
  FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS,
  FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS,
  FETCH_CANCELLATION_STATUS_SUCCESS,
  FILTER_SUBSCRIPTIONS_BY_NAME,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  loader: false,
  subscriptionsList: [],
  subscriptionSession: null,
  subscriptionCancelattionHistory: [],
  cancellationReasons: [],
  searchingForSubscriptions: false,
  filteredSubscriptions: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_SUBSCRIPTION_SESSION: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptionsList: action.payload,
        loader: false,
      };
    }

    case FETCH_SUBSCRIPTION_SESSION_SUCCESS: {
      return {
        ...state,
        subscriptionSession: action.payload,
        loader: false,
      };
    }

    case FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS: {
      return {
        ...state,
        subscriptionCancelattionHistory: action.payload,
      };
    }

    case FETCH_CANCELLATION_STATUS_SUCCESS: {
      return {
        ...state,
        cancellationReasons: action.payload,
      };
    }

    case FILTER_SUBSCRIPTIONS_BY_NAME: {
      return {
        ...state,
        searchingForSubscriptions: true,
      };
    }

    case FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS: {
      return {
        ...state,
        searchingForSubscriptions: false,
        filteredSubscriptions: action.payload.subscriptions,
      };
    }

    default:
      return state;
  }
};
