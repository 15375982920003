import { FILTER_USERS_COMMUNITY } from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  filter: 'All'
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FILTER_USERS_COMMUNITY: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    default:
      return state;
  }
};
