import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { GET_SINGLE_CATEGORY, GET_CATEGORIES_ACTIVE_WITH_CONTENT } from 'shared/providers/redux/actionTypes';
import { getSingleCategorySuccess, getCategoriesActiveWithContentSuccess } from 'shared/providers/redux/actions';

import api from 'shared/infra/services/tenantAPI';

const onGetSingleCategoryRequest = async slug => {
  try {
    const response = await api.get(`category/${slug}`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetSingleCategory(payload) {
  const category = yield call(onGetSingleCategoryRequest, payload.slug);
  yield put(getSingleCategorySuccess(category));
}

export function* fetchSingleBundle() {
  yield takeEvery(GET_SINGLE_CATEGORY, onGetSingleCategory);
}

const ongetCategoriesActiveWithRequest = async() => {
  try {
    const response = await api.get(`category?hasItems=true`);
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* ongetCategoriesActiveWithContent() {
  const categories = yield call(ongetCategoriesActiveWithRequest);
  yield put(getCategoriesActiveWithContentSuccess(categories));
}

export function* fetchCategoriesActiveWithContent() {
  yield takeEvery(GET_CATEGORIES_ACTIVE_WITH_CONTENT, ongetCategoriesActiveWithContent);
}

export default function* rootSaga() {
  yield all([fork(fetchSingleBundle)]);
  yield all([fork(fetchCategoriesActiveWithContent)]);
}
