import {
  GET_MAIN_MENU,
  GET_PROFILE_MENU,
  GET_FOOTER_MENU,
  GET_MENU_SHORTCUTS,
  FETCH_MAIN_MENU_SUCCESS,
  FETCH_PROFILE_MENU_SUCCESS,
  FETCH_FOOTER_MENU_SUCCESS,
  FETCH_MENU_SHORTCUTS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const onGetMainMenu = () => {
  return {
    type: GET_MAIN_MENU,
  };
};

export const onGetProfileMenu = () => {
  return {
    type: GET_PROFILE_MENU,
  };
};

export const onGetFooterMenu = () => {
  return {
    type: GET_FOOTER_MENU,
  };
};

export const onGetMenuShortcuts = () => {
  return {
    type: GET_MENU_SHORTCUTS,
  };
};

export const fetchMainMenuSuccess = mainMenuList => {
  return {
    type: FETCH_MAIN_MENU_SUCCESS,
    payload: mainMenuList,
  };
};

export const fetchProfileMenuSuccess = profileMenuList => {
  return {
    type: FETCH_PROFILE_MENU_SUCCESS,
    payload: profileMenuList,
  };
};

export const fetchFooterMenuSuccess = footerMenuList => {
  return {
    type: FETCH_FOOTER_MENU_SUCCESS,
    payload: footerMenuList,
  };
};

export const fetchMenuShortcutsSuccess = shortcutsList => {
  return {
    type: FETCH_MENU_SHORTCUTS_SUCCESS,
    payload: shortcutsList,
  };
};
