import VLibras from '@djpfs/react-vlibras';
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import Routes from 'shared/routes';

import { getLocalUser } from 'shared/infra/services/auth';
import toggleThemeMode from 'shared/utils/toggleThemeMode.js';

import {
  onGetLoggedUser,
  setBrowserNotSuportObserver,
} from 'shared/providers/redux/actions';

import CircularProgress from 'shared/components/atoms/CircularProgress';
import EnsinioBrand from 'shared/components/atoms/EnsinioBrand';
import ToastNotification from 'shared/components/molecules/ToastNotification';
import LoginModal from 'shared/components/organisms/AuthModal';
import BrowserNotSupported from 'shared/components/organisms/BrowserNotSupported';
import OfflineFooterModal from 'shared/components/organisms/OfflineFooterModal';
import OnlyEnrolledUsersPermission from 'shared/components/organisms/OnlyEnrolledUsersPermission';
import PurchaseOptionsModal from 'shared/components/organisms/PurchaseOptionsModal';
import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';

import Sidebar from 'shared/components/organisms/Sidebar';
import Topbar from 'shared/components/organisms/Topbar';

import { Content, Layout } from './styles';

const ComponentsTree = () => {
  const user = getLocalUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    if (!user) {
      getLocalUser();
    }
  }, [user]);

  const [updatedUserData, setUpdatedUserData] = useState(!user);
  const isSideMenuEnabled = useFeature(typeFeatures.sideMenu);

  const tenantData = useSelector(({ tenancy }) => tenancy?.tenantData);

  const themeDarkModeOptions =
    tenantData?.settings['general-theme']?.themeDarkModeOptions;

  const loggedUser = useSelector(({ user }) => user.loggedUser);
  const sidebarIsRendered = useSelector(
    ({ sidebar }) => sidebar?.sidebarIsRendered
  );
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const urlValidateCertificate = location.pathname.split('/')[1];

  const isVisibleEnsinioBrand =
    location.pathname !== '/authentication' &&
    location.pathname !== '/payment' &&
    urlValidateCertificate !== 'certificate';

  useEffect(() => {
    if (!loggedUser && user) dispatch(onGetLoggedUser(user.username));

    if (loggedUser) setUpdatedUserData(true);
  }, [dispatch, loggedUser, user]);

  useEffect(() => {
    if (toggleThemeMode) {
      toggleThemeMode(true, dispatch, themeDarkModeOptions);
    }
  }, [dispatch, themeDarkModeOptions]);

  useLayoutEffect(() => {
    if (typeof IntersectionObserver !== 'function') {
      dispatch(setBrowserNotSuportObserver());
    }
  }, []);

  return (
    <>
      <BrowserNotSupported />

      <Layout className="gx-app-layout">
        <Layout>
          <Topbar />
          <Sidebar />

          <OnlyEnrolledUsersPermission />
          <LoginModal />

          <Content
            className="gx-layout-content"
            isDarkMode={isDarkMode}
            tenantTheme={tenantData?.settings['general-theme']}
            sidebarIsRendered={sidebarIsRendered && isSideMenuEnabled}
          >
            {updatedUserData ? (
              <Routes match={match} location={location} />
            ) : (
              <CircularProgress />
            )}

            <ToastNotification />
            {isVisibleEnsinioBrand && <EnsinioBrand />}
          </Content>
        </Layout>

        <PurchaseOptionsModal />
        {!user && location.pathname !== '/authentication' && (
          <OfflineFooterModal />
        )}

        {tenantData?.uuid === 'univertile' && <VLibras />}
      </Layout>
    </>
  );
};

export default memo(ComponentsTree);
