import moment from 'moment';
import 'moment/locale/pt-br';

const momentDate = (date) => {
  const lang = sessionStorage.getItem('lang');

  const defaultLang =
    (lang?.includes('br') || lang === null)
    ? 'pt-br'
    : 'en';

  if (date?.indexOf('T') < 0) return 'há poucos segundos';

  const formatedDate = moment(date)?.locale(defaultLang)?.fromNow();

  return formatedDate;
};

export default momentDate;
