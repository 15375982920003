import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import replaceAllInserter from 'string.prototype.replaceall';

import App from 'shared/providers/containers/App';
import { unregister } from './registerServiceWorker';

replaceAllInserter.shim();

const render = (ComponentTree) => {
  ReactDOM.render(
      <ComponentTree />,
    document.getElementById('root')
  );
};

unregister();
render(App);


