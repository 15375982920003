import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ObjectIntlTranslator from 'shared/components/atoms/ObjectIntlTranslator';

import { List } from './styles';

const MainMenuList = ({ mainMenuList }) => {
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const tenantTheme = useSelector(
    ({ tenancy }) => tenancy.tenantData.settings['general-theme']
  );

  return (
    <List isDarkMode={isDarkMode} tenantTheme={tenantTheme}>
      {mainMenuList &&
        mainMenuList?.length &&
        Array.isArray(mainMenuList) &&
        mainMenuList.map((main) =>
          main.items.map((item) => (
            <li key={item.id}>
              {item.value !== '/' ? (
                <a href={item.value} target={item.target}>
                  <i className={item.menu_icon} />
                  <p>
                    <ObjectIntlTranslator variable={item.name} />
                  </p>
                </a>
              ) : (
                <Link to={item.value}>
                  <i className={item.menu_icon} />
                  <p>
                    <ObjectIntlTranslator variable={item.name} />
                  </p>
                </Link>
              )}
            </li>
          ))
        )}
    </List>
  );
};

MainMenuList.propTypes = {
  mainMenuList: PropTypes.array.isRequired,
};
export default MainMenuList;
