export const emailProviderValidator = (email) => {
  const atIndex = email.indexOf('@');
  const isGmail = email.includes('@gmail');

  const userName = email.slice(0, atIndex);
  const hasHyphen = userName.includes('-');

  if (isGmail && hasHyphen) {
    return false;
  }

  return true;
};
