import React from 'react';

import decorativeImg from 'shared/assets/run.svg';
import { Container } from './styles';

const MaintenanceWarn = () => {
  return (
    <Container>
      <h1>Plataforma em manutenção</h1>

      <span>Lamentamos o inconveniente.</span>

      <p>Estamos trabalhando para reestabelecer a plataforma o mais breve possível.</p>

      <img className="decorative-img" src={decorativeImg} alt="" />
    </Container>
  );
};

export default MaintenanceWarn;
