import { Layout } from 'antd';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Header = styled(Layout.Header)`
  height: 62px;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 20px;
  align-items: center;
  z-index: 99;
  width: 100%;

  ${(props) =>
    props?.isDarkMode &&
    css`
      .ant-badge.badge-notification {
        sup {
          box-shadow: none;
        }
      }
    `}

  .content-container {
    width: 100%;
    display: flex;
    max-width: 1800px;
    align-items: center;

    .gamificationLink {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    ul.gx-header-notifications {
      flex-wrap: nowrap;
      margin-right: 15px;

      li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        span {
          height: 100%;
          margin: 0;

          span {
            height: 100%;

            i,
            i::before {
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      li + li,
      li:first-of-type {
        margin-right: 15px;
      }
    }

    .user-avatar {
      margin-top: 1px;
      margin-left: 15px;

      .ant-popover {
        z-index: 9 !important;
      }
    }
  }

  .logo {
    height: 38px;
    margin-right: 20px;
  }

  .icon-logo {
    display: none;
  }

  .site-title {
    display: flex;
    margin-right: 20px;
    align-items: center;

    h1 {
      margin: 0;
      font-size: 1.75em;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .scale-animation {
    transition: 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .icon {
    font-size: 18px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .icon-shopping-cart {
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .search-on-mobile {
    display: none;
    margin-right: 14px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
  }

  .shortcuts-on-mobile {
    min-width: 24px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
  }

  .ant-badge.badge-notification {
    margin: 0;

    sup {
      top: 14px;
      padding: 0px 6px;
      line-height: 17px;
      min-width: 17px;

      &,
      .ant-scroll-number-only-unit {
        height: 17px;
        color: ${({ isDarkMode, tenantTheme }) =>
          isDarkMode === 'dark'
            ? tenantTheme?.themeDarkModeTextMasterColor
            : tenantTheme?.themeMasterTextColor} !important;
      }
    }
  }

  .shortcuts-popover {
    border: none;
    height: 38px;
    display: flex;
    min-height: 38px;
    margin-right: 12px;
    border-radius: 6px;
    align-items: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

    svg {
      margin-right: 10px;
    }
  }

  .shortcuts-popover {
    padding: 0 12px 0 10px;

    svg {
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
    }

    .shortcuts-text {
      margin: 1px 0 0;
    }
  }

  .points {
    display: flex;
    padding: 2px 4px;
    margin-right: 15px;
    align-items: center;
    border-radius: 20px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.03));
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.03));

    p {
      margin: 0 2px;
      font-size: 17px;
      font-weight: 500;
    }

    .dollar-icon {
      font-size: 20px;
    }
  }

  .chat-icon {
    margin-top: 8px;

    .ant-badge-count {
      margin-top: 12px;
      height: 18px;
      width: 18px;
      line-height: 18px;

      box-shadow: none;
      padding: 1px 0 0;

      .ant-scroll-number-only > p.ant-scroll-number-only-unit.current {
        margin: -3px 0 0;
      }
    }
  }

  @media (max-width: 991px) {
    .horizontal-logo,
    .site-title {
      margin-left: 20px;
    }

    .shortcuts-popover {
      display: none;
    }

    .search-on-mobile {
      display: block;
    }

    .points {
      padding: 0;
      filter: none;
      -webkit-filter: none;
      flex-direction: column;
      justify-content: center;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

      p {
        top: 9px;
        margin: 0;
        font-size: 10px;
        font-weight: 400;
        position: absolute;
      }
    }

    button {
      height: 100%;
    }
  }

  .ant-badge.badge-notification {
    margin: 0;
  }

  @media (max-width: 700px) {
    .site-title {
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding: 0 5px;

    .horizontal-logo {
      display: none;
    }

    .icon-logo {
      display: flex;
      justify-content: center;
    }

    .content-container {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  @media (max-width: 385px) {
    .content-container {
      a {
        margin-right: 10px;
      }

      ul.gx-header-notifications {
        margin-right: 0;
      }

      .user-avatar {
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }
  }

  @media (max-width: 350px) {
    .points p {
      display: none;
    }

    *::-webkit-scrollbar {
      height: 10px;
    }

    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${(props) =>
        props.isDarkMode
          ? props?.tenantTheme?.themeDarkModeMasterColor
          : props?.tenantTheme?.themeTopBarIconsColor};
      background-clip: content-box;
      border: 3px solid transparent;
    }
  }
`;
