import axios from 'axios';
import { getToken } from './auth';
import errorHandler from './errorHandler';
import { guestId } from './uuid';

export const getMode = (returnBool = true) => {
  const hostnamesUsedByDevs = ['ngrok', 'loca.lt', 'localhost', 'dev.ensinio.com'];

  const isDevelopmentEnvironment = hostnamesUsedByDevs.includes(window.location.hostname);

  switch (window.location.hostname) {
    case 'loadtest.ensinio.com':
      return 'loadtest';

    case 'qa1.ensinio.com':
      return 'qa1';

    case 'qa2.ensinio.com':
      return 'qa2';

    case 'qa3.ensinio.com':
      return 'qa3';

    case 'release.ensinio.com':
      return 'homologation';

    default:
      if (isDevelopmentEnvironment) {
        if (returnBool) return true;
        return 'development';
      }

      if (returnBool) return false;
      return 'production';
  }
};

const apiMode = getMode(false);

let tenantUUID = JSON.parse(sessionStorage.getItem('tenant_data'))?.uuid;

const apiEndpoint = {
  local: 'escola.ensinio.local/api',
  development: 'ensiniodev.com/api',
  homologation: 'ensiniostaging.com/api',
  loadtest: 'ensin.io/api',
  production: 'ensinio.cloud/api',
  qa1: 'ensinioqa1.click/api',
  qa2: 'ensinioqa2.click/api',
  qa3: 'ensinioqa3.click/api',
};

export async function getFullEndPoint(tenancyAPI = false) {
  if (!tenantUUID) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    tenantUUID = JSON.parse(sessionStorage.getItem('tenant_data'))?.uuid;
  }

  if (tenancyAPI) {
    return `https://${apiEndpoint[apiMode]}/v1`;
  }

  switch (apiMode) {
    case 'local':
      return `http://${apiEndpoint.local}/v1`;

    case 'production':
      return `https://${tenantUUID}.${apiEndpoint[apiMode]}/v1`;

    case 'loadtest':
      return `https://${tenantUUID}.${apiEndpoint[apiMode]}/v1`;

    case 'homologation':
      return `https://${tenantUUID}.${apiEndpoint[apiMode]}/v1`;

    case 'qa1':
      return `https://qa1.${apiEndpoint[apiMode]}/v1`;

    case 'qa2':
      return `https://qa2.${apiEndpoint[apiMode]}/v1`;

    case 'qa3':
      return `https://qa3.${apiEndpoint[apiMode]}/v1`;

    default:
      return `https://back.${apiEndpoint[apiMode]}/v1`;
  }
}

const api = axios.create();

api.defaults.withCredentials = true;

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const apiConfig = config;

  apiConfig.baseURL = await getFullEndPoint();

  if (token) {
    apiConfig.headers = {
      Authorization: `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest',
      'X-GUEST-ID': guestId(),
    };
  } else {
    apiConfig.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-GUEST-ID': guestId(),
    };
  }

  apiConfig.withCredentials = false;

  return apiConfig;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);

export default api;
