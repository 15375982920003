import styled, { css } from 'styled-components';
import hexToRgb from 'shared/utils/hexToRgb';
import contrastAutoAdjust from 'shared/utils/contrastAutoAdjust';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  position: absolute;
  z-index: 99;
  bottom: 0;
  width: 100%;
  height: max-content;

  box-shadow: 1px 5px 14px #b7b7b7;
  backdrop-filter: blur(4px);
  background: ${(props) =>
    `rgba(${hexToRgb(
      props.$isDarkMode || props.$generalTheme.themeTopBar === 'dark'
        ? '#121212'
        : props.$generalTheme.themeTopBar === 'advanced'
        ? props.$generalTheme.themeTopBarBackgroundColor
        : props.$generalTheme.themeTopBar === 'light'
        ? '#ffffff'
        : props.$generalTheme?.themeMasterColor
    )}, 0.9)`};
`;

export const Header = styled.section`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 0;

  border: none;
  background: none;

  cursor: pointer;

  svg {
    font-size: 1.5rem;
  }

  ${(props) =>
    props.$isDarkMode || props.$generalTheme?.themeTopBar === 'dark'
      ? css`
          color: #f5f5f5;
        `
      : props.$generalTheme?.themeTopBar === 'advanced'
      ? css`
          color: ${props.$generalTheme?.themeTopBarIconsColor};
        `
      : props.$generalTheme?.themeTopBar === 'light'
      ? css`
          color: #1a1a1a;
          text-shadow: 0 0 20px white;
        `
      : css`
          color: ${contrastAutoAdjust({
            background: props.$generalTheme?.themeMasterColor,
            text: '#f5f5f5',
          })};
        `}
`;
