import {
  GET_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS
} from 'shared/providers/redux/actionTypes'

export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const fetchCountriesSuccess = countriesList => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: countriesList,
});
