import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';

import {
  SET_COMMENTS_GLOBAL,
  GET_COMMENTS_GLOBAL,
  GET_LIKES_INFO_USERS,
  LOAD_COMMENTS_GLOBAL,
  UPDATE_COMMENTS_GLOBAL,
  DELETE_COMMENTS_GLOBAL,
  LIKE_DISLIKE_COMMENTS_GLOBAL,
  LAST_COMMENTS_GLOBAL,
  GET_SINGLE_COMMENT,
} from 'shared/providers/redux/actionTypes';

import {
  setCommentGlobalStateSuccess,
  setCommentGlobalApiSuccess,
  updateCommentGlobalSuccess,
  getCommentsGlobalSuccess,
  fetchLikesInfoUsersSuccess,
  loadCommentsGlobalSuccess,
  deleteCommentGlobalSuccess,
  lastCommentsGlobalSuccess,
  getSingleCommentSuccess,
} from 'shared/providers/redux/actions';

import api from 'shared/infra/services/tenantAPI';

const setCommentRequest = async ({
  commentableType,
  commentableId,
  comment,
  parent,
  mentions,
  images,
}) => {
  let response;
  if (parent) {
    response = await api.post('comment', {
      commentable_type: commentableType,
      commentable_id: commentableId,
      parent_id: parent,
      comment: comment.comment,
      mentions_ids: mentions,
      images,
    });
  } else {
    response = await api.post('comment', {
      commentable_type: commentableType,
      commentable_id: commentableId,
      comment: comment.comment,
      mentions_ids: mentions,
      images,
    });
  }
  return response.data.data;
};

function* setComment({ payload }) {
  const { comment, parent } = payload;
  const { commentGlobal } = yield select();

  if (!parent) {
    // change state
    commentGlobal.comments.unshift(comment);
    commentGlobal.totalComments += 1;
    yield put(
      setCommentGlobalStateSuccess(
        commentGlobal.comments,
        commentGlobal.totalComments
      )
    );
    // subscribe state
    try {
      const newComment = yield call(setCommentRequest, payload);
      commentGlobal.comments[0] = newComment;
      yield put(
        setCommentGlobalApiSuccess(
          commentGlobal.comments,
          commentGlobal.totalComments
        )
      );
    } catch (err) {
      console.log(err);
    }
  } else {
    const commentParent = commentGlobal.comments.find(
      (item) => item.id === parent
    );
    commentGlobal.totalComments += 1;
    commentParent.children.push(comment);
    yield put(
      setCommentGlobalStateSuccess(
        commentGlobal.comments,
        commentGlobal.totalComments
      )
    );
    try {
      const newChildrenComment = yield call(setCommentRequest, payload);
      commentParent.children.pop();
      commentParent.children.push(newChildrenComment);
      yield put(
        setCommentGlobalApiSuccess(
          commentGlobal.comments,
          commentGlobal.totalComments
        )
      );
    } catch (err) {
      console.log(err);
    }
  }
}

export function* setCommentSaga() {
  yield takeLatest(SET_COMMENTS_GLOBAL, setComment);
}

const getCommentsApi = async (payload) => {
  const { commentableType, commentableId, page, orderDirection } = payload;

  try {
    const response = await api.get(`comment?page=${page}`, {
      params: {
        commentable_type: commentableType,
        commentable_id: commentableId,
        order_direction: orderDirection,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* getComments({ payload }) {
  const { orderDirection } = yield select((state) => state.commentGlobal);
  const data = yield call(getCommentsApi, { ...payload, orderDirection });
  const newArray = yield data.data.filter((item) => {
    if (!item.parent_id) {
      return item;
    }

    return null;
  });
  data.data = newArray;

  if (data) yield put(getCommentsGlobalSuccess(data));
}

export function* getCommentsSaga() {
  yield takeLatest(GET_COMMENTS_GLOBAL, getComments);
}

function* loadComments({ payload }) {
  const { orderDirection } = yield select((state) => state.commentGlobal);
  const { currentPage, totalPages } = yield select(
    (state) => state.commentGlobal
  );
  const { commentableType, commentableId } = payload;

  if (currentPage + 1 > totalPages) {
    return;
  }

  const request = {
    commentableType,
    commentableId,
    page: currentPage + 1,
    orderDirection,
  };

  const data = yield call(getCommentsApi, request);
  const newArray = yield data.data.filter((item) => {
    if (!item.parent_id) {
      return item;
    }

    return null;
  });
  data.data = newArray;

  if (data) yield put(loadCommentsGlobalSuccess(data));
}

export function* loadCommentsSaga() {
  yield takeLatest(LOAD_COMMENTS_GLOBAL, loadComments);
}

const updateCommentRequest = async (payload) => {
  const { commentableType, commentableId, comment } = payload;
  try {
    const response = await api.put(`comment/${comment.id}`, {
      comment: comment.comment,
      commentable_id: commentableId,
      commentable_type: commentableType,
    });
    return response.status;
  } catch (err) {
    console.log(err);
  }

  return null;
};

function* updateCommentGlobal({ payload }) {
  const { comment } = payload;
  const { commentGlobal } = yield select();
  const Comments = JSON.parse(JSON.stringify(commentGlobal.comments));
  const updatePrimaryComment = Comments.find((item) => item.id === comment.id);

  if (updatePrimaryComment) {
    Object.assign(updatePrimaryComment, comment);
    yield put(updateCommentGlobalSuccess(Comments));
    yield call(updateCommentRequest, payload);
  }

  if (!updatePrimaryComment) {
    const updateSecondaryComment = Comments.map((comment) => comment.children)
      .reduce((prev, next) => prev.concat(next))
      .find((item) => item.id === comment.id);

    Object.assign(updateSecondaryComment, comment);
    yield put(updateCommentGlobalSuccess(Comments));
    yield call(updateCommentRequest, payload);
  }
}

export function* updateCommentSaga() {
  yield takeLatest(UPDATE_COMMENTS_GLOBAL, updateCommentGlobal);
}

const deleteCommentApi = async ({
  commentableType,
  commentableId,
  commentId,
}) => {
  try {
    const response = await api.delete(`comment/${commentId}`, {
      params: {
        commentable_type: commentableType,
        commentable_id: commentableId,
      },
    });

    return response.status;
  } catch (err) {
    console.log(err);
  }

  return null;
};

function* deleteCommentGlobal({ payload }) {
  const { commentGlobal } = yield select();
  const Comments = JSON.parse(JSON.stringify(commentGlobal.comments));
  const { commentId } = payload;
  const total = commentGlobal.totalComments - 1;
  const deletePrimaryComment = Comments.findIndex(
    (item) => item.id === commentId
  );

  if (deletePrimaryComment >= 0) {
    Comments.splice(deletePrimaryComment, 1);
    yield call(deleteCommentApi, payload);
    yield put(deleteCommentGlobalSuccess(Comments, total));
  } else {
    const deleteSecondaryComment = Comments.map((comment) => comment.children)
      .reduce((prev, next) => prev.concat(next))
      .find((item) => item.id === commentId);
    const FatherComment = Comments.findIndex((comment) => {
      if (comment.children.indexOf(deleteSecondaryComment) >= 0) {
        return comment;
      }
      return null;
    });
    const index = Comments[FatherComment].children.indexOf(
      deleteSecondaryComment
    );
    Comments[FatherComment].children.splice(index, 1);
    yield call(deleteCommentApi, payload);
    yield put(deleteCommentGlobalSuccess(Comments, total));
  }
}

export function* deleteCommentSaga() {
  yield takeLatest(DELETE_COMMENTS_GLOBAL, deleteCommentGlobal);
}

const likeDislikeCommentRequest = async ({
  commentableType,
  commentableId,
  commentId,
  option,
}) => {
  let response;
  try {
    if (option === 'like') {
      response = await api.post(`comment/like/${commentId}`, {
        commentable_type: commentableType,
        commentable_id: commentableId,
      });
      return response.data;
    }

    if (option === 'dislike') {
      response = await api.post(`comment/dislike/${commentId}`, {
        commentable_type: commentableType,
        commentable_id: commentableId,
      });
      return response.data;
    }
  } catch (err) {
    return err;
  }

  return null;
};

function* likeDislikeCommentGlobal({ payload }) {
  yield call(likeDislikeCommentRequest, payload);
}

export function* likeDislikeCommentSaga() {
  yield takeLatest(LIKE_DISLIKE_COMMENTS_GLOBAL, likeDislikeCommentGlobal);
}

const lastCommentsRequest = async (payload) => {
  const { commentableType, commentableId } = payload;

  try {
    const response = await api.get(`comments/feed`, {
      params: {
        className: commentableType,
        id: commentableId,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* lastCommentsGlobal({ payload }) {
  const { data } = yield call(lastCommentsRequest, payload);
  if (data) yield put(lastCommentsGlobalSuccess(data));
}

export function* lastCommentsSaga() {
  yield takeLatest(LAST_COMMENTS_GLOBAL, lastCommentsGlobal);
}

const getLikesInfoUsersRequest = async (payload) => {
  const { likeableType, likeableId } = payload;

  try {
    const response = await api.get(
      `likes?likeable_type=${likeableType}&likeable_id=${likeableId}`
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* getLikesInfoUsers({ payload }) {
  const { data } = yield call(getLikesInfoUsersRequest, payload);
  if (data) yield put(fetchLikesInfoUsersSuccess(data));
}

export function* onGetLikesInfoUsers() {
  yield takeLatest(GET_LIKES_INFO_USERS, getLikesInfoUsers);
}

const getSingleCommentRequest = async (payload) => {
  const { commentableType, commentableId, id } = payload;

  try {
    const response = await api.get(`comment`, {
      params: {
        commentable_type: commentableType,
        commentable_id: commentableId,
        'where[0][index]': 'id',
        'where[0][condition]': 'like',
        'where[0][value]': id,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* getSingleComment({ payload }) {
  const { data } = yield call(getSingleCommentRequest, payload);
  if (data) yield put(getSingleCommentSuccess(data));
}

export function* onGetSingleComment() {
  yield takeLatest(GET_SINGLE_COMMENT, getSingleComment);
}

export default function* rootSaga() {
  yield all([
    fork(setCommentSaga),
    fork(deleteCommentSaga),
    fork(getCommentsSaga),
    fork(loadCommentsSaga),
    fork(updateCommentSaga),
    fork(likeDislikeCommentSaga),
    fork(lastCommentsSaga),
    fork(onGetLikesInfoUsers),
    fork(onGetSingleComment),
  ]);
}
