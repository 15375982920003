import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import RenderStringHtml from 'shared/components/atoms/RenderStringHtml';
import hexToRgb from 'shared/utils/hexToRgb.js';
import simpleEditorQuillConverter from 'shared/utils/simpleEditorQuillConverter';
import { Container } from './styles';
import handleCardIcon from './utils/handleCardIcon';

const ResultCard = ({ src, title, subtitle, description, url }) => {
  const [failedToLoadImage, setFailedToLoadImage] = useState(false);

  const theme = useSelector(({ settings }) => settings?.mode);
  const modelClass = useSelector(
    ({ globalSearch }) => globalSearch?.modelClass
  );

  const tenantData = JSON.parse(sessionStorage.getItem('tenant_data'));

  const generalSettings = tenantData.settings['general-theme'];
  const isDarkMode = theme === 'dark';

  const masterColor = isDarkMode
    ? generalSettings?.themeDarkModeMasterColor
    : generalSettings?.themeMasterColor;

  const isTopicItem = modelClass?.model === 'Deskti\\Forum\\Models\\ForumTopic';

  const complamentaryContent = subtitle || description;

  function handleNavigate() {
    window.open(url, '_self');
  }

  function handleFailedToLoadImage(event) {
    setFailedToLoadImage(event.type === 'error');
  }

  return (
    <Container onClick={handleNavigate} $masterColorRgb={hexToRgb(masterColor)}>
      <div className="result-card-container">
        {!src || failedToLoadImage ? (
          handleCardIcon({ modelClass })
        ) : (
          <img src={src} alt="" onError={handleFailedToLoadImage} />
        )}
      </div>

      <section className="info">
        <h3>{title || ''}</h3>
        <p>
          {isTopicItem ? (
            <> {simpleEditorQuillConverter(complamentaryContent)} </>
          ) : (
            <RenderStringHtml value={complamentaryContent} />
          )}
        </p>
      </section>
    </Container>
  );
};

ResultCard.defaultProps = {
  src: '',
  title: '',
  subtitle: '',
  description: '',
  url: '',
};

ResultCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  description: PropTypes.any,
  url: PropTypes.any,
};

export default ResultCard;
