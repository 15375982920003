import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  loader: false,
  pageData: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAGE: {
      return {
        ...state,
        loader: true,
      }
    }
    case GET_PAGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        pageData: action.payload,
      }
    }
    default:
      return state;
  }
};
