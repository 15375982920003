import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { resendConfirmationEmail } from 'shared/providers/redux/actions';
import NavigateBackModal from 'shared/components/organisms/AuthModal/components/NavigateBackModal';

import { Container, ErrorSection, Button } from './styles';

function EmailConfirmation({ navigate }) {
  const intl = useIntl();

  const userEmail = useSelector(
    ({ warnings }) => warnings.newlyCreatedUserEmail
  );

  const { settings, site } = useSelector(({ tenancy }) => tenancy.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const { resendEmailLoader, resendEmailStatus } = useSelector(
    ({ auth }) => auth
  );

  const dispatchGlobal = useDispatch();

  const handleResend = () => {
    if (resendEmailStatus?.status !== 409) {
      dispatchGlobal(resendConfirmationEmail(userEmail));
    }
  };

  const { users } = settings;
  const theme = settings['general-theme'];

  const signUpConfirmationImageStatus = users?.userSignUpConfirmationImage;
  const signUpConfirmationImage = users?.userSignUpConfirmationCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  const { userSignUpConfirmationTitle, userSignUpConfirmationDescription } =
    users;

  return (
    <>
      <NavigateBackModal page="register" navigate={navigate} />
      <Container isDarkMode={isDarkMode}>
        <div className="titles">
          {signUpConfirmationImageStatus === 'none' || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <img
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              alt="logo"
            />
          ) : signUpConfirmationImageStatus === 'default' ? (
            <img src={defaultImage || fallbackImage} alt="logo" />
          ) : (
            <img
              src={signUpConfirmationImage || fallbackImage || defaultImage}
              alt="logo"
            />
          )}

          <section className="header-title">
            <h1>
              {userSignUpConfirmationTitle?.length > 3
                ? userSignUpConfirmationTitle
                : intl?.formatMessage({
                    id: 'authModal.confirmation.confirmAccount',
                  })}
            </h1>

            <h2>{userEmail}</h2>

            <h3>
              {userSignUpConfirmationDescription.length
                ? userSignUpConfirmationDescription
                : intl?.formatMessage({
                    id: 'authModal.confirmation.weSentYouAMessage',
                  })}
            </h3>
          </section>
          <section className="bottom-container">
            <span>
              {resendEmailLoader ? (
                <Spin />
              ) : resendEmailStatus?.success ? (
                <CheckCircleTwoTone
                  style={{ fontSize: '20px' }}
                  twoToneColor="#52c41a"
                />
              ) : (
                resendEmailStatus.status === 409 && (
                  <ErrorSection>
                    <CloseCircleTwoTone
                      style={{ fontSize: '20px' }}
                      twoToneColor="#ff0000"
                    />

                    <p>{resendEmailStatus?.message}</p>
                  </ErrorSection>
                )
              )}
            </span>

            <Button>
              {intl?.formatMessage({
                id: 'authModal.confirmation.linkExpiresIn',
              })}
            </Button>
            <Button
              $btnType="resend"
              onClick={handleResend}
              disabled={resendEmailStatus.status === 409}
            >
              {intl?.formatMessage({
                id: 'authModal.confirmation.newLink',
              })}
            </Button>
          </section>
        </div>
      </Container>
    </>
  );
}

EmailConfirmation.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default EmailConfirmation;
