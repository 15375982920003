/* eslint-disable react/no-danger */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

function formatter(variable) {
  if (variable.pt_BR) {
    const formattedVariable = variable;

    formattedVariable['pt-BR'] = formattedVariable.pt_BR;
    delete formattedVariable.pt_BR;
  }
}

const ObjectIntlTranslator = ({ variable, intl, style, firstWord }) => {
  const translate = () => {
    if (!variable) return '';

    formatter(variable);

    const variableLocales = Object.keys(variable);

    if (variableLocales.length === 1) {
      return variable[variableLocales[0]];
    }

    if (variable[intl.locale]) {
      return variable[intl.locale];
    }

    return variable[intl.defaultLocale];
  };

  return (
    <span
      style={{ ...style }}
      dangerouslySetInnerHTML={{
        __html: firstWord
          ? translate()?.split(' ')?.slice(0, 1)?.join()
          : translate(),
      }}
    />
  );
};

ObjectIntlTranslator.defaultProps = {
  firstWord: false,
  variable: {},
  style: {},
  intl: {},
};

ObjectIntlTranslator.propTypes = {
  firstWord: PropTypes.bool,
  variable: PropTypes.any,
  style: PropTypes.any,
  intl: PropTypes.object,
};

export default injectIntl(ObjectIntlTranslator);
