import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  fetchMainMenuSuccess,
  fetchProfileMenuSuccess,
  fetchFooterMenuSuccess,
  fetchMenuShortcutsSuccess,
} from 'shared/providers/redux/actions';
import {
  GET_MAIN_MENU,
  GET_PROFILE_MENU,
  GET_FOOTER_MENU,
  GET_MENU_SHORTCUTS,
} from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const getMainMenuRequest = async () => {
  try {
    const response = await api.get('menu/menu');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getProfileMenuRequest = async () => {
  try {
    const response = await api.get('menu/profile');

    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getFooterMenuRequest = async () => {
  try {
    const response = await api.get('menu/footer');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

const getMenuShortcutsRequest = async () => {
  try {
    const response = await api.get('menu/topbar');
    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }
};

function* getMainMenu() {
  const mainMenuStoraged = JSON.parse(sessionStorage.getItem('main_menu'));
  if (mainMenuStoraged) {
    yield put(fetchMainMenuSuccess(mainMenuStoraged));
    return;
  }

  const mainMenu = yield call(getMainMenuRequest);
  if (mainMenu) {
    sessionStorage.setItem('main_menu', JSON.stringify(mainMenu));
    yield put(fetchMainMenuSuccess(mainMenu));
  }
}

function* getProfileMenu() {
  const profileMenuStoraged = JSON.parse(
    sessionStorage.getItem('profile_menu')
  );
  if (profileMenuStoraged) {
    yield put(fetchProfileMenuSuccess(profileMenuStoraged));
    return;
  }

  const profileMenu = yield call(getProfileMenuRequest);
  if (profileMenu) {
    sessionStorage.setItem('profile_menu', JSON.stringify(profileMenu));
    yield put(fetchProfileMenuSuccess(profileMenu));
  }
}

function* getFooterMenu() {
  const footerMenu = yield call(getFooterMenuRequest);
  if (footerMenu) {
    sessionStorage.setItem('footer_menu', JSON.stringify(footerMenu));
    yield put(fetchFooterMenuSuccess(footerMenu));
  }
}

function* getMenuShortcuts() {
  const shortcutsMenuStoraged = JSON.parse(
    sessionStorage.getItem('shortcuts_menu')
  );
  if (shortcutsMenuStoraged) {
    yield put(fetchMenuShortcutsSuccess(shortcutsMenuStoraged));
    return;
  }

  const shortcutsMenu = yield call(getMenuShortcutsRequest);
  if (shortcutsMenu) {
    sessionStorage.setItem('shortcuts_menu', JSON.stringify(shortcutsMenu));
    yield put(fetchMenuShortcutsSuccess(shortcutsMenu));
  }
}

export function* fetchMainMenu() {
  yield takeLatest(GET_MAIN_MENU, getMainMenu);
}

export function* fetchProfileMenu() {
  yield takeLatest(GET_PROFILE_MENU, getProfileMenu);
}

export function* fetchFooterMenu() {
  yield takeLatest(GET_FOOTER_MENU, getFooterMenu);
}

export function* fetchMenuShortcuts() {
  yield takeLatest(GET_MENU_SHORTCUTS, getMenuShortcuts);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMainMenu),
    fork(fetchProfileMenu),
    fork(fetchFooterMenu),
    fork(fetchMenuShortcuts),
  ]);
}
