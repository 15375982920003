import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Text, Image } from './styles';

const PasswordInvalid = ({ isDarkMode }) => {
  const intl = useIntl();

  return (
    <Container>
      <Image $isDarkMode={isDarkMode} />

      <Text $isDarkMode={isDarkMode}>
        {intl.formatMessage({ id: 'authModal.register.notAValidPassword' })}
      </Text>
    </Container>
  );
};

PasswordInvalid.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
};

export default PasswordInvalid;
