import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { startEnrollmentSuccess } from 'shared/providers/redux/actions';
import { START_ENROLLMENT } from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const startEnrollmentRequest = async payload => {
  try {
    const response = await api.post(`enrollment/start/${payload.id}`, { type: payload.entityType });

    return response.status;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onStartEnrollment({ payload }) {
  const responseStatus = yield call(startEnrollmentRequest, payload);

  if (responseStatus === 200) yield put(startEnrollmentSuccess());
}

export function* startEnrollment() {
  yield takeEvery(START_ENROLLMENT, onStartEnrollment);
}

export default function* rootSaga() {
  yield all([fork(startEnrollment)]);
}
