import {
  GET_MEDIA_SPACE_DATA,
  GET_MEDIA_SPACE_CONTENTS,
  GET_ALL_MEDIAS_FROM_AN_AUTHOR,
  GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS,
  GET_MEDIA_SPACE_CATEGORIES,
  FETCH_MEDIA_SPACE_DATA_SUCCESS,
  FETCH_MEDIA_SPACE_CONTENTS_SUCCESS,
  FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS,
  FILTER_CATEGORY_MEDIA_CONTENT_BY,
  FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS,
  FILTER_MEDIA_CATEGORY_BY_AUTHOR,
  FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS,
  GET_ALL_BASIC_CATEGORIES,
  GET_ALL_BASIC_CATEGORIES_SUCCESS
} from 'shared/providers/redux/actionTypes';

export const getMediaSpaceData = () => ({
  type: GET_MEDIA_SPACE_DATA,
});

export const fetchMediaSpaceDataSuccess = (data) => ({
  type: FETCH_MEDIA_SPACE_DATA_SUCCESS,
  payload: data,
});

export const fetchMediasFromAnAuthor = (author_id, per_page = "4", page = "1") => ({
  type: GET_ALL_MEDIAS_FROM_AN_AUTHOR,
  payload: { author_id, per_page, page },
});

export const fetchMediasFromAnAuthorSuccess = (medias) => ({
  type: GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS,
  payload: medias,
});

export const getMediaSpaceContents = (filterId, slug = null, page = 1) => ({
  type: GET_MEDIA_SPACE_CONTENTS,
  payload: {
    filterId,
    slug,
    page,
  },
});

export const fetchMediaSpaceContentsSuccess = (
  filterId,
  contents,
  currentPage
) => ({
  type: FETCH_MEDIA_SPACE_CONTENTS_SUCCESS,
  payload: {
    filterId,
    contents,
    currentPage,
  },
});

export const getMediaSpaceCategories = (per_page = 30, page = 1) => ({
  type: GET_MEDIA_SPACE_CATEGORIES,
  payload: { per_page, page },
});

export const fetchMediaSpaceCategoriesSuccess = (categories) => ({
  type: FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS,
  payload: categories,
});

export const filterCategoryMediaContentBy = ({ screen, value, type }) => ({
    type: FILTER_CATEGORY_MEDIA_CONTENT_BY,
    payload: { screen, value, type },
  });

export const filterCategoryMediaContentBySuccess = (mediaContent) => ({
    type: FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS,
    payload: mediaContent,
  });

export const filterMediaCategoryByAuthor = (author_id) => ({
    type: FILTER_MEDIA_CATEGORY_BY_AUTHOR,
    payload: { author_id },
  });

export const filterMediaCategoryByAuthorSuccess = (categories) => ({
    type: FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS,
    payload: categories,
  });

export const getAllBasicCategory = () => ({
  type: GET_ALL_BASIC_CATEGORIES,
})

export const getAllBasicCategorySucess = (category) => ({
  type: GET_ALL_BASIC_CATEGORIES_SUCCESS,
  payload: category,
})
