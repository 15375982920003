/* eslint-disable no-useless-escape */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import {
  FILTER_CATEGORY_MEDIA_CONTENT_BY,
  FILTER_MEDIA_CATEGORY_BY_AUTHOR,
  GET_ALL_BASIC_CATEGORIES,
  GET_ALL_MEDIAS_FROM_AN_AUTHOR,
  GET_MEDIA_SPACE_CATEGORIES,
  GET_MEDIA_SPACE_CONTENTS,
  GET_MEDIA_SPACE_DATA,
} from 'shared/providers/redux/actionTypes';

import {
  fetchMediasFromAnAuthorSuccess,
  fetchMediaSpaceCategoriesSuccess,
  fetchMediaSpaceContentsSuccess,
  fetchMediaSpaceDataSuccess,
  filterCategoryMediaContentBySuccess,
  filterMediaCategoryByAuthorSuccess,
  getAllBasicCategorySucess,
} from 'shared/providers/redux/actions';

const getMediaSpaceDataRequest = async () => {
  try {
    const response = await api.get('media-space/1');

    return response.data.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onGetMediaSpaceData() {
  const mediaSpaceData = yield call(getMediaSpaceDataRequest);
  if (mediaSpaceData) yield put(fetchMediaSpaceDataSuccess(mediaSpaceData));
}

export function* getMediaSpaceData() {
  yield takeEvery(GET_MEDIA_SPACE_DATA, onGetMediaSpaceData);
}

const getMediaSpaceContentsRequest = async ({ filterId, slug, page }) => {
  try {
    let params = {};

    switch (filterId) {
      case 'new':
        params = {
          'order[0][index]': 'published_at',
          'order[0][value]': 'desc',
        };
        break;

      case 'position':
        params = {
          'order[0][index]': 'position',
          'order[0][value]': 'desc',
        };
        break;

      case 'featured':
        params = {
          'where[0][index]': 'featured',
          'where[0][condition]': 'like',
          'where[0][value]': 1,
          'order[0][index]': 'position',
          'order[0][value]': 'asc',
        };
        break;

      case 'next':
        params = {
          'order[0][index]': 'position',
          'order[0][value]': 'asc',
          'where[0][index]': 'position',
          'where[0][condition]': '>',
          'where[0][value]': slug,
          per_page: 4,
        };
        break;

      case 'fromCategory':
        params = {
          'whereHas[0][index]': 'slug',
          'whereHas[0][condition]': '=',
          'whereHas[0][value]': slug,
          'whereHasWith[0]': 'Categories',
          page,
        };
        break;

      default:
        break;
    }

    let response = {};

    if (filterId === 'singleContent') {
      response = await api.get(`media-space-content/${slug}`);
    } else {
      response = await api.get('media-space-content', { params });
    }

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onGetMediaSpaceContents({ payload }) {
  const currentPage = payload.page;

  const mediaSpaceContents = yield call(getMediaSpaceContentsRequest, payload);
  if (mediaSpaceContents)
    yield put(
      fetchMediaSpaceContentsSuccess(
        payload?.filterId,
        mediaSpaceContents,
        currentPage
      )
    );
}

export function* getMediaSpaceContents() {
  yield takeEvery(GET_MEDIA_SPACE_CONTENTS, onGetMediaSpaceContents);
}

const getMediasFromAnAuthorRequest = async (payload) => {
  try {
    const response = await api.get(`media-space-content`, {
      params: {
        'whereHas[1][index]': 'id',
        'whereHas[1][condition]': '=',
        'whereHas[1][value]': payload.author_id,
        'whereHasWith[1]': 'Authors',
        page: payload.page,
        per_page: payload.per_page,
      },
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getMediasFromAnAuthor({ payload }) {
  const medias = yield call(getMediasFromAnAuthorRequest, payload);
  if (medias) yield put(fetchMediasFromAnAuthorSuccess(medias));
}

export function* fetchMediasFromAnAuthor() {
  yield takeEvery(GET_ALL_MEDIAS_FROM_AN_AUTHOR, getMediasFromAnAuthor);
}

const getMediaSpaceCategoriesRequest = async ({ per_page, page }) => {
  try {
    const response = await api.get(`media-space-content/categories`, {
      params: {
        with_mediaspace_content: true,
        per_page,
        page,
        'order[0][index]': 'position',
        'order[0][value]': 'asc',
      },
    });

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onGetMediaSpaceCategories({ payload }) {
  const categories = yield call(getMediaSpaceCategoriesRequest, payload);
  if (categories) yield put(fetchMediaSpaceCategoriesSuccess(categories));
}

export function* getMediaSpaceCategories() {
  yield takeEvery(GET_MEDIA_SPACE_CATEGORIES, onGetMediaSpaceCategories);
}

const filterCategoryMediaContentByRequest = async ({ screen, value, type }) => {
  let params = {};

  if (type === 'Title') {
    params = {
      'where[0][index]': 'title',
      'where[0][condition]': 'like',
      'where[0][value]': value,
      'whereHas[0][index]': 'slug',
      'whereHas[0][condition]': 'like',
      'whereHas[0][value]': screen,
      'whereHasWith[0]': 'Categories',
    };
  }

  if (type === 'Author') {
    params = {
      'whereHas[0][index]': 'slug',
      'whereHas[0][condition]': 'like',
      'whereHas[0][value]': screen,
      'whereHasWith[0]': 'Categories',
      'whereHas[1][index]': 'username',
      'whereHas[1][condition]': 'like',
      'whereHas[1][value]': value,
      'whereHasWith[1]': 'Authors',
    };
  }

  try {
    const response = await api.get('media-space-content', {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

const filterMediaCategoryByAuthorRequest = async (author_id) => {
  const params = {
    'whereHasWith[0]': 'mediaSpaceContentMorph',
    'whereHas[0][index]': 'relationshas',
    'whereHas[0][value]': 'Authors',
    'whereRelation[0][index]': 'id',
    'whereRelation[0][condition]': 'like',
    'whereRelation[0][value]': author_id,
  };

  try {
    const response = await api.get(
      'media-space-content/categories?with_mediaspace_content=true',
      {
        params,
      }
    );

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onFilterCategoryMediaContentBy({ payload }) {
  const contentsFound = yield call(
    filterCategoryMediaContentByRequest,
    payload
  );

  if (contentsFound)
    yield put(filterCategoryMediaContentBySuccess(contentsFound));
}

function* onFilterMediaCategoryByAuthor({ payload }) {
  const response = yield call(
    filterMediaCategoryByAuthorRequest,
    payload.author_id
  );

  if (response) yield put(filterMediaCategoryByAuthorSuccess(response));
}

const filterGetAllBasicCategory = async () => {
  const params = {
    'whereHas[0][index]': 'status',
    'whereHas[0][condition]': 'like',
    'whereHas[0][value]': 'published',
    'whereHasWith[0]': 'mediaSpaceContentMorph',
  };

  try {
    const response = await api.get(`/category`, { params });
    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

function* onGetAllBasicCategory() {
  const categories = yield call(filterGetAllBasicCategory);
  if (categories) yield put(getAllBasicCategorySucess(categories));
}

export function* filterCategoryMediaContentBy() {
  yield takeLatest(
    FILTER_CATEGORY_MEDIA_CONTENT_BY,
    onFilterCategoryMediaContentBy
  );
}

export function* filterMediaCategoryByAuthor() {
  yield takeLatest(
    FILTER_MEDIA_CATEGORY_BY_AUTHOR,
    onFilterMediaCategoryByAuthor
  );
}

export function* getAllBasicCategory() {
  yield takeLatest(GET_ALL_BASIC_CATEGORIES, onGetAllBasicCategory);
}

export default function* rootSaga() {
  yield all([
    fork(getMediaSpaceData),
    fork(getMediaSpaceContents),
    fork(getMediaSpaceCategories),
    fork(filterCategoryMediaContentBy),
    fork(filterMediaCategoryByAuthor),
    fork(fetchMediasFromAnAuthor),
    fork(getAllBasicCategory),
  ]);
}
