import {
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_CATEGORIES_ACTIVE_WITH_CONTENT,
  GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS
} from 'shared/providers/redux/actionTypes';

export const getSingleCategory = slug => {
  return {
    type: GET_SINGLE_CATEGORY,
    slug,
  };
};

export const getSingleCategorySuccess = content => {
  return {
    type: GET_SINGLE_CATEGORY_SUCCESS,
    payload: content,
  };
};

export const getCategoriesActiveWithContent = () => {
  return {
    type: GET_CATEGORIES_ACTIVE_WITH_CONTENT,
  };
};

export const getCategoriesActiveWithContentSuccess = categories => {
  return {
    type: GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS,
    payload: categories,
  };
};
