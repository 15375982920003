import {
  GET_DASHBOARD_SPOTLIGHTS,
  FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const getDashboardSpotlights = () => ({
  type: GET_DASHBOARD_SPOTLIGHTS,
});

export const fetchDashboardSpotlightsSuccess = data => ({
  type: FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS,
  payload: data,
});
