import {
  GET_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  SET_TOAST_NOTIFICATIONS,
  SET_LAST_TOAST_NOTIFICATIONS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  notificationsList: [],
  loader: false,
  toastNotificationsList: null,
  lastToastNotificationsList: null,
  appNotificationVisible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsList: action.payload,
        loader: false,
      };
    }

    case SET_TOAST_NOTIFICATIONS: {
      return {
        ...state,
        toastNotificationsList: action.payload
      };
    }

    case SET_LAST_TOAST_NOTIFICATIONS: {
      return {
        ...state,
        lastToastNotificationsList: action.payload
      };
    }

    default:
      return state;
  }
};
