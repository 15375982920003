import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import {
  getBlogCommentsSuccess,
  onGetFeaturedBlogSuccess,
} from 'shared/providers/redux/actions';
import {
  GET_BLOG_COMMENTS,
  GET_FEATURED_BLOG,
  SET_DISLIKE_BLOG,
  SET_LIKE_BLOG,
} from 'shared/providers/redux/actionTypes';
import {
  GET_BLOGS,
  GET_SINGLE_BLOG,
} from '../../shared/providers/redux/actionTypes';
import {
  getBlogsSuccess,
  getSingleBlogFailed,
  getSingleBlogSuccess,
} from './actions';

const getBlogCommentsApi = async (payload) => {
  const { slug } = payload;

  try {
    const response = await api.get(`/blog/${slug}/comments`);

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const getFeaturedBlogRequest = async () => {
  try {
    const response = await api.get('/blog?featured=true');
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const setLikeBlogRequest = async (payload) => {
  const slug = payload;

  try {
    await api.post(`/blog/${slug}/like`);
  } catch (error) {
    console.log(error);
  }
  return null;
};

const setDislikeBlogRequest = async (payload) => {
  const slug = payload;

  try {
    await api.post(`/blog/${slug}/dislike`);
  } catch (error) {
    console.log(error);
  }
  return null;
};

const getSingleBlogRequest = async (payload) => {
  const response = await api.get(`/blog/${payload?.slug}`);
  return response.data;
};

const getBlogsRequest = async (payload) => {
  try {
    const response = await api.get(
      `/blog?page=${payload.page}&per_page=${payload.perPage}&order[0][index]=position&order[0][value]=desc`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

function* getFeaturedBlog() {
  const data = yield call(getFeaturedBlogRequest);
  if (data) yield put(onGetFeaturedBlogSuccess({ data }));
}

function* getBlogComments({ payload }) {
  const { id } = payload;
  const data = yield call(getBlogCommentsApi, payload);
  if (data) yield put(getBlogCommentsSuccess({ data, id }));
}

function* setLikeBlog({ payload }) {
  yield call(setLikeBlogRequest, payload);
}

function* setDislikeBlog({ payload }) {
  yield call(setDislikeBlogRequest, payload);
}

function* getSingleBlog({ payload }) {
  try {
    const response = yield call(getSingleBlogRequest, payload);
    yield put(getSingleBlogSuccess(response));
  } catch {
    yield put(getSingleBlogFailed());
  }
}

function* getBlogs({ payload }) {
  const response = yield call(getBlogsRequest, payload);
  yield put(getBlogsSuccess(response));
}

export function* fetchGetFeaturedBlog() {
  yield takeEvery(GET_FEATURED_BLOG, getFeaturedBlog);
}

export function* fetchBlogComments() {
  yield takeEvery(GET_BLOG_COMMENTS, getBlogComments);
}

export function* fetchSetLikeBlog() {
  yield takeEvery(SET_LIKE_BLOG, setLikeBlog);
}

export function* fetchSetDislikeBlog() {
  yield takeEvery(SET_DISLIKE_BLOG, setDislikeBlog);
}

export function* fetchGetSingleBlog() {
  yield takeEvery(GET_SINGLE_BLOG, getSingleBlog);
}

export function* fetchGetBlogs() {
  yield takeEvery(GET_BLOGS, getBlogs);
}

export default function* rootSaga() {
  yield all([
    fork(fetchGetFeaturedBlog),
    fork(fetchBlogComments),
    fork(fetchSetLikeBlog),
    fork(fetchSetDislikeBlog),
    fork(fetchGetSingleBlog),
    fork(fetchGetBlogs),
  ]);
}
