import {
  CLEAN_RESOURCES_POSITION,
  FETCH_ALL_COLLECTIONS_SUCCESS,
  FETCH_COLLECTION_SESSION_SUCCESS,
  GET_ALL_COLLECTIONS,
  LOAD_MORE_COLLECTION,
  LOAD_MORE_COLLECTION_ITEMS,
  LOAD_MORE_COLLECTION_ITEMS_SUCCESS,
  LOAD_MORE_COLLECTION_SUCCESS,
  REMOVE_COLLECTION,
  REMOVE_ITEM_COLLECTIONS,
  REMOVE_ITEM_COLLECTIONS_SUCCESS,
  SET_COLLECTION,
  SET_COLLECTION_ITEM_POSITION,
  SET_COLLECTION_ITEM_SEARCH,
  SET_COLLECTION_POSITION,
  SET_COLLECTION_SEARCH,
  SET_COLLECTION_SESSION,
  SET_COLLECTION_TYPE,
  SET_ITEMS_COLLECTIONS,
  SET_ITEMS_COLLECTIONS_SUCCESS,
  SET_PRIVATE_COLLECTION,
  UPDATE_COLLECTION,
} from 'shared/providers/redux/actionTypes';

export const fetchCollections = (loader, page = 1) => ({
  type: GET_ALL_COLLECTIONS,
  payload: loader,
  page,
});

export const loadMoreCollections = (page) => ({
  type: LOAD_MORE_COLLECTION,
  page,
});

export const loadMoreCollectionsSuccess = (collections, meta) => ({
  type: LOAD_MORE_COLLECTION_SUCCESS,
  payload: { collections, meta },
});

export const loadMoreCollectionItems = (page, id) => ({
  type: LOAD_MORE_COLLECTION_ITEMS,
  page,
  id,
});

export const loadMoreCollectionItemsSuccess = (collections, meta) => ({
  type: LOAD_MORE_COLLECTION_ITEMS_SUCCESS,
  payload: { collections, meta },
});

export const setCollection = (name, description, collections, currentPage) => ({
  type: SET_COLLECTION,
  payload: { name, description, collections, currentPage },
});

export const setCollectionSession = ({ loader, userId, slug }) => ({
  type: SET_COLLECTION_SESSION,
  payload: { loader, userId, slug },
});

export const setCollectionSearch = (searchCollection) => ({
  type: SET_COLLECTION_SEARCH,
  payload: searchCollection,
});

export const setCollectionItemSearch = (searchCollectionItem) => ({
  type: SET_COLLECTION_ITEM_SEARCH,
  payload: searchCollectionItem,
});

export const setCollectionType = (type, id) => ({
  type: SET_COLLECTION_TYPE,
  payload: { type, id },
});

export const setPrivateCollection = ({
  id,
  privateCollection,
  userId,
  slug,
}) => ({
  type: SET_PRIVATE_COLLECTION,
  payload: { id, privateCollection, userId, slug },
});

export const setCollectionItemPosition = (idCollection, idArrays) => ({
  type: SET_COLLECTION_ITEM_POSITION,
  payload: { idCollection, idArrays },
});

export const setCollectionPosition = (idArrays) => ({
  type: SET_COLLECTION_POSITION,
  payload: idArrays,
});

export const cleanResourcesPosition = () => ({
  type: CLEAN_RESOURCES_POSITION,
});

export const setItemCollections = (className, item, collections) => ({
  type: SET_ITEMS_COLLECTIONS,
  payload: { className, item, collections },
});

export const setItemsCollectionsSuccess = ({ content }) => ({
  type: SET_ITEMS_COLLECTIONS_SUCCESS,
  payload: { content },
});

export const updateCollection = (id, name, description, collection) => ({
  type: UPDATE_COLLECTION,
  payload: { id, name, description, collection },
});

export const removeItemCollections = (className, item, collections) => ({
  type: REMOVE_ITEM_COLLECTIONS,
  payload: { className, item, collections },
});

export const removeItemCollectionsSuccess = () => ({
  type: REMOVE_ITEM_COLLECTIONS_SUCCESS,
});

export const removeCollection = (id) => ({
  type: REMOVE_COLLECTION,
  payload: id,
});

export const fetchCollectionsSuccess = (collections, meta) => ({
  type: FETCH_ALL_COLLECTIONS_SUCCESS,
  payload: { collections, meta },
});

export const fetchCollectionSessionSuccess = (collectionSession) => ({
  type: FETCH_COLLECTION_SESSION_SUCCESS,
  payload: collectionSession,
});
