import { MailOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.$isDarkMode ? '#F6FFED21' : '#F6FFED')};
  border: 4px solid #c9f1aa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 36px;
  text-align: center;
`;

export const Title = styled.h1`
  font-weight: 600 !important;
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #606060 !important;
`;

export const Description = styled.h2`
  color: #606060 !important;
  font-size: 1rem;
  margin-bottom: 4px;
`;

export const Icon = styled(MailOutlined)`
  margin-right: 12px;

  svg {
    margin-top: 4px;
  }
`;
