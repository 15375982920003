import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchDashboardSpotlightsSuccess } from 'shared/providers/redux/actions';
import { GET_DASHBOARD_SPOTLIGHTS } from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const getDashboardSpotlightsRequest = async () => {
  try {
    const response = await api.get('dashboard/spotlights');

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetDashboardSpotlights() {
  const response = yield call(getDashboardSpotlightsRequest);

  if (response) yield put(fetchDashboardSpotlightsSuccess(response));
}

export function* getDashboardSpotlights() {
  yield takeEvery(GET_DASHBOARD_SPOTLIGHTS, onGetDashboardSpotlights);
}

export default function* rootSaga() {
  yield all([
    fork(getDashboardSpotlights),
  ]);
}
