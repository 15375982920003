import {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_ENTITY_PLANS,
  FETCH_ENTITY_PLANS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const fetchPlans = () => ({
  type: FETCH_PLANS,
});

export const fetchPlansSuccess = plans => ({
  type: FETCH_PLANS_SUCCESS,
  payload: plans,
});

export const fetchEntityPlans = (context, slug) => ({
  type: FETCH_ENTITY_PLANS,
  payload: { context, slug },
});

export const fetchEntityPlansSuccess = (plans, slug) => ({
  type: FETCH_ENTITY_PLANS_SUCCESS,
  payload: { plans, slug },
});
