/* eslint-disable import/no-cycle */
import languages from 'shared/providers/i18n';
import {
  setCurrentStep,
  setPaymentErrors,
  setToastNotifications,
} from 'shared/providers/redux/actions';
import { getLocalUser, logout } from './auth';

const errorHandler = async (error) => {
  let store;

  if (process.env.NODE_ENV !== 'test') {
    const App = await import('shared/providers/containers/App');
    store = App.store;
  } else {
    const renderWrapper = await import('shared/providers/tests/renderWrapper');
    store = renderWrapper.store;
  }

  const { settings } = store.getState();
  const translations = languages[settings?.locale?.locale]?.messages;

  const test = error?.response?.status;
  const config = error?.response?.config;
  const isLoginRoute = config.url === 'login';
  const user = getLocalUser();

  switch (test) {
    case 401:
      if (user) {
        logout();
        window.location.href = '/401';
      }
      break;
    case 403:
      store.dispatch(
        setToastNotifications({
          type: 'error',
          message: translations['toastNotification.403'],
        })
      );
      break;
    case 404:
      if (
        isLoginRoute ||
        window.location.pathname.slice(0, 12) === '/certificate' ||
        window.location.pathname.slice(0, 15) === '/authentication' ||
        window.location.pathname.slice(0, 9) === '/myAccess' ||
        window.location.pathname.slice(0, 12) === '/collections'
      ) {
        break;
      }
      window.location.href = '/404';
      break;
    case 408:
      store.dispatch(
        setToastNotifications({
          type: 'error',
          message: translations['toastNotification.408'],
        })
      );
      break;
    case 422:
      break;
    case 500: {
      if (
        window.location.pathname === '/payment' &&
        error?.response?.data?.message === 'Transação autorizada gerada'
      ) {
        store.dispatch(setCurrentStep(2));
        return store.dispatch(setPaymentErrors({ waitProcessing: true }));
      }

      store.dispatch(
        setToastNotifications({
          type: 'error',
          message: translations['toastNotification.500'],
        })
      );

      break;
    }
    default:
      store.dispatch(
        setToastNotifications({
          type: 'error',
          message: error?.message || error?.msg,
        })
      );
      break;
  }
};

export default errorHandler;
