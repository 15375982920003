import styled from 'styled-components';

export const Container = styled.a`
  --master-color-rgb: ${(props) => props.$masterColorRgb};

  display: flex;
  cursor: pointer;
  width: 100%;

  &:last-child {
    display: none;
  }

  .result-card-container {
    margin-right: 1rem;
    max-width: 11.375rem;
    max-height: 6.375rem;
    min-width: 11.375rem;
    min-height: 6.375rem;
  }

  .result-card-icon {
    background-color: rgba(var(--master-color-rgb), 0.8);
    height: 100%;
  }

  .result-card-icon svg {
    height: 2.5rem;
    width: 2.5rem;

    * {
      stroke: var(--text-tenant-color);
    }
  }

  img,
  .result-card-container,
  .result-card-icon {
    border-radius: 8px;
    display: grid;
    place-items: center;
    width: 100%;
    max-height: 6.375rem;
  }

  .info {
    display: flex;
    flex-direction: column;
    word-break: keep-all;
    gap: 0.5rem;

    h3,
    p {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;

      overflow: hidden;

      font-family: Inter;
      font-style: normal;

      margin: 0;
      width: 100%;
      color: var(--text-color);
    }

    h3 {
      -webkit-line-clamp: 2;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    p {
      -webkit-line-clamp: 3;
      height: 3.4rem;
      max-height: 3.4rem;

      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.094rem;
    }
  }

  @media (max-width: 500px) {
    img {
      min-width: 140px;
      max-width: 140px;
      height: 100%;
    }

    .info {
      h3 {
        font-size: 1rem;
        -webkit-line-clamp: 1;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }

  &,
  .info h3 {
    transition: 0.4s all ease-in-out;
  }

  &:hover {
    transform: translateY(-3px);

    h3 {
      color: var(--master-color);
    }
  }
`;
