import {
  GET_USER_SETTING,
  SET_USER_SETTING,
  FETCH_USER_SETTING_SUCCESS,
  REMOVE_FIELD_USER_SETTING,
  REMOVE_FIELD_USER_SETTING_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  settings: [],
  loader: false,
  loaderRemoveSetting: false,
  loaderSetUserSetting: false,
  loaderSetUserSettingWithCoupon: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SETTING: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case SET_USER_SETTING: {
      return {
        ...state,
        loaderSetUserSetting: action.payload.loaderSetUserSetting,
        loaderSetUserSettingWithCoupon:
          action.payload.loaderSetUserSettingWithCoupon,
      };
    }

    case FETCH_USER_SETTING_SUCCESS: {
      return {
        ...state,
        settings: action.payload,
        loader: false,
        loaderSetUserSetting: false,
        loaderSetUserSettingWithCoupon: false,
      };
    }

    case REMOVE_FIELD_USER_SETTING: {
      return {
        ...state,
        loaderRemoveSetting: true,
      };
    }

    case REMOVE_FIELD_USER_SETTING_SUCCESS: {
      return {
        ...state,
        loaderRemoveSetting: false,
      };
    }

    default:
      return state;
  }
};
