import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    mix-blend-mode: multiply;
  }

  ${props =>
    props.isDarkMode &&
    css`
      p,
      h1 {
        color: #ffffff;
      }

      img {
        width: 200px;
      }
    `}
`;
