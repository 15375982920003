import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const Sider = styled(Layout.Sider)`
  position: absolute;
  top: 58px;
  left: 0;
  bottom: 0;
  transition: transform width 0.5s;
  box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
  z-index: 45 !important;

  ${(props) =>
    props.collapsed
      ? css`
          width: 80px !important;

          ul li {
            p,
            span > span {
              display: none;
            }
          }

          @media (max-width: 990px) {
            transform: translateX(-80px);
          }
        `
      : css`
          width: 240px !important;

          @media (max-width: 990px) {
            transform: translateX(0px);
          }
        `}
`;
