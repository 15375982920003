import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_TENANT_DATA } from 'shared/providers/redux/actionTypes';
import { fetchTenantDataSuccess } from 'shared/providers/redux/actions';
import tenancyAPI from 'shared/infra/services/tenancyAPI';

const getTenantDataRequest = async payload => {
  try {
    const params = new URLSearchParams();
    params.append('host', window.btoa(payload));
    const response = await tenancyAPI.post('ten', params);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }

  return null;
};

function* onGetTenantData({ payload }) {
  const tenantData = yield call(getTenantDataRequest, payload);
  if (tenantData) {
    sessionStorage.setItem('tenant_data', JSON.stringify(tenantData));
    yield put(fetchTenantDataSuccess(tenantData));
  }
}

export function* getTenantData() {
  yield takeEvery(GET_TENANT_DATA, onGetTenantData);
}

export default function* rootSaga() {
  yield all([fork(getTenantData)]);
}
