import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

import MainMenuList from './components/MainMenuList';
import { Container } from './styles';

const MainMenu = memo(() => {
  const mainMenuList = useSelector(({ dynamicMenu }) => dynamicMenu?.mainMenuList);
  const [isVisible, setIsVisible] = useState(false);

  return (
    mainMenuList && mainMenuList[0]?.items?.length > 0 && (
      <Popover
        trigger="click"
        visible={isVisible}
        placement="bottomRight"
        content={<MainMenuList mainMenuList={mainMenuList} />}
        overlayClassName="item gx-popover-horizantal"
      >
        <Container
          onClick={() => setIsVisible(!isVisible)}
          isVisible={isVisible}
          className="icon sidebar"
        >
          <i className="gx-icon-btn icon icon-menu-unfold" />
        </Container>
      </Popover>
    )
  );
});

export default memo(MainMenu);
