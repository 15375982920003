import {
  STYLES_CONTENT_COURSE,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  styles: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STYLES_CONTENT_COURSE: {
      return {
        ...state,
        styles: action.payload,
      };
    }

    default:
      return state;
  }
};
