import {
  STYLES_CONTENT_COURSE,
} from 'shared/providers/redux/actionTypes';

export const setStyleContentCourse = styles => {
  return {
    type: STYLES_CONTENT_COURSE,
    payload: styles,
  };
};
