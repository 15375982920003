/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'shared/components/templates/DefaultModal';
import { setOpenModal, setForgotModal } from 'shared/providers/redux/actions';

import EmailConfirmation from './screens/EmailConfirmation';
import Login from './screens/Login/index';
import Close from './screens/Close';
import Register from './screens/Register/index';
import InitialScreen from './screens/Initial';
import ForgotPassword from './screens/ForgotPassword';

function AuthModal() {
  const dispatch = useDispatch();

  const [loginPage, setLoginPage] = useState('first');
  const [history, setHistory] = useState([]);

  const showLoginModal = useSelector(
    ({ warnings }) => warnings?.showLoginModal
  );

  const showForgotModal = useSelector(
    ({ warnings }) => warnings?.showForgotModal
  );

  const { userSignUpAnyoneCanCreateAccount } = useSelector(
    ({ tenancy }) => tenancy?.tenantData?.settings?.users
  );

  function handleCloseAuthModal() {
    setLoginPage('first');
    dispatch(setForgotModal(false));
    dispatch(setOpenModal(false));
  }

  const handleBackAuthModal =
    loginPage === 'first'
      ? false
      : () => {
          const tempHistory = history;
          const lastItem = tempHistory.pop();
          setHistory(tempHistory);
          setLoginPage(lastItem);
        };

  function handleNavigate(page) {
    setHistory([...history, loginPage]);
    setLoginPage(page);
  }

  function RenderPage() {
    switch (loginPage) {
      case 'first':
        return (
          <InitialScreen
            navigate={handleNavigate}
          />
        );
      case 'login':
        return <Login navigate={handleNavigate} />;
      case 'register':
        return (
          <>
            {userSignUpAnyoneCanCreateAccount === '1' ? (
              <Register navigate={handleNavigate} />
            ) : (
              <Close navigate={handleNavigate} />
            )}
          </>
        );
      case 'confirmation':
        return <EmailConfirmation navigate={handleNavigate} />;
      case 'forgot':
        return <ForgotPassword navigate={handleNavigate} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (showForgotModal) {
      setLoginPage('forgot');
    }
  }, [showForgotModal]);

  return (
    showLoginModal && (
      <Modal onGoBack={handleBackAuthModal} onClose={handleCloseAuthModal}>
        <RenderPage />
      </Modal>
    )
  );
}

export default AuthModal;
