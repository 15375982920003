import {
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SET_COMMING_SOON,
  SET_LOGING_MODAL,
  SET_FORGOT_MODAL,
  SIGNUP_USER_SUCCESS,
  REGISTER_NEW_USER,
  DOES_NOT_SUPPORT_OBSERVER,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  openCommingSoon: false,
  showLoginModal: false,
  showForgotModal: false,
  commingSoonText: '',
  alertMessage: '',
  messageSuccess: null,
  showMessage: false,
  newlyCreatedUserEmail: null,
  browserDoesNotSuportObserver: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        newlyCreatedUserEmail: action.payload,
      };
    }

    case REGISTER_NEW_USER: {
      return {
        ...state,
        newlyCreatedUserEmail: null,
      };
    }

    case SHOW_AUTH_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.message,
        messageSuccess: action.payload.success,
        showMessage: true,
      };
    }

    case HIDE_AUTH_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        messageSuccess: null,
        showMessage: false,
      };
    }

    case SET_COMMING_SOON: {
      return {
        ...state,
        openCommingSoon: action.payload.open,
        commingSoonText: action.payload.text,
      };
    }

    case SET_LOGING_MODAL: {
      return {
        ...state,
        showLoginModal: action.payload.open,
      };
    }

    case SET_FORGOT_MODAL: {
      return {
        ...state,
        showForgotModal: action.payload,
      };
    }

    case DOES_NOT_SUPPORT_OBSERVER: {
      return {
        ...state,
        browserDoesNotSuportObserver: true,
      };
    }

    default:
      return state;
  }
};
