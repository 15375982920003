import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import momentDate from 'shared/utils/momentDate';
import userInitials from 'shared/components/atoms/UserInitials';
import {
  onRemoveUniqueNotification,
  onMarkReadNotificationUnique,
} from 'shared/providers/redux/actions';
import {
  Container,
  Footer,
  Actions,
  IconCloseOutlined,
  IconNotificationOutlined,
} from './styles';

const NotificationItem = ({ notification }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { mode } = useSelector(({ settings }) => settings);
  const { tenantData } = useSelector(({ tenancy }) => tenancy);
  const { created_at, type, id, is_read } = notification;
  const [checked, setChecked] = useState(is_read);

  const handleIconNotificationOrAvatarUser = useCallback(() => {
    let userInfo = {};

    if (!notification?.data?.user && !notification?.user)
      return <IconNotificationOutlined theme={mode} />;

    if (notification?.data) {
      const { user, user_commented_display_name } = notification.data;

      if (user) {
        const { display_name, photo, avatar_url, username } = user;

        userInfo = {
          display_name,
          avatar_url: avatar_url || photo,
          username,
        };
      } else {
        userInfo = {
          display_name: user_commented_display_name,
          avatar_url: user.avatar_url || user.photo,
          username: null,
        };
      }
    } else {
      const { user, user_commented_display_name } = notification;

      if (user) {
        const { display_name, photo, avatar_url, username } = user;

        userInfo = {
          display_name,
          avatar_url: avatar_url || photo,
          username,
        };
      } else {
        userInfo = {
          display_name: user_commented_display_name,
          avatar_url: user.avatar_url || user.photo,
          username: null,
        };
      }
    }

    return userInitials(userInfo, null, false, 'gx-size-40 gx-mr-3');
  }, [notification, mode]);

  const handleType = () => {
    switch (type) {
      case 'App\\Notifications\\SocialNetworkLike':
        return <i className="icon icon-thumb-up gx-text-blue gx-pr-2" />;

      case 'App\\Notifications\\SocialNetwork\\ReceiveCommentNotification':
        return <i className="icon icon-chat gx-text-grey gx-pr-2" />;

      case 'App\\Notifications\\SocialNetworkMention':
        return <i className="icon icon-profile gx-text-grey gx-pr-2" />;

      case 'App\\Notifications\\SocialNetworkReceiveComment':
        return <i className="icon icon-chat gx-text-grey gx-pr-2" />;

      default:
        return null;
    }
  };

  useEffect(() => {
    setChecked(is_read);
  }, [is_read]);

  return (
    <Container theme={mode}>
      <li className="gx-media">
        {handleIconNotificationOrAvatarUser()}
        <div
          className="gx-media-body gx-align-self-center"
          onClick={() => {
            history.push(
              `${
                notification?.data?.notification_url_action ||
                notification?.notification_url_action
              }`
            )
            if (!checked) {
              setChecked(!checked);
              dispatch(onMarkReadNotificationUnique(id));
            }
          }}
        >
          <p className="gx-fs-sm gx-mb-0 content-string">
            {notification?.data?.template_notification_content_string
              ?.replaceAll(/(&quot\;)/g, '"')
              ?.replaceAll('&#039;', "'")
              ?.replaceAll('&lt;', '<')
              ?.replaceAll('&gt;', '>')
              ?.replaceAll('&gt;', '>')
              ?.replaceAll('&amp;', '&')
              ||
              notification?.template_notification_content_string
                ?.replaceAll(/(&quot\;)/g, '"')
                ?.replaceAll('&#039;', "'")
                ?.replaceAll('&lt;', '<')
                ?.replaceAll('&gt;', '>')
                ?.replaceAll('&amp;', '&')
              }
          </p>

          <Footer>
            {handleType()}{' '}
            <span className="gx-meta-date">
              <small>{momentDate(created_at)}</small>
            </span>
          </Footer>
        </div>

        <Actions tenantData={tenantData}>
          <Tooltip
            title={intl.formatMessage({ id: 'notification.remove.unique' })}
            className="notifications-actions"
          >
            <IconCloseOutlined
              theme={mode}
              onClick={() => dispatch(onRemoveUniqueNotification(id))}
            />
          </Tooltip>

          {!checked && (
            <Tooltip
              title={intl.formatMessage({ id: 'notification.mark.unique' })}
            >
              <Checkbox
                checked={!checked}
                onChange={() => {
                  setChecked(!checked);
                  dispatch(onMarkReadNotificationUnique(id));
                }}
              />
            </Tooltip>
          )}
        </Actions>
      </li>
    </Container>
  );
};

NotificationItem.defaultProps = {
  notification: {},
};

NotificationItem.propTypes = {
  notification: PropTypes.object,
};

export default NotificationItem;
