import {
  GET_TENANT_DATA,
  GET_TENANT_DATA_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const fetchTenantData = (hostname) => {
  const checkHostname = () => {
    if (/ngrok|loca.lt|localhost/.test(hostname)) return 'dev.ensinio.com';
    if (hostname === 'loadtest.ensinio.com') return 'magicaonline.ensinio.com';
    return hostname;
  };

  return {
    type: GET_TENANT_DATA,
    payload: checkHostname(),
  };
};

export const fetchTenantDataSuccess = (tenantData) => ({
  type: GET_TENANT_DATA_SUCCESS,
  payload: tenantData,
});
