import {
  SET_CURRENT_STEP,
  SET_OPTIONS_FREIGHT,
  SET_CHECKOUT_SESSION,
  SET_OPTIONS_FREIGHT_SUCCESS,
  SIGNIN_USER_CHECKOUT,
  SIGNIN_USER_CHECKOUT_ERROR,
  SIGNIN_USER_CHECKOUT_SUCCESS,
  SET_RESET_SIGN_UP_FIELDS,
  SET_CHECKOUT_LOADER_TRUE,
  SET_CHECKOUT_LOADER_FALSE,
} from 'shared/providers/redux/actionTypes';

export const setProductSession = (session) => ({
  type: SET_CHECKOUT_SESSION,
  payload: session,
});

export const setOptionsFreight = (zipcode) => ({
  type: SET_OPTIONS_FREIGHT,
  payload: zipcode,
});

export const setOptionsFreightSuccess = (optionsFreight) => ({
  type: SET_OPTIONS_FREIGHT_SUCCESS,
  payload: optionsFreight,
});

export const signInUser = (user) => ({
  type: SIGNIN_USER_CHECKOUT,
  payload: user,
});

export const signInUserError = () => ({
  type: SIGNIN_USER_CHECKOUT_ERROR,
});

export const signInUserSuccess = () => ({
  type: SIGNIN_USER_CHECKOUT_SUCCESS,
});

export const setCurrentStep = (current) => ({
  type: SET_CURRENT_STEP,
  payload: current,
});

export const resetRegisterFields = () => ({
  type: SET_RESET_SIGN_UP_FIELDS,
});

export const setCheckoutLoaderTrue = () => ({
  type: SET_CHECKOUT_LOADER_TRUE,
});

export const setCheckoutLoaderFalse = () => ({
  type: SET_CHECKOUT_LOADER_FALSE,
});
