import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;

  width: max-content;
  position: absolute;

  z-index: 1;
  top: 45.5px;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0.5rem 0;
  background: ${(props) => (props.$isDarkMode ? '#242424' : '#ffffff')};
  border-radius: 8px;
  backdrop-filter: blur(30px);
`;

export const OptionList = styled.li`
  display: flex;
  align-items: center;

  cursor: pointer;
  text-align: start;
  padding: 0.5rem 1.125rem;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--text-color) !important;

  transition: 0.3s ease-in-out all;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;

    * {
      stroke: var(--text-color);
    }
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    transform: translateX(+5px);
  }
`;
