import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Button, Input, Alert, Spin } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { connect, useDispatch } from 'react-redux';

import {
  showAuthLoader,
  resetPassword,
  hideAuthMessage,
} from 'shared/providers/redux/actions';
import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import PasswordInput from 'shared/components/atoms/PasswordInput';
import { Container } from './styles';

const FormItem = Form.Item;

const ResetPassword = ({
  form,
  match,
  history,
  authUser,
  tenantData,
  showMessage,
  alertMessage,
  resetPassword,
  messageSuccess,
  showAuthLoader,
  hideAuthMessage,
}) => {
  const globalDispatch = useDispatch();

  const [alert, setAlert] = useState(null);
  const [loader, setLoader] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPassIsCorrect, setConfirmPassIsCorrect] = useState(false);
  const [successAlert, setSuccessAlert] = useState(null);

  const { site } = tenantData?.settings;
  const theme = tenantData?.settings['general-theme'];
  const defaultImage = theme?.themeLogoHorizontal;

  const { token } = match.params;

  useEffect(() => {
    if ((authUser !== null && !messageSuccess) || !token) {
      history.push('/');
    }
  }, [authUser, token, messageSuccess, history]);

  useEffect(() => {
    if (showMessage && !messageSuccess) {
      setAlert(alertMessage);
      setLoader(false);
      globalDispatch(hideAuthMessage());
    }

    // eslint-disable-next-line
  }, [showMessage, messageSuccess, alertMessage, hideAuthMessage]);

  useEffect(() => {
    if (messageSuccess) {
      setSuccessAlert(alertMessage);
      setLoader(false);
    }
  }, [messageSuccess, alertMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        setLoader(true);
        showAuthLoader();

        if (passwordValue.length >= 6) {
          resetPassword(
            { password: passwordValue, confirm: values?.confirm },
            token
          );
        } else {
          setAlert('O campo senha deve ter ao menos 6 caracteres.');
          setLoader(false);
        }
      }
    });
  };

  useEffect(() => {
    setConfirmPassIsCorrect(passwordValue === confirmPassword);
  }, [passwordValue, confirmPassword]);

  return (
    <Container className="gx-login-container" tenantData={tenantData}>
      <div className="gx-login-content">
        <div className="gx-mb-4">
          {defaultImage ? (
            <img src={defaultImage} alt="logo" />
          ) : (
            site?.siteName && <h1 className="siteName">{site?.siteName}</h1>
          )}
          <h2>
            <StaticIntlTranslator id="app.userAuth.resetPassword" />
          </h2>
          <p>
            <StaticIntlTranslator id="appModule.enterPasswordReset" />
          </p>
        </div>

        {loader && <Spin />}

        {alert && !successAlert && (
          <Alert
            description={alert}
            type="error"
            showIcon
            closable
            onClose={() => setAlert(null)}
            style={{ marginBottom: '30px' }}
          />
        )}

        {successAlert ? (
          <>
            <Alert
              message="Senha redefinida com sucesso!"
              description="Fácil, não? :)"
              type="success"
              showIcon
              style={{ marginBottom: '30px' }}
            />
            <Button
              type="primary"
              block
              size="large"
              onClick={() => history.push('/')}
            >
              Acessar a plataforma
            </Button>
          </>
        ) : (
          <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
            <PasswordInput
              FormItemComponent={FormItem}
              InputComponent={Input}
              getValue={(e) => {
                setPasswordValue(e);
              }}
            />

            <FormItem>
              <Input
                style={{ height: '50px' }}
                type="password"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirmação da nova senha"
                size="large"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormItem>

            <span className="passwordStatus">
              {passwordValue && confirmPassword
                ? !confirmPassIsCorrect
                  ? 'As senhas informadas não são iguais.'
                  : confirmPassword < 6 &&
                    'O campo senha deve ter ao menos 6 caracteres'
                : null}
            </span>

            <FormItem className="gx-text-center">
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                disabled={
                  !confirmPassIsCorrect || !passwordValue || !confirmPassword
                }
              >
                <StaticIntlTranslator id="app.userAuth.reset" />
              </Button>
            </FormItem>
          </Form>
        )}
      </div>
    </Container>
  );
};

ResetPassword.defaultProps = {
  authUser: null,
  alertMessage: null,
  messageSuccess: null,
};

ResetPassword.propTypes = {
  authUser: PropTypes.any,
  messageSuccess: PropTypes.any,
  alertMessage: PropTypes.string,
  form: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired,
  tenantData: PropTypes.object.isRequired,
  showAuthLoader: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideAuthMessage: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const WrappedResetPasswordForm = Form.create()(ResetPassword);

const mapStateToProps = ({ auth, warnings, tenancy }) => {
  const { tenantData } = tenancy;
  const { loader, authUser } = auth;
  const { alertMessage, messageSuccess, showMessage } = warnings;

  return {
    loader,
    authUser,
    tenantData,
    alertMessage,
    messageSuccess,
    showMessage,
  };
};

export default connect(mapStateToProps, {
  showAuthLoader,
  resetPassword,
  hideAuthMessage,
})(WrappedResetPasswordForm);
