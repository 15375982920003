import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import {
  fetchCoursesBeingWatchedSuccess,
  fetchCoursesCategoriesSuccess,
  fetchCoursesFromAnAuthorSuccess,
  fetchCoursesFromCategorySuccess,
  fetchCoursesSuccess,
  fetchCourseSuccess,
  fetchFeaturedCoursesSuccess,
  fetchSearchLessonsModulesSuccess,
  filterCategoryByNameSuccess,
  filterCategoryCourseBySuccess,
  setNotFoundOnCourseState,
} from 'shared/providers/redux/actions';
import {
  FILTER_CATEGORY_BY_NAME,
  FILTER_CATEGORY_COURSE_BY,
  GET_ALL_COURSES,
  GET_ALL_COURSES_FROM_AN_AUTHOR,
  GET_COURSE,
  GET_COURSES_BEING_WATCHED,
  GET_COURSES_CATEGORIES,
  GET_COURSES_FROM_CATEGORY,
  GET_FEATURED_COURSES,
  SET_SEARCH_LESSONS_MODULES,
} from 'shared/providers/redux/actionTypes';

const getCoursesFromAnAuthorRequest = async (payload) => {
  try {
    const response = await api.get(`course`, {
      params: {
        'whereHas[1][index]': 'id',
        'whereHas[1][condition]': '=',
        'whereHas[1][value]': payload.author_id,
        'whereHasWith[1]': 'Authors',
        page: payload.page,
        per_page: payload.per_page,
      },
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getCoursesFromAnAuthor({ payload }) {
  const courses = yield call(getCoursesFromAnAuthorRequest, payload);
  if (courses) yield put(fetchCoursesFromAnAuthorSuccess(courses));
}

export function* fetchCoursesFromAnAuthor() {
  yield takeEvery(GET_ALL_COURSES_FROM_AN_AUTHOR, getCoursesFromAnAuthor);
}

const getFeaturedCoursesRequest = async () => {
  try {
    const response = await api.get(`course`, {
      params: {
        'where[0][index]': 'featured',
        'where[0][condition]': 'like',
        'where[0][value]': 1,
        'order[0][index]': 'position',
        'order[0][value]': 'asc',
      },
    });

    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getFeaturedCourses({ payload }) {
  const courses = yield call(getFeaturedCoursesRequest, payload);
  if (courses) yield put(fetchFeaturedCoursesSuccess(courses));
}

export function* fetchFeaturedCourses() {
  yield takeEvery(GET_FEATURED_COURSES, getFeaturedCourses);
}

const getAllCoursesRequest = async ({ order_by, order }) => {
  try {
    const response = await api.get(`course`, {
      params: {
        'order[0][index]': order_by,
        'order[0][value]': order,
      },
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getCourseRequest = async (slug) => {
  try {
    const response = await api.get(`course/${slug}`);
    return response?.data;
  } catch (error) {
    return { data: error };
  }
};

const setSearchLessonsModulesRequest = async (slug) => {
  try {
    const response = await api.get(
      `course/${slug}/search?search=course&resources%5B0%5D=modules&resources%5B1%5D=lessons`
    );

    return response.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* getCourse({ payload }) {
  const { data } = yield call(getCourseRequest, payload);
  if (data) {
    if (data instanceof Error) {
      yield put(setNotFoundOnCourseState());
    } else {
      yield put(fetchCourseSuccess(data));
    }
  }
}

function* setSearchLessonsModules({ payload }) {
  const resourcesFilter = yield call(setSearchLessonsModulesRequest, payload);
  if (resourcesFilter)
    yield put(fetchSearchLessonsModulesSuccess(resourcesFilter));
}

function* getAllCourses({ payload }) {
  const courses = yield call(getAllCoursesRequest, payload);
  if (courses) yield put(fetchCoursesSuccess(courses));
}

export function* fetchAllCourses() {
  yield takeEvery(GET_ALL_COURSES, getAllCourses);
}

export function* fetchSingleCourse() {
  yield takeEvery(GET_COURSE, getCourse);
}

export function* fetchSearchLessonsModules() {
  yield takeEvery(SET_SEARCH_LESSONS_MODULES, setSearchLessonsModules);
}

const getCoursesCategoriesRequest = async ({ with_course, per_page, page }) => {
  try {
    let response;

    if (with_course) {
      response = await api.get('category/course', {
        params: {
          with_course,
          per_page,
          page,
        },
      });
    } else {
      response = await api.get('category/course');
    }

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getCoursesCategories({ payload }) {
  const categories = yield call(getCoursesCategoriesRequest, payload);
  if (categories) yield put(fetchCoursesCategoriesSuccess(categories));
}

export function* fetchCoursesCategories() {
  yield takeEvery(GET_COURSES_CATEGORIES, getCoursesCategories);
}

const getCoursesBeingWatchedRequest = async () => {
  try {
    const response = await api.get('course/watching');
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getCoursesBeingWatched() {
  const courses = yield call(getCoursesBeingWatchedRequest);
  if (courses) yield put(fetchCoursesBeingWatchedSuccess(courses));
}

export function* fetchCoursesBeingWatched() {
  yield takeEvery(GET_COURSES_BEING_WATCHED, getCoursesBeingWatched);
}

const getCoursesFromCategoryRequest = async ({
  category_slug,
  per_page,
  page,
}) => {
  try {
    const response = await api.get(
      `category/course/${category_slug}?per_page=${per_page}&page=${page}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

function* getCoursesFromCategory({ payload }) {
  const response = yield call(getCoursesFromCategoryRequest, payload);
  if (response) {
    if (response instanceof Error) {
      yield put(setNotFoundOnCourseState());
    } else {
      yield put(fetchCoursesFromCategorySuccess(response));
    }
  }
}

export function* fetchCoursesFromCategory() {
  yield takeEvery(GET_COURSES_FROM_CATEGORY, getCoursesFromCategory);
}

const onfilterCategoryByNameRequest = async (name) => {
  const params = {
    'where[0][index]': 'name',
    'where[0][condition]': 'like',
    'where[0][value]': name,
  };

  try {
    const response = await api.get('/category/course?with_course=false', {
      params,
    });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onFilterCategoryByName(payload) {
  const categories = yield call(onfilterCategoryByNameRequest, payload.payload);
  yield put(filterCategoryByNameSuccess(categories));
}

export function* filterCategoryByName() {
  yield takeLatest(FILTER_CATEGORY_BY_NAME, onFilterCategoryByName);
}

const onFilterCategoryCourseByRequest = async ({ screen, value, type }) => {
  let params = {};

  if (type === 'Title') {
    params = {
      'where[0][index]': 'name',
      'where[0][condition]': 'like',
      'where[0][value]': value,
      'whereHas[0][index]': 'slug',
      'whereHas[0][condition]': 'like',
      'whereHas[0][value]': screen,
      'whereHasWith[0]': 'Categories',
    };
  }

  if (type === 'Author') {
    params = {
      'whereHas[0][index]': 'slug',
      'whereHas[0][condition]': 'like',
      'whereHas[0][value]': screen,
      'whereHasWith[0]': 'Categories',
      'whereHas[1][index]': 'username',
      'whereHas[1][condition]': 'like',
      'whereHas[1][value]': value,
      'whereHasWith[1]': 'Authors',
    };
  }

  try {
    const response = await api.get('/course', {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onFilterCategoryCourseBy(payload) {
  const courses = yield call(onFilterCategoryCourseByRequest, payload.payload);
  yield put(filterCategoryCourseBySuccess(courses));
}

export function* filterCategoryCourseBy() {
  yield takeLatest(FILTER_CATEGORY_COURSE_BY, onFilterCategoryCourseBy);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllCourses),
    fork(fetchSingleCourse),
    fork(fetchFeaturedCourses),
    fork(fetchSearchLessonsModules),
    fork(fetchCoursesCategories),
    fork(fetchCoursesBeingWatched),
    fork(fetchCoursesFromCategory),
    fork(filterCategoryByName),
    fork(filterCategoryCourseBy),
    fork(fetchCoursesFromAnAuthor),
  ]);
}
