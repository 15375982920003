import {
  GET_FILTER,
  FETCH_FILTER_SUCCESS,
  CLEAR_FILTER,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  filterOn: false,
  data: [],
  loading: false,
  meta: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;

    case CLEAR_FILTER: {
      return INIT_STATE;
    }

    case GET_FILTER: {
      return {
        loading: true,
        filterOn: true,
        data: [],
      };
    }

    case FETCH_FILTER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    }
  }
};
