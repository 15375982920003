import {
  FETCH_CERTIFICATE_INFO_SUCCESS,
  FETCH_CERTIFICATE_VALIDATION_SUCCESS,
  GET_CERTIFICATE_INFO,
  GET_CERTIFICATE_VALIDATION,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  data: null,
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CERTIFICATE_INFO: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_CERTIFICATE_INFO_SUCCESS: {
      return {
        ...state,
        loader: false,
        data: action.payload,
      };
    }

    case GET_CERTIFICATE_VALIDATION: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_CERTIFICATE_VALIDATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        data: action.payload,
      };
    }

    default:
      return state;
  }
};
