import {
  CLEAR_NOT_FOUND_ON_COURSE_STATE,
  FETCH_ALL_COURSES_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_SEARCH_LESSONS_MODULES_SUCCESS,
  FILTER_CATEGORY_COURSE_BY,
  FILTER_CATEGORY_COURSE_BY_SUCCESS,
  GET_ALL_COURSES_FROM_AN_AUTHOR,
  GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS,
  GET_COURSE,
  GET_COURSES_BEING_WATCHED_SUCCESS,
  GET_COURSES_CATEGORIES,
  GET_COURSES_CATEGORIES_SUCCESS,
  GET_COURSES_FROM_CATEGORY_SUCCESS,
  GET_FEATURED_COURSES_SUCCESS,
  SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL,
  SET_NOT_FOUND_ON_COURSE_STATE,
  SET_SEARCH_LESSONS_MODULES,
  UPDATE_PERCENTAGE,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  coursesList: null,
  resourcesFilter: [],
  course: null,
  currentCourseName: null,
  params: {},
  paramsFilter: {},
  loader: true,
  loadingCourses: true,
  dashboardLoader: true,
  categoryLoader: true,
  loaderFilter: false,
  category: null,
  categoriesList: null,
  loadingCoursesListFromAnAuthor: false,
  coursesListFromAnAuthor: null,
  coursesBeingWatched: null,
  notFound: null,
  featuredCourses: null,
  searchingForCourses: false,
  searchingForCategoryCourses: false,
  coursesFoundBySearch: null,
  completed_course_now: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSE: {
      return {
        ...state,
        loader: true,
        params: action.payload,
        notFound: null,
      };
    }

    case GET_ALL_COURSES_FROM_AN_AUTHOR: {
      return {
        ...state,
        loadingCoursesListFromAnAuthor: true,
      };
    }

    case GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS: {
      return {
        ...state,
        coursesListFromAnAuthor: action.payload,
        loadingCoursesListFromAnAuthor: false,
      };
    }

    case SET_NOT_FOUND_ON_COURSE_STATE: {
      return {
        ...state,
        notFound: true,
      };
    }

    case CLEAR_NOT_FOUND_ON_COURSE_STATE: {
      return {
        ...state,
        notFound: null,
      };
    }

    case SET_SEARCH_LESSONS_MODULES: {
      return {
        ...state,
        loaderFilter: true,
        paramsFilter: action.payload,
      };
    }

    case UPDATE_PERCENTAGE: {
      const { moduleId, courseLessons, modules } = action.payload;

      const countCourseAssisted = courseLessons.filter(
        (lesson) => lesson.user_assisted
      ).length;

      const countCourseLesson = courseLessons.length;

      const newCoursePercentageCalc =
        (countCourseAssisted / countCourseLesson) * 100;

      const newCourse = { ...state.course };
      newCourse.complete_percentage = newCoursePercentageCalc;

      const countModuleAssisted = courseLessons.filter(
        (lesson) => lesson.user_assisted && lesson.module_id === moduleId
      ).length;

      const countModuleLesson = courseLessons.filter(
        (lesson) => lesson.module_id === moduleId
      ).length;

      const newModulePercentageCalc =
        (countModuleAssisted / countModuleLesson) * 100;

      const newModule = modules.map((item) => {
        const moduleItem = item;

        if (item.id === moduleId) {
          moduleItem.complete_percentage = Math.floor(newModulePercentageCalc);
        }
        return moduleItem;
      });

      return {
        ...state,
        course: newCourse,
        modules: newModule,
        completed_course_now: newCourse.complete_percentage === 100,
      };
    }

    case FETCH_COURSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        course: action.payload,
        currentCourseName: action.payload?.name || state.currentCourseName,
      };
    }

    case FETCH_ALL_COURSES_SUCCESS: {
      return {
        ...state,
        loader: false,
        loadingCourses: false,
        coursesList: action.payload,
      };
    }

    case GET_COURSES_CATEGORIES: {
      return {
        ...state,
        categoryLoader: true,
      };
    }

    case GET_COURSES_CATEGORIES_SUCCESS: {
      const categoriesList = state.categoriesList;

      return {
        ...state,
        categoriesList: categoriesList?.data
          ? {
              data: [...categoriesList?.data, ...action.payload?.data],
              meta: action.payload?.meta,
            }
          : action.payload,
        categoryLoader: false,
        dashboardLoader: false,
      };
    }

    case GET_COURSES_FROM_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: action.payload,
      };
    }

    case GET_COURSES_BEING_WATCHED_SUCCESS: {
      return {
        ...state,
        coursesBeingWatched: action.payload,
      };
    }

    case FETCH_SEARCH_LESSONS_MODULES_SUCCESS: {
      return {
        ...state,
        loaderFilter: false,
        resourcesFilter: action.payload,
      };
    }

    case GET_FEATURED_COURSES_SUCCESS: {
      return {
        ...state,
        featuredCourses: action.payload,
      };
    }

    case FILTER_CATEGORY_COURSE_BY: {
      return {
        ...state,
        searchingForCategoryCourses: true,
      };
    }

    case FILTER_CATEGORY_COURSE_BY_SUCCESS: {
      return {
        ...state,
        category: {
          courses: action.payload,
          data: state.category.data,
          links: state.category.links,
          meta: state.category.meta,
        },
        searchingForCategoryCourses: false,
      };
    }

    case SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL: {
      return {
        ...state,
        completed_course_now: false,
      };
    }

    default:
      return state;
  }
};
