import api from 'shared/infra/services/tenantAPI';
import { generateExpirationDate } from 'shared/utils/expirationDate';

export const getToken = () => localStorage.getItem('token');

export const login = (token) => {
  localStorage.setItem('token', token);
};

export const setLocalUser = (user) => {
  localStorage.setItem('user', user);
};

export const getLocalUser = () =>
  localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).data;

export const updateLocalUserData = (key, data) => {
  const userData = JSON.parse(localStorage.getItem('user'));

  switch (key) {
    case 'username': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          username: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'biography': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          biography: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'cover_image': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          cover_image: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'photo': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          photo: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'display_name': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          display_name: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'first_name': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          first_name: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'last_name': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          last_name: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'site_public': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          site_public: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'email_public': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          email_public: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'email': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          email: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'phone_public': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          phone_public: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'address_public': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          address_public: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    case 'phone': {
      const newUserData = {
        ...userData,
        data: {
          ...userData.data,
          phone: data,
        },
      };

      return setLocalUser(JSON.stringify(newUserData));
    }

    default:
      return null;
  }
};

export function signInLocally({ data, token }) {
  const dateWithExpiration = generateExpirationDate(3, data);
  const parseToken = token?.plainTextToken?.split('|')[1];

  const user = JSON.stringify({ data: dateWithExpiration, token });

  api?.interceptors?.request?.use(async (config) => {
    const param = config;
    param.headers.Authorization = `Bearer ${parseToken}`;

    return config;
  });

  localStorage.setItem('user', user);
  localStorage.setItem('user_id', data?.id);
  localStorage.setItem('token', parseToken);
}

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('cover_image');
  localStorage.removeItem('photo');
};
