import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, Title, Description, Icon } from './styles';

const SuccessMessage = ({ title, msg1, email, msg2 }) => {
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  return (
    <Container $isDarkMode={isDarkMode}>
      <Title>
        <Icon />
        {title}
      </Title>
      <Description>{msg1}:</Description>
      <Description>
        <b>{email}</b>
      </Description>
      <Description>{msg2}</Description>
    </Container>
  );
};

SuccessMessage.propTypes = {
  title: PropTypes.any.isRequired,
  msg1: PropTypes.any.isRequired,
  email: PropTypes.any.isRequired,
  msg2: PropTypes.any.isRequired,
};

export default SuccessMessage;
