import styled from 'styled-components';

export const ContainerConfirm = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);

  .modal {
    width: calc(100% - 30px);
    height: 300px;
    margin: 0 10px;
    max-width: 600px;
    background: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-container {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .modal h1 {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 122%;
    text-align: center;
    letter-spacing: -0.340547px;
    color: #606060;
  }

  .modal .spin {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
`;
