import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 17.5px;
  left: 25px;
  z-index: 1;

  color: #fff;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }

  transition: transform 0.4s;

  &:hover {
    transition: transform 0.4s;
    transform: translateX(-5px);
  }

  @media (max-width: 900px) {
    top: 25px;
  }
`;
