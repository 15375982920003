import { useSelector } from 'react-redux';

export const useFeature = (context) => {
  if (!context) throw new Error('Context most be provided !');

  if (typeof context !== 'string')
    throw new Error('typeof context most be string !');

  const features = useSelector(
    ({ tenancy }) => tenancy.tenantData.enabled_features
  );

  if (!features) return true;

  return features.includes(context);
};

export const typeFeatures = {
  courses: 'f_courses',
  quizzes: 'f_quizzes',
  meets: 'f_meets',
  manualEnrollments: 'f_manual_enrollments',
  mediaSpace: 'f_media_space',
  complementaryContents: 'f_complementary_contents',
  certificates: 'f_certifcates',
  uploadEnsinioVideo: 'f_upload_ensinio_video',
  uploadYoutube: 'f_upload_youtube',
  uploadWistia: 'f_upload_wistia',
  uploadVimeo: 'f_upload_vimeo',
  uploadLoom: 'f_upload_loom',
  community: 'f_community',
  blog: 'f_blog',
  comments: 'f_comments',
  collections: 'f_collections',
  pages: 'f_pages',
  customScripts: 'f_custom_scripts',
  engagementVisualizer: 'f_engagement_visualizer',
  engagementMetrics: 'f_engagement_metrics',
  learningMetrics: 'f_learning_metrics',
  salesMetrics: 'f_sales_metrics',
  shortcutMenu: 'f_shortcut_menu',
  sideMenu: 'f_side_menu',
  emailManagement: 'f_email_management',
  spotlights: 'f_spotlights',
  notifications: 'f_notifications',
  flags: 'f_flags',
  tags: 'f_tags',
  categories: 'f_categories',
  imports: 'f_imports',
  myAccess: 'f_my_access',
  privateAccess: 'f_private_access',
  customDomain: 'f_custom_domain',
  webhooks: 'f_webhooks',
  webhooksUser_events: 'f_webhooks_user_events',
  webhooksOrdersEvents: 'f_webhooks_orders_events',
  webhooksEnrollmentsEvents: 'f_webhooks_enrollments_events',
  webhooksSubscriptionsEvents: 'f_webhooks_subscriptions_events',
  webhooksHistory: 'f_webhooks_history',
  adminPermissions: 'f_admin_permissions',
  globalSearch: 'f_global_search',
  checkout: 'f_checkout',
  statement: 'f_statement',
  paymentPix: 'f_payment_pix',
  paymentCreditCard: 'f_payment_credit_card',
  paymentSlip: 'f_payment_boleto',
  adminWithdraws: 'f_admin_withdraws',
  transactionsVisualizer: 'f_transactions_visualizer',
  ordersVisualizer: 'f_orders_visualizer',
  coupons: 'f_coupons',
  productBundles: 'f_product_bundles',
  planSubscriptions: 'f_plan_subscriptions',
  whiteLabel: 'f_white_label',
  pixel: 'f_pixel',
  pixelFacebook: 'f_pixel_facebook',
  pixelGoogleAds: 'f_pixel_google_ads',
  pixelGoogleAnalytics: 'f_pixel_google_analytics',
  pixelGoogleAnalyticsG4: 'f_pixel_google_analytics_g4',
  checkoutLink: 'f_checkout_link',
  adminRefund: 'f_admin_refund',
  hotmart: 'f_hotmart',
};
