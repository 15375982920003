import {
  GET_ALL_PAY_ORDERS,
  GET_SESSION_PAY_ORDER,
  FETCH_ALL_PAY_ORDERS_SUCCESS,
  FETCH_SESSION_PAY_ORDER_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const getAllAPayOrders = loader => {
  return {
    type: GET_ALL_PAY_ORDERS,
    payload: loader,
  };
};

export const getAllAPayOrdersSuccess = orders => {
  return {
    type: FETCH_ALL_PAY_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const getSessionPayOrder = id => {
  return {
    type: GET_SESSION_PAY_ORDER,
    payload: id,
  };
};

export const getSessionPayOrderSuccess = order => {
  return {
    type: FETCH_SESSION_PAY_ORDER_SUCCESS,
    payload: order,
  };
};
