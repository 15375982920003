import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_PRODUCT, GET_ALL_PRODUCTS } from 'shared/providers/redux/actionTypes';

import api from 'shared/infra/services/tenantAPI';
import { fetchProductSuccess, fetchAllProductsSuccess } from 'shared/providers/redux/actions';

const getProductRequest = async payload => {
  try {
    const response = await api.get(`product/${payload}`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getProduct({ payload }) {
  const productSession = yield call(getProductRequest, payload);
  if (productSession) yield put(fetchProductSuccess(productSession));
}

export function* fetchProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

const getAllProductsRequest = async () => {
  try {
    const response = await api.get(`product`);
    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* getAllProducts({ payload }) {
  const products = yield call(getAllProductsRequest, payload);
  if (products) yield put(fetchAllProductsSuccess(products));
}

export function* fetchAllProducts() {
  yield takeEvery(GET_ALL_PRODUCTS, getAllProducts);
}

export default function* rootSaga() {
  yield all([fork(fetchProduct), fork(fetchAllProducts)]);
}
