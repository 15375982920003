import {
  SET_CREDIT_CARD,
  SET_ID_CREDIT_CARD,
  SET_CREDIT_CARD_ERROR,
  SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD,
  SET_OPEN_MODAL_REGISTER_CREDIT_CARD,
  SET_CURRENT_STEP_REGISTER_CREDIT_CARD,
  SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS,
  GET_ALL_CREDIT_CARDS,
  REMOVE_CREDIT_CARD_SUCCESS,
  FETCH_ALL_CREDIT_CARDS_SUCCESS,
  SET_PAYMENT_OPTIONS_MODAL_OPEN,
  CLEAR_PURCHASE_OPTIONS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  cardsList: [],
  context: '',
  show: false,
  entity: null,
  loader: false,
  handlePurchase: null,
  currentStepModal: 0,
  loaderRegisterCreditCard: false,
  isVisibleFormNewCreditCard: false,
  isVisibleMethodsPaymentsForm: false,
  paymentMethodCardId: null,
  registedCurrentCardId: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CREDIT_CARDS: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case FETCH_ALL_CREDIT_CARDS_SUCCESS: {
      return {
        ...state,
        loader: false,
        cardsList: action.payload,
      };
    }

    case SET_CREDIT_CARD: {
      return {
        ...state,
        loader: true,
        loaderRegisterCreditCard: true,
      };
    }

    case SET_ID_CREDIT_CARD: {
      return {
        ...state,
        registedCurrentCardId: action.payload,
      };
    }

    case SET_CREDIT_CARD_ERROR: {
      return {
        ...state,
        loader: false,
        loaderRegisterCreditCard: false,
      };
    }

    case REMOVE_CREDIT_CARD_SUCCESS: {
      return {
        ...state,
        cardsList: Array.from(action.payload),
      };
    }

    case SET_CURRENT_STEP_REGISTER_CREDIT_CARD: {
      return {
        ...state,
        currentStepModal: action.payload,
      };
    }

    case SET_OPEN_MODAL_REGISTER_CREDIT_CARD: {
      return {
        ...state,
        isVisibleMethodsPaymentsForm: action.payload,
      };
    }

    case SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loaderRegisterCreditCard: false,
        paymentMethodCardId: action.payload,
      };
    }

    case SET_PAYMENT_OPTIONS_MODAL_OPEN: {
      return {
        ...state,
        entity: action.payload.entity,
        context: action.payload.context,
        handlePurchase: action.payload.handlePurchase,
        show: action.payload.show,
      };
    }

    case SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD: {
      return {
        ...state,
        isVisibleFormNewCreditCard: action.payload,
      };
    }

    case CLEAR_PURCHASE_OPTIONS: {
      return {
        ...state,
        entity: null,
        context: '',
        handlePurchase: null,
        show: false,
      };
    }

    default:
      return state;
  }
};
