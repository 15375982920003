import {
  SET_ADDRESS,
  SET_ADDRESS_ERROR,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_SUCCESS_API,
  SET_DEFAULT_ADDRESS,
  SET_ADDRESS_EDITING,
  SHOW_BUTTON_RELOAD,
  GET_ALL_ADDRESS,
  GET_ADDRESS_BRASIL_API,
  FETCH_ALL_ADDRESS_SUCCESS,
  FETCH_ADDRESS_BRASIL_API_SUCCESS,
  UPDATE_ADDRESS_SESSION,
  UPDATE_ADDRESS_SESSION_SUCCESS,
  REMOVE_ADDRESS,
  REMOVE_ADDRESS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const getAllAddress = () => ({
  type: GET_ALL_ADDRESS,
});

export const getAllAddressSuccess = ({ addressList, loader }) => ({
  type: FETCH_ALL_ADDRESS_SUCCESS,
  payload: { addressList, loader },
});

export const getAddressBrazilAPI = (zipcode) => ({
  type: GET_ADDRESS_BRASIL_API,
  payload: zipcode,
});

export const getAddressBrazilAPISuccess = (address) => ({
  type: FETCH_ADDRESS_BRASIL_API_SUCCESS,
  payload: address,
});

export const updateAddressSession = (
  id,
  deliveryAddress,
  navigationType = null,
  paymentType = null
) => ({
  type: UPDATE_ADDRESS_SESSION,
  payload: { id, deliveryAddress, navigationType, paymentType },
});

export const updateAddressSessionSuccess = (session) => ({
  type: UPDATE_ADDRESS_SESSION_SUCCESS,
  payload: session,
});

export const removeAddress = (id) => ({
  type: REMOVE_ADDRESS,
  payload: id,
});

export const removeAddressSuccess = (addressList) => ({
  type: REMOVE_ADDRESS_SUCCESS,
  payload: addressList,
});

export const setAddress = ({
  form,
  city,
  state,
  street,
  number,
  country,
  zipcode,
  complement,
  neighborhood,
  navigationType,
  creditCard = null,
  paymentType = null,
}) => ({
  type: SET_ADDRESS,
  payload: {
    form,
    city,
    state,
    street,
    number,
    country,
    zipcode,
    creditCard,
    complement,
    paymentType,
    neighborhood,
    navigationType,
  },
});

export const setAddressSuccess = (addressList) => ({
  type: SET_ADDRESS_SUCCESS,
  payload: addressList,
});

export const setAddressError = () => ({
  type: SET_ADDRESS_ERROR,
});

export const setAddressSuccessApi = (addressList) => ({
  type: SET_ADDRESS_SUCCESS_API,
  payload: addressList,
});

export const setDefaultAddress = (id) => ({
  type: SET_DEFAULT_ADDRESS,
  payload: id,
});

export const setAddressEditing = (isEditing) => ({
  type: SET_ADDRESS_EDITING,
  payload: isEditing,
});

export const showButtonReload = (isEditing) => ({
  type: SHOW_BUTTON_RELOAD,
  payload: isEditing,
});
