import styled, { css } from 'styled-components';
import { Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

export const Container = styled.div`
  cursor: default;

  .container-loader {
    padding: 10px 15px;
  }

  @media (min-width: 700px) {
    .gx-popover-scroll {
      height: 82vh !important;
      width: 500px !important;
    }
  }

  .gx-sub-popover {
    margin: 0;
  }

  .ant-skeleton.ant-skeleton-with-avatar.ant-skeleton-active {
    margin-bottom: 20px;
  }

  .gx-popover-header {
    padding: 8px 16px 12px 16px;
  }
`;

export const IconSettingOutlined = styled(SettingOutlined)`
  font-size: 20px;
`;

export const ContainerEmptyData = styled.div`
  @media (min-width: 700px) {
    height: 73vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuAnt = styled(Menu)`
  ${props =>
    props.theme === 'dark'
      ? css`
          background: #303030;

          li {
            background: #303030;
          }

          button,
          i {
            background: #303030 !important;
            color: #ffffff;
          }
        `
      : css`
          background: #ffffff;
        `}
`;
