import {
  FETCH_MODULES_SESSION_SUCCESS,
  FETCH_MODULES_SUCCESS,
  GET_MODULES,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  modulesList: null,
  moduleSession: null,
  params: {},
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODULES: {
      return {
        ...state,
        loader: true,
        params: action.payload,
      };
    }

    case FETCH_MODULES_SUCCESS: {
      return {
        ...state,
        loader: false,
        modulesList: action.payload,
      };
    }

    case FETCH_MODULES_SESSION_SUCCESS: {
      return {
        ...state,
        moduleSession: action.payload,
      };
    }

    default:
      return state;
  }
};
