import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DefaultModal from 'shared/components/templates/DefaultModal';
import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import ObjectIntlTranslator from 'shared/components/atoms/ObjectIntlTranslator';
import empty from 'shared/assets/images/mock_empty.svg';
import locked from 'shared/assets/images/locked.svg';
import { showEnrolledUsersPermission } from 'shared/providers/redux/actions';

import {
  ContainerImage,
  ContainerModal,
  LockedIcon,
  Header,
  Container,
} from './styles';

export default function OnlyEnrolledUsersPermission() {
  const dispatch = useDispatch();

  const {
    reasonsCourses: requiredCourses,
    reasonsProductBundles: requiredBundles,
    contextType,
    reasonsAnyDescription,
    showEnrolledUsersPermissionModal,
  } = useSelector(({ community }) => community);

  const [showModalVisibility, setShowModalVisibility] = useState(
    showEnrolledUsersPermissionModal
  );

  const { mode } = useSelector(({ settings }) => settings);

  const handleNavigationType = (type, slug) => {
    switch (type) {
      case 'App\\MediaSpaceContent':
        return window.open(`/media/${slug}`);

      case 'Vico068\\CourseTools\\Course':
        return window.open(`/courses/${slug}`);

      case 'Vico068\\CourseTools\\Lesson':
        return window.open(`/courses/${slug}`);

      default:
        return null;
    }
  };

  const handleInfoType = (type) => {
    switch (type) {
      case 'App\\MediaSpaceContent':
        return 'Media space';

      case 'Vico068\\CourseTools\\Course':
        return <StaticIntlTranslator id="en.title.course" />;

      case 'Vico068\\CourseTools\\Lesson':
        return <StaticIntlTranslator id="en.title.course" />;

      default:
        return null;
    }
  };

  const handleHeaderTitleType = () => {
    switch (contextType) {
      case 'community':
        return (
          <h1>
            <StaticIntlTranslator id="info.view.content" />
          </h1>
        );

      case 'communityTopic':
        return (
          <h1>
            <StaticIntlTranslator id="info.forum.modalTitle" />
          </h1>
        );

      case 'comment':
        return (
          <h1>
            <StaticIntlTranslator id="info.view.comments" />
          </h1>
        );

      case 'commentEditor':
        return (
          <h1>
            <StaticIntlTranslator id="info.write.comments" />
          </h1>
        );

      default:
        return null;
    }
  };

  const handleOnClose = () => {
    setShowModalVisibility(false);
    dispatch(
      showEnrolledUsersPermission({
        reasonsCourses: [],
        reasonsProductBundles: [],
        contextType: '',
        showEnrolledUsersPermissionModal: false,
      })
    );
  };

  useEffect(() => {
    setShowModalVisibility(!!showEnrolledUsersPermissionModal);
  }, [showEnrolledUsersPermissionModal]);

  return (
    <>
      {showModalVisibility && (
        <DefaultModal onClose={handleOnClose}>
          <Container theme={mode}>
            <LockedIcon src={locked} alt="" />

            <Header>
              {handleHeaderTitleType()}

              <p>
                <StaticIntlTranslator id="info.permission.comments" />:
              </p>
            </Header>

            {/* Private treatments for the community */}
            {requiredCourses?.length > 0 &&
              requiredCourses?.map((course) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() => window.open(`/courses/${course.slug}`)}
                  key={course.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <img
                          src={
                            course?.thumbnail ||
                            course?.image_thumbnail ||
                            empty
                          }
                          alt=""
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>
                      <StaticIntlTranslator id="en.title.course" />:{' '}
                    </strong>
                    <i>{course?.title}</i>
                  </div>
                </ContainerModal>
              ))}

            {/* Private treatments for the community */}
            {requiredBundles?.length > 0 &&
              requiredBundles?.map((bundle) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() => window.open(`/bundles/${bundle.slug}`)}
                  key={bundle.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <img
                          src={
                            bundle?.thumbnail ||
                            bundle?.image_thumbnail ||
                            empty
                          }
                          alt=""
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>
                      <StaticIntlTranslator id="en.title.bundle" />:{' '}
                    </strong>
                    <i>{bundle?.title}</i>
                  </div>
                </ContainerModal>
              ))}

            {reasonsAnyDescription?.length > 0 &&
              reasonsAnyDescription?.map((entity) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() =>
                    handleNavigationType(entity.className, entity.slug)
                  }
                  key={entity.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <img
                          src={
                            entity?.thumbnail ||
                            entity?.image_thumbnail ||
                            empty
                          }
                          alt=""
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>{handleInfoType(entity?.className)}: </strong>
                    <i>
                      <ObjectIntlTranslator variable={entity?.title} />
                    </i>
                  </div>
                </ContainerModal>
              ))}
          </Container>
        </DefaultModal>
      )}
    </>
  );
}
