/* eslint-disable import/order */
import React from 'react';
import { all, put, call, fork, select, takeEvery } from 'redux-saga/effects';
import { createIntl } from 'react-intl';
import AppLocale from 'shared/providers/i18n';

import {
  setCurrentStep,
  setUserSetting,
  setIdCreditCard,
  setCreditCardError,
  setCreditCardSuccess,
  removeFieldUserSetting,
  removeCreditCardSuccess,
  getAllCreditCardsSuccess,
  setOpenModalForRegisterCreditCard,
  setCreditCardOnSubscriptionOrAccountSuccess,
} from 'shared/providers/redux/actions';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import api from 'shared/infra/services/tenantAPI';

import {
  GET_ALL_CREDIT_CARDS,
  REMOVE_CREDIT_CARD,
  SET_CREDIT_CARD,
} from 'shared/providers/redux/actionTypes';

const setCreditCardRequest = async (payload) => {
  const {
    number,
    name,
    expiry,
    cvc,
    phone,
    document,
    address_id,
    type_entity,
    document_type,
  } = payload;

  try {
    const response = await api.post('pay/card', {
      number,
      document,
      address_id,
      type_entity,
      cvv: cvc,
      holder_name: name,
      expiration_date: expiry,
      type: document_type,
      phone_number: phone,
    });
    return response.data;
  } catch (error) {
    const { data } = error.response;
    const { message, errors } = data;

    if (errors) {
      sessionStorage.setItem(
        'payment_error',
        JSON.stringify(Object.values(errors))
      );
    } else {
      sessionStorage.setItem('payment_error', JSON.stringify(message));
    }

    console.log('error -->', message, errors);
  }
  return null;
};

const removeCreditCardRequest = async (payload) => {
  try {
    await api.delete(`pay/card/${payload}`);
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getAllCreditCardsRequest = async () => {
  try {
    const response = await api.get('pay/card');
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* setCreditCard({ payload }) {
  const { settings, checkout, methodsPayment } = yield select();
  const { locale } = settings;
  const { currentStep } = checkout;
  const { cardsList } = methodsPayment;

  const currentAppLocale = AppLocale[locale.locale];
  const intl = createIntl({
    locale: locale.locale,
    messages: currentAppLocale.messages,
  });

  const { navigation_type, document, document_type, type_entity } = payload;
  const card = yield call(setCreditCardRequest, payload);

  if (!card) {
    const paymentError = JSON.parse(sessionStorage.getItem('payment_error'));
    const contentError = (
      <>
        <p>
          {intl.formatMessage({
            id: 'eCommerce.check.your.information.credit.card.register',
          })}
        </p>
        <ul>
          {Array.isArray(paymentError) ? (
            paymentError.map((error) => (
              <li key={error} style={{ fontWeight: 500 }}>
                {error}
              </li>
            ))
          ) : (
            <li style={{ fontWeight: 500 }}>{paymentError}</li>
          )}
        </ul>
      </>
    );

    yield put(setCreditCardError());
    openNotificationWithIcon(
      'error',
      intl.formatMessage({ id: 'eCommerce.card.not.registered' }),
      contentError,
      8
    );
  }

  if (card?.attrs) {
    if (card.attrs?.valid) {
      cardsList.push(card.attrs);
      yield put(setCreditCardSuccess(cardsList));
      yield put(setIdCreditCard(card.attrs?.id));

      if (navigation_type === 'checkout') {
        yield put(
          setUserSetting(
            ['card_id', 'document', 'document_type', 'type_entity'],
            [card.attrs?.id, document, document_type, type_entity],
            ['payment', 'payment', 'payment', 'payment']
          )
        );

        yield put(setCurrentStep(currentStep + 1));
      } else {
        yield put(setOpenModalForRegisterCreditCard(false));
        yield put(setCreditCardOnSubscriptionOrAccountSuccess(card.attrs?.id));
      }
    } else {
      yield put(setCreditCardError());
      openNotificationWithIcon(
        'error',
        intl.formatMessage({ id: 'eCommerce.invalid.card' }),
        `${intl.formatMessage({
          id: 'appRedux.sagas.methodsPayment.creditCardError',
        })}`,
        6
      );
    }
  }
}

function* removeCreditCard({ payload }) {
  const { methodsPayment, userSetting } = yield select();
  const { settings } = userSetting;
  const { payment } = settings;
  const { card_id } = payment;

  if (payload === Number(card_id))
    yield put(removeFieldUserSetting(['card_id'], ['payment']));

  const cardsList = methodsPayment.cardsList.filter(
    (card) => card.id !== payload
  );
  yield put(removeCreditCardSuccess(cardsList));
  yield call(removeCreditCardRequest, payload);
}

function* getAllCreditCards({ payload }) {
  const cards = yield call(getAllCreditCardsRequest, payload);
  if (cards?.attrs) yield put(getAllCreditCardsSuccess(cards.attrs));
}

export function* onSetCreditCard() {
  yield takeEvery(SET_CREDIT_CARD, setCreditCard);
}

export function* onRemoveCreditCard() {
  yield takeEvery(REMOVE_CREDIT_CARD, removeCreditCard);
}

export function* onGetCreditCards() {
  yield takeEvery(GET_ALL_CREDIT_CARDS, getAllCreditCards);
}

export default function* rootSaga() {
  yield all([
    fork(onSetCreditCard),
    fork(onGetCreditCards),
    fork(onRemoveCreditCard),
  ]);
}
