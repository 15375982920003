import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSidebarIsCollapsed,
  sidebarIsRendered as sidebarIsRenderedAction,
} from 'shared/providers/redux/actions';

import { typeFeatures, useFeature } from 'shared/utils/hooks/useFeature';
import SidebarContent from './SidebarContent/index';
import { Sider } from './styles';
import { AdvancedMode, AutoMode, DarkMode, LightMode } from './Themes';

const Sidebar = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const tenantTheme = useSelector(
    ({ tenancy }) => tenancy?.tenantData?.settings
  );
  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');
  const { sidebarIsRendered } = useSelector(({ sidebar }) => sidebar);
  const { navStyle } = useSelector(({ settings }) => settings);
  const isLessonPage = useSelector(({ lessons }) => lessons?.isLessonPage);
  const sidebarIsCollapsed = useSelector(
    ({ sidebar }) => sidebar.sidebarIsCollapsed
  );

  const sideBarTheme = isDarkMode
    ? 'dark'
    : tenantTheme['general-theme']?.themeSideBar;
  const themeMainMenu = tenantTheme['general-theme']?.themeMainMenu;
  const themeTopBar = tenantTheme['general-theme']?.themeTopBar;
  const isSideMenuEnabled = useFeature(typeFeatures.sideMenu);

  const sidebarIsCollapsedRef = useRef(sidebarIsCollapsed);

  const SidebarTheme = useCallback(() => {
    switch (sideBarTheme) {
      case 'dark':
        return (
          <DarkMode>
            <SidebarContent />
          </DarkMode>
        );
      case 'automatic':
        return (
          <AutoMode tenantTheme={tenantTheme} themeTopBar={themeTopBar}>
            <SidebarContent />
          </AutoMode>
        );
      case 'advanced':
        return (
          <AdvancedMode tenantTheme={tenantTheme}>
            <SidebarContent />
          </AdvancedMode>
        );
      case 'light':
        return (
          <LightMode tenantTheme={tenantTheme}>
            <SidebarContent />
          </LightMode>
        );
      default:
        return <SidebarContent />;
    }
  });

  const handleClickOutside = (event) => {
    const element = event.target;
    const IconSidebar = 'gx-icon-btn icon icon-menu-unfold';
    if (ref.current && !ref.current.contains(event.target)) {
      const className = element?.className;

      if (typeof className === 'string' && !className?.includes(IconSidebar)) {
        if (!sidebarIsCollapsedRef.current) {
          dispatch(setSidebarIsCollapsed());
        }
      }
    }
  };

  useEffect(() => {
    if (
      themeMainMenu === 'on_top_bar' ||
      location.pathname.slice(0, 8) === '/payment' ||
      location.pathname === '/authentication' ||
      !navStyle
    ) {
      dispatch(sidebarIsRenderedAction(false));
    } else {
      dispatch(sidebarIsRenderedAction(true));
      if (!sidebarIsCollapsed) {
        dispatch(setSidebarIsCollapsed());
      }
    }
  }, [location.pathname, navStyle]);

  useEffect(() => {
    sidebarIsCollapsedRef.current = sidebarIsCollapsed;
  }, [sidebarIsCollapsed]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  if (!isSideMenuEnabled) return <></>;

  return (
    <>
      {((isLessonPage && !sidebarIsCollapsed) ||
        (!isLessonPage && sidebarIsRendered)) && (
        <Sider
          className="gx-app-sidebar gx-collapsed-sidebar"
          trigger={null}
          collapsed={sidebarIsCollapsed}
          collapsible
          ref={ref}
        >
          <SidebarTheme />
        </Sider>
      )}
    </>
  );
};

export default memo(Sidebar);
