import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import Route from 'shared/components/organisms/Route';

import { getMode } from 'shared/infra/services/tenantAPI';

import AppLoader from 'shared/components/atoms/AppLoader';
import { GlobalDarkTheme } from 'shared/styles/GlobalDarkTheme';
import { Container, GlobalStyles } from './styles';

const App = ({ match }) => {
  const stylesContentCourse = useSelector(({ custom }) => custom.styles);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const tenantData = useSelector(({ tenancy }) => tenancy.tenantData);
  const loggedUser = useSelector(({ user }) => user.loggedUser);
  const pageAccess = useSelector(({ page }) => page.pageData?.page_access);

  const generalTheme = tenantData?.settings['general-theme'];
  const advancedTheme = tenantData?.settings['advanced-theme'];
  const themeTopBar = tenantData?.settings['general-theme']?.themeTopBar;

  const { coursesAreaAccess, courseActive } = tenantData?.settings?.courses;
  const { blogPageAccess, blogActive } = tenantData?.settings?.blog;
  const { bundleAreaAccess, bundleActive } = tenantData?.settings?.bundles;
  const { colletionsActive } = tenantData?.settings?.collections;

  const dashboardAccess = tenantData?.settings?.dashboard?.dashboardAccess;
  const { communityAreaAccess, communityActive, socialNetworkAreaAccess } =
    tenantData?.settings['social-network'];
  const { mediaSpaceAreaAccess, mediaSpaceActive } =
    tenantData?.settings['media-space'];

  const isNotProduction = getMode();

  return (
    <Container
      themeTopBar={themeTopBar}
      className="gx-main-content-wrapper"
      stylesContentCourse={stylesContentCourse}
    >
      <GlobalStyles
        $generalTheme={generalTheme}
        $advancedTheme={advancedTheme}
        $isDarkMode={isDarkMode}
      />

      {isDarkMode && (
        <GlobalDarkTheme
          tenantData={tenantData}
          generalTheme={generalTheme}
          isDarkMode={isDarkMode}
        />
      )}

      <Suspense fallback={<AppLoader />}>
        <Switch>
          <Route
            isPrivate={socialNetworkAreaAccess}
            exact
            path={`${match.url}user/:user`}
            component={lazy(() => import('modules/User/routes/UserProfile'))}
          />

          <Route
            exact
            path={`${match.url}courses`}
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            component={lazy(() => import('modules/Course/routes/CourseList'))}
          />

          {isNotProduction && !loggedUser && (
            <Route
              exact
              path={`${match.url}courses/watch-now`}
              component={lazy(() => import('modules/Course/routes/WatchNow'))}
            />
          )}

          <Route
            exact
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            path={`${match.url}courses/category/:category_slug`}
            component={lazy(() =>
              import('modules/Course/routes/CourseCategories')
            )}
          />

          <Route
            exact
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            path={`${match.url}courses/content/:slug_course`}
            component={lazy(() => import('modules/Course/routes/SingleCourse'))}
          />

          <Route
            exact
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            path={`${match.url}courses/:slug_course`}
            component={lazy(() => import('modules/Course/routes/SingleCourse'))}
          />

          <Route
            exact
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            path={`${match.url}courses/:slug_course/:commentId`}
            component={lazy(() => import('modules/Course/routes/SingleCourse'))}
          />

          {isNotProduction && (
            <Route
              exact
              path={`${match.url}courses/:slug_course/meeting/:slug_lesson`}
              component={lazy(() => import('modules/Course/routes/Meeting'))}
            />
          )}

          <Route
            exact
            disabled={courseActive}
            isPrivate={coursesAreaAccess || false}
            path={`${match.url}courses/:slug_course/:slug_module/:slug_lesson`}
            component={lazy(() => import('modules/Course/routes/Lesson'))}
          />

          <Route
            exact
            path={`${match.url}courses/:slug_course/:slug_module/:slug_lesson/:commentId`}
            component={lazy(() => import('modules/Course/routes/Lesson'))}
          />

          <Route
            isPrivate={dashboardAccess}
            exact
            path={`${match.url}dashboard`}
            component={lazy(() => import('modules/Dashboard/routes/Dashboard'))}
          />

          <Route
            exact
            path={`${match.url}categories`}
            component={lazy(() =>
              import('modules/Category/routes/CategoryList')
            )}
          />

          <Route
            exact
            path={`${match.url}categories/:slug_category`}
            component={lazy(() =>
              import('modules/Category/routes/SingleCategory')
            )}
          />

          <Route
            isPrivate={communityAreaAccess}
            disabled={communityActive}
            exact
            path={`${match.url}social`}
            component={lazy(() =>
              import('modules/Community/routes/CommunityList')
            )}
          />

          <Route
            isPrivate={communityAreaAccess}
            disabled={communityActive}
            exact
            path={`${match.url}social/:category`}
            component={lazy(() =>
              import('modules/Community/routes/CommunityTopicList')
            )}
          />

          <Route
            isPrivate={communityAreaAccess}
            disabled={communityActive}
            exact
            path={`${match.url}social/:category/:topic`}
            component={lazy(() =>
              import('modules/Community/routes/SingleTopic')
            )}
          />

          <Route
            isPrivate={communityAreaAccess}
            disabled={communityActive}
            exact
            path={`${match.url}social/:category/:topic/:commentId`}
            component={lazy(() =>
              import('modules/Community/routes/SingleTopic')
            )}
          />

          <Route
            exact
            isPrivate
            path={`${match.url}payment`}
            component={lazy(() =>
              import('modules/Checkout/routes/CheckoutWrapper')
            )}
          />

          <Route
            exact
            isPrivate
            path={`${match.url}payment/:id`}
            component={lazy(() =>
              import('modules/Checkout/routes/CheckoutWrapper')
            )}
          />

          <Route
            isPrivate
            exact
            disabled={colletionsActive}
            path={`${match.url}collections`}
            component={lazy(() =>
              import('modules/Collection/routes/CollectionList')
            )}
          />

          <Route
            exact
            disabled={colletionsActive}
            path={`${match.url}collections/:slug`}
            component={lazy(() =>
              import('modules/Collection/routes/SingleCollection')
            )}
          />

          <Route
            exact
            disabled={colletionsActive}
            path={`${match.url}collections/:id/:slug`}
            component={lazy(() =>
              import('modules/Collection/routes/SingleCollection')
            )}
          />

          <Route
            isPrivate
            exact
            path={`${match.url}orders`}
            component={lazy(() => import('modules/Order/routes/MyOrders'))}
          />

          <Route
            isPrivate
            exact
            path={`${match.url}orders/:id`}
            component={lazy(() => import('modules/Order/routes/SingleOrder'))}
          />

          <Route
            isPrivate
            path={`${match.url}account`}
            component={lazy(() => import('modules/User/routes/Account'))}
          />

          <Route
            isPrivate={pageAccess || false}
            path={`${match.url}pages/:page`}
            component={lazy(() =>
              import('modules/CustomPage/routes/CustomPage')
            )}
          />

          <Route
            disabled={blogActive}
            isPrivate={blogPageAccess || false}
            exact
            path={`${match.url}blog`}
            component={lazy(() => import('modules/Blog/routes/BlogTopicList'))}
          />

          <Route
            disabled={blogActive}
            isPrivate={blogPageAccess || false}
            exact
            path={`${match.url}blog?page=:page`}
            component={lazy(() => import('modules/Blog/routes/BlogTopicList'))}
          />

          <Route
            disabled={blogActive}
            isPrivate={blogPageAccess || false}
            exact
            path={`${match.url}blog/:magica`}
            component={lazy(() =>
              import('modules/Blog/routes/SingleBlogTopic')
            )}
          />

          <Route
            disabled={blogActive}
            isPrivate={blogPageAccess || false}
            exact
            path={`${match.url}blog/:magica/:commentId`}
            component={lazy(() =>
              import('modules/Blog/routes/SingleBlogTopic')
            )}
          />

          <Route
            exact
            path={`${match.url}authentication`}
            component={lazy(() =>
              import('modules/Checkout/routes/Authentication')
            )}
          />

          <Route
            exact
            path={`${match.url}resetpassword`}
            component={lazy(() =>
              import(
                'shared/components/organisms/AuthModal/screens/ForgotPassword'
              )
            )}
          />

          <Route
            exact
            isPrivate={mediaSpaceAreaAccess}
            disabled={mediaSpaceActive}
            path={`${match.url}media`}
            component={lazy(() =>
              import('modules/MediaSpace/routes/MediaSpaceContentList')
            )}
          />

          <Route
            exact
            isPrivate={mediaSpaceAreaAccess}
            disabled={mediaSpaceActive}
            path={`${match.url}media/:content_slug`}
            component={lazy(() =>
              import('modules/MediaSpace/routes/SingleContent')
            )}
          />

          <Route
            exact
            isPrivate={mediaSpaceAreaAccess}
            disabled={mediaSpaceActive}
            path={`${match.url}media/category/:category_slug`}
            component={lazy(() =>
              import('modules/MediaSpace/routes/MediaSpaceCategories')
            )}
          />

          <Route
            exact
            isPrivate={mediaSpaceAreaAccess}
            disabled={mediaSpaceActive}
            path={`${match.url}media/:content_slug/:commentId`}
            component={lazy(() =>
              import('modules/MediaSpace/routes/SingleContent')
            )}
          />

          <Route
            exact
            path={`${match.url}plans`}
            component={lazy(() => import('modules/Plan/routes/PlanList'))}
          />

          <Route
            exact
            path={`${match.url}plans/:plan_slug`}
            component={lazy(() => import('modules/Plan/routes/SinglePlan'))}
          />

          <Route
            exact
            isPrivate={bundleAreaAccess}
            disabled={bundleActive}
            path={`${match.url}bundles`}
            component={lazy(() => import('modules/Bundle/routes/BundleList'))}
          />

          <Route
            exact
            isPrivate={bundleAreaAccess}
            disabled={bundleActive}
            path={`${match.url}bundles/:bundle_slug`}
            component={lazy(() => import('modules/Bundle/routes/SingleBundle'))}
          />

          <Route
            isPrivate
            path={`${match.url}subscriptions`}
            exact
            component={lazy(() =>
              import('modules/Subscription/routes/SubscriptionList')
            )}
          />

          <Route
            isPrivate
            path={`${match.url}subscriptions/:id`}
            exact
            component={lazy(() =>
              import('modules/Subscription/routes/SingleSubscription')
            )}
          />

          <Route
            path={`${match.url}500`}
            component={lazy(() =>
              import('shared/components/organisms/WarningPages/500')
            )}
          />

          <Route
            path={`${match.url}404`}
            component={lazy(() =>
              import('shared/components/organisms/WarningPages/404')
            )}
          />

          <Route
            path={`${match.url}401`}
            component={lazy(() =>
              import('modules/Auth/routes/InvalidTokenWarning')
            )}
          />

          <Route
            exact
            path={`${match.url}auth-token/:token`}
            component={lazy(() =>
              import('modules/Auth/routes/DirectTokenAuth')
            )}
          />

          <Route
            isPrivate
            exact
            path={`${match.url}usersCommunity`}
            component={lazy(() => import('modules/User/routes/UsersCommunity'))}
          />

          <Route
            exact
            path={`${match.url}search`}
            component={lazy(() =>
              import('modules/GlobalSearch/routes/SearchResults')
            )}
          />

          <Route
            exact
            path={`${match.url}certificate/:uuid`}
            component={lazy(() =>
              import('modules/Course/routes/ValidateCertificate')
            )}
          />

          <Route
            exact
            isPrivate
            path={`${match.url}myAccess`}
            component={lazy(() =>
              import('modules/MyAccess/routes/MyAccessList')
            )}
          />

          <Route
            exact
            isPrivate
            path={`${match.url}myAccess/:contentType/:slug/:id`}
            component={lazy(() =>
              import('modules/MyAccess/routes/SingleAccess')
            )}
          />

          <Route
            component={lazy(() =>
              import('shared/components/organisms/NotFound')
            )}
          />
        </Switch>
      </Suspense>
    </Container>
  );
};

App.propTypes = {
  match: PropTypes.object.isRequired,
};

export default App;
