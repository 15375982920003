import {
  GET_COMMUNITY_CATEGORIES,
  GET_COMMUNITY_TOPICS,
  GET_COMMUNITY_AUTHORS,
  FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS,
  FETCH_COMMUNITY_LAST_TOPICS_SUCCESS,
  FETCH_COMMUNITY_CATEGORIES_SUCCESS,
  FETCH_COMMUNITY_TOPICS_SUCCESS,
  FETCH_COMMUNITY_AUTHORS_SUCCESS,
  SET_COMMUNITY_TOPIC,
  SET_COMMUNITY_TOPIC_SUCCESS,
  READ_COMMUNITY_TOPIC,
  READ_COMMUNITY_TOPIC_SUCCESS,
  UPDATE_COMMUNITY_TOPIC,
  UPDATE_COMMUNITY_TOPIC_SUCCESS,
  DELETE_COMMUNITY_TOPIC,
  DELETE_COMMUNITY_TOPIC_SUCCESS,
  LIKE_DISLIKE_COMMUNITY_TOPIC,
  LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS,
  SHOW_ENROLLED_USERS_PERMISSION,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  loader: false,
  loaderLike: false,
  loaderAuthors: false,
  deleted: '',
  update: '',
  authorsList: [],
  communitiesList: [],
  fixedTopics: [],
  communityTopics: null,
  currentCommunity: null,
  communityPages: null,
  communityTopic: {},
  lastTopics: [],
  lastComments: [],
  reasonsCourses: [],
  reasonsProductBundles: [],
  contextType: '',
  showEnrolledUsersPermissionModal: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;

    case GET_COMMUNITY_CATEGORIES: {
      return {
        ...state,
        loader: true,
        deleted: '',
        update: '',
      };
    }

    case FETCH_COMMUNITY_CATEGORIES_SUCCESS: {
      return {
        ...state,
        communitiesList: action.payload,
        loader: false,
      };
    }

    case GET_COMMUNITY_TOPICS: {
      return {
        ...state,
        loader: true,
        deleted: '',
        update: '',
      };
    }

    case GET_COMMUNITY_AUTHORS: {
      return {
        ...state,
        loaderAuthors: true,
      };
    }

    case FETCH_COMMUNITY_TOPICS_SUCCESS: {
      return {
        ...state,
        fixedTopics: action.payload.fixed,
        communityTopics: action.payload.topics,
        currentCommunity: action.payload.forum,
        communityPages: action.payload.pages,
        loader: false,
        deleted: '',
      };
    }

    case FETCH_COMMUNITY_LAST_TOPICS_SUCCESS: {
      return {
        ...state,
        lastTopics: action.payload,
      };
    }

    case FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS: {
      return {
        ...state,
        lastComments: action.payload,
      };
    }

    case FETCH_COMMUNITY_AUTHORS_SUCCESS: {
      return {
        ...state,
        loaderAuthors: false,
        authorsList: action.payload,
      };
    }

    case SET_COMMUNITY_TOPIC: {
      return {
        ...state,
        loader: true,
        deleted: '',
        update: '',
      };
    }

    case SET_COMMUNITY_TOPIC_SUCCESS: {
      return {
        ...state,
        communityTopics: action.payload.topics,
        communityFixedTopics: action.payload.fixed,
        currentCommunity: action.payload.forum,
        communityPages: action.payload.pages,
        loader: false,
      };
    }

    case READ_COMMUNITY_TOPIC: {
      return {
        ...state,
        loader: true,
        deleted: '',
        update: '',
      };
    }

    case READ_COMMUNITY_TOPIC_SUCCESS: {
      return {
        ...state,
        communityTopic: action.payload.topic,
        loader: false,
      };
    }

    case UPDATE_COMMUNITY_TOPIC: {
      return {
        ...state,
        loader: true,
        deleted: '',
      };
    }

    case UPDATE_COMMUNITY_TOPIC_SUCCESS: {
      return {
        ...state,
        update: action.payload.slug,
        loader: false,
      };
    }

    case DELETE_COMMUNITY_TOPIC: {
      return {
        ...state,
        loader: true,
        update: '',
      };
    }

    case DELETE_COMMUNITY_TOPIC_SUCCESS: {
      return {
        ...state,
        deleted: action.payload.status,
        communityTopics: {
          ...state.communityTopics,
          topics: state.communityTopics?.topics?.filter(
            (topic) => topic?.slug !== action.payload.deletedTopicSlug
          ),
        },
        loader: false,
      };
    }

    case LIKE_DISLIKE_COMMUNITY_TOPIC: {
      return {
        ...state,
        loaderLike: true,
      };
    }

    case LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS: {
      return {
        ...state,
        loaderLike: false,
        communityTopic: action.payload.topic,
      };
    }

    case SHOW_ENROLLED_USERS_PERMISSION: {
      return {
        ...state,
        reasonsCourses: action.payload?.reasonsCourses,
        reasonsProductBundles: action.payload?.reasonsProductBundles,
        reasonsAnyDescription: action.payload?.reasonsAnyDescription,
        contextType: action.payload?.contextType,
        showEnrolledUsersPermissionModal:
          !state.showEnrolledUsersPermissionModal,
      };
    }
  }
};
