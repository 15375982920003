import {
  ON_SEARCH_GLOBALLY,
  ON_SEARCH_GLOBALLY_SUCCESS,
  SET_MODEL_CLASS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  loader: false,
  searchText: '',
  results: [],
  modelClass: null,
  searchMeta: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_SEARCH_GLOBALLY: {
      return { ...state, searchText: action.payload.search, loader: true };
    }

    case ON_SEARCH_GLOBALLY_SUCCESS: {
      return {
        ...state,
        results: action.payload.results?.data?.attrs,
        searchMeta: action.payload.results?.config,
        loader: false,
      };
    }

    case SET_MODEL_CLASS: {
      return {
        ...state,
        modelClass: action.payload.model,
      };
    }

    default:
      return state;
  }
};
