import {
  SWITCH_LANGUAGE,
  TAB_ACTIVE,
  TOGGLE_COLLAPSED_NAV,
  VISUALIZATION_MODE,
  WINDOW_WIDTH,
  SET_THEME_MODE,
  SET_SEARCH_MODE,
  NAV_STYLE,
  NAV_STYLE_MINI_SIDEBAR,
} from 'shared/providers/redux/actionTypes';

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_MINI_SIDEBAR,
  mode: 'light',

  pathname: '/',
  width: window.innerWidth,
  locale: {
    languageId: 'portuguese',
    locale: 'br',
    name: 'Português',
  },
  activeTab: '1',
  visualizationMode: 'grid',
  showModalNotes: false,
  searchMode: 'courses',
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };

    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };

    case SET_THEME_MODE:
      return {
        ...state,
        mode: action.mode,
      };

    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };

    case SWITCH_LANGUAGE:
      sessionStorage.setItem('lang', action?.payload?.locale);

      return {
        ...state,
        locale: action.payload,
      };

    case TAB_ACTIVE:
      return {
        ...state,
        activeTab: action.payload,
      };

    case VISUALIZATION_MODE:
      return {
        ...state,
        visualizationMode: action.payload,
      };

    case SET_SEARCH_MODE:
      return {
        ...state,
        searchMode: action?.payload?.mode,
      };

    default:
      return state;
  }
};

export default settings;
