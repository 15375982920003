/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const RenderStringHtml = ({ value, style, className }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      style={{ ...style }}
      className={className}
    />
  );
};

RenderStringHtml.defaultProps = {
  value: '',
  className: '',
  style: {},
};

RenderStringHtml.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

export default RenderStringHtml;
