import { GET_COURSES_KEEP_WATCHING_SUCCESS } from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  coursesKeepWatching: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSES_KEEP_WATCHING_SUCCESS: {
      return {
        ...state,
        coursesKeepWatching: action.payload,
      };
    }
    default:
      return state;
  }
};
