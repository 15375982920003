import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import api from 'shared/infra/services/tenantAPI';
import {
  filterBundleBySuccess,
  getBundleCoursesSuccess,
  getBundleMediaContentsSuccess,
  getSingleBundleSuccess,
  loadMoreBundlesSuccess,
  onGetBundlesSuccess,
} from 'shared/providers/redux/actions';
import {
  FILTER_BUNDLE_BY,
  GET_BUNDLES,
  GET_BUNDLE_COURSES,
  GET_BUNDLE_MEDIA_CONTENTS,
  GET_SINGLE_BUNDLE,
  LOAD_MORE_BUNDLES,
} from 'shared/providers/redux/actionTypes';

const onGetBundlesRequest = async ({ payload }) => {
  let params = {};

  switch (payload.filterBy) {
    case 'featured':
      params = {
        'where[0][index]': 'featured',
        'where[0][condition]': 'like',
        'where[0][value]': '1',
        'order[0][index]': 'position',
        'order[0][value]': 'asc',
      };
      break;

    default:
      params = {
        page: payload.page,
        'where[0][index]': 'status',
        'where[0][condition]': 'like',
        'where[0][value]': 'published',
        'order[0][index]': 'position',
        'order[0][value]': 'desc',
      };
      break;
  }

  try {
    const response = await api.get('product-bundle', { params });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetBundles(payload) {
  const bundles = yield call(onGetBundlesRequest, payload);
  if (bundles)
    yield put(onGetBundlesSuccess(bundles, payload.payload.filterBy));
}

export function* fetchBundles() {
  yield takeEvery(GET_BUNDLES, onGetBundles);
}

const onGetSingleBundleRequest = async (payload) => {
  try {
    const response = await api.get(`product-bundle/${payload}`);

    return response.data.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetSingleBundle({ payload }) {
  const bundle = yield call(onGetSingleBundleRequest, payload);
  if (bundle) yield put(getSingleBundleSuccess(bundle));
}

export function* fetchSingleBundle() {
  yield takeEvery(GET_SINGLE_BUNDLE, onGetSingleBundle);
}

const onGetBundleCoursesRequest = async ({ bundleSlug, page }) => {
  try {
    const params = {
      per_page: 6,
      page,
    };

    const response = await api.get(`product-bundle/${bundleSlug}/courses`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetBundleCourses({ payload }) {
  const courses = yield call(onGetBundleCoursesRequest, payload);
  if (courses) yield put(getBundleCoursesSuccess(courses));
}

export function* fetchBundleCourses() {
  yield takeEvery(GET_BUNDLE_COURSES, onGetBundleCourses);
}

const onGetBundleMediaContentsRequest = async ({ bundleSlug, page }) => {
  try {
    const params = {
      per_page: 6,
      page,
    };

    const response = await api.get(
      `product-bundle/${bundleSlug}/media-space-contents`,
      { params }
    );

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onGetBundleMediaContents({ payload }) {
  const contents = yield call(onGetBundleMediaContentsRequest, payload);
  if (contents) yield put(getBundleMediaContentsSuccess(contents));
}

export function* fetchBundleMediaContents() {
  yield takeEvery(GET_BUNDLE_MEDIA_CONTENTS, onGetBundleMediaContents);
}

const onfilterBundleByRequest = async ({ category }) => {
  const params = {};

  if (category) {
    params[`whereHas[0][index]`] = 'slug';
    params[`whereHas[0][condition]`] = 'like';
    params[`whereHas[0][value]`] = category;
    params[`whereHasWith[0]`] = 'Categories';
  }

  try {
    const response = await api.get('/product-bundle', {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onfilterBundleBy(payload) {
  const bundles = yield call(onfilterBundleByRequest, payload.payload);
  yield put(filterBundleBySuccess(bundles));
}

export function* filterBundleBy() {
  yield takeLatest(FILTER_BUNDLE_BY, onfilterBundleBy);
}

const onLoadMoreBundlesRequest = async (currentPage) => {
  const params = {
    page: currentPage,
    'order[0][index]': 'created_at',
    'order[0][value]': 'desc',
  };

  try {
    const response = await api.get('/product-bundle', { params });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onLoadMoreBundles(payload) {
  const bundles = yield call(
    onLoadMoreBundlesRequest,
    payload.payload.currentPage
  );

  yield put(loadMoreBundlesSuccess(bundles));
}

export function* fetchLoadMoreBundles() {
  yield takeLatest(LOAD_MORE_BUNDLES, onLoadMoreBundles);
}

export default function* rootSaga() {
  yield all([
    fork(fetchBundles),
    fork(fetchSingleBundle),
    fork(fetchBundleCourses),
    fork(fetchBundleMediaContents),
    fork(filterBundleBy),
    fork(fetchLoadMoreBundles),
  ]);
}
