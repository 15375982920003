import styled from 'styled-components';

export const ContainerImage = styled.div`
  img {
    margin-right: 5px;
    border-radius: 5px;
  }

  .gx-product-image {
    width: 25% !important;
  }

  .gx-grid-thumb-equal {
    padding-bottom: 0 !important;
  }

  @media (max-width: 500px) {
    .gx-product-image {
      width: 100% !important;
    }

    margin-bottom: 5px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  cursor: pointer;

  .gx-product-body {
    padding: 0px 24px 0px 24px;
  }
`;

export const LockedIcon = styled.img`
  margin-bottom: 2em;
  height: 54px;
`;

export const Header = styled.div`
  text-align: center;

  h1 {
    margin-bottom: 32px;
    font-size: 25px !important;
  }

  p {
    margin-bottom: 32px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1,
  p {
    color: ${({ theme }) => theme === 'dark' && '#ffffff'};
  }

  .gx-product-item.gx-product-horizontal {
    background-color: ${({ theme }) =>
      theme === 'dark' ? '#545454' : '#ffffff'};

    color: ${({ theme }) => (theme === 'dark' ? '#ffffff' : '#545454')};
  }
`;
