import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 60px;

  i {
    font-size: 28px !important;
    margin: 0;
    padding: 0;
    background: none !important;

    ${(props) =>
      props?.isCollapsed
        ? css`
            transition: transform 0.4s;
            transform: rotateZ(0deg);
          `
        : css`
            transition: transform 0.4s;
            transform: rotateZ(180deg);
          `}
  }
`;
