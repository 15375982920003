import useGlobalSearch from 'modules/GlobalSearch/hooks/useGlobalSearch';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Container, OptionList } from './styles';

const ModelList = ({ onClose }) => {
  const intl = useIntl();
  const { onSelectSearchMethod, activeModelClasses } = useGlobalSearch();

  const mode = useSelector(({ settings }) => settings.mode);

  const isDarkMode = mode === 'dark';

  function handleClose() {
    if (onClose) onClose();
  }

  function handleSelectModel(e) {
    const index = e.target.value;
    const model = activeModelClasses[index];

    onSelectSearchMethod(model);

    handleClose();
  }

  useEffect(() => {
    function onClick() {
      handleClose();
    }

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <Container $isDarkMode={isDarkMode}>
      {activeModelClasses?.map(
        ({ name, icon: Icon, active }, index) =>
          active && (
            <OptionList
              $mode={mode}
              value={index}
              onClick={handleSelectModel}
              className="search-option"
            >
              <Icon />

              {intl.formatMessage({ id: name })}
            </OptionList>
          )
      )}
    </Container>
  );
};

ModelList.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModelList;
