/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import {
  userSignUp,
  hideAuthMessage,
  resetRegisterFields,
} from 'shared/providers/redux/actions';
import Alert from 'shared/components/molecules/Alert';

import NavigateBackModal from '../../components/NavigateBackModal';
import Login from './components/Login';

import { Container } from './styles';

function RegisterModal({ navigate }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false);
  const [message, setMessage] = useState(null);

  const { settings } = useSelector(({ tenancy }) => tenancy.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  const { alertMessage, showMessage } = useSelector(({ warnings }) => warnings);

  const { users, site } = settings;
  const theme = settings['general-theme'];

  const signUpImageStatus = users?.userSignUpImage;
  const registerImage = users?.userSignUpCustomImage;
  const defaultImage = theme?.themeLogoHorizontal;
  const fallbackImage = theme?.themeLogoTopBar;

  const title = users?.signUpTitle;
  const description = users?.userSignUpDescriptionTop;

  const platformHasNoImage = !defaultImage && !fallbackImage;

  useEffect(() => {
    if (showMessage && alertMessage) {
      setMessage(alertMessage);
      setLoadingSpin(false);

      setEmailAlreadyInUse(
        alertMessage[0].includes('e-mail') &&
          alertMessage[0].includes('escolhido')
      );

      dispatch(hideAuthMessage());
    }
    // eslint-disable-next-line
  }, [showMessage, hideAuthMessage, alertMessage]);

  const handleRegister = ({ name, email, password }) => {
    dispatch(userSignUp({ name, email, password }));
    setLoadingSpin(true);
  };

  const { signUpSuccess } = useSelector(({ checkout }) => checkout);

  useEffect(() => {
    if (signUpSuccess) navigate('confirmation');
  }, [signUpSuccess]);

  useEffect(() => () => dispatch(resetRegisterFields()), []);

  return (
    <>
      <NavigateBackModal page="first" navigate={navigate} />
      <Container isDarkMode={isDarkMode}>
        <div className="col">
          {signUpImageStatus === 'none' || platformHasNoImage ? (
            <h1 className="siteName">{site?.siteName}</h1>
          ) : isDarkMode ? (
            <img
              src={theme?.themeDarkModeLogoHorizontal || defaultImage}
              alt="logo"
            />
          ) : signUpImageStatus === 'default' ? (
            <img src={defaultImage || fallbackImage} alt="logo" />
          ) : (
            <img
              src={registerImage || fallbackImage || defaultImage}
              alt="logo"
            />
          )}

          <div className="titulos">
            <h1>
              {title && title?.length > 3
                ? title
                : intl?.formatMessage({
                    id: 'authModal.register.createYourAccount',
                  })}
            </h1>
            <h2>
              {description && description?.length > 3
                ? description
                : intl?.formatMessage({
                    id: 'authModal.register.createYourAccountToContinue',
                  })}
            </h2>
          </div>

          {loadingSpin && <Spin />}

          {message ? (
            emailAlreadyInUse ? (
              <Alert error onClose={() => setMessage(null)}>
                {intl?.formatMessage({
                  id: 'authModal.register.alreadyExistAnAccount',
                })}
                <p>
                  {intl?.formatMessage({
                    id: 'authModal.register.clickToRedefine',
                  })}
                </p>
              </Alert>
            ) : (
              <Alert message={message} error onClose={() => setMessage(null)} />
            )
          ) : null}

          <Login
            title={title}
            description={description}
            handleRegister={handleRegister}
            navigate={navigate}
          />
        </div>
      </Container>
    </>
  );
}

RegisterModal.propTypes = {
  navigate: PropTypes.array.isRequired,
};

export default RegisterModal;
