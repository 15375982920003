import antdPortugueseProvider from 'antd/lib/locale-provider/pt_BR';
import antdEnglishProvider from 'antd/lib/locale-provider/en_US';
import portugueseTranslations from './translations/pt_BR.json';
import englishTranslations from './translations/en_US.json';

const english = {
  messages: {
    ...englishTranslations,
  },
  antd: antdEnglishProvider,
  locale: 'en-US',
};

const portuguese = {
  messages: {
    ...portugueseTranslations,
  },
  antd: antdPortugueseProvider,
  locale: 'pt-BR',
};

const languages = {
  en: english,
  br: portuguese,
};

export default languages;
