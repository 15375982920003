import {
  SET_METRICS_ID,
  STARTING_PURCHASE_PROCESS,
  FINISHING_PURCHASE_PROCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  os: '',
  device: '',
  browser: '',
  metricsId: null,
  productId: null,
  productType: '',
  browserVersion: '',
  paymentMethod: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STARTING_PURCHASE_PROCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_METRICS_ID: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case FINISHING_PURCHASE_PROCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
