import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: transparent;
  max-width: 300px;
  height: 50px;

  input {
    font-size: 16px;
    margin: 1px 5px 0px;
    min-height: 100%;
    padding-top: 3px;
    padding: 0 40px;
    border: none;
    border-radius: 10px;
    outline: none;
  }

  span.inputOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    width: 300px;
    height: 53px;
    pointer-events: none;

    span.prefix {
      font-size: 16px;
    }

    .eyePassword {
      font-size: 18px;
      pointer-events: visible;
      cursor: pointer;
    }
  }

  ${(props) =>
    props.$isDarkMode
      ? css`
          input {
            background: #242424;

            &:-webkit-autofill {
              box-shadow: 0 0 0 50px #242424 inset !important;
              -webkit-text-fill-color: #ffffff;
              background: content-box;
            }
          }

          input,
          input::placeholder,
          .eyePassword,
          span.prefix svg {
            color: #fff;
          }
        `
      : css`
          input {
            background: #f5f5f5;
          }
        `}
`;
