import React from 'react';
import { useSelector } from 'react-redux';

const MyAccessIcon = () => {
  const isDarkMode = useSelector(({ settings }) => settings?.mode === 'dark');

  return (
    <div
      style={{
        width: '1.5rem',
        height: '1.5rem',
      }}
    >
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.572 13.1359L24.1728 13.4363C25.975 14.6378 25.975 17.3412 24.1728 18.5427L10.0551 27.554C8.25282 28.7555 6 27.4038 6 25.0008V6.97822C6 4.7254 8.25282 3.22351 10.0551 4.42502L17.5 9"
          stroke={isDarkMode ? '#ffffff' : 'var(--topbar-icon-color)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0004 7L15.0117 17.0408L10.6562 12.5352"
          stroke={isDarkMode ? '#ffffff' : 'var(--topbar-icon-color)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MyAccessIcon;
