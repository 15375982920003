import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ObjectIntlTranslator from 'shared/components/atoms/ObjectIntlTranslator';
import { SpanMiniSideBar, Content, Badge } from './styles';

const BadgeBuilder = ({ navStyle, item, charsNumber }) => {
  const tenantData = useSelector(({ tenancy }) => tenancy?.tenantData);
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  return navStyle === 'NAV_STYLE_MINI_SIDEBAR' ? (
    <SpanMiniSideBar tenantData={tenantData}>
      <p>
        <ObjectIntlTranslator variable={item.name} />
      </p>
    </SpanMiniSideBar>
  ) : (
    <>
      {Object.keys(item.name).length < charsNumber ? (
        <>
          <ObjectIntlTranslator variable={item.name} />
          {item.use_badge && (
            <Badge
              background={item.badge_color}
              tenantData={tenantData}
              isDarkMode={isDarkMode}
            >
              <ObjectIntlTranslator variable={item.badge_text} />
            </Badge>
          )}
        </>
      ) : (
        <Content>
          <ObjectIntlTranslator variable={item.name} />
          {item.use_badge && (
            <Badge
              background={item.badge_color}
              top="true"
              tenantData={tenantData}
              isDarkMode={isDarkMode}
            >
              <ObjectIntlTranslator variable={item.badge_text} />
            </Badge>
          )}
        </Content>
      )}
    </>
  );
};

BadgeBuilder.defaultProps = {
  item: {},
  navStyle: '',
  charsNumber: 0,
};

BadgeBuilder.propTypes = {
  item: PropTypes.object,
  navStyle: PropTypes.string,
  charsNumber: PropTypes.number,
};

export default BadgeBuilder;
