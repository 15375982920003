import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchCountriesSuccess } from 'shared/providers/redux/actions';
import { GET_COUNTRIES } from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const getCountriesRequest = async () => {
  try {
    const { data } = await api.get('country');
    return data.data;
  } catch (error) {
    console.log('🚀 console turbo --> error', error);
  }

  return null;
};

function* getCountries() {
  const countriesList = yield call(getCountriesRequest);
  if (countriesList) {
    localStorage.setItem('countries', JSON.stringify(countriesList));
    yield put(fetchCountriesSuccess(countriesList));
  }
}

export function* onGetCountries() {
  yield takeLatest(GET_COUNTRIES, getCountries);
}

export default function* rootSaga() {
  yield all([fork(onGetCountries)]);
}
