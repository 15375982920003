/* eslint-disable react/button-has-type */
import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { FiSearch } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import useGlobalSearch from 'modules/GlobalSearch/hooks/useGlobalSearch';

import Results from './components/Results';
import SelectModelClass from './components/SelectModelClass';

import {
  Content,
  SearchBox,
  Container,
  SearchInput,
  InputSection,
} from './styles';

const GlobalSearch = () => {
  const intl = useIntl();
  const { onSearchChange } = useGlobalSearch();

  const [showResultsModal, setShowResultsModal] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [clearVisible, setclearVisible] = useState(false);

  const isSearchPage = window.location?.pathname.includes('search');

  const searchText = useSelector(({ globalSearch }) => globalSearch.searchText);
  const tenantData = useSelector(({ tenancy }) => tenancy.tenantData);

  function handleCloseResults() {
    setShowResultsModal(false);
  }

  function handleShowResults(e) {
    if (e?.type === 'focus' && searchText.length > 2) {
      setShowResultsModal(true);
    }
  }

  function handleSearchChange(e) {
    onSearchChange(e);
    setclearVisible(true);

    setShowResultsModal(e.target.value.length > 2);

    if (e.target.value.length === 0) {
      setclearVisible(false);
    }
  }

  function handleSetSearchValue() {
    setSearchValue('');
    setclearVisible(false);

    setTimeout(() => {
      setSearchValue();
    }, 100);
  }

  return (
    <Container>
      <Content>
        <SearchInput
          onBlur={handleShowResults}
          onFocus={handleShowResults}
          className="search-input"
        >
          {!!tenantData && (
            <InputSection>
              <FiSearch className="search-icon" />

              <SearchBox
                placeholder={intl.formatMessage({ id: 'userCommunity.search' })}
                onChange={handleSearchChange}
                value={searchValue}
              />
              {clearVisible && (
                <button
                  className="clear-button"
                  onClick={() => handleSetSearchValue()}
                >
                  Limpar
                </button>
              )}
            </InputSection>
          )}

          <SelectModelClass />
        </SearchInput>

        {showResultsModal && !isSearchPage && (
          <Results onClose={handleCloseResults} />
        )}
      </Content>
    </Container>
  );
};

export default memo(GlobalSearch);
