import styled from 'styled-components';
import { DefaultThemeSettings } from './DefaultThemes';

export const TransparentMode = styled(DefaultThemeSettings)`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 999;

  header.ant-layout-header,
  .search-container-on-mobile {
    backdrop-filter: blur(6px);
    background: rgba(0, 0, 0, 0.2) !important;
  }

  .search-input {
    background: #323232;
  }

  header.ant-layout-header {
    .icon.sidebar {
      color: #1a1a1a;
    }

    .content-container {
      .site-title h1,
      .anticon-search.icon,
      .course_options,
      ul.gx-header-notifications li,
      ul.gx-header-notifications li i {
        color: #1a1a1a;
      }
    }
  }
`;
