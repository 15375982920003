import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
  min-height: 450px;
  width: 100%;

  animation: appearFromLeft 0.4s;

  .ant-legacy-form-item {
    margin-bottom: 15px;
  }
  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    height: 100%;

    h1 {
      font-family: Poppins;
      font-weight: bold;
    }
    h1.siteName {
      font-size: 24px;
      text-align: center;
    }
    h2,
    span,
    p,
    a,
    .link {
      font-family: Roboto;
    }
    .link {
      font-size: 14px;
      color: #606060 !important;
    }
    .link + .link {
      font-size: 16px;
      margin-top: 20px;
    }
    button {
      padding: 0px !important;
    }
    
    ${(props) =>
      props.$isDarkMode
        ? css`
            background-color: #303030 !important;
            h1,
            h2,
            a.link,
            b,
            span i,
            .bottomDescription {
              color: #ffffff !important;
            }

            button.primary {
              span {
                color: #1a1a1a !important;
              }
            }
          `
        : css`
            button.primary {
              span {
                color: #fefefe !important;
              }
            }
          `}

    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 8px 0 0 0;
      height: max-content;
      width: max-content;
    }
    .ant-alert.ant-alert-error {
      .ant-alert-close-icon {
        height: 30px;
        margin-top: -1px;
      }
      .ant-alert-description {
        margin-left: -10px;
        margin-right: 10px;
      }
    }
    span.ant-input-affix-wrapper {
      max-width: 300px;
      height: 50px;
      input {
        font-size: 16px;
        margin: 1px 5px 0px;
        min-height: 100%;
      }
    }
    img.logo {
      max-width: 250px;
      max-height: 70px;
      height: 100%;
      margin: 0;
    }
    div.titulos {
      text-align: center;
      margin: 60px 0 15px 0;
      h1 {
        color: #606060;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 115%;
        letter-spacing: -0.5px;
        ${(props) =>
          props.hasImage
            ? css`
                margin: 0px 0px 10px 0px;
              `
            : css`
                margin-bottom: 10px;
              `}
      }
      h2 {
        font-size: 16px;
        line-height: 132.5%;
        text-align: center;
        letter-spacing: -0.5px;
        margin: 0;
        color: #606060;
      }
      img {
        padding-top: 0px !important;
      }
    }

    .bottomDescription {
      text-align: center;
      margin: 10px 0 0 0;
      font-size: 12px;

      a {
        color: inherit;
        text-decoration: inherit;
        font-weight: bolder;
      }
    }

    .ant-legacy-form {
      margin-top: 15px !important;
    }

    @media (max-width: 1400px) {
      margin: 0;

      div.titulos {
        margin: 40px 0 20px 0;
      }

      .links {
        width: 100%;
        text-align: center;
        margin: 20px 0 0 0;
      }
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
