/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
import {
  FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS,
  FETCH_MEDIA_SPACE_CONTENTS_SUCCESS,
  FETCH_MEDIA_SPACE_DATA_SUCCESS,
  FILTER_CATEGORY_MEDIA_CONTENT_BY,
  FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS,
  FILTER_MEDIA_CATEGORY_BY_AUTHOR,
  FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS,
  GET_ALL_BASIC_CATEGORIES_SUCCESS,
  GET_ALL_MEDIAS_FROM_AN_AUTHOR,
  GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS,
  GET_MEDIA_SPACE_CATEGORIES,
  GET_MEDIA_SPACE_CONTENTS,
  SEARCH_FOR_MEDIA_CONTENTS,
  SEARCH_FOR_MEDIA_CONTENTS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  mediaSpace: null,
  categories: null,
  newContents: null,
  nextContents: null,
  categoryMeta: null,
  singleContent: null,
  featuredContents: null,
  categoryContents: null,
  categoryContentsFiltered: null,
  loadingCategories: true,
  loadingMediaCategoryCotents: true,
  loadingNewContents: false,
  loadingNextContents: false,
  contentsFoundBySearch: null,
  searchingForContents: false,
  searchingForCategoryContents: false,
  loadingMediasListFromAnAuthor: false,
  mediasListFromAnAuthor: null,
  filterGetAllBasicCategory: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MEDIA_SPACE_DATA_SUCCESS: {
      return {
        ...state,
        mediaSpace: action.payload,
      };
    }

    case GET_MEDIA_SPACE_CONTENTS: {
      switch (action.payload.filterId) {
        case 'new':
          return {
            ...state,
            loadingNewContents: true,
          };

        case 'position':
          return {
            ...state,
            loadingNewContents: true,
          };

        case 'next':
          return {
            ...state,
            loadingNextContents: true,
          };

        default:
          return {
            ...state,
          };
      }
    }

    case SEARCH_FOR_MEDIA_CONTENTS: {
      return {
        ...state,
        searchingForContents: true,
      };
    }

    case SEARCH_FOR_MEDIA_CONTENTS_SUCCESS: {
      return {
        ...state,
        searchingForContents: false,
        contentsFoundBySearch: action.payload,
      };
    }

    case GET_ALL_MEDIAS_FROM_AN_AUTHOR: {
      return {
        ...state,
        loadingMediasListFromAnAuthor: true,
      };
    }

    case GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS: {
      return {
        ...state,
        mediasListFromAnAuthor: action.payload,
        loadingMediasListFromAnAuthor: false,
      };
    }

    case FETCH_MEDIA_SPACE_CONTENTS_SUCCESS: {
      switch (action.payload.filterId) {
        case 'new':
          return {
            ...state,
            newContents: action.payload.contents,
            loadingNewContents: false,
          };

        case 'position':
          return {
            ...state,
            newContents: action.payload.contents,
            loadingNewContents: false,
          };

        case 'next':
          return {
            ...state,
            nextContents: action.payload.contents,
            loadingNextContents: false,
          };

        case 'featured':
          return {
            ...state,
            featuredContents: action.payload.contents,
          };

        case 'fromCategory':
          const currentPage = action.payload.currentPage;
          const { data, meta } = action.payload.contents;
          return {
            ...state,
            categoryContents:
              currentPage > 1
                ? [...state.categoryContents, ...data.reverse()]
                : data.reverse(),
            categoryMeta: { meta },
            loadingMediaCategoryCotents: false,
          };

        case 'singleContent':
          return {
            ...state,
            singleContent: action.payload.contents,
          };

        default:
          return {
            ...state,
          };
      }
    }

    case GET_MEDIA_SPACE_CATEGORIES: {
      return {
        ...state,
        loadingCategories: true,
      };
    }

    case FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: state?.categories
          ? {
              ...action.payload,
              data: [...state.categories?.data, ...action.payload?.data],
              meta: action.payload?.meta,
            }
          : action.payload,
        loadingCategories: false,
      };
    }

    case FILTER_CATEGORY_MEDIA_CONTENT_BY: {
      return {
        ...state,
        searchingForCategoryContents: true,
      };
    }

    case FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS: {
      return {
        ...state,
        categoryContentsFiltered: action.payload,
        searchingForCategoryContents: false,
      };
    }

    case FILTER_MEDIA_CATEGORY_BY_AUTHOR: {
      return {
        ...state,
        loadingCategories: true,
      };
    }

    case FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS: {
      return {
        ...state,
        filteredCategories: action.payload,
        loadingCategories: false,
      };
    }

    case GET_ALL_BASIC_CATEGORIES_SUCCESS: {
      return {
        ...state,
        filterGetAllBasicCategory: action.payload.data,
      };
    }

    default:
      return state;
  }
};
