import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import staticIntlTranslatorToString from 'shared/utils/staticIntlTranslatorToString';
import ResultCard from '../ResultCard';
import {
  Container,
  FoundedResultsText, NoResultsFound, SeeMoreResultsButton
} from './styles';

const Results = ({ onClose }) => {
  const intl = useIntl();
  const history = useHistory();

  const theme = useSelector(({ settings }) => settings?.mode);
  const loader = useSelector(({ globalSearch }) => globalSearch?.loader);
  const results = useSelector(({ globalSearch }) => globalSearch?.results);

  const isDarkMode = theme === 'dark';

  const validTopicResults = results
    .filter(topic => !topic.action_url.includes('social//'));

  function handleClose () {
    if (onClose) onClose();
  }

  function handleSeeMoreResults () {
    history.push('/search');
    handleClose();
  }

  useEffect(() => {
    function onClick (e) {
      const { className } = e.target;

      if (
        typeof className === 'string' &&
        !className.toLocaleLowerCase().includes('search')
      )
        handleClose();
    }

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <Container id="result" $isDarkMode={isDarkMode}>
      {loader ? (
        <Spin />
      ) : (
        <>
          {validTopicResults?.length ? (
            <>
              <FoundedResultsText>
                {intl.formatMessage({ id: 'approximately' })}
                &nbsp;{validTopicResults?.length}&nbsp;
                {intl.formatMessage({
                  id:
                    validTopicResults?.length > 1
                      ? 'globalSearch.resultsFounds'
                      : 'globalSearch.resultFound',
                })}
              </FoundedResultsText>

              {validTopicResults?.map(
                (result, index) =>
                  index < 4 && (
                    <ResultCard
                      key={result.action_url}
                      src={result.thumbnail_url}
                      title={staticIntlTranslatorToString(result.title, intl)}
                      subtitle={staticIntlTranslatorToString(result.subtitle, intl)}
                      description={staticIntlTranslatorToString(
                        result.description,
                        intl
                      )}
                      url={result.action_url}
                    />
                  )
              )}

              <SeeMoreResultsButton onClick={handleSeeMoreResults}>
                <section>
                  {intl.formatMessage({ id: 'globalSearch.seeMoreResults' })}
                  <FiArrowRight />
                </section>
              </SeeMoreResultsButton>
            </>
          ) : (
            <NoResultsFound>
              {intl.formatMessage({ id: 'globalSearch.noResultsWereFound' })}
            </NoResultsFound>
          )}
        </>
      )}
    </Container>
  );
};

Results.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Results;
