import styled from 'styled-components';

import { DefaultThemeSettings } from './DefaultThemes';

export const LightMode = styled(DefaultThemeSettings)`
  header.ant-layout-header,
  .search-container-on-mobile {
    background: #fffffc;
  }

  button.clear-button {
    color: #545454;
    border-color: #545454;
  }

  .search-input {
    background: #e2e2e2;
  }

  header.ant-layout-header {
    .icon.sidebar {
      color: #545454;
    }

    .content-container {
      .site-title h1,
      .anticon-search.icon,
      .course_options,
      ul.gx-header-notifications li,
      ul.gx-header-notifications li i {
        color: #545454;
      }
    }
  }
`;
