import { push } from 'react-router-redux';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import {
  fetchCollections,
  fetchCollectionSessionSuccess,
  fetchCollectionsSuccess,
  loadMoreCollectionsSuccess,
  removeItemCollectionsSuccess,
  setCollectionSession as fetchCollectionSessionUpdate,
  setItemsCollectionsSuccess,
} from 'shared/providers/redux/actions';
import {
  GET_ALL_COLLECTIONS,
  LOAD_MORE_COLLECTION,
  LOAD_MORE_COLLECTION_ITEMS,
  REMOVE_COLLECTION,
  REMOVE_ITEM_COLLECTIONS,
  SET_COLLECTION,
  SET_COLLECTION_ITEM_POSITION,
  SET_COLLECTION_POSITION,
  SET_COLLECTION_SESSION,
  SET_ITEMS_COLLECTIONS,
  SET_PRIVATE_COLLECTION,
  UPDATE_COLLECTION,
} from 'shared/providers/redux/actionTypes';
import { loadMoreCollectionItemsSuccess } from './actions';

const getAllCollectionsRequest = async (page = 1) => {
  try {
    const response = await api.get(`collection?per_page=${15 * page}`);
    return response?.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getMoreCollectionsRequest = async (page = 1) => {
  try {
    const response = await api.get(`collection?page=${page}`);
    return response?.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getMoreCollectionsItemsRequest = async ({ page = 1, id }) => {
  try {
    const response = await api.get(`collection/${id}?page=${page}`);
    return response?.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const setCollectionRequest = async (payload) => {
  const { name, description } = payload;
  try {
    const response = await api.post('collection', { name, description });
    return response.data?.data;
  } catch (error) {
    console.log('error --> ', error);
  }

  return null;
};

const setCollectionSessionRequest = async (payload) => {
  const { slug, userId } = payload;
  try {
    const response = await api.get(`collection/${userId}/${slug}`);
    return response?.data;
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setCollectionPositionRequest = async (payload) => {
  try {
    await api.post('collection/order', {
      ids: payload,
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setCollectionItemPositionRequest = async (payload) => {
  const { idCollection, idArrays } = payload;
  try {
    await api.post(`collection/order/${idCollection}`, {
      ids: idArrays,
    });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const setItemsCollectionsRequest = async (payload) => {
  const data = {
    className: payload.className,
    items: [payload.item],
    collections: [payload.collections],
  };

  try {
    await api.post('collection/relation-item', data);

    return data;
  } catch (error) {
    console.log('error --> ', error);
    return false;
  }
};

const setPrivateCollectionsRequest = async (payload) => {
  const { id } = payload;
  try {
    await api.post(`collection/private/${id}`);
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const updateCollectionRequest = async (payload) => {
  const { id, name, description } = payload;
  try {
    await api.put(`collection/${id}`, { name, description });
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

const removeItemCollectionsRequest = async (payload) => {
  const { className, item, collections } = payload;
  try {
    await api.post('collection/remove-relation-item', {
      className,
      items: [item],
      collections: [collections],
    });

    return true;
  } catch (error) {
    console.log('error --> ', error);
    return false;
  }
};

const removeCollectionRequest = async (payload) => {
  try {
    await api.delete(`collection/${payload}`);
  } catch (error) {
    console.log('error --> ', error);
  }
  return null;
};

function* setCollection({ payload }) {
  const collection = yield call(setCollectionRequest, payload);
  if (collection) yield put(fetchCollections(true, payload?.currentPage));
}

function* setCollectionSession({ payload }) {
  const collectionSession = yield call(setCollectionSessionRequest, payload);
  if (collectionSession)
    yield put(
      fetchCollectionSessionSuccess({
        collectionSession,
        privateCollection: collectionSession.private,
      })
    );
}

function* setCollectionItemPosition({ payload }) {
  yield call(setCollectionItemPositionRequest, payload);
}

function* setCollectionPosition({ payload }) {
  yield call(setCollectionPositionRequest, payload);
}

function* setItemsCollections({ payload }) {
  const content = yield call(setItemsCollectionsRequest, payload);
  if (content) yield put(setItemsCollectionsSuccess({ content }));
}

function* setPrivateCollections({ payload }) {
  const { userId, slug } = payload;
  yield call(setPrivateCollectionsRequest, payload);
  yield put(fetchCollectionSessionUpdate({ userId, loader: false, slug }));
}

function* updateCollection({ payload }) {
  yield call(updateCollectionRequest, payload);
}

function* removeItemCollections({ payload }) {
  const success = yield call(removeItemCollectionsRequest, payload);
  if (success) yield put(removeItemCollectionsSuccess());
}

function* removeCollection({ payload }) {
  yield call(removeCollectionRequest, payload);
  yield put(push('/collections'));
}

function* getMoreCollections({ page }) {
  const response = yield call(getMoreCollectionsRequest, page);
  if (response?.data)
    yield put(loadMoreCollectionsSuccess(response?.data, response?.meta));
}

function* getMoreCollectionsItems(payload) {
  const response = yield call(getMoreCollectionsItemsRequest, payload);
  if (response?.data) {
    yield put(loadMoreCollectionItemsSuccess(response?.data, response?.meta));
  }
}

function* getAllCollections({ page }) {
  const response = yield call(getAllCollectionsRequest, page);
  if (response?.data)
    yield put(fetchCollectionsSuccess(response?.data, response?.meta));
}

export function* fetchAllCollections() {
  yield takeLatest(GET_ALL_COLLECTIONS, getAllCollections);
}

export function* loadMoreCollections() {
  yield takeLatest(LOAD_MORE_COLLECTION, getMoreCollections);
}

export function* loadMoreCollectionsItems() {
  yield takeLatest(LOAD_MORE_COLLECTION_ITEMS, getMoreCollectionsItems);
}

export function* fetchCollection() {
  yield takeLatest(SET_COLLECTION, setCollection);
}

export function* fetchItemsCollections() {
  yield takeLatest(SET_ITEMS_COLLECTIONS, setItemsCollections);
}

export function* fetchPrivateCollections() {
  yield takeEvery(SET_PRIVATE_COLLECTION, setPrivateCollections);
}

export function* fetchUpdateCollection() {
  yield takeEvery(UPDATE_COLLECTION, updateCollection);
}

export function* fetchRemoveItemCollections() {
  yield takeEvery(REMOVE_ITEM_COLLECTIONS, removeItemCollections);
}

export function* fetchRemoveCollection() {
  yield takeEvery(REMOVE_COLLECTION, removeCollection);
}

export function* fetchCollectionSession() {
  yield takeLatest(SET_COLLECTION_SESSION, setCollectionSession);
}

export function* fetchCollectionItemPosition() {
  yield takeEvery(SET_COLLECTION_ITEM_POSITION, setCollectionItemPosition);
}

export function* fetchCollectionPosition() {
  yield takeEvery(SET_COLLECTION_POSITION, setCollectionPosition);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCollection),
    fork(fetchAllCollections),
    fork(fetchItemsCollections),
    fork(fetchCollectionSession),
    fork(fetchUpdateCollection),
    fork(fetchRemoveCollection),
    fork(fetchPrivateCollections),
    fork(fetchCollectionPosition),
    fork(fetchCollectionItemPosition),
    fork(fetchRemoveItemCollections),
    fork(loadMoreCollections),
    fork(loadMoreCollectionsItems),
  ]);
}
