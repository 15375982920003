import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchPlansSuccess, fetchEntityPlansSuccess } from 'shared/providers/redux/actions';
import { FETCH_PLANS, FETCH_ENTITY_PLANS } from 'shared/providers/redux/actionTypes';
import api from 'shared/infra/services/tenantAPI';

const fetchEntityPlansRequest = async payload => {
  try {
    let response;

    switch (payload.context) {
      case "course":
        response = await api.get(`plan/by-course/${payload.slug}`);
        break;

      case "single_plan":
        response = await api.get(`plan/${payload.slug}`);
        break;

      case "media_space_content":
        response = await api.get(`plan/by-media-space-content/${payload.slug}`);
        break;

      default:
        break;
    }

    return response?.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onFetchEntityPlans({ payload }) {
  const response = yield call(fetchEntityPlansRequest, payload);

  if (response) yield put(fetchEntityPlansSuccess(response, payload.slug));
}

export function* fetchEntityPlans() {
  yield takeEvery(FETCH_ENTITY_PLANS, onFetchEntityPlans);
}

const fetchPlansRequest = async () => {
  try {
    const response = await api.get('plan');

    return response?.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onFetchPlans() {
  const response = yield call(fetchPlansRequest);

  if (response) yield put(fetchPlansSuccess(response));
}

export function* fetchPlans() {
  yield takeEvery(FETCH_PLANS, onFetchPlans);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPlans),
    fork(fetchEntityPlans)
  ]);
}
