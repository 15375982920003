import React from 'react';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_USER_EMAIL,
  AUTHENTICATE_TOKEN,
} from 'shared/providers/redux/actionTypes';

import {
  showAuthMessage,
  userSignUpError,
  userSignUpSuccess,
  userSignInSuccess,
  fetchLoggedUser,
  onGetLoggedUser,
  userSignOutSuccess,
  onSetPasswordLength,
  authenticateTokenSuccess,
  confirmEmailSuccess,
} from 'shared/providers/redux/actions';

import {
  login,
  getToken,
  setLocalUser,
  signInLocally,
} from 'shared/infra/services/auth';
import api from 'shared/infra/services/tenantAPI';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import { setOpenModal } from 'shared/providers/redux/toBeRefactored/Warnings/actions';
import autoLogin from './utils/login';

const createUserWithEmailPasswordRequest = async (payload) => {
  try {
    const response = await api.post('register', {
      ...payload,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const signInUserWithEmailPasswordRequest = async (payload) => {
  const { email, password } = payload;

  try {
    const response = await api.post('login', {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    return error?.response?.data || error;
  }
};

const forgotPasswordRequest = async (email) => {
  const response = await api.post('user/password/reset', {
    email,
  });

  return response.data;
};

const resetPasswordRequest = async ({ formValues, token }) => {
  const { password } = formValues;

  const response = await api.post('user/password/newpassword', {
    password,
    token,
  });

  api.interceptors.request.use(async (config) => {
    const param = config;
    const token = getToken();

    if (token) param.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return response.data;
};

const confirmEmailRequest = async (payload) => {
  const response = await api.post('user/confirm', {
    token: payload,
  });

  return response.data;
};

function* createUserWithEmailPassword({ payload }) {
  const { user, navigation_type } = payload;
  const { name, email, password } = user;

  const splitName = name.split(' ');
  const splitNameWithNoSpace = splitName.filter(
    (split) => split !== '' && split !== ' '
  );

  const [first_name] = splitNameWithNoSpace;
  const last_name =
    splitNameWithNoSpace.length > 1 ? splitNameWithNoSpace.reverse()[0] : '';
  const second_name =
    splitNameWithNoSpace.length > 1 ? splitNameWithNoSpace.reverse()[1] : '';

  const display_name = `${first_name}${second_name ? ' ' : ''}${second_name}`;

  const signUpUser = yield call(createUserWithEmailPasswordRequest, {
    email,
    password,
    first_name,
    last_name,
    display_name,
  });

  signInLocally({
    data: signUpUser?.attrs?.user,
    token: signUpUser?.attrs?.token,
  });

  if (signUpUser && (signUpUser.data || signUpUser.type === 'success')) {
    yield put(userSignUpSuccess(email));

    const { attrs } = signUpUser;
    const { token, user } = attrs;

    const storagedUser = JSON.stringify({ data: user, token });
    const parseToken = token.plainTextToken.split('|')[1];

    yield autoLogin({ user: storagedUser, token: parseToken, userId: user.id });

    yield put(userSignInSuccess(user.id));
    yield put(onGetLoggedUser(user.username));

    if (navigation_type === 'checkout') {
      yield put(push('/payment'));
    }
  } else if (navigation_type === 'checkout') {
    const messages = Object.values(signUpUser);
    const contentError = (
      <ul>
        {messages?.map((error) => (
          <li key={error} style={{ fontWeight: 500 }}>
            {error}
          </li>
        ))}
      </ul>
    );

    openNotificationWithIcon('error', 'Erro de registro', contentError, 8);
    yield put(userSignUpError());
  } else {
    const firstArrayOfMessages = signUpUser[Object.keys(signUpUser)[0]];

    yield put(showAuthMessage(firstArrayOfMessages || 'Something went wrong.'));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);

  if (signInUser.message) {
    yield put(showAuthMessage(signInUser.message));
  } else {
    signInLocally({ data: signInUser?.data, token: signInUser?.token });
    localStorage.setItem('user_id', signInUser.data.id);

    yield put(userSignInSuccess(signInUser?.data.id));
    yield put(onGetLoggedUser(signInUser?.username));
    yield put(onSetPasswordLength(payload.password.length));

    yield put(setOpenModal(false));
  }

  if (!signInUser) {
    const errorResponse = signInUser?.response?.data;
    const errorMessage =
      errorResponse?.errors?.email[0] || errorResponse?.message;

    yield put(showAuthMessage(errorMessage));
  }
}

function* resetNewPassword({ payload }) {
  try {
    const resetPass = yield call(resetPasswordRequest, payload);

    if (resetPass) {
      yield put(showAuthMessage('password_reset_id', true));

      const storagedUser = JSON.stringify(resetPass);
      const parseToken = resetPass.token.plainTextToken.split('|')[1];

      yield autoLogin({
        user: storagedUser,
        token: parseToken,
        userId: resetPass.data.id,
      });

      yield put(userSignInSuccess(resetPass.data.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error?.response?.data?.message));
  }
}

function* forgotMyPassword({ payload }) {
  try {
    const resetPass = yield call(forgotPasswordRequest, payload);

    if (resetPass) {
      yield put(
        showAuthMessage(resetPass?.message, resetPass?.type === 'success')
      );
    }
  } catch (error) {
    yield put(showAuthMessage(error?.response?.data?.message));
  }
}

function* onConfirmEmail({ payload }) {
  try {
    const response = yield call(confirmEmailRequest, payload);

    if (response) {
      const user = response?.attrs?.user;
      const token = response?.attrs?.token;

      if (user && token) {
        const storagedUser = JSON.stringify({ data: user, token });
        const parseToken = token.plainTextToken.split('|')[1];

        yield autoLogin({
          user: storagedUser,
          token: parseToken,
          userId: user.id,
        });
      }

      if (response.attrs?.success) {
        yield put(confirmEmailSuccess());
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error?.response?.data?.message));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* forgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotMyPassword);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetNewPassword);
}

export function* confirmEmail() {
  yield takeEvery(CONFIRM_USER_EMAIL, onConfirmEmail);
}

const signOutRequest = async (currentDeviceId) => {
  try {
    const response = await api.delete(`user-device/${currentDeviceId}`);

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* signOut() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user?.token?.accessToken?.id) {
    const tokenInvalidationResponse = yield call(
      signOutRequest,
      user.token.accessToken.id
    );

    if (tokenInvalidationResponse) {
      yield put(userSignOutSuccess());
    }
  }
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

const authenticateTokenRequest = async (payload) => {
  try {
    const response = await api.post('auth-factory-token', { token: payload });

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* onAuthenticateToken({ payload }) {
  const tokenAuthenticationResponse = yield call(
    authenticateTokenRequest,
    payload
  );

  if (tokenAuthenticationResponse) {
    setLocalUser(JSON.stringify(tokenAuthenticationResponse));

    const parseToken =
      tokenAuthenticationResponse.token.plainTextToken.split('|')[1];
    login(parseToken);

    api.interceptors.request.use(async (config) => {
      const param = config;
      param.headers.Authorization = `Bearer ${parseToken}`;

      return config;
    });

    localStorage.setItem('user_id', tokenAuthenticationResponse.data.id);
    yield put(authenticateTokenSuccess(tokenAuthenticationResponse));
    yield put(userSignInSuccess(tokenAuthenticationResponse.data.id));
    yield put(fetchLoggedUser(tokenAuthenticationResponse.data));
  } else {
    yield put(authenticateTokenSuccess(null));
  }
}

export function* authenticateToken() {
  yield takeEvery(AUTHENTICATE_TOKEN, onAuthenticateToken);
}

export default function* rootSaga() {
  yield all([
    fork(forgotPassword),
    fork(resetPassword),
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    fork(confirmEmail),
    fork(authenticateToken),
  ]);
}
