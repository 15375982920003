import styled, { keyframes, css } from 'styled-components';
import { Modal } from 'antd';

const appearFromTop = keyframes`
  from {
    opacity:0;
    transform: translateX(100px);
  }
  to {
    opacity:1;
    transform: translateX(0px);
  }
`;

export const Container = styled(Modal)`
  top: 0;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100% !important;
  max-width: unset;
  position: relative;

  .ant-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    background: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    padding: ${(props) =>
      props.paddingDisabled ? '0 !important' : '0 60px 60px'};
    width: 500px;
    margin: 0;

    border-radius: 13px;

    animation: ${appearFromTop} 0.5s;

    ${(props) =>
      props?.isDarkMode
        ? css`
            background: #303030;
          `
        : css`
            background-color: #fefefe;
          `}

    @media (max-width: 1400px) {
      padding: 0 1.25rem 1.25rem;
      width: 450px;
    }
  }

  @media (min-width: 900px) {
    .ant-modal-body {
      min-height: 580px;
      padding: 30px 20px;
    }
  }

  @media (max-width: 900px) {
    align-items: flex-start;
    width: 100% !important;

    .ant-modal-wrap {
      justify-content: flex-start;
    }

    .ant-modal-content {
      min-height: 100vh;
      height: max-content;
    }

    .ant-modal-body {
      width: 100vw;
      min-height: 100vh;
      padding: 60px;

      border-radius: 0;

      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
      .ant-modal-body {
        padding: 15px !important;
      }
    }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props?.goBackIsEnable ? 'space-between' : 'flex-end'};

  margin: 17.5px 0;

  position: absolute;
  top: 0;
  padding: 0 25px;

  svg {
    cursor: pointer;
    font-size: 25px;
  }

  svg.back_button#back,
  svg.close_button#close {
    color: ${(props) => (props.isDarkMode ? '#FFFFFF' : '#1a1a1a')} !important;

    z-index: 99;
  }

  @media (max-width: 1400px) {
    margin: 17.5px 0 7.5px 0;
  }

  @media (max-width: 900px) {
    margin: 25px 0 0 0;
  }
`;
