import { GET_TENANT_DATA_SUCCESS } from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  tenantData: JSON.parse(sessionStorage.getItem('tenant_data')),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TENANT_DATA_SUCCESS: {
      return {
        ...state,
        tenantData: action.payload,
      };
    }

    default:
      return state;
  }
};
