import styled from 'styled-components';

export const DefaultSidebarSettings = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 7px;
    margin-left: 3px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ededed;
  }

  ::-webkit-scrollbar-corner {
    visibility: hidden;
  }

  scrollbar-width: thin;

  li.ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 30px !important;
  }

  .ant-menu {
    padding: 10px 0;
  }

  .ant-menu-sub.ant-menu-inline {
    background: none;
    padding: 0 20px;
  }
`;

export const AdvancedMode = styled(DefaultSidebarSettings)`
  background: ${(props) =>
    props.tenantTheme['general-theme']?.themeSideBarBackgroundColor};

  .gx-linebar i,
  .gx-site-logo span p {
    color: ${(props) =>
      props.tenantTheme['general-theme']?.themeSideBarColapseIconColorColor};
  }

  .gx-layout-sider-header {
    background: ${(props) =>
      props.tenantTheme['general-theme']
        ?.themeSideBarColapseIconBackgroundColor};
  }

  ul li {
    i,
    a span,
    p span {
      color: ${(props) =>
        props.tenantTheme['general-theme']?.themeSideBarItemColor};
    }
  }
`;

export const DarkMode = styled(DefaultSidebarSettings)`
  background: #121212;

  .gx-linebar i,
  .gx-site-logo span p {
    color: #ffffff;
  }

  .gx-layout-sider-header {
    background: #080808;
  }

  ul li {
    i,
    a span,
    p span {
      color: #ffffff;
    }
  }
`;

export const LightMode = styled(DefaultSidebarSettings)`
  background: #fffffc;

  .gx-linebar i,
  .gx-site-logo span p {
    color: #545454;
  }

  .gx-layout-sider-header {
    background: #fffffc;
  }

  ul li {
    i,
    a span,
    p span {
      color: #545454;
    }
  }
`;

export const AutoMode = styled(DefaultSidebarSettings)`
  --topbarAutoColor: ${(props) =>
    props.tenantTheme['general-theme']?.themeMasterColor};
  --topbarAdvancedColor: ${(props) =>
    props.tenantTheme['general-theme']?.themeTopBarBackgroundColor};
  --topbarWhiteColor: #fff;
  --topbarDarkColor: #181818;

  --iconTopbarAutoColor: #fff;
  --iconTopbarAdvancedColor: ${(props) =>
    props.tenantTheme['general-theme']?.themeTopBarIconsColor};
  --iconTopbarWhiteColor: #545454;
  --iconTopbarDarkColor: #f7f7f7;

  --colorIcons: var(
    ${({ themeTopBar }) =>
      themeTopBar === 'advanced'
        ? '--iconTopbarAdvancedColor'
        : themeTopBar === 'light'
        ? '--iconTopbarWhiteColor'
        : themeTopBar === 'dark'
        ? '--iconTopbarDarkColor'
        : '--iconTopbarAutoColor'}
  );

  &,
  .gx-layout-sider-header {
    background: var(
      ${({ themeTopBar }) =>
        themeTopBar === 'advanced'
          ? '--topbarAdvancedColor'
          : themeTopBar === 'light'
          ? '--topbarWhiteColor'
          : themeTopBar === 'dark'
          ? '--topbarDarkColor'
          : '--topbarAutoColor'}
    );
  }

  .gx-linebar i,
  .gx-site-logo span p {
    color: var(--colorIcons);
  }

  ul li {
    i,
    a span,
    p span {
      color: var(--colorIcons);
    }
  }
`;
