import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import PropTypes from 'prop-types';

import { Container } from './styles';

const NavigateBackModal = ({ page, navigate }) => {
  function handleGoBack() {
    if (navigate && page) navigate(page);
  }

  return (
    <Container>
      <FiArrowLeft onClick={handleGoBack} />
    </Container>
  );
};

NavigateBackModal.propTypes = {
  page: PropTypes.any.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default NavigateBackModal;
