import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { DefaultThemeSettings } from './DefaultThemes';

export const AdvancedMode = styled(DefaultThemeSettings)`
  header.ant-layout-header,
  .search-container-on-mobile {
    background: ${(props) =>
      props.tenantTheme['general-theme'].themeTopBarBackgroundColor};
  }

  .search-input {
    background: ${({ tenantTheme }) =>
      tinycolor(
        tenantTheme['general-theme'].themeTopBarBackgroundColor
      ).getLuminance() > 0.8
        ? '#cccccc'
        : tenantTheme['general-theme'].themeTopBarBackgroundColor};

    filter: brightness(1.15);
  }

  header.ant-layout-header {
    .icon.sidebar {
      color: ${(props) =>
        props.tenantTheme['general-theme'].themeTopBarIconsColor};
    }

    .content-container {
      .site-title h1,
      .anticon-search.icon,
      .course_options,
      ul.gx-header-notifications li,
      ul.gx-header-notifications li i {
        color: ${(props) =>
          props.tenantTheme['general-theme'].themeTopBarIconsColor} !important;
      }
    }
  }
`;
