import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import useWindowSize from 'shared/utils/hooks/useWindowSize';
import { setOpenModal } from 'shared/providers/redux/toBeRefactored/Warnings/actions';

import { Container, SignInAndSignUpButton, InfoMessages } from './styles';

const ModalContent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 800;

  const theme = useSelector(({ settings }) => settings.mode);
  const tenantData = useSelector(({ tenancy }) => tenancy.tenantData);

  const handleOpenAuthModal = useCallback(() => {
    dispatch(setOpenModal(true));
  }, [dispatch]);

  const generalTheme = tenantData?.settings['general-theme'];

  const lightLogo =
    generalTheme?.themeLogoIcon || generalTheme?.themeLogoHorizontal;

  const darkLogo =
    generalTheme?.themeDarkModeLogoIcon ||
    generalTheme?.themeDarkModeLogoHorizontal;

  return (
    <Container $generalTheme={generalTheme} $isDarkMode={theme === 'dark'}>
      <section className="plataformInfo">
        {!isMobile && (
          <figure className="plataformIcon">
            <img src={theme === 'dark' ? darkLogo : lightLogo} alt="" />
          </figure>
        )}

        <InfoMessages
          $generalTheme={generalTheme}
          $isDarkMode={theme === 'dark'}
        >
          <h1>
            {intl.formatMessage({
              id: isMobile
                ? 'footerAuthModal.sortTitle'
                : 'footerAuthModal.title',
            })}
          </h1>
        </InfoMessages>
      </section>

      <section className="authButtons">
        <SignInAndSignUpButton
          $generalTheme={generalTheme}
          $isDarkMode={theme === 'dark'}
          type="button"
          onClick={handleOpenAuthModal}
        >
          {intl.formatMessage({ id: 'footerAuthModal.signIn' })}
        </SignInAndSignUpButton>
      </section>
    </Container>
  );
};

export default ModalContent;
