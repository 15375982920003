import {
  SET_COMMING_SOON,
  SET_LOGING_MODAL,
  SET_FORGOT_MODAL,
  REGISTER_NEW_USER,
  DOES_NOT_SUPPORT_OBSERVER,
} from 'shared/providers/redux/actionTypes';

export const setCommingSoon = (open, text) => ({
  type: SET_COMMING_SOON,
  payload: { open, text },
});

export const setOpenModal = (open) => ({
  type: SET_LOGING_MODAL,
  payload: { open },
});

export const setForgotModal = (isForgot) => ({
  type: SET_FORGOT_MODAL,
  payload: isForgot,
});

export const registerNewUser = () => ({
  type: REGISTER_NEW_USER,
});

export const setBrowserNotSuportObserver = () => ({
  type: DOES_NOT_SUPPORT_OBSERVER,
});
