import { put, fork, all, call, takeLatest } from 'redux-saga/effects';

import api from 'shared/infra/services/tenantAPI';

import {
  STARTING_PURCHASE_PROCESS,
  FINISHING_PURCHASE_PROCESS,
} from 'shared/providers/redux/actionTypes';

import { setMetricsId } from 'shared/providers/redux/actions';

const startingPurchaseProcessRequest = async (payload) => {
  const { os, device, browser, productId, productType, browserVersion } =
    payload;

  try {
    const response = await api.post('checkout/begin', {
      contentable_id: productId,
      contentable_type: productType,
      device: JSON.stringify({
        os,
        device,
        browser,
        browserVersion,
      }),
    });

    return response.data;
  } catch (error) {
    const { data } = error.response;

    return data;
  }
};

function* startingPurchaseProcess({ payload }) {
  const data = yield call(startingPurchaseProcessRequest, payload);

  const metricsId = data?.attrs?.checkout_metric_id;

  if (!metricsId) return;

  yield put(setMetricsId(metricsId));
}

export function* onStartingPurchaseProcess() {
  yield takeLatest(STARTING_PURCHASE_PROCESS, startingPurchaseProcess);
}

const finishingPurchaseProcessRequest = async (payload) => {
  const { paymentMethod, metricsId } = payload;

  try {
    const response = await api.put(`checkout/end/${metricsId}`, {
      payment_method: paymentMethod,
    });

    return response.data;
  } catch (error) {
    const { data } = error.response;

    return data;
  }
};

function* finishingPurchaseProcess({ payload }) {
  yield call(finishingPurchaseProcessRequest, payload);
}

export function* onFinishingPurchaseProcess() {
  yield takeLatest(FINISHING_PURCHASE_PROCESS, finishingPurchaseProcess);
}

export default function* rootSaga() {
  yield all([
    fork(onStartingPurchaseProcess),
    fork(onFinishingPurchaseProcess),
  ]);
}
