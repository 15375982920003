import moment from 'moment';

export const generateExpirationDate = (days, data, time = 'days') => {
  const today = moment().format();
  const expirationDate = moment(today).add(days, time).format();

  return { ...data, expireAt: expirationDate };
};

export const checkingExpirationDate = (date) => {
  const today = moment().format();
  const isExpired = moment(today).isAfter(date);

  return isExpired;
};
