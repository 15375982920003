import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_SESSION,
  GET_SUBSCRIPTION_CANCELLATION_HISTORY,
  GET_CANCELLATION_STATUS,
  SET_SUBSCRIPTION_CANCELLATION,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_SESSION_SUCCESS,
  FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS,
  FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS,
  FETCH_CANCELLATION_STATUS_SUCCESS,
  FILTER_SUBSCRIPTIONS_BY_NAME,
} from 'shared/providers/redux/actionTypes';

export const getSubscriptions = () => ({
  type: GET_SUBSCRIPTIONS,
});

export const fetchSubscriptionsSuccess = (subscriptions) => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
});

export const getSubscriptionSession = (id) => ({
  type: GET_SUBSCRIPTION_SESSION,
  payload: id,
});

export const fetchSubscriptionSessionSuccess = (subscription) => ({
  type: FETCH_SUBSCRIPTION_SESSION_SUCCESS,
  payload: subscription,
});

export const getSubscriptionCancellationHistory = (id) => ({
  type: GET_SUBSCRIPTION_CANCELLATION_HISTORY,
  payload: id,
});

export const fetchSubscriptionCancellationHistorySuccess = (list) => ({
  type: FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS,
  payload: list,
});

export const getCancellationStatus = () => ({
  type: GET_CANCELLATION_STATUS,
});

export const setSubscriptionCancellation = (
  id,
  reason,
  reason_description
) => ({
  type: SET_SUBSCRIPTION_CANCELLATION,
  payload: { id, reason, reason_description },
});

export const fetchCancellationStatusSuccess = (status) => ({
  type: FETCH_CANCELLATION_STATUS_SUCCESS,
  payload: status,
});

export const filterSubscriptionsByName = (search) => ({
  type: FILTER_SUBSCRIPTIONS_BY_NAME,
  payload: { search },
});

export const filterSubscriptionsByNameSuccess = (subscriptions) => ({
  type: FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS,
  payload: { subscriptions },
});
