import { Form as DefaultForm } from '@ant-design/compatible';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  animation: appearFromLeft 0.4s;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 450px;
    margin: 20px 0;
    .logo {
      height: 90px;
    }

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
    }

    h1.siteName {
      font-size: 24px;
      text-align: center;
    }

    h2,
    span,
    p,
    a {
      font-style: normal;
      font-weight: normal;
      font-family: Roboto;
    }

    button {
      padding: 0px !important;
    }

    ${(props) =>
      props.isDarkMode &&
      css`
        h1,
        h2,
        a.link,
        b,
        .bottomDescription,
        span i {
          color: #ffffff !important;
        }
      `}

    .top-header {
      border: 1px solid red;
    }

    .ant-alert {
      width: 100%;
      max-width: 300px;
    }

    .ant-alert-success {
      max-width: 100%;
    }

    img {
      max-width: 250px;
      max-height: 90px;
    }

    div.titulos {
      text-align: center;
      margin: 30px 0 20px 0;

      h1 {
        color: #606060;
        font-style: normal;
        font-weight: 600;
        font-size: 29px;
        line-height: 115%;
        letter-spacing: -0.5px;
        margin-bottom: 12px;
      }

      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 132.5%;
        align-items: center;
        text-align: center;
        letter-spacing: -0.5px;
        color: #606060;
      }

      img {
        padding-top: 0px !important;
      }
    }

    @media (max-width: 1400px) {
      margin: 0;
    }

    @media (max-width: 900px) {
      justify-content: center;

      div.titulos {
        margin: 60px 0;
      }
    }
  }

  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Form = styled(DefaultForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-legacy-form-item {
    width: 100%;
    max-width: 300px;

    input {
      width: 100%;
    }
  }

  .ant-legacy-form-item-children button {
    border: none;
    color: ${({ theme, isDarkMode }) =>
      isDarkMode
        ? theme.themeDarkModeTextMasterColor
        : theme.themeTextMasterColor};

    background-color: ${({ theme, isDarkMode }) =>
      isDarkMode ? theme.themeDarkModeMasterColor : theme.themeMasterColor};
  }

  .bottomDescription {
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 12px;

    a {
      color: inherit;
      text-decoration: inherit;
      font-weight: bolder;
    }
  }
`;
