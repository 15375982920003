import {
  ON_SEARCH_GLOBALLY,
  ON_SEARCH_GLOBALLY_SUCCESS,
  SET_MODEL_CLASS,
} from 'shared/providers/redux/actionTypes';

export const onSearchGlobally = (search, model_class) => ({
  type: ON_SEARCH_GLOBALLY,
  payload: { search, model_class },
});

export const onSearchGloballySuccess = (results) => ({
  type: ON_SEARCH_GLOBALLY_SUCCESS,
  payload: { results },
});

export const setModelClass = (model) => ({
  type: SET_MODEL_CLASS,
  payload: { model },
});
