import {
  SET_CURRENT_STEP,
  SET_CHECKOUT_SESSION,
  SET_OPTIONS_FREIGHT_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_ERROR,
  SIGNUP_USER_SUCCESS,
  SIGNIN_USER_CHECKOUT,
  SIGNIN_USER_CHECKOUT_ERROR,
  SIGNIN_USER_CHECKOUT_SUCCESS,
  SET_RESET_SIGN_UP_FIELDS,
  SET_CHECKOUT_LOADER_TRUE,
  SET_CHECKOUT_LOADER_FALSE,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  loader: false,
  signUpSuccess: false,
  session: null,
  optionsFreight: null,
  currentStep: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_CHECKOUT: {
      return {
        ...state,
        loader: true,
      };
    }

    case SIGNIN_USER_CHECKOUT_ERROR: {
      return {
        ...state,
        loader: false,
      };
    }

    case SIGNIN_USER_CHECKOUT_SUCCESS: {
      return {
        ...state,
        loader: true,
      };
    }

    case SIGNUP_USER: {
      return {
        ...state,
        loader: true,
      };
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: true,
        signUpSuccess: true,
      };
    }

    case SIGNUP_USER_ERROR: {
      return {
        ...state,
        loader: false,
      };
    }

    case SET_CHECKOUT_SESSION: {
      return {
        ...state,
        session: action.payload,
      };
    }

    case SET_OPTIONS_FREIGHT_SUCCESS: {
      return {
        ...state,
        optionsFreight: action.payload,
      };
    }

    case SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload,
      };
    }

    case SET_RESET_SIGN_UP_FIELDS: {
      return {
        ...state,
        signUpSuccess: false,
      };
    }

    case SET_CHECKOUT_LOADER_TRUE: {
      return {
        ...state,
        loader: true,
      };
    }

    case SET_CHECKOUT_LOADER_FALSE: {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
