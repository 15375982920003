import {
  GET_MODULES,
  GET_MODULE_SESSION,
  FETCH_MODULES_SUCCESS,
  FETCH_MODULES_SESSION_SUCCESS,
} from 'shared/providers/redux/actionTypes';

export const fetchModules = courseSlug => ({
  type: GET_MODULES,
  payload: courseSlug,
});

export const fetchModuleSession = (courseSlug, moduleSlug) => ({
  type: GET_MODULE_SESSION,
  payload: { courseSlug, moduleSlug },
});

export const fetchModuleSessionSuccess = module => ({
  type: FETCH_MODULES_SESSION_SUCCESS,
  payload: module,
});

export const fetchModuleSuccess = modules => ({
  type: FETCH_MODULES_SUCCESS,
  payload: modules,
});
