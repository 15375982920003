import React from 'react';
import PropTypes from 'prop-types';
import { VscWarning, VscError } from 'react-icons/vsc';
import { AiFillCheckCircle } from 'react-icons/ai';

import { Container, Close } from './styles';

const Alert = ({
  message,
  title,
  error,
  warning,
  success,
  onClose,
  children,
  margin,
}) => {
  const errorType = { error, warning, success };

  const handleClose = () => {
    onClose();
  };

  return (
    <Container errorType={errorType} margin={margin}>
      <section className="content">
        {error ? (
          <VscError />
        ) : warning ? (
          <VscWarning />
        ) : (
          <AiFillCheckCircle className="success" />
        )}

        <section className="message">
          {title && <h1 className="title">{title}</h1>}

          {message ? (
            <h2 className="description">{message}</h2>
          ) : (
            <h2 className="description">{children}</h2>
          )}
        </section>

        <section className="close">
          <Close onClick={handleClose} />
        </section>
      </section>
    </Container>
  );
};

Alert.defaultProps = {
  title: '',
  error: false,
  warning: false,
  success: false,
  children: false,
  margin: '10px 0 25px 0',
  onClose: () => {},
};

Alert.propTypes = {
  message: PropTypes.bool.isRequired,
  title: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  onClose: PropTypes.func,
  margin: PropTypes.string,
  children: PropTypes.object,
};

export default Alert;
