import styled, { css } from 'styled-components';

export const SpanMiniSideBar = styled.div`
  p {
    margin: 0px 0px 0px 5px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

    span {
      display: flex;
      white-space: normal;
      line-height: 1.5;
    }
  }

  span {
      display: flex;
      white-space: normal;
      line-height: 1.5;
    }
`;

export const Container = styled.div`
  border: none;

  .badge-container {
    height: 16px;
    padding: 0 6px;
    margin-left: 8px;
    background: #038fde;
    border-radius: 25px;

    .badge {
      color: #fff;
      font-size: 0.7em;
      font-weight: 500;
      margin-top: -3px;
    }
  }

  i {
    margin-right: 10px !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    font-size: ${({ navStyle }) =>
    navStyle === 'NAV_STYLE_FIXED' ? '1em' : '1.3em'};
  }

  .ant-menu-submenu-title {
    min-height: 45px !important;
    height:  100% !important;
  }

  ul.ant-menu {
    border: none !important;

    li.ant-menu-item {
      display: flex;
      align-items: center;
      min-height: 45px !important;
      height:  100% !important;

      a {
        display: flex;
        align-items: center;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .track-horizontal {
    display: none;
  }
  .ant-badge {
    margin: 0;
  }

  .ant-menu-submenu-active {
    color: yellowgreen !important;
  }

  .ant-menu-submenu-title {
    display: flex;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .gx-sidebar-notifications {
    height: auto;
    padding: 10px !important;
    border: none !important;
  }
`;

export const Badge = styled.div`
  box-shadow: none;
  background-color: ${({ background }) => background};
  font-size: 0.8em;
  border-radius: 5px;
  margin-left: 5px;
  padding: 0px 3px;
  height: auto;
  line-height: 16px;

  ${(props) =>
    props.top === 'true' &&
    css`
      top: -10px;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SubMenuTitle = styled.span`
  display: flex;
  align-items: center;
  height: 45px;
  margin-right: 8px;
`;
