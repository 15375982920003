import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { validateEmail } from 'shared/utils/regex';

import Input from '../../../../components/Input';
import EmailInvalid from '../../../../components/EmailInvalid';
import PasswordInvalid from '../../../../components/PasswordInvalid';

import { Container } from './styles';
import { emailProviderValidator } from '../../../../../../../utils/emailProviderValidator';

const LoginComponent = ({ handleSubmit }) => {
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password, setPassword] = useState('');

  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const settings = useSelector(({ tenancy }) => tenancy.tenantData.settings);

  useEffect(() => {
    if (!email) {
      setEmailInvalid(false);
    }
  }, [email]);

  useEffect(() => {
    if (password?.length < 6) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    if (!password) {
      setPasswordInvalid(false);
    }
  }, [password]);

  const handleLogin = (e) => {
    const isMouseClick = e.target.type === 'button';
    const isKeyEnter = e.keyCode === 13;

    if (handleSubmit && (isMouseClick || isKeyEnter))
      handleSubmit({ email, password });
  };

  return (
    <Container $isDarkMode={isDarkMode} onKeyUp={handleLogin}>
      <Container.Item>
        <Input
          isDarkMode={isDarkMode}
          autoComplete="off"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          value={email}
          onChange={(e) => {
            const emailValue = e.target.value;

            setEmail(
              emailValue?.toLowerCase().replace(/[^a-zA-Zs0-9@._-]/g, '')
            );
          }}
          onBlur={() => {
            if (email.length) {
              if (validateEmail(email) && emailProviderValidator(email)) {
                setEmailInvalid(false);
              } else {
                setEmailInvalid(true);
              }
            }
          }}
          onFocus={() => {
            setEmailInvalid(false);
          }}
          placeholder="Email"
        />
        {emailInvalid && <EmailInvalid isDarkMode={isDarkMode} />}
      </Container.Item>

      <Container.Item>
        <Input
          isDarkMode={isDarkMode}
          autoComplete="off"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          value={password}
          onChange={(e) => {
            const passwordValue = e.target.value;

            setPassword(passwordValue?.replace(' ', ''));
          }}
          placeholder={intl?.formatMessage({ id: 'authModal.login.password' })}
        />
        {passwordInvalid && <PasswordInvalid isDarkMode={isDarkMode} />}
      </Container.Item>

      {settings?.users?.userLoginDescriptionBottom ? (
        <div
          className="bottomDescription"
          dangerouslySetInnerHTML={{
            __html: settings?.users?.userLoginDescriptionBottom,
          }}
        />
      ) : null}

      <Container.Item className="gx-text-center">
        <Button
          type="primary"
          htmlType="button"
          size="large"
          className="login"
          disabled={!password || !email || emailInvalid || passwordInvalid}
          onClick={handleLogin}
        >
          {intl?.formatMessage({ id: 'authModal.login.enter' })}
        </Button>
      </Container.Item>
    </Container>
  );
};

LoginComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginComponent;
