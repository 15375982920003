import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ON_SEARCH_GLOBALLY } from 'shared/providers/redux/actionTypes';

import api from 'shared/infra/services/tenantAPI';
import { onSearchGloballySuccess } from './actions';

async function onSearchGloballyRequest({ search, model_class }) {
  try {
    const response = await api.get('/globalsearch', {
      params: {
        term: search,
        model_class,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function* onSearchGlobally({ payload }) {
  const results = yield call(onSearchGloballyRequest, payload);

  yield put(onSearchGloballySuccess(results));
}

export default function* rootSaga() {
  yield all([takeLatest(ON_SEARCH_GLOBALLY, onSearchGlobally)]);
}
