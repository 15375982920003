import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FaAward } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { clearPurchaseOptions } from 'shared/providers/redux/actions';
import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';
import staticIntlTranslatorToString from 'shared/utils/staticIntlTranslatorToString';
import { BackgroundBlur, Content } from './styles';

function PurchaseOptionsModal() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);

  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');
  const { entity, context, handlePurchase, show } = useSelector(
    ({ methodsPayment }) => methodsPayment
  );

  const handleClosePurchaseOptionsModal = () => {
    setShowPurchaseOptions(false);
    dispatch(clearPurchaseOptions());
  };

  const handlePurchaseOptions = (e) => {
    const classes = String(e.target.className);

    const isTheElement = classes.includes('p-opt');

    if (!isTheElement) {
      handleClosePurchaseOptionsModal();
    }
  };

  useEffect(() => {
    setShowPurchaseOptions(show);
  }, [show]);

  useEffect(() => {
    if (showPurchaseOptions) {
      document.addEventListener('click', handlePurchaseOptions, false);
    }

    return () =>
      document.removeEventListener('click', handlePurchaseOptions, false);

    // eslint-disable-next-line
  }, [showPurchaseOptions]);

  const goToCheckout = (plan = null) => {
    let paramType;
    let entityType;

    switch (context) {
      case 'course':
        entityType = 'course';
        paramType = 'course_id';
        break;

      case 'product_bundle':
        entityType = 'product-bundle';
        paramType = 'product_bundle_id';
        break;

      case 'media_space_content':
        entityType = 'media-space-content';
        paramType = 'media_space_content_id';
        break;

      default:
        break;
    }

    if (plan) {
      entityType = 'plan';
      paramType = 'plan_id';

      if (handlePurchase) handlePurchase(entityType, paramType, plan);
      handleClosePurchaseOptionsModal();

      return;
    }

    if (handlePurchase) handlePurchase(entityType, paramType, entity);
    handleClosePurchaseOptionsModal();
  };

  return (
    <>
      {showPurchaseOptions && (
        <BackgroundBlur>
          <BackgroundBlur />
          <Content className="p-opt" isDarkMode={isDarkMode}>
            {!!entity?.individual_sale && (
              <div className="p-opt option-card" onClick={() => goToCheckout()}>
                <span className="p-opt badge green">
                  <StaticIntlTranslator id="enrollmentItems.singlePayment" />
                </span>

                <div className="p-opt option-info">
                  <h5 className="p-opt">{entity.price.replace('R$', 'R$ ')}</h5>
                  <span className="p-opt">
                    <StaticIntlTranslator id="enrollmentItems.singlePayment" />
                  </span>
                </div>

                <div className="p-opt icon-container">
                  <FaAward className="p-opt" size={30} color="#ccc" />
                </div>
              </div>
            )}

            {entity.plans.map((plan) => {
              if (plan?.status !== 'published') return null;

              return (
                <div
                  key={plan.id}
                  className="p-opt option-card"
                  onClick={() => goToCheckout(plan)}
                >
                  <span className="p-opt badge blue">
                    <StaticIntlTranslator id="enrollmentItems.subscription" />
                  </span>

                  <div className="p-opt option-info">
                    <h5 className="p-opt">{plan.price.replace('R$', 'R$ ')}</h5>
                    <span className="p-opt">
                      {staticIntlTranslatorToString(plan.title, intl)}
                    </span>
                    <span className="p-opt p-opt-font-size">
                      (
                      {staticIntlTranslatorToString(
                        plan?.interval_description.toLowerCase(),
                        intl
                      )}
                      )
                    </span>
                  </div>

                  <div className="p-opt icon-container">
                    <FaAward className="p-opt" size={30} color="#ccc" />
                  </div>
                </div>
              );
            })}
          </Content>
        </BackgroundBlur>
      )}
    </>
  );
}

export default PurchaseOptionsModal;
