import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import api from 'shared/infra/services/tenantAPI';
import {
  fetchCertificateInfoSuccess,
  fetchCertificateValidationSuccess,
} from 'shared/providers/redux/actions';
import {
  GET_CERTIFICATE_INFO,
  GET_CERTIFICATE_VALIDATION,
} from 'shared/providers/redux/actionTypes';

const getCertificateInfoRequest = async (payload) => {
  try {
    const response = await api.post(`create/certificate/${payload}`);
    return response.data;
  } catch (error) {
    const { data } = error.response;
    return data;
  }
};

const getCertificateValidationRequest = async (payload) => {
  try {
    const response = await api.get(`certificate/${payload}`);
    return response.data;
  } catch (error) {
    const { data } = error.response;
    return data;
  }
};

function* getCertificateInfo({ payload }) {
  const { attrs } = yield call(getCertificateInfoRequest, payload);
  yield put(fetchCertificateInfoSuccess(attrs));
}

function* getCertificateValidation({ payload }) {
  const { data } = yield call(getCertificateValidationRequest, payload);
  yield put(fetchCertificateValidationSuccess(data));
}

export function* onGetCertificateInfo() {
  yield takeLatest(GET_CERTIFICATE_INFO, getCertificateInfo);
}

export function* onGetCertificateValidation() {
  yield takeLatest(GET_CERTIFICATE_VALIDATION, getCertificateValidation);
}

export default function* rootSaga() {
  yield all([fork(onGetCertificateInfo), fork(onGetCertificateValidation)]);
}
