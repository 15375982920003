import styled, { css } from 'styled-components';
import contrastAutoAdjust from 'shared/utils/contrastAutoAdjust';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1800px;
  width: 100%;
  padding: 1.5rem 0;

  figure {
    margin: 0;

    img {
      height: 60px;
      min-width: 60px;
    }
  }

  .plataformInfo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .plataformInfo {
      padding-bottom: 1.5rem;
    }
  }
`;

export const SignInAndSignUpButton = styled.button`
  cursor: pointer;
  margin: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  padding: 0 22px;
  letter-spacing: -0.03em;

  height: 3rem;
  border-radius: 8px;

  border: none;
  box-shadow: 15px 15px 100px rgba(0, 0, 0, 0.05);

  transition: 0.4s all;

  ${(props) =>
    props.$isDarkMode || props.$generalTheme?.themeTopBar === 'dark'
      ? css`
          background: #ffffff;
          color: #1a1a1a;
        `
      : props.$generalTheme?.themeTopBar === 'advanced'
      ? css`
          background: ${props.$generalTheme?.themeTopBarIconsColor};
          color: ${props.$generalTheme?.themeTopBarBackgroundColor};
        `
      : props.$generalTheme?.themeTopBar === 'light'
      ? css`
          background: #1a1a1a;
          color: #ffffff;
        `
      : css`
          background: #ffffff;
          color: ${props.$generalTheme?.themeMasterColor};
        `}

  &:hover {
    filter: brightness(85%);
  }
`;

export const InfoMessages = styled.section`
  margin: 0 20px;

  h1 {
    margin: 0;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 150%;

    letter-spacing: -0.03em;

    ${(props) =>
      props.$isDarkMode || props.$generalTheme?.themeTopBar === 'dark'
        ? css`
            color: #f5f5f5;
          `
        : props.$generalTheme?.themeTopBar === 'advanced'
        ? css`
            color: ${props.$generalTheme?.themeTopBarIconsColor};
          `
        : props.$generalTheme?.themeTopBar === 'light'
        ? css`
            color: #1a1a1a;
            text-shadow: 0 0 20px white;
          `
        : css`
            color: ${contrastAutoAdjust({
              background: props.$generalTheme?.themeMasterColor,
              text: '#f5f5f5',
            })};
          `}

    @media (max-width: 500px) {
      text-align: center;
    }
  }
`;
