import styled, { css } from 'styled-components';
import { Layout as LayoutAnt } from 'antd';

export const Content = styled(LayoutAnt.Content)`
  ${(props) =>
    props.sidebarIsRendered
      ? css`
          padding: 60px 0 0 80px;

          @media (max-width: 990px) {
            padding: 60px 0 0 0;
          }
        `
      : css`
          padding: 60px 0 0 0;
        `}

  ${(props) =>
    props.isDarkMode &&
    css`
      background: linear-gradient(to bottom, #181818, #242424);

      .ant-spin {
        .ant-spin-dot-item {
          background: ${(props) => props.tenantTheme.themeDarkModeMasterColor};
        }
      }
    `}
`;

export const Layout = styled(LayoutAnt)`
  && {
    height: 100% !important;
  }
`;
