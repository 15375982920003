import {
  FETCH_USER_LINK_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_LOGGED_USER_SUCCESS,
  UPDATE_LOGGED_USER,
  FETCH_DEVICES_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_INTERESTS_SUCCESS,
  FETCH_USER_PHOTO_SUCCESS,
  FETCH_PASSWORD_STATUS_SUCCESS,
  FETCH_USER_NOT_FOUND,
  SET_PAGE,
  UPDATE_DISPLAY_NAME,
  SET_USER_LINK,
  UPDATE_USER_LINK,
  UPDATE_BIOGRAPHY,
  UPDATE_COVER_IMAGE,
  DELETE_COVER_IMAGE,
  UPDATE_PROFILE_IMAGE,
  DELETE_PROFILE_IMAGE,
  UPDATE_SITE,
  UPDATE_NAME,
  UPDATE_PASSWORD,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_PUBLIC_EMAIL,
  UPDATE_PRIVATE_EMAIL,
  UPDATE_PUBLIC_PHONE,
  UPDATE_PUBLIC_ADDRESS,
  UPDATE_PRIVATE_PHONE,
  UPDATE_USER_LINK_SUCCESS,
  CREATE_PRIVATE_PHONE,
  CLEAR_STATUS_PASSWORD,
  CHECK_USERNAME_SUCCESS,
  GET_FOLLOWING,
  GET_FOLLOWERS,
  FETCH_FOLLOWING_SUCCESS,
  FETCH_FOLLOWERS_SUCCESS,
  GET_INTERESTS,
  SET_PASSWORD_LENGTH,
  REMOVE_LINK,
  REMOVE_LINK_SUCCESS,
  GET_USER,
  GET_LOGGED_USER,
  SET_CONTENT_LENGTH,
  SET_USERNAME,
  CHECK_USERNAME,
  GET_USER_DEVICES,
  GET_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  GET_ALL_AUTHORS,
  GET_ALL_AUTHORS_SUCCESS,
  GET_ALL_NON_AUTHOR_USERS,
  GET_ALL_NON_AUTHOR_USERS_SUCCESS,
  FOLLOW_USER,
  FOLLOW_USER_SUCCESS,
  UNFOLLOW_USER,
  GET_USER_MEDIA_SPACE_CONTENT,
  GET_USER_MEDIA_SPACE_CONTENT_SUCCESS,
  CLEAN_USER_DATA,
  LAST_TOPICS_USER,
  LAST_TOPICS_USER_SUCCESS,
  GET_USER_COLLECTION,
  GET_USER_COLLECTION_SUCCESS,
  GET_USER_COMMENTS,
  GET_USER_COMMENTS_SUCCESS,
  SET_IS_FOLLOWING_USER,
} from 'shared/providers/redux/actionTypes';

export const onGetUser = (username) => ({
  type: GET_USER,
  payload: username,
});

export const setIsFollowingUser = (isFollowing) => ({
  type: SET_IS_FOLLOWING_USER,
  payload: { isFollowing },
});

export const onUpdatePassword = (currentPassword, newPassword) => ({
  type: UPDATE_PASSWORD,
  payload: {
    current_password: currentPassword,
    new_password: newPassword,
  },
});

export const fetchPassword = (statusPassword) => ({
  type: FETCH_PASSWORD_STATUS_SUCCESS,
  payload: statusPassword,
});

export const fetchUserNotFound = () => ({
  type: FETCH_USER_NOT_FOUND,
});

export const onClearStatusPassword = () => ({
  type: CLEAR_STATUS_PASSWORD,
});

export const onSetPasswordLength = (length) => ({
  type: SET_PASSWORD_LENGTH,
  payload: length,
});

export const onGetLoggedUser = (username) => ({
  type: GET_LOGGED_USER,
  payload: username,
});

export const onSetUserInfo = (displayName, biography, user) => ({
  type: UPDATE_DISPLAY_NAME,
  payload: {
    display_name: displayName,
    biography,
    user,
  },
});

export const onCheckUsername = (username, user) => ({
  type: CHECK_USERNAME,
  payload: {
    username,
    user,
  },
});

export const fetchCheckUsername = (username) => ({
  type: CHECK_USERNAME_SUCCESS,
  payload: username,
});

export const onUpdateUsername = (username, user) => ({
  type: SET_USERNAME,
  payload: {
    username,
    user,
  },
});
export const onUpdateCoverImage = (img, user) => ({
  type: UPDATE_COVER_IMAGE,
  payload: {
    cover_img: img,
    user,
  },
});
export const onUpdateProfileImage = (img, user) => ({
  type: UPDATE_PROFILE_IMAGE,
  payload: { profile_img: img, user },
});

export const onDeleteCoverImage = (user) => ({
  type: DELETE_COVER_IMAGE,
  payload: user,
});

export const onDeleteProfileImage = (user) => ({
  type: DELETE_PROFILE_IMAGE,
  payload: user,
});

export const onUpdateBiography = (biography, user) => ({
  type: UPDATE_BIOGRAPHY,
  payload: {
    biography,
    user,
  },
});
export const onUpdateSite = (site, user) => ({
  type: UPDATE_SITE,
  payload: {
    site,
    user,
  },
});
export const onUpdateName = (name, user) => ({
  type: UPDATE_NAME,
  payload: {
    display_name: name,
    user,
  },
});
export const onUpdateFirstName = (name, user) => ({
  type: UPDATE_FIRST_NAME,
  payload: {
    first_name: name,
    user,
  },
});
export const onUpdateLastName = (name, user) => ({
  type: UPDATE_LAST_NAME,
  payload: {
    last_name: name,
    user,
  },
});
export const onUpdatePublicEmail = (email, user) => ({
  type: UPDATE_PUBLIC_EMAIL,
  payload: {
    email_public: email,
    user,
  },
});
export const onUpdatePrivateEmail = (email, user) => ({
  type: UPDATE_PRIVATE_EMAIL,
  payload: {
    email,
    user,
  },
});
export const onUpdatePublicPhone = (phone, user) => ({
  type: UPDATE_PUBLIC_PHONE,
  payload: {
    phone,
    user,
  },
});
export const onUpdatePublicAddress = (address, user) => ({
  type: UPDATE_PUBLIC_ADDRESS,
  payload: {
    address,
    user,
  },
});
export const onUpdatePrivatePhone = ({
  id,
  countryCode,
  areaCode,
  number,
}) => ({
  type: UPDATE_PRIVATE_PHONE,
  payload: {
    id,
    country_code: countryCode,
    area_code: areaCode,
    number,
  },
});
export const createPrivatePhone = ({ countryCode, areaCode, number }) => ({
  type: CREATE_PRIVATE_PHONE,
  payload: {
    country_code: countryCode,
    area_code: areaCode,
    number,
  },
});

export const onSetUserLink = (name, url, user) => ({
  type: SET_USER_LINK,
  payload: {
    name,
    url,
    user,
  },
});

export const onUpdateUserLink = (id, name, url, user) => ({
  type: UPDATE_USER_LINK,
  payload: { id, name, url, user },
});

export const removeLink = (id, newLinks) => ({
  type: REMOVE_LINK,
  payload: {
    id,
    newLinks,
  },
});

export const removeLinkSuccess = (link) => ({
  type: REMOVE_LINK_SUCCESS,
  payload: link,
});

export const updateUserLinkSuccess = (links) => ({
  type: UPDATE_USER_LINK_SUCCESS,
  payload: links,
});

export const fetchUserLink = (link) => ({
  type: FETCH_USER_LINK_SUCCESS,
  payload: link,
});

export const fetchUserPhoto = (user) => ({
  type: FETCH_USER_PHOTO_SUCCESS,
  payload: user,
});

export const onSetContentLength = (length) => ({
  type: SET_CONTENT_LENGTH,
  payload: length,
});

export const fetchOneUser = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchLoggedUser = (user) => ({
  type: FETCH_LOGGED_USER_SUCCESS,
  payload: user,
});

export const updateLoggedUser = (updatedLoggedUserField) => ({
  type: UPDATE_LOGGED_USER,
  payload: updatedLoggedUserField,
});

export const getAllInterests = () => ({
  type: GET_INTERESTS,
});

export const getFollowing = (username, page) => ({
  type: GET_FOLLOWING,
  payload: { username, page },
});

export const getFollowers = (username, page) => ({
  type: GET_FOLLOWERS,
  payload: { username, page },
});

export const fetchAllFollowing = (following) => ({
  type: FETCH_FOLLOWING_SUCCESS,
  payload: following,
});

export const fetchAllFollowers = (followers) => ({
  type: FETCH_FOLLOWERS_SUCCESS,
  payload: followers,
});

export const fetchAllInterests = (interests) => ({
  type: FETCH_INTERESTS_SUCCESS,
  payload: interests,
});

export const fetchAllCountries = (countries) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: countries,
});

export const onGetUserDevices = () => ({
  type: GET_USER_DEVICES,
});

export const fetchAllDevices = (devices) => ({
  type: FETCH_DEVICES_SUCCESS,
  payload: devices,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page,
});

export const getAllUsers = (page, length) => ({
  type: GET_ALL_USERS,
  payload: { page, length },
});

export const getAllUsersSuccess = (users) => ({
  type: FETCH_ALL_USERS_SUCCESS,
  payload: users,
});

export const getAllAuthors = (page, length) => ({
  type: GET_ALL_AUTHORS,
  payload: { page, length },
});

export const getAllAuthorsSuccess = (authors) => ({
  type: GET_ALL_AUTHORS_SUCCESS,
  payload: authors,
});

export const getAllNonAuthorUsers = (page) => ({
  type: GET_ALL_NON_AUTHOR_USERS,
  payload: page,
});

export const getAllNonAuthorUsersSuccess = (users) => ({
  type: GET_ALL_NON_AUTHOR_USERS_SUCCESS,
  payload: users,
});

export const followUser = ({ followMethod, user, loggedUser }) => ({
  type: FOLLOW_USER,
  payload: { followMethod, user, loggedUser },
});

export const followUserSuccess = (succeeded) => ({
  type: FOLLOW_USER_SUCCESS,
  payload: succeeded,
});

export const unfollowUser = ({ followMethod, user, loggedUser }) => ({
  type: UNFOLLOW_USER,
  payload: { followMethod, user, loggedUser },
});

export const getUserMediaSpaceContent = (username) => ({
  type: GET_USER_MEDIA_SPACE_CONTENT,
  payload: { username },
});

export const getUserMediaSpaceContentSuccess = (contents) => ({
  type: GET_USER_MEDIA_SPACE_CONTENT_SUCCESS,
  payload: { contents },
});

export const cleanUserData = () => ({
  type: CLEAN_USER_DATA,
});

export const lastTopicsUser = (user_id, currentePage = 1) => ({
  type: LAST_TOPICS_USER,
  payload: { user_id, currentePage },
});

export const lastTopicsUserSuccess = (posts) => ({
  type: LAST_TOPICS_USER_SUCCESS,
  payload: { posts },
});

export const getUserCollection = (username, content_per_page) => ({
  type: GET_USER_COLLECTION,
  payload: { username, content_per_page },
});

export const getUserCollectionSuccess = (collections) => ({
  type: GET_USER_COLLECTION_SUCCESS,
  payload: { collections },
});

export const getUserComments = (username, current_page) => ({
  type: GET_USER_COMMENTS,
  payload: { username, current_page },
});

export const getUserCommentsSuccess = (collections) => ({
  type: GET_USER_COMMENTS_SUCCESS,
  payload: { collections },
});
