import React from 'react';
import { Provider } from 'react-redux';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import 'shared/assets/vendors/style';
import 'shared/styles/wieldy.less';
import '@ant-design/compatible/assets/index.css';
import 'react-quill/dist/quill.snow.css';
import configureStore, { history } from 'shared/providers/redux/store';
import ErrorBoundary from '../ErrorBoundary';
import App from './App';

export const store = configureStore();

// Sentry.init({
//   dsn: "https://7371d97277a64430ac07945bea3f1e72@o924488.ingest.sentry.io/5904558",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
//     }),
//   ],
//   tracesSampleRate: 0.2,
// });

const AppWrapper = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default AppWrapper;
