import styled, { css } from 'styled-components';
import { Card as CardANTD } from 'antd';

export const Card = styled(CardANTD)`
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.theme === 'dark' &&
    css`
      sup.ant-scroll-number.ant-badge-count {
        box-shadow: none;
      }

      ${props.transparentDarkDisabled
        ? css`
            background-color: #303030;
          `
        : css`
            backdrop-filter: blur(5px) !important;
            background-color: #ffffff1c !important;
          `}

      .ant-card-head {
        border-bottom: 1px solid #242424 !important;
      }

      svg.recharts-surface {
        g {
          path {
            stroke: transparent !important;
          }
        }
      }

      .ant-card-head {
        h1,
        h2,
        h3,
        div,
        svg {
          color: #ffffff;
        }

        p,
        span {
          color: #f7f7f7;
        }
      }
    `}
`;
