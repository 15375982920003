import React, { memo } from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

import StaticIntlTranslator from 'shared/components/atoms/StaticIntlTranslator';

const EmptyData = memo(({ style }) => {
  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 80 }}
      style={{ ...style }}
      description={
        <span>
          <StaticIntlTranslator id="message.noData" />
        </span>
      }
    />
  );
});

EmptyData.defaultProps = {
  style: {},
};

EmptyData.propTypes = {
  style: PropTypes.object,
};

export default EmptyData;
