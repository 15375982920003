import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  showAuthLoader,
  hideAuthMessage,
  confirmEmail,
} from 'shared/providers/redux/actions';

import { ContainerConfirm } from './styles';
import ConfirmationSuccess from './ConfirmationSuccess';
import ConfirmationFailed from './ConfirmationFailed';

const EmailConfirmation = ({
  match,
  confirmEmail,
  alertMessage,
  showMessage,
  hideAuthMessage,
}) => {
  const intl = useIntl();
  const globalDispatch = useDispatch();
  const { token } = match.params;

  const [confirmationFailed, setConfirmationFailed] = useState(false);

  const confirmEmailLoader = useSelector(
    ({ auth }) => auth?.confirmEmailLoader
  );
  const confirmEmailSuccess = useSelector(
    ({ auth }) => auth?.confirmEmailSuccess
  );

  useEffect(() => {
    if (token) {
      confirmEmail(token);
    }
  }, [token]);

  useEffect(() => {
    if (showMessage && alertMessage) {
      setConfirmationFailed(true);
      globalDispatch(hideAuthMessage());
    }

    // eslint-disable-next-line
  }, [showMessage, hideAuthMessage, alertMessage]);



  return (
    <ContainerConfirm>
      <div
        className="modal"
        style={{ padding: '20px 30px', position: 'absolute' }}
      >
        <div
          className="gx-login-header gx-text-center"
          style={{ marginBottom: 0 }}
        />

        {token && (
          <div className="content-container">
            {confirmEmailLoader && !confirmationFailed ? (
              <>
                <h1>{intl.formatMessage({ id: 'confirming-your-account' })}</h1>
                <div className="spin">
                  <Spin />
                </div>
              </>
            ) : confirmationFailed ? (
              <ConfirmationFailed />
            ) : (
              confirmEmailSuccess && <ConfirmationSuccess />
            )}
          </div>
        )}
      </div>
    </ContainerConfirm>
  );
};

EmailConfirmation.defaultProps = {
  alertMessage: null,
};

EmailConfirmation.propTypes = {
  alertMessage: PropTypes.string,
  match: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  showMessage: PropTypes.any.isRequired,
  hideAuthMessage: PropTypes.any.isRequired,
};

const mapStateToProps = ({ auth, warnings }) => {
  const { loader, authUser } = auth;
  const { alertMessage, showMessage } = warnings;

  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  showAuthLoader,
  confirmEmail,
  hideAuthMessage,
})(EmailConfirmation);
