import {
  GET_ALL_PAY_ORDERS,
  GET_SESSION_PAY_ORDER,
  FETCH_ALL_PAY_ORDERS_SUCCESS,
  FETCH_SESSION_PAY_ORDER_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INIT_STATE = {
  ordersList: [],
  orderSession: null,
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PAY_ORDERS: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case FETCH_ALL_PAY_ORDERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        ordersList: action.payload,
      };
    }

    case GET_SESSION_PAY_ORDER: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_SESSION_PAY_ORDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        orderSession: action.payload,
      };
    }

    default:
      return state;
  }
};
