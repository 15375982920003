import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const AvatarUser = styled(Avatar)`
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .ant-avatar-string {
    ${({ styles }) => styles}
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const userInitials = (item, history, comment, avatarSize, styles) => {
  const info = [];

  if (comment) {
    try {
      const { commenter } = item;
      const { display_name, avatar_url, username } = commenter;

      info.push({ display_name, avatar_url, username });
    } catch (error) {
      return (
        <AvatarUser
          styles={styles}
          className={`${avatarSize} gx-bg-primary`}
          onClick={() => history.push('/')}
        >
          not user
        </AvatarUser>
      );
    }
  } else if (item) {
    const { display_name, avatar_url, username, photo } = item;
    info.push({ display_name, avatar_url: avatar_url || photo, username });
  } else {
    info.push({ display_name: '', avatar_url: '', username: '' });
  }

  const { display_name, avatar_url, username } = info[0];

  if (!avatar_url) {
    const nameSplit = display_name.split(' ');
    if (display_name.split(' ').length === 1) {
      const initials = nameSplit[0].charAt(0).toUpperCase();
      return (
        <AvatarUser
          styles={styles}
          className={`${avatarSize} gx-bg-primary`}
          onClick={() => history.push(`/user/${username}`)}
        >
          {initials}
        </AvatarUser>
      );
    }

    const initials =
      nameSplit[0].charAt(0).toUpperCase() +
      nameSplit[1].charAt(0).toUpperCase();

    return (
      <AvatarUser
        styles={styles}
        className={`${avatarSize} gx-bg-cyan`}
        onClick={() => history.push(`/user/${username}`)}
      >
        {initials}
      </AvatarUser>
    );
  }

  return (
    <AvatarUser
      styles={styles}
      className={avatarSize}
      src={avatar_url}
      onClick={() => history.push(`/user/${username}`)}
    />
  );
};

export default userInitials;
