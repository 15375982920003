import styled from 'styled-components';

import { FiAlertTriangle } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-family: Roboto;

  /* height: 30px; */
  margin: 0 0 0 8px;
  padding-top: 2px;
  color: ${(props) => (props.$isDarkMode ? '#ffffff' : '#1a1a1a')};
`;

export const Image = styled(FiAlertTriangle)`
  color: ${(props) => (props.$isDarkMode ? '#fef65b' : '#1a1a1a')};
  fill: ${(props) => (props.$isDarkMode ? '#303030' : '#fef65b')};

  font-size: 20px;
`;
