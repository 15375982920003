import React from 'react';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import api from 'shared/infra/services/tenantAPI';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import {
  SET_OPTIONS_FREIGHT,
  SIGNIN_USER_CHECKOUT,
} from 'shared/providers/redux/actionTypes';
import { login, setLocalUser } from 'shared/infra/services/auth';
import { onGetLoggedUser, onSetPasswordLength } from 'modules/User/actions';
import {
  signInUserError,
  signInUserSuccess,
  setOptionsFreightSuccess,
} from 'shared/providers/redux/actions';

const setOptionsFreightRequest = async (payload) => {
  try {
    const response = await api.post('shipping/quote', {
      cart_name: 'ensiniopay',
      cep: payload,
    });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* setOptionsFreight({ payload }) {
  const optionsFreight = yield call(setOptionsFreightRequest, payload);
  if (optionsFreight) yield put(setOptionsFreightSuccess(optionsFreight));
}

export function* onSetOptionsFreight() {
  yield takeEvery(SET_OPTIONS_FREIGHT, setOptionsFreight);
}

const signInUserRequest = async (payload) => {
  const { email, password } = payload;

  try {
    const response = await api.post('login', {
      email,
      password,
    });

    setLocalUser(JSON.stringify(response.data));
    const parseToken = response.data.token.plainTextToken.split('|')[1];
    login(parseToken);

    api.interceptors.request.use(async (config) => {
      const param = config;
      param.headers.Authorization = `Bearer ${parseToken}`;

      return config;
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* signInUser({ payload }) {
  const user = yield call(signInUserRequest, payload);

  if (user.message) {
    openNotificationWithIcon(
      'error',
      'Erro de autenticação',
      <li style={{ fontWeight: 500 }}>{user.message}</li>,
      8
    );
    yield put(signInUserError());
  } else {
    localStorage.setItem('user_id', user.data.id);
    yield put(signInUserSuccess(user?.data.id));
    yield put(onGetLoggedUser(user?.data.username));
    yield put(onSetPasswordLength(payload.password.length));

    yield put(push('/payment'));
  }

  if (!user) {
    const errorResponse = user?.response?.data;
    const errorMessage =
      errorResponse?.errors?.email[0] || errorResponse?.message;

    openNotificationWithIcon(
      'error',
      'Erro de autenticação',
      <li style={{ fontWeight: 500 }}>{errorMessage}</li>,
      8
    );
    yield put(signInUserError());
  }
}

export function* onSignInUser() {
  yield takeEvery(SIGNIN_USER_CHECKOUT, signInUser);
}

export default function* rootSaga() {
  yield all([fork(onSetOptionsFreight), fork(onSignInUser)]);
}
