import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import lock from 'shared/assets/icons/Lock.svg';
import { setOpenModal } from 'shared/providers/redux/actions';
import { Container, Modal } from './styles';

function ExclusivePage({ accessWarn }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector(({ settings }) => settings.mode === 'dark');

  return (
    <Container>
      <Modal isDarkMode={isDarkMode}>
        <img src={lock} alt="lock_logo" />
        <strong>{accessWarn || 'Esta página é exclusiva para membros'}</strong>
        <div className="buttons">
          <Button onClick={() => history.push('/')}>
            Voltar à plataforma
          </Button>
          <Button onClick={() => dispatch(setOpenModal(true))} type="primary">
            Fazer login
          </Button>
        </div>
      </Modal>
    </Container>
  );
}

ExclusivePage.defaultProps = {
  accessWarn: null,
};

ExclusivePage.propTypes = {
  accessWarn: PropTypes.string,
};

export default ExclusivePage;
