import React from 'react';
import {
  all,
  put,
  fork,
  call,
  delay,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { createIntl } from 'react-intl';
import AppLocale from 'shared/providers/i18n';

import {
  setUserSetting,
  removeFieldUserSetting,
} from 'modules/Checkout/entities/UserSetting/actions';
import api from 'shared/infra/services/tenantAPI';
import openNotificationWithIcon from 'shared/utils/openNotificationWithIcon';
import {
  SET_PIX_PAYMENT,
  SET_BANK_SLIP_PAYMENT,
  SET_CREDIT_CARD_PAYMENT,
} from 'shared/providers/redux/actionTypes';
import { setCurrentStep } from 'modules/Checkout/actions';
import {
  setPaymentErrors,
  setPixPaymentSuccess,
  setBankSlipPaymentSuccess,
  removeCouponBookingSuccess,
  setCreditCardPaymentSuccess,
} from 'shared/providers/redux/actions';

const setPixRequest = async (payload) => {
  const {
    phone,
    document,
    documentType,
    paramType,
    typeEntity,
    entityType,
    entityId,
    addressId,
    couponCode,
    couponBookingId,
  } = payload;

  try {
    const response = await api.post(`pay/${entityType}/pix`, {
      phone_number: phone,
      [paramType]: entityId,
      address_id: addressId,
      type: documentType,
      type_entity: typeEntity,
      coupon: couponCode,
      coupon_booking: couponBookingId,
      document,
    });
    return response.data;
  } catch (error) {
    const { data } = error.response;

    return data;
  }
};

const setBankSlipPaymentRequest = async (payload) => {
  const {
    phone,
    document,
    documentType,
    paramType,
    typeEntity,
    entityType,
    entityId,
    addressId,
    couponCode,
    couponBookingId,
  } = payload;

  try {
    const response = await api.post(`pay/${entityType}/boleto`, {
      phone_number: phone,
      [paramType]: entityId,
      address_id: addressId,
      type: documentType,
      type_entity: typeEntity,
      coupon: couponCode,
      coupon_booking: couponBookingId,
      document,
    });
    return response.data;
  } catch (error) {
    const { data } = error.response;

    return data;
  }
};

const setCreditCardPaymentRequest = async (payload) => {
  const {
    cardId,
    addressId,
    paramType,
    entityType,
    entityId,
    hasInstallments,
    installments,
    couponCode,
    couponBookingId,
  } = payload;

  try {
    const response = await api.post(`pay/${entityType}/credit_card`, {
      card_id: cardId,
      address_id: addressId,
      [paramType]: entityId,
      coupon: couponCode,
      coupon_booking: couponBookingId,
      installments: !hasInstallments ? '1' : installments,
    });
    return response.data;
  } catch (error) {
    const { data } = error.response;

    return data;
  }
};

function* setPixPayment({ payload }) {
  const { checkout } = yield select();
  const { currentStep } = checkout;

  const { entityType } = payload;
  const pixPayment = yield call(setPixRequest, payload);

  if (pixPayment.message) {
    const { errors, message } = pixPayment;

    const { settings } = yield select();
    const { locale } = settings;
    const currentAppLocale = AppLocale[locale.locale];
    const intl = createIntl({
      locale: locale.locale,
      messages: currentAppLocale.messages,
    });

    yield put(
      setPaymentErrors({ errors: errors ? Object.values(errors) : message })
    );

    const err = errors ? Object.values(errors) : message;
    const errorsMessage = Array.isArray(err)
      ? err.map((error) => <li key={error}>{error[0]}</li>)
      : err?.message || err?.attrs?.message || err;

    return openNotificationWithIcon(
      'error',
      intl.formatMessage({ id: 'eCommerce.error.payment.title' }),
      <ul>{errorsMessage}</ul>,
      6
    );
  }

  if (pixPayment?.attrs) {
    sessionStorage.setItem(
      'pixInfo',
      JSON.stringify(pixPayment?.attrs?.response)
    );

    yield put(setCurrentStep(currentStep + 1));
    yield put(setPixPaymentSuccess(pixPayment?.attrs?.response));
    yield delay(2000);
  }

  try {
    const subscriptionId =
      pixPayment?.attrs?.response?.transactions[0]?.subscription_id;

    if (entityType === 'plan' && subscriptionId)
      yield put(
        setUserSetting(
          `payment_method_${subscriptionId}`,
          'pix',
          'subscription_payment'
        )
      );
  } catch (error) {
    console.log('🚀 console turbo --> error', error);
  }

  const { userSetting } = yield select();
  const { settings } = userSetting;

  const couponCode = settings?.payment?.coupon_code;

  if (couponCode) {
    const fields = [
      'coupon_code',
      'coupon_entity_id',
      'coupon_booking_id',
      'coupon_entity_type',
      'coupon_discount_value',
      'coupon_product_price_discount',
    ];

    const groups = [
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
    ];

    yield put(removeFieldUserSetting(fields, groups));
    yield put(removeCouponBookingSuccess());
  }
}

function* setBankSlipPayment({ payload }) {
  const { checkout } = yield select();
  const { currentStep } = checkout;

  const { entityType } = payload;
  const bankSlipPayment = yield call(setBankSlipPaymentRequest, payload);

  if (bankSlipPayment.message) {
    const { errors, message } = bankSlipPayment;

    const { settings } = yield select();
    const { locale } = settings;
    const currentAppLocale = AppLocale[locale.locale];
    const intl = createIntl({
      locale: locale.locale,
      messages: currentAppLocale.messages,
    });

    yield put(
      setPaymentErrors({ errors: errors ? Object.values(errors) : message })
    );

    const err = errors ? Object.values(errors) : message;
    const errorsMessage = Array.isArray(err)
      ? err.map((error) => <li key={error}>{error[0]}</li>)
      : err?.message || err?.attrs?.message || err;

    return openNotificationWithIcon(
      'error',
      intl.formatMessage({ id: 'eCommerce.error.payment.title' }),
      <ul>{errorsMessage}</ul>,
      6
    );
  }

  if (bankSlipPayment?.attrs) {
    sessionStorage.setItem(
      'bank_slip_info',
      JSON.stringify(bankSlipPayment?.attrs?.response)
    );

    yield put(setCurrentStep(currentStep + 1));
    yield put(setBankSlipPaymentSuccess(bankSlipPayment?.attrs?.response));
    yield delay(2000);
  }

  try {
    const subscriptionId =
      bankSlipPayment?.attrs?.response?.transactions[0]?.subscription_id;

    if (entityType === 'plan' && subscriptionId)
      yield put(
        setUserSetting(
          `payment_method_${subscriptionId}`,
          'bank_slip',
          'subscription_payment'
        )
      );
  } catch (error) {
    console.log('🚀 console turbo --> error', error);
  }

  const { userSetting } = yield select();
  const { settings } = userSetting;

  const couponCode = settings?.payment?.coupon_code;

  if (couponCode) {
    const fields = [
      'coupon_code',
      'coupon_entity_id',
      'coupon_booking_id',
      'coupon_entity_type',
      'coupon_discount_value',
      'coupon_product_price_discount',
    ];

    const groups = [
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
    ];

    yield put(removeFieldUserSetting(fields, groups));
    yield put(removeCouponBookingSuccess());
  }
}

function* setCreditCardPayment({ payload }) {
  const { checkout } = yield select();
  const { currentStep } = checkout;

  const { entityType, cardId } = payload;
  const creditCardPayment = yield call(setCreditCardPaymentRequest, payload);

  try {
    const subscriptionId =
      creditCardPayment?.attrs?.transactions[0]?.subscription_id;

    if (entityType === 'plan' && subscriptionId)
      yield put(
        setUserSetting(
          `payment_method_${subscriptionId}`,
          cardId,
          'subscription_payment'
        )
      );
  } catch (error) {
    console.log('🚀 console turbo --> error', error);
  }

  if (creditCardPayment.message) {
    const { errors, message } = creditCardPayment;

    const { settings, pay } = yield select();
    const { locale } = settings;

    const currentAppLocale = AppLocale[locale.locale];
    const intl = createIntl({
      locale: locale.locale,
      messages: currentAppLocale.messages,
    });

    const { waitProcessing } = pay;

    if (!waitProcessing)
      yield put(
        setPaymentErrors({ errors: errors ? Object.values(errors) : message })
      );

    const err = errors ? Object.values(errors) : message;
    const errorsMessage = Array.isArray(err)
      ? err.map((error) => <li key={error}>{error[0]}</li>)
      : err?.message || err?.attrs?.message || err;

    if (!waitProcessing)
      return openNotificationWithIcon(
        'error',
        intl.formatMessage({ id: 'eCommerce.error.payment.title' }),
        <ul>{errorsMessage}</ul>,
        6
      );
  }

  if (creditCardPayment?.attrs) {
    sessionStorage.setItem(
      'credit_card_info',
      JSON.stringify(creditCardPayment?.attrs?.response)
    );
    sessionStorage.setItem('paid_product', true);

    yield put(setCurrentStep(currentStep + 1));
    yield put(setCreditCardPaymentSuccess(creditCardPayment?.attrs?.response));
  }

  const { userSetting } = yield select();
  const { settings } = userSetting;

  const couponCode = settings?.payment?.coupon_code;

  if (couponCode) {
    const fields = [
      'coupon_code',
      'coupon_entity_id',
      'coupon_booking_id',
      'coupon_entity_type',
      'coupon_discount_value',
      'coupon_product_price_discount',
    ];

    const groups = [
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
      'payment',
    ];

    yield put(removeFieldUserSetting(fields, groups));
    yield put(removeCouponBookingSuccess());
  }
}

export function* onPixPayment() {
  yield takeEvery(SET_PIX_PAYMENT, setPixPayment);
}

export function* onSetBankSlipPayment() {
  yield takeEvery(SET_BANK_SLIP_PAYMENT, setBankSlipPayment);
}

export function* onSetCreditCardPayment() {
  yield takeEvery(SET_CREDIT_CARD_PAYMENT, setCreditCardPayment);
}

export default function* rootSaga() {
  yield all([
    fork(onSetBankSlipPayment),
    fork(onSetCreditCardPayment),
    fork(onPixPayment),
  ]);
}
