import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchUserSettingSuccess,
  removeFieldUserSettingSuccess,
} from 'shared/providers/redux/actions';
import api from 'shared/infra/services/tenantAPI';

import {
  SET_USER_SETTING,
  GET_USER_SETTING,
  REMOVE_FIELD_USER_SETTING,
} from 'shared/providers/redux/actionTypes';

import urlConcatenator from '../../utils/urlConcatenator';

const setUserSettingRequest = async (payload) => {
  const { key, value, group } = payload;
  try {
    const response = await api.post('user/setting', { key, value, group });
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const getUserSettingRequest = async () => {
  try {
    const response = await api.get('user/setting');
    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

const removeFieldUserSettingRequest = async (payload) => {
  const { field, group } = payload;

  const url = 'user/setting';
  const finalUrl = urlConcatenator(url, field, group);

  try {
    const response = await api.delete(finalUrl);

    return response.data;
  } catch (error) {
    console.log('error -->', error);
  }
  return null;
};

function* setUserSetting({ payload }) {
  const newSettings = yield call(setUserSettingRequest, payload);
  if (newSettings.attrs) yield put(fetchUserSettingSuccess(newSettings.attrs));
}

function* getUserSetting() {
  const settings = yield call(getUserSettingRequest);
  if (settings.attrs) yield put(fetchUserSettingSuccess(settings.attrs));
}

function* removeFieldUserSetting({ payload }) {
  const response = yield call(removeFieldUserSettingRequest, payload);
  if (response.attrs) {
    yield put(fetchUserSettingSuccess(response.attrs));
    yield put(removeFieldUserSettingSuccess());
  }
}

export function* onSetUserSetting() {
  yield takeEvery(SET_USER_SETTING, setUserSetting);
}

export function* onGetUserSetting() {
  yield takeEvery(GET_USER_SETTING, getUserSetting);
}

export function* onRemoveFieldUserSetting() {
  yield takeLatest(REMOVE_FIELD_USER_SETTING, removeFieldUserSetting);
}

export default function* rootSaga() {
  yield all([
    fork(onSetUserSetting),
    fork(onGetUserSetting),
    fork(onRemoveFieldUserSetting),
  ]);
}
