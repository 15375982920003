import {
  FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS,
} from 'shared/providers/redux/actionTypes';

const INITIAL_STATE = {
  dashboardSpotlights: null,
  loaderSpotlights: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS: {
      return {
        ...state,
        loaderSpotlights: false,
        dashboardSpotlights: action.payload,
      };
    }

    default:
      return state;
  }
};
