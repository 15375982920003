import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  h1,
  span {
    text-align: center;
  }

  span.description {
    font-weight: normal;
    font-size: 1rem;
    line-height: 132.5%;
    text-align: center;
    letter-spacing: -0.5px;
    margin: 0px;
    color: #888888;
  }
`;
