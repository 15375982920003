import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  max-width: 600px;
  width: 100%;
  height: 100%;

  position: relative;
  padding: 0 3rem;
`;

export const Content = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 991px) {
    align-items: center;
  }

  .clear-button {
    cursor: pointer;
    height: 20px;
    width: 20%;
    background: none;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    font-size: 0.7rem;
    padding: 2px;
    margin-left: 1rem;

    @media (max-width: 375px) {
      margin-left: 0;
      border: none;
      margin-right: 2px;
    }
  }

  .clear-button:hover {
    transition: all 0.4s;
    transform: perspective(1px) scale(1.03);
  }
`;

export const SearchInput = styled.section`
  display: flex;
  align-items: center;

  height: 42px;
  z-index: 99;
  width: 100%;
  max-width: 32rem;
  font-size: 0.875rem;
  padding: 0.75rem 0rem 0.75rem 1rem;
  border-radius: 8px;

  svg.search-icon {
    font-size: 1.125rem;
    color: var(--themeTopbar-iconsColor);
    margin-right: 1rem;
  }

  @media (max-width: 991px) {
    align-self: center;
  }
`;

export const SearchBox = styled.input`
  width: 100%;

  padding: 0;
  outline: none;
  border: none;
  background: none;

  box-sizing: border-box;
  border-right: 1px solid;

  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: var(--themeTopbar-iconsColor);

  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.01));

  &::placeholder {
    color: var(--themeTopbar-iconsColor);
  }

  @media (max-width: 800px) {
    margin-right: 1rem;
  }
`;

export const InputSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
