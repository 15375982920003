// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const STYLES_CONTENT_COURSE = 'STYLES_CONTENT_COURSE';

export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const NAV_STYLE = 'NAV_STYLE';
export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';
export const NAV_STYLE_CHECKOUT_HEADER = 'NAV_STYLE_CHECKOUT_HEADER';

// Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const AUTHENTICATE_TOKEN = 'AUTHENTICATE_TOKEN';
export const AUTHENTICATE_TOKEN_SUCCESS = 'AUTHENTICATE_TOKEN_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGNUP_USER = 'SIGNUP_USER';
export const CONFIRM_USER_EMAIL = 'CONFIRM_USER_EMAIL';
export const CONFIRM_USER_EMAIL_SUCCESS = 'CONFIRM_USER_EMAIL_SUCCESS';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

// Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Tab
export const TAB_ACTIVE = 'TAB_ACTIVE';

// Bundle
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';
export const GET_SINGLE_BUNDLE = 'GET_SINGLE_BUNDLE';
export const GET_SINGLE_BUNDLE_SUCCESS = 'GET_SINGLE_BUNDLE_SUCCESS';
export const LOAD_MORE_BUNDLES = 'LOAD_MORE_BUNDLES';
export const LOAD_MORE_BUNDLES_SUCCESS = 'LOAD_MORE_BUNDLES_SUCCESS';
export const GET_BUNDLE_COURSES = 'GET_BUNDLE_COURSES';
export const GET_BUNDLE_COURSES_SUCCESS = 'GET_BUNDLE_COURSES_SUCCESS';
export const GET_BUNDLE_MEDIA_CONTENTS = 'GET_BUNDLE_MEDIA_CONTENTS';
export const GET_BUNDLE_MEDIA_CONTENTS_SUCCESS =
  'GET_BUNDLE_MEDIA_CONTENTS_SUCCESS';
export const FILTER_BUNDLE_BY = 'FILTER_BUNDLE_BY';
export const FILTER_BUNDLE_BY_SUCCESS = 'FILTER_BUNDLE_BY_SUCCESS';
export const REMOVE_BUNDLE_DATA = 'REMOVE_BUNDLE_DATA';

// Media Space
export const GET_MEDIA_SPACE_DATA = 'GET_MEDIA_SPACE_DATA';
export const FETCH_MEDIA_SPACE_DATA_SUCCESS = 'FETCH_MEDIA_SPACE_DATA_SUCCESS';
export const GET_MEDIA_SPACE_CONTENTS = 'GET_MEDIA_SPACE_CONTENTS';
export const FETCH_MEDIA_SPACE_CONTENTS_SUCCESS =
  'FETCH_MEDIA_SPACE_CONTENTS_SUCCESS';
export const GET_ALL_MEDIAS_FROM_AN_AUTHOR = 'GET_ALL_MEDIAS_FROM_AN_AUTHOR';
export const GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS =
  'GET_ALL_MEDIAS_FROM_AN_AUTHOR_SUCCESS';
export const GET_MEDIA_SPACE_CATEGORIES = 'GET_MEDIA_SPACE_CATEGORIES';
export const FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS =
  'FETCH_MEDIA_SPACE_CATEGORIES_SUCCESS';
export const SEARCH_FOR_MEDIA_CONTENTS = 'SEARCH_FOR_MEDIA_CONTENTS';
export const FILTER_MEDIA_SPACE_CONTENT_BY_AUTHOR =
  'FILTER_MEDIA_SPACE_CONTENT_BY_AUTHOR';
export const SEARCH_FOR_MEDIA_CONTENTS_SUCCESS =
  'SEARCH_FOR_MEDIA_CONTENTS_SUCCESS';
export const FILTER_CATEGORY_MEDIA_CONTENT_BY =
  'FILTER_CATEGORY_MEDIA_CONTENT_BY';
export const FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS =
  'FILTER_CATEGORY_MEDIA_CONTENT_BY_SUCCESS';
export const FILTER_MEDIA_CATEGORY_BY_AUTHOR =
  'FILTER_MEDIA_CATEGORY_BY_AUTHOR';
export const FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS =
  'FILTER_MEDIA_CATEGORY_BY_AUTHOR_SUCCESS';
export const GET_ALL_BASIC_CATEGORIES = 'GET_ALL_BASIC_CATEGORIES';
export const GET_ALL_BASIC_CATEGORIES_SUCCESS =
  'GET_ALL_BASIC_CATEGORIES_SUCCESS';

// Courses
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const GET_ALL_COURSES_FROM_AN_AUTHOR = 'GET_ALL_COURSES_FROM_AN_AUTHOR';
export const GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS =
  'GET_ALL_COURSES_FROM_AN_AUTHOR_SUCCESS';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const SET_NOT_FOUND_ON_COURSE_STATE = 'SET_NOT_FOUND_ON_COURSE_STATE';
export const CLEAR_NOT_FOUND_ON_COURSE_STATE =
  'CLEAR_NOT_FOUND_ON_COURSE_STATE';
export const FETCH_ALL_COURSES_SUCCESS = 'FETCH_ALL_COURSES_SUCCESS';
export const VISUALIZATION_MODE = 'VISUALIZATION_MODE';
export const UPDATE_PERCENTAGE = 'UPDATE_PERCENTAGE';
export const GET_COURSES_CATEGORIES = 'GET_COURSES_CATEGORIES';
export const GET_COURSES_CATEGORIES_SUCCESS = 'GET_COURSES_CATEGORIES_SUCCESS';
export const GET_COURSES_FROM_CATEGORY = 'GET_COURSES_FROM_CATEGORY';
export const GET_COURSES_FROM_CATEGORY_SUCCESS =
  'GET_COURSES_FROM_CATEGORY_SUCCESS';
export const GET_COURSES_BEING_WATCHED = 'GET_COURSES_BEING_WATCHED';
export const GET_COURSES_BEING_WATCHED_SUCCESS =
  'GET_COURSES_BEING_WATCHED_SUCCESS';
export const GET_FEATURED_COURSES = 'GET_FEATURED_COURSES';
export const GET_FEATURED_COURSES_SUCCESS = 'GET_FEATURED_COURSES_SUCCESS';
export const FILTER_CATEGORY_BY_NAME = 'FILTER_CATEGORY_BY_NAME';
export const FILTER_CATEGORY_BY_NAME_SUCCESS =
  'FILTER_CATEGORY_BY_NAME_SUCCESS';
export const FILTER_CATEGORY_COURSE_BY = 'FILTER_CATEGORY_COURSE_BY';
export const FILTER_CATEGORY_COURSE_BY_SUCCESS =
  'FILTER_CATEGORY_COURSE_BY_SUCCESS';
export const SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL =
  'SET_COMPLETE_COURSE_AFTER_CLOSE_MODAL';

// Module
export const GET_MODULES = 'GET_MODULES';
export const GET_MODULE_SESSION = 'GET_MODULE_SESSION';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_SESSION_SUCCESS = 'FETCH_MODULES_SESSION_SUCCESS';

// Lesson
export const GET_ALL_LESSONS = 'GET_ALL_LESSONS';
export const GET_LESSON = 'GET_LESSON';
export const GET_COMMENTS = 'GET_COMMENTS';
export const FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const SET_COMMENT = 'SET_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS';
export const SET_COMMENT_SUCCESS = 'SET_COMMENT_SUCCESS';
export const SET_COMMENT_SUCCESS_API = 'SET_COMMENT_SUCCESS_API';
export const SET_LESSON_ASSISTED = 'SET_LESSON_ASSISTED';
export const SET_LESSON_ASSISTED_SUCCESS = 'SET_LESSON_ASSISTED_SUCCESS';
export const SET_URL_LESSON = 'SET_URL_LESSON';
export const SET_URL_LESSON_SUCCESS = 'SET_URL_LESSON_SUCCESS';
export const SET_NOTE = 'SET_NOTE';
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS';
export const GET_INIT_LESSON = 'GET_INIT_LESSON';
export const GET_LIST_LESSON = 'GET_LIST_LESSON';
export const FETCH_INIT_LESSON_SUCCESS = 'FETCH_INIT_LESSON_SUCCESS';
export const FETCH_LIST_LESSON_SUCCESS = 'FETCH_LIST_LESSON_SUCCESS';
export const GET_NOTES = 'GET_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const REMOVE_NOTE = 'REMOVE_NOTE';
export const FETCH_ALL_LESSONS_SUCCESS = 'FETCH_ALL_LESSONS_SUCCESS';
export const SET_ASSISTED_LESSON = 'SET_ASSISTED_LESSON';
export const SET_LOADING_LAND_PAGE = 'SET_LOADING_LAND_PAGE';
export const FILTER_LESSONS = 'FILTER_LESSONS';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const SET_COMMENT_ID_REPLY = 'SET_COMMENT_ID_REPLY';
export const SET_COMMENT_LIKE = 'SET_COMMENT_LIKE';
export const SET_COMMENT_LIKE_SUCCESS = 'SET_COMMENT_LIKE_SUCCESS';
export const IS_LESSON_PAGE = 'IS_LESSON_PAGE';

// COMMENTS GLOBAL
export const GET_SINGLE_COMMENT = 'GET_SINGLE_COMMENT';
export const GET_SINGLE_COMMENT_SUCCESS = 'GET_SINGLE_COMMENT_SUCCESS';
export const LAST_COMMENTS_GLOBAL = 'LAST_COMMENTS_GLOBAL';
export const LAST_COMMENTS_GLOBAL_SUCCESS = 'LAST_COMMENTS_GLOBAL_SUCCESS';
export const STATUS_COMMENTS_GLOBAL = 'STATUS_COMMENTS_GLOBAL';
export const GET_LIKES_INFO_USERS = 'GET_LIKES_INFO_USERS';
export const FETCH_LIKES_INFO_USERS_SUCCESS = 'FETCH_LIKES_INFO_USERS_SUCCESS';
export const LIKE_DISLIKE_COMMENTS_GLOBAL = 'LIKE_DISLIKE_COMMENTS_GLOBAL';
export const SET_COMMENTS_GLOBAL = 'SET_COMMENTS_GLOBAL';
export const SET_COMMENTS_GLOBAL_STATE_SUCCESS =
  'SET_COMMENTS_GLOBAL_STATE_SUCCESS';
export const SET_COMMENTS_GLOBAL_API_SUCCESS =
  'SET_COMMENTS_GLOBAL_API_SUCCESS';
export const GET_COMMENTS_GLOBAL = 'GET_COMMENTS_GLOBAL';
export const FETCH_COMMENTS_GLOBAL_SUCCESS = 'FETCH_COMMENTS_GLOBAL_SUCCESS';
export const GET_COMMENTS_FEED_GLOBAL = 'GET_COMMENTS_FEED_GLOBAL';
export const SET_OLD_COMMENT_ID = 'SET_OLD_COMMENT_ID';
export const GET_COMMENTS_FEED_GLOBAL_SUCCESS =
  'GET_COMMENTS_FEED_GLOBAL_SUCCESS';
export const LOAD_COMMENTS_GLOBAL = 'LOAD_COMMENTS_GLOBAL';
export const LOAD_COMMENTS_GLOBAL_SUCCESS = 'LOAD_COMMENTS_GLOBAL_SUCCESS';
export const UPDATE_COMMENTS_GLOBAL = 'UPDATE_COMMENTS_GLOBAL';
export const UPDATE_COMMENTS_GLOBAL_SUCCESS = 'UPDATE_COMMENTS_GLOBAL_SUCCESS';
export const DELETE_COMMENTS_GLOBAL = 'DELETE_COMMENTS_GLOBAL';
export const DELETE_COMMENTS_GLOBAL_SUCCESS = 'DELETE_COMMENTS_GLOBAL_SUCCESS';
export const SET_COMMENTS_GLOBAL_REPLY_ID = 'SET_COMMENTS_GLOBAL_REPLY_ID';
export const GET_USER_COMMENTS = 'GET_USER_COMMENTS';
export const GET_USER_COMMENTS_SUCCESS = 'GET_USER_COMMENTS_SUCCESS';
export const SET_FOUND_COMMENT = 'SET_FOUND_COMMENT';

// REACT QUILL
export const SET_LOADER_IMAGE_QUILL = 'SET_LOADER_IMAGE_QUILL';

// PRODUCT
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_ALL_PRODUCTS_SUCCESS = 'FETCH_ALL_PRODUCTS_SUCCESS';

// SIDEBAR
export const SET_SIDEBAR_IS_COLLAPSED = 'SET_SIDEBAR_IS_COLLAPSED';
export const SIDEBAR_IS_RENDERED = 'SIDEBAR_IS_RENDERED';

// CATEGORY
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const GET_SINGLE_CATEGORY_SUCCESS = 'GET_SINGLE_CATEGORY_SUCCESS';
export const GET_CATEGORIES_ACTIVE_WITH_CONTENT =
  'GET_CATEGORIES_ACTIVE_WITH_CONTENT';
export const GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS =
  'GET_CATEGORIES_ACTIVE_WITH_CONTENT_SUCCESS';

// CART
export const SET_OPTIONS_FREIGHT = 'SET_OPTIONS_FREIGHT';
export const SET_OPTIONS_FREIGHT_SUCCESS = 'SET_OPTIONS_FREIGHT_SUCCESS';

// User
export const SET_PAGE = 'SET_PAGE';
export const SET_USERS_TO_MENTION = 'SET_USERS_TO_MENTION';
export const FETCH_USER_NOT_FOUND = 'FETCH_USER_NOT_FOUND';
export const UPDATE_DISPLAY_NAME = 'UPDATE_DISPLAY_NAME';
export const SET_USERNAME = 'SET_USERNAME';
export const CHECK_USERNAME = 'CHECK_USERNAME';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const UPDATE_COVER_IMAGE = 'UPDATE_COVER_IMAGE';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const DELETE_COVER_IMAGE = 'DELETE_COVER_IMAGE';
export const DELETE_PROFILE_IMAGE = 'DELETE_PROFILE_IMAGE';
export const UPDATE_BIOGRAPHY = 'UPDATE_BIOGRAPHY';
export const UPDATE_SITE = 'UPDATE_SITE';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FETCH_PASSWORD_STATUS_SUCCESS = 'FETCH_PASSWORD_STATUS_SUCCESS';
export const CLEAR_STATUS_PASSWORD = 'CLEAR_STATUS_PASSWORD';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_PUBLIC_EMAIL = 'UPDATE_PUBLIC_EMAIL';
export const UPDATE_PRIVATE_EMAIL = 'UPDATE_PRIVATE_EMAIL';
export const UPDATE_PUBLIC_PHONE = 'UPDATE_PUBLIC_PHONE';
export const UPDATE_PUBLIC_ADDRESS = 'UPDATE_PUBLIC_ADDRESS';
export const UPDATE_PRIVATE_PHONE = 'UPDATE_PRIVATE_PHONE';
export const CREATE_PRIVATE_PHONE = 'CREATE_PRIVATE_PHONE';
export const SET_USER_LINK = 'SET_USER_LINK';
export const UPDATE_USER_LINK = 'UPDATE_USER_LINK';
export const REMOVE_LINK = 'REMOVE_LINK';
export const REMOVE_LINK_SUCCESS = 'REMOVE_LINK_SUCCESS';
export const UPDATE_DISPLAY_NAME_SUCCESS = 'UPDATE_DISPLAY_NAME_SUCCESS';
export const SET_CONTENT_LENGTH = 'SET_CONTENT_LENGTH';
export const GET_USER = 'GET_USER';
export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER';
export const SET_PASSWORD_LENGTH = 'SET_PASSWORD_LENGTH';
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO';
export const FETCH_USER_PHOTO_SUCCESS = 'FETCH_USER_PHOTO_SUCCESS';
export const GET_INTERESTS = 'GET_INTERESTS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_DEVICES = 'GET_USER_DEVICES';
export const GET_USER_COUNTRIES = 'GET_USER_COUNTRIES';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const GET_ALL_AUTHORS = 'GET_ALL_AUTHORS';
export const GET_ALL_AUTHORS_SUCCESS = 'GET_ALL_AUTHORS_SUCCESS';
export const GET_ALL_NON_AUTHOR_USERS = 'GET_ALL_NON_AUTHOR_USERS';
export const GET_ALL_NON_AUTHOR_USERS_SUCCESS =
  'GET_ALL_NON_AUTHOR_USERS_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_LOGGED_USER_SUCCESS = 'FETCH_LOGGED_USER_SUCCESS';
export const FETCH_USER_LINK_SUCCESS = 'FETCH_USER_LINK_SUCCESS';
export const UPDATE_USER_LINK_SUCCESS = 'UPDATE_USER_LINK_SUCCESS';
export const FETCH_INTERESTS_SUCCESS = 'FETCH_INTERESTS_SUCCESS';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_USER_COUNTRIES_SUCCESS = 'FETCH_USER_COUNTRIES_SUCCESS';
export const GET_FOLLOWING = 'GET_FOLLOWING';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const FETCH_FOLLOWING_SUCCESS = 'FETCH_FOLLOWING_SUCCESS';
export const FETCH_FOLLOWERS_SUCCESS = 'FETCH_FOLLOWERS_SUCCESS';
export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const SET_IS_FOLLOWING_USER = 'SET_IS_FOLLOWING_USER';
export const GET_USER_MEDIA_SPACE_CONTENT = 'GET_USER_MEDIA_SPACE_CONTENT';
export const GET_USER_MEDIA_SPACE_CONTENT_SUCCESS =
  'GET_USER_MEDIA_SPACE_CONTENT_SUCCESS';
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';
export const LAST_TOPICS_USER = 'LAST_TOPICS_USER';
export const LAST_TOPICS_USER_SUCCESS = 'LAST_TOPICS_USER_SUCCESS';
export const GET_USER_COLLECTION = 'GET_USER_COLLECTION';
export const GET_USER_COLLECTION_SUCCESS = 'GET_USER_COLLECTION_SUCCESS';
export const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL';
export const CONFIRMATION_EMAIL_STATUS = 'CONFIRMATION_EMAIL_STATUS';

// Theme

export const SET_THEME_MODE = 'SET_THEME_MODE';

// Checkout
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_POST_OFFICES = 'SET_POST_OFFICES';
export const SET_CHECKOUT_SESSION = 'SET_CHECKOUT_SESSION';
export const SIGNIN_USER_CHECKOUT = 'SIGNIN_USER_CHECKOUT';
export const SET_RESET_SIGN_UP_FIELDS = 'SET_RESET_SIGN_UP_FIELDS';
export const SET_INFO_INSTALLMENT = 'SET_INFO_INSTALLMENT';
export const SIGNIN_USER_CHECKOUT_ERROR = 'SIGNIN_USER_CHECKOUT_ERROR';
export const SIGNIN_USER_CHECKOUT_SUCCESS = 'SIGNIN_USER_CHECKOUT_SUCCESS';
export const SET_CHECKOUT_LOADER_TRUE = 'SET_CHECKOUT_LOADER_TRUE';
export const SET_CHECKOUT_LOADER_FALSE = 'SET_CHECKOUT_LOADER_FALSE';

// Register Method Payment
export const GET_ALL_CREDIT_CARDS = 'GET_ALL_CREDIT_CARDS';
export const FETCH_ALL_CREDIT_CARDS_SUCCESS = 'FETCH_ALL_CREDIT_CARDS_SUCCESS';
export const SET_CREDIT_CARD = 'SET_CREDIT_CARD';
export const SET_ID_CREDIT_CARD = 'SET_ID_CREDIT_CARD';
export const SET_CREDIT_CARD_SUCCESS = 'SET_CREDIT_CARD_SUCCESS';
export const SET_CREDIT_CARD_ERROR = 'SET_CREDIT_CARD_ERROR';
export const REMOVE_CREDIT_CARD = 'REMOVE_CREDIT_CARD';
export const REMOVE_CREDIT_CARD_SUCCESS = 'REMOVE_CREDIT_CARD_SUCCESS';
export const SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD =
  'SET_IS_VISIBLE_FORM_NEW_CREDIT_CARD';

// Order
export const GET_ALL_PAY_ORDERS = 'GET_ALL_PAY_ORDERS';
export const FETCH_ALL_PAY_ORDERS_SUCCESS = 'FETCH_ALL_PAY_ORDERS_SUCCESS';
export const GET_SESSION_PAY_ORDER = 'GET_SESSION_PAY_ORDER';
export const FETCH_SESSION_PAY_ORDER_SUCCESS =
  'FETCH_SESSION_PAY_ORDER_SUCCESS';

// Pay
export const SET_PIX_PAYMENT = 'SET_PIX_PAYMENT';
export const SET_PIX_PAYMENT_SUCCESS = 'SET_PIX_PAYMENT_SUCCESS';

export const SET_CREDIT_CARD_PAYMENT = 'SET_CREDIT_CARD_PAYMENT';
export const SET_CREDIT_CARD_PAYMENT_SUCCESS =
  'SET_CREDIT_CARD_PAYMENT_SUCCESS';
export const SET_BANK_SLIP_PAYMENT = 'SET_BANK_SLIP_PAYMENT';
export const SET_BANK_SLIP_PAYMENT_SUCCESS = 'SET_BANK_SLIP_PAYMENT_SUCCESS';
export const SET_PAYMENT_ERRORS = 'SET_PAYMENT_ERRORS';

export const SET_PAYMENT_OPTIONS_MODAL_OPEN = 'SET_PAYMENT_OPTIONS_MODAL_OPEN';
export const CLEAR_PURCHASE_OPTIONS = 'CLEAR_PURCHASE_OPTIONS';

// User Settings
export const GET_USER_SETTING = 'GET_USER_SETTING';
export const SET_USER_SETTING = 'SET_USER_SETTING';
export const FETCH_USER_SETTING_SUCCESS = 'FETCH_USER_SETTING_SUCCESS';
export const REMOVE_FIELD_USER_SETTING = 'REMOVE_FIELD_USER_SETTING';
export const REMOVE_FIELD_USER_SETTING_SUCCESS =
  'REMOVE_FIELD_USER_SETTING_SUCCESS';
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';

// Address
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_ADDRESS_ERROR = 'SET_ADDRESS_ERROR';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const SET_ADDRESS_SUCCESS = 'SET_ADDRESS_SUCCESS';
export const SET_ADDRESS_SUCCESS_API = 'SET_ADDRESS_SUCCESS_API';
export const SET_ADDRESS_EDITING = 'SET_ADDRESS_EDITING';
export const SHOW_BUTTON_RELOAD = 'SHOW_BUTTON_RELOAD';
export const GET_ALL_ADDRESS = 'GET_ALL_ADDRESS';
export const FETCH_ALL_ADDRESS_SUCCESS = 'FETCH_ALL_ADDRESS_SUCCESS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const GET_ADDRESS_BRASIL_API = 'GET_ADDRESS_BRASIL_API';
export const FETCH_ADDRESS_BRASIL_API_SUCCESS =
  'FETCH_ADDRESS_BRASIL_API_SUCCESS';
export const UPDATE_ADDRESS_SESSION = 'UPDATE_ADDRESS_SESSION';
export const UPDATE_ADDRESS_SESSION_SUCCESS = 'UPDATE_ADDRESS_SESSION_SUCCESS';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const REMOVE_ADDRESS_SUCCESS = 'REMOVE_ADDRESS_SUCCESS';

// Tenancy
export const GET_TENANT_DATA = 'GET_TENANT_DATA';
export const GET_TENANT_DATA_SUCCESS = 'GET_TENANT_DATA_SUCCESS';

// GAMIFICATION
export const GET_DATA = 'GET_DATA';

// DYNAMIC MENU
export const GET_MAIN_MENU = 'GET_MAIN_MENU';
export const GET_PROFILE_MENU = 'GET_PROFILE_MENU';
export const GET_FOOTER_MENU = 'GET_FOOTER_MENU';
export const GET_MENU_SHORTCUTS = 'GET_MENU_SHORTCUTS';
export const FETCH_MAIN_MENU_SUCCESS = 'FETCH_MAIN_MENU_SUCCESS';
export const FETCH_PROFILE_MENU_SUCCESS = 'FETCH_PROFILE_MENU_SUCCESS';
export const FETCH_FOOTER_MENU_SUCCESS = 'FETCH_FOOTER_MENU_SUCCESS';
export const FETCH_MENU_SHORTCUTS_SUCCESS = 'FETCH_MENU_SHORTCUTS_SUCCESS';

// DYNAMIC TODO
export const GET_TODO = 'GET_TODO';
export const FETCH_DYNAMIC_TODO_SUCCESS = 'FETCH_DYNAMIC_TODO_SUCCESS';

// DYNAMIC BLOG
export const GET_BLOG = 'GET_BLOG';
export const GET_SINGLE_BLOG = 'GET_SINGLE_BLOG';
export const GET_SINGLE_BLOG_SUCCESS = 'GET_SINGLE_BLOG_SUCCESS';
export const GET_SINGLE_BLOG_FAILED = 'GET_SINGLE_BLOG_FAILED';
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_FEATURED_BLOG = 'GET_FEATURED_BLOG';
export const GET_FEATURED_BLOG_SUCCESS = 'GET_FEATURED_BLOG_SUCCESS';
export const GET_BLOG_COMMENTS = 'GET_BLOG_COMMENTS';
export const GET_BLOG_COMMENTS_SUCCESS = 'GET_BLOG_COMMENTS_SUCCESS';
export const SET_LIKE_BLOG = 'SET_LIKE_BLOG';
export const SET_DISLIKE_BLOG = 'SET_DISLIKE_BLOG';
export const FETCH_DYNAMIC_BLOG_SUCCESS = 'FETCH_DYNAMIC_BLOG_SUCCESS';

// DENOUNCE
export const SET_DENOUNCE = 'SET_DENOUNCE';
export const SET_DENOUNCE_SUCCESS = 'SET_DENOUNCE_SUCCESS';
export const CHANGE_DENOUNCE_SUCCESS_STATE = 'CHANGE_DENOUNCE_SUCCESS_STATE';

// REVIEWS
export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const CREATE_REVIEW = 'CREATE_REVIEW';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const CLEANING_NEWLY_CREATED_REVIEWS = 'CLEANING_NEWLY_CREATED_REVIEWS';
export const CHANGE_DELETED_REVIEW_STATE = 'CHANGE_DELETED_REVIEW_STATE';
export const CHANGE_REVIEW_SUCCESS_STATE = 'CHANGE_REVIEW_SUCCESS_STATE';

// COMMUNITY
export const GET_COMMUNITY_CATEGORIES = 'GET_COMMUNITY_CATEGORIES';
export const FETCH_COMMUNITY_CATEGORIES_SUCCESS =
  'FETCH_COMMUNITY_CATEGORIES_SUCCESS';
export const GET_COMMUNITY_TOPICS = 'GET_COMMUNITY_TOPICS';
export const FETCH_COMMUNITY_TOPICS_SUCCESS = 'FETCH_COMMUNITY_TOPICS_SUCCESS';
export const GET_COMMUNITY_LAST_TOPICS = 'GET_COMMUNITY_LAST_TOPICS';
export const FETCH_COMMUNITY_LAST_TOPICS_SUCCESS =
  'FETCH_COMMUNITY_LAST_TOPICS_SUCCESS';
export const GET_COMMUNITY_LAST_COMMENTS = 'GET_COMMUNITY_LAST_COMMENTS';
export const FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS =
  'FETCH_COMMUNITY_LAST_COMMENTS_SUCCESS';
export const GET_COMMUNITY_AUTHORS = 'GET_COMMUNITY_AUTHORS';
export const FETCH_COMMUNITY_AUTHORS_SUCCESS =
  'FETCH_COMMUNITY_AUTHORS_SUCCESS';

export const SET_COMMUNITY_TOPIC = 'SET_COMMUNITY_TOPIC';
export const SET_COMMUNITY_TOPIC_SUCCESS = 'SET_COMMUNITY_TOPIC_SUCCESS';
export const READ_COMMUNITY_TOPIC = 'READ_COMMUNITY_TOPIC';
export const READ_COMMUNITY_TOPIC_SUCCESS = 'READ_COMMUNITY_TOPIC_SUCCESS';
export const UPDATE_COMMUNITY_TOPIC = 'UPDATE_COMMUNITY_TOPIC';
export const UPDATE_COMMUNITY_TOPIC_SUCCESS = 'UPDATE_COMMUNITY_TOPIC_SUCCESS';
export const DELETE_COMMUNITY_TOPIC = 'DELETE_COMMUNITY_TOPIC';
export const DELETE_COMMUNITY_TOPIC_SUCCESS = 'DELETE_COMMUNITY_TOPIC_SUCCESS';

export const PIN_COMMUNITY_TOPIC = 'PIN_COMMUNITY_TOPIC';
export const LIKE_DISLIKE_COMMUNITY_TOPIC = 'LIKE_DISLIKE_COMMUNITY_TOPIC';
export const LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS =
  'LIKE_DISLIKE_COMMUNITY_TOPIC_SUCCESS';
export const SHOW_ENROLLED_USERS_PERMISSION = 'SHOW_ENROLLED_USERS_PERMISSION';

// COLLECTIONS
export const GET_ALL_COLLECTIONS = 'GET_ALL_COLLECTIONS';
export const FETCH_ALL_COLLECTIONS_SUCCESS = 'FETCH_ALL_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTION_SESSION_SUCCESS =
  'FETCH_COLLECTION_SESSION_SUCCESS';
export const SET_COLLECTION = 'SET_COLLECTION';
export const SET_COLLECTION_POSITION = 'SET_COLLECTION_POSITION';
export const SET_COLLECTION_ITEM_POSITION = 'SET_COLLECTION_ITEM_POSITION';
export const SET_PRIVATE_COLLECTION = 'SET_PRIVATE_COLLECTION';
export const SET_COLLECTION_SESSION = 'SET_COLLECTION_SESSION';
export const SET_COLLECTION_SEARCH = 'SET_COLLECTION_SEARCH';
export const SET_COLLECTION_ITEM_SEARCH = 'SET_COLLECTION_ITEM_SEARCH';
export const SET_COLLECTION_TYPE = 'SET_COLLECTION_TYPE';
export const SET_ITEMS_COLLECTIONS = 'SET_ITEMS_COLLECTIONS';
export const SET_ITEMS_COLLECTIONS_SUCCESS = 'SET_ITEMS_COLLECTIONS_SUCCESS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const REMOVE_ITEM_COLLECTIONS = 'REMOVE_ITEM_COLLECTIONS';
export const REMOVE_ITEM_COLLECTIONS_SUCCESS =
  'REMOVE_ITEM_COLLECTIONS_SUCCESS';
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
export const CLEAN_RESOURCES_POSITION = 'CLEAN_RESOURCES_POSITION';
export const LOAD_MORE_COLLECTION = 'LOAD_MORE_COLLECTION';
export const LOAD_MORE_COLLECTION_SUCCESS = 'LOAD_MORE_COLLECTION_SUCCESS';
export const LOAD_MORE_COLLECTION_ITEMS = 'LOAD_MORE_COLLECTION_ITEMS';
export const LOAD_MORE_COLLECTION_ITEMS_SUCCESS =
  'LOAD_MORE_COLLECTION_ITEMS_SUCCESS';

// PAGE
export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';

// SEARCH
export const SET_SEARCH_LESSONS_MODULES = 'SET_SEARCH_LESSONS_MODULES';
export const FETCH_SEARCH_LESSONS_MODULES_SUCCESS =
  'FETCH_SEARCH_LESSONS_MODULES_SUCCESS';

// NOTIFICATIONS
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_TOAST_NOTIFICATIONS = 'SET_TOAST_NOTIFICATIONS';
export const SET_LAST_TOAST_NOTIFICATIONS = 'SET_LAST_TOAST_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const MARK_READ_NOTIFICATION_ALL = 'MARK_READ_NOTIFICATION_ALL';
export const MARK_READ_NOTIFICATION_UNIQUE = 'MARK_READ_NOTIFICATION_UNIQUE';
export const MARK_UNVIEWED_NOTIFICATION = 'MARK_UNVIEWED_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const REMOVE_UNIQUE_NOTIFICATIONS = 'REMOVE_UNIQUE_NOTIFICATIONS';
export const PUST_NOTIFICATION_REAL_TIME = 'PUST_NOTIFICATION_REAL_TIME';

// CUSTOM SCRIPTS
export const GET_CUSTOM_SCRIPTS = 'GET_CUSTOM_SCRIPTS';
export const GET_CUSTOM_SCRIPTS_SUCCESS = 'GET_CUSTOM_SCRIPTS_SUCCESS';

// USER COMMUNITY
export const FILTER_USERS_COMMUNITY = 'FILTER_USERS_COMMUNITY';

// WARNING
export const SET_COMMING_SOON = 'SET_COMMING_SOON';
export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const SET_LOGING_MODAL = 'SET_LOGING_MODAL';
export const SET_FORGOT_MODAL = 'SET_FORGOT_MODAL';
export const DOES_NOT_SUPPORT_OBSERVER = 'DOES_NOT_SUPPORT_OBSERVER';

// PLANS
export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_ENTITY_PLANS = 'FETCH_ENTITY_PLANS';
export const FETCH_ENTITY_PLANS_SUCCESS = 'FETCH_ENTITY_PLANS_SUCCESS';

// FILTER
export const GET_FILTER = 'GET_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS';

// SUBSCRIPTIONS
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTION_SESSION = 'GET_SUBSCRIPTION_SESSION';
export const GET_SUBSCRIPTION_CANCELLATION_HISTORY =
  'GET_SUBSCRIPTION_CANCELLATION_HISTORY';
export const GET_CANCELLATION_STATUS = 'GET_CANCELLATION_STATUS';
export const SET_SUBSCRIPTION_CANCELLATION = 'SET_SUBSCRIPTION_CANCELLATION';
export const SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS =
  'SET_CREDIT_CARD_ON_SUBSCRIPTION_OR_ACCOUNT_SUCCESS';
export const SET_OPEN_MODAL_REGISTER_CREDIT_CARD =
  'SET_OPEN_MODAL_REGISTER_CREDIT_CARD';
export const SET_CURRENT_STEP_REGISTER_CREDIT_CARD =
  'SET_CURRENT_STEP_REGISTER_CREDIT_CARD';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTION_SESSION_SUCCESS =
  'FETCH_SUBSCRIPTION_SESSION_SUCCESS';
export const FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS =
  'FETCH_SUBSCRIPTION_CANCELLATION_HISTORY_SUCCESS';
export const FETCH_CANCELLATION_STATUS_SUCCESS =
  'FETCH_CANCELLATION_STATUS_SUCCESS';
export const FILTER_SUBSCRIPTIONS_BY_NAME = 'FILTER_SUBSCRIPTIONS_BY_NAME';
export const FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS =
  'FILTER_SUBSCRIPTIONS_BY_NAME_SUCCESS';

// SPOTLIGHTS
export const GET_DASHBOARD_SPOTLIGHTS = 'GET_DASHBOARD_SPOTLIGHTS';
export const FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS =
  'FETCH_DASHBOARD_SPOTLIGHTS_SUCCESS';

// ENROLLMENTS
export const START_ENROLLMENT = 'START_ENROLLMENT';
export const START_ENROLLMENT_SUCCESS = 'START_ENROLLMENT_SUCCESS';
export const CLEAN_STARTED_ENROLLMENT = 'CLEAN_STARTED_ENROLLMENT';
export const CLEAN_NEWLY_CREATED_ENROLLMENT = 'CLEAN_NEWLY_CREATED_ENROLLMENT';

// COUPON
export const SET_COUPON = 'SET_COUPON';
export const SET_COUPON_SUCCESS = 'SET_COUPON_SUCCESS';
export const REMOVE_COUPON_BOOKING = 'REMOVE_COUPON_BOOKING';
export const REMOVE_COUPON_BOOKING_ERROR = 'REMOVE_COUPON_BOOKING_ERROR';
export const REMOVE_COUPON_BOOKING_SUCCESS = 'REMOVE_COUPON_BOOKING_SUCCESS';

// CHECKOUT METRICS
export const STARTING_PURCHASE_PROCESS = 'STARTING_PURCHASE_PROCESS';
export const SET_METRICS_ID = 'SET_METRICS_ID';
export const FINISHING_PURCHASE_PROCESS = 'FINISHING_PURCHASE_PROCESS';

// GLOBAL SEARCH
export const ON_SEARCH_GLOBALLY = 'ON_SEARCH_GLOBALLY';
export const ON_SEARCH_GLOBALLY_SUCCESS = 'ON_SEARCH_GLOBALLY_SUCCESS';
export const SET_MODEL_CLASS = 'SET_MODEL_CLASS';

// CONTINUE WATCHING
export const GET_COURSES_KEEP_WATCHING = 'GET_COURSES_KEEP_WATCHING';
export const GET_COURSES_KEEP_WATCHING_SUCCESS =
  'GET_COURSES_KEEP_WATCHING_SUCESS';

// CERTIFICATE
export const GET_CERTIFICATE_INFO = 'GET_CERTIFICATE_INFO';
export const FETCH_CERTIFICATE_INFO_SUCCESS = 'FETCH_CERTIFICATE_INFO_SUCCESS';
export const GET_CERTIFICATE_VALIDATION = 'GET_CERTIFICATE_VALIDATION';
export const FETCH_CERTIFICATE_VALIDATION_SUCCESS =
  'FETCH_CERTIFICATE_VALIDATION_SUCCESS';
