import styled, { css } from 'styled-components';

export const BackgroundBlur = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  z-index: 1001;

  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  max-width: 457px;
  padding: 0 20px;

  position: absolute;
  z-index: 1002;
  bottom: 20%;

  animation: appearFromBottom 0.5s;

  .option-card {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    margin-top: 12px;
    padding: 15px 20px;
    position: relative;
    align-items: center;
    border-radius: 10px;
    animation: appearFromLeft 1s;
    justify-content: space-between;
    transition: all ease-in-out 0.3s;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    ${(props) =>
      props?.isDarkMode
        ? css`
            background: #242424;

            .option-info .badge {
              color: #1a1a1a;
            }

            .option-info h5 {
              color: #ffffff;
            }
          `
        : css`
            background: #ffffff;

            .badge {
              color: #ffffff;
            }

            .option-info h5 {
              color: #1a1a1a;
            }
          `}

    &:hover {
      transform: scale(1.03);
    }

    &:nth-child(1) {
      animation: appearFromLeft 0.2s;
    }

    &:nth-child(2) {
      animation: appearFromLeft 0.4s;
    }

    &:nth-child(3) {
      animation: appearFromLeft 0.6s;
    }

    &:nth-child(4) {
      animation: appearFromLeft 0.8s;
    }

    &:nth-child(5) {
      animation: appearFromLeft 1s;
    }

    .badge {
      top: 0;
      right: 0;
      font-size: 13px;
      padding: 3px 7px;
      position: absolute;
      border-radius: 0 0 0 9px;
    }

    .green {
      background: #00a757;
    }

    .blue {
      background: #0090ce;
    }

    .option-info {
      display: flex;
      flex-direction: column;

      h5 {
        margin: 0 0 3px;
        font-size: 23px;
        font-weight: 500 !important;
        text-align: left !important;
        letter-spacing: -1px;
      }

      span {
        margin: 0;
        color: #aaa;
        font-size: 15.5px;
      }

      .p-opt-font-size {
        font-size: 13px;
        margin-top: 5px;
      }
    }

    .icon-container {
      width: 30px;
      height: 30px;
      margin-top: 22px;
      margin-right: -3px;
    }
  }

  @keyframes appearFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes appearFromBottom {
    0% {
      opacity: 0;
      transform: translateY(-50%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
