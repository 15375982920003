import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  margin-top: 70px;
  padding: 40px;
  width: 600px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;

  ${(props) =>
    props.isDarkMode
      ? css`
          background: #303030;

          strong {
            color: #eeeeee;
          }

          button {
            border: none !important;
          }
        `
      : css`
          background: #ffffff;

          strong {
            color: #606060;
          }
        `}

  strong {
    padding: 20px 0;
    font-size: 25px;
    font-weight: 600;
    line-height: 122%;
    text-align: center;
    letter-spacing: -0.340547px;
  }

  button {
    margin: 0;
  }

  .ant-btn-primary span {
    color: ${(props) =>
      props.$isDarkMode
        ? props?.$generalTheme?.themeDarkModeTextMasterColor
        : props?.$generalTheme?.themeTextMasterColor} !important;
  }

  @media screen and (max-width: 380px) {
    .ant-btn:not(:last-child) {
      margin: 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        margin-top: 10px;
      }
    }
  }
`;
